import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowPointerTransparent } from '../../../assets/imgComponent/IconArrows';
import IconInfo from '../../../assets/imgComponent/IconInfo';
import { LEADERBOARD_TYPES } from '../../../constants/userProfile';
import { getCurrentUser } from '../../../store/selectors/authSelectors';
import { getLeaderboardData } from '../../../store/selectors/studySelectors';
import { setModal, setModalType } from '../../../store/slices/modal';
import { MODAL_TYPES } from '../../../types/modals';
import { ILeaderboardItem, TLeague } from '../../../types/study';
import UserAvatarImage from '../../moleculs/UserAvatarImage';
import styles from './leadership-board.module.scss';

interface LeadershipBoardProps {
  currentLeagueData?: Array<ILeaderboardItem>;
  currentLeague: TLeague;
  leftDays: number;
}

const LeadershipBoard: React.FC<LeadershipBoardProps> = ({
  currentLeagueData,
  currentLeague,
  leftDays,
}) => {
  const { id: currentUserId } = useSelector(getCurrentUser);
  const allLeaderBoardData = useSelector(getLeaderboardData);
  const dispatch = useDispatch();

  const handleInfoClick = () => {
    dispatch(setModal(true));
    dispatch(setModalType(MODAL_TYPES.leaderboardInfo));
  };

  return (
    <section className={styles.leadershipBoardWrapper}>
      <header>
        <h2>
          <span>{currentLeague}</span>
        </h2>
        {currentLeague !== 'Total Points Champion' && (
          <p className={styles.leftDays}>
            {leftDays} day{leftDays > 1 ? 's' : ''} left
            <span className={styles.infoIcon} onClick={handleInfoClick}>
              <IconInfo />
            </span>
          </p>
        )}
      </header>
      {currentLeagueData?.length ? (
        <ul className={styles.leadershipList}>
          {currentLeagueData.map((user) => {
            const pointType: 'total_points' | 'weekly_points' =
              allLeaderBoardData?.leagues[LEADERBOARD_TYPES[currentLeague]].type || 'weekly_points';
            return (
              <li
                key={user.id}
                className={`${currentUserId == user.id ? styles.currentUser : ''} ${
                  user.transition ? styles[user.transition] : ''
                }`}
              >
                <p className={styles.leadershipListPlace}>{user.position}</p>
                <div className={styles.leadershipListUser}>
                  <UserAvatarImage imgSrc={user.avatar} />
                  <span>{user.name}</span>
                </div>
                <div className={`${styles.leadershipListScore}`}>
                  {user.transition && <ArrowPointerTransparent />}
                  <p className={`${user.transition ? styles[user.transition] : ''}`}>
                    {user[pointType]}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className={styles.emptyLeagueText}>There are no members in this league yet.</p>
      )}

      {/*<li className={`${styles.currentUser} ${styles.scoreHigh}`}>*/}
      {/*  <p className={styles.leadershipListPlace}>6</p>*/}
      {/*  <div className={styles.leadershipListUser}>*/}
      {/*    <UserAvatarImage />*/}
      {/*    <span>{'firstName'}</span>*/}
      {/*  </div>*/}
      {/*  <p className={styles.leadershipListScore}>200</p>*/}
      {/*</li>*/}

      {/*<li className={`${styles.currentUser} ${styles.scoreLow}`}>*/}
      {/*  <p className={styles.leadershipListPlace}>6</p>*/}
      {/*  <div className={styles.leadershipListUser}>*/}
      {/*    <UserAvatarImage />*/}
      {/*    <span>{firstName}</span>*/}
      {/*  </div>*/}
      {/*  <p className={styles.leadershipListScore}>200</p>*/}
      {/*</li>*/}

      {/*<li className={`${styles.leadershipBoardNotify} ${styles.notifyLevelUp}`}>*/}
      {/*  <ArrowPointerTransparent />*/}
      {/*  <p>LEVEL UP</p>*/}
      {/*  <ArrowPointerTransparent />*/}
      {/*</li>*/}

      {/*<li className={`${styles.leadershipBoardNotify} ${styles.notifyDropDown}`}>*/}
      {/*  <ArrowPointerTransparent />*/}
      {/*  <p>DROP DOWN</p>*/}
      {/*  <ArrowPointerTransparent />*/}
      {/*</li>*/}
    </section>
  );
};

export default LeadershipBoard;
