export const MODAL_TYPES = {
  userProfile: 'userProfile',
  deleteCourse: 'deleteCourse',
  resetCourse: 'resetCourse',
  selectCourse: 'selectCourse',
  congratulation: 'congratulation',
  wellDone: 'wellDone',
  billing: 'billing',
  billingSuccess: 'billingSuccess',
  billingCancel: 'billingCancel',
  cancelSubscription: 'cancelSubscription',
  updatePlan: 'updatePlan',
  successfulCancellation: 'successfulCancellation',
  statisticsInfo: 'statisticsInfo',
  pointsInfo: 'pointsInfo',
  leaderboardInfo: 'leaderboardInfo',
  leaderboardUserInfo: 'leaderboardUserInfo',
};

export interface ModalType {
  title: string;
  component: any;
  className: any;
}
