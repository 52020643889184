import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUserImg } from '../../../store/selectors/authSelectors';
import DefaultUserPhoto from '../../atoms/ModalUserPhoto/DefaultUserPhoto';
import styles from './user-avatar.module.scss';

interface UserAvatarImageProps {
  imgSrc?: string;
  icClickAble?: boolean;
}
const UserAvatarImage: React.FC<UserAvatarImageProps> = ({ imgSrc, icClickAble }) => {
  const userPhoto = imgSrc || useSelector(getUserImg);
  const navigate = useNavigate();

  const handleClick = () => {
    if (icClickAble) {
      navigate('/profile');
    }
  };
  return (
    <div className={styles.avatarImage} onClick={handleClick}>
      {userPhoto ? <img src={userPhoto} alt="" /> : <DefaultUserPhoto />}
    </div>
  );
};

export default UserAvatarImage;
