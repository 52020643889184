import React from 'react';
import { useDispatch } from 'react-redux';

import Icon from '../../../../assets/images/onboard-step04-mob.png';
import { closeModal } from '../../../../utils/modal';
import Button from '../../../atoms/Button';
import styles from './info_modal.module.scss';

const LeaderboardInfo: React.FC = () => {
  const dispatch = useDispatch();

  const handleBtnClick = () => {
    closeModal(dispatch);
  };
  return (
    <>
      <div className={styles.leaderboardContentWrapper}>
        <div className={styles.leaderboardContentImage}>
          <img src={Icon} alt={'leaderboardIcon'} />
        </div>
        <p className={styles.leaderboardContentText}>
          Each league is based on the number of dedication points earned during the week and is
          recalculated each Sunday night at midnight (00:00 UTC). At the end of the week, the top
          five users in each league will move up a league, and the bottom five users will move down
          a league.
        </p>
      </div>
      <Button onClick={handleBtnClick} type={'primary'} value={'Continue'} />
    </>
  );
};

export default LeaderboardInfo;
