import { createSlice } from '@reduxjs/toolkit';

import { IModal } from '../types';

const initialState: IModal = {
  isOpen: false,
  type: '',
  isLoading: false,
  currentPayment: {
    month: 0,
    id: '',
  },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action) => {
      return {
        ...state,
        isOpen: action.payload,
      };
    },
    setModalType: (state, action) => {
      return {
        ...state,
        type: action.payload,
      };
    },
    setIsModalLoading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setCurrentPayment: (state, action) => {
      return {
        ...state,
        currentPayment: { ...action.payload },
      };
    },
  },
});

export const { setModal, setModalType, setIsModalLoading, setCurrentPayment } = modalSlice.actions;

export default modalSlice.reducer;
