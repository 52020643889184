import React from 'react';

const IconSound: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_683_12612)">
        <path
          d="M15.0004 9.99958C15.0004 8.03849 13.8671 6.34401 12.2227 5.52734V14.4773C13.8671 13.6552 15.0004 11.9607 15.0004 9.99958Z"
          fill="white"
        />
        <path d="M0 6.66495V13.3316H4.44443L10 18.8872V1.10938L4.44443 6.66495H0Z" fill="white" />
        <path
          d="M12.2227 0.257812V2.55224C15.4338 3.50781 17.7782 6.48 17.7782 10.0022C17.7782 13.5245 15.4338 16.4967 12.2227 17.4522V19.7467C16.6727 18.7356 20.0004 14.7633 20.0004 10.0022C20.0004 5.24115 16.6727 1.26891 12.2227 0.257812Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_683_12612">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSound;
