import React from 'react';

const IconAssessment: React.FC = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_2_7778)">
        <path
          d="M9.77759 14.6666C9.77759 10.6166 13.0608 7.33331 17.1109 7.33331H70.8887C74.9388 7.33331 78.222 10.6166 78.222 14.6666V80.6666C78.222 84.7167 74.9388 88 70.8887 88H17.1109C13.0608 88 9.77759 84.7167 9.77759 80.6666V14.6666Z"
          fill="url(#paint0_linear_2_7778)"
        />
      </g>
      <g filter="url(#filter1_d_2_7778)">
        <path
          d="M70.8889 17.1111C70.8889 15.7611 69.7945 14.6667 68.4444 14.6667H19.5555C18.2055 14.6667 17.1111 15.7611 17.1111 17.1111V78.2223C17.1111 79.5723 18.2055 80.6667 19.5555 80.6667H68.4444C69.7945 80.6667 70.8889 79.5723 70.8889 78.2222V17.1111Z"
          fill="#F0F8FF"
        />
      </g>
      <g filter="url(#filter2_i_2_7778)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6477 3.66667C38.9156 1.47473 41.2856 0 43.9999 0C46.7143 0 49.0842 1.47473 50.3522 3.66667H57.4444C60.1444 3.66667 62.3333 5.8555 62.3333 8.55556V13.4444C62.3333 16.1445 60.1444 18.3333 57.4444 18.3333H30.5555C27.8554 18.3333 25.6666 16.1445 25.6666 13.4444V8.55555C25.6666 5.8555 27.8554 3.66667 30.5555 3.66667H37.6477Z"
          fill="#F0F8FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6477 3.66667C38.9156 1.47473 41.2856 0 43.9999 0C46.7143 0 49.0842 1.47473 50.3522 3.66667H57.4444C60.1444 3.66667 62.3333 5.8555 62.3333 8.55556V13.4444C62.3333 16.1445 60.1444 18.3333 57.4444 18.3333H30.5555C27.8554 18.3333 25.6666 16.1445 25.6666 13.4444V8.55555C25.6666 5.8555 27.8554 3.66667 30.5555 3.66667H37.6477Z"
          fill="#B0C7EB"
          fillOpacity="0.25"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6477 3.66667C38.9156 1.47473 41.2856 0 43.9999 0C46.7143 0 49.0842 1.47473 50.3522 3.66667H57.4444C60.1444 3.66667 62.3333 5.8555 62.3333 8.55556V13.4444C62.3333 16.1445 60.1444 18.3333 57.4444 18.3333H30.5555C27.8554 18.3333 25.6666 16.1445 25.6666 13.4444V8.55555C25.6666 5.8555 27.8554 3.66667 30.5555 3.66667H37.6477Z"
          fill="url(#paint1_linear_2_7778)"
          fillOpacity="0.15"
        />
      </g>
      <g filter="url(#filter3_d_2_7778)">
        <ellipse cx="43.9999" cy="7.33335" rx="3.66667" ry="3.66667" fill="#F0F8FF" />
        <path
          d="M47.1666 7.33335C47.1666 9.08226 45.7488 10.5 43.9999 10.5C42.251 10.5 40.8333 9.08226 40.8333 7.33335C40.8333 5.58445 42.251 4.16669 43.9999 4.16669C45.7488 4.16669 47.1666 5.58445 47.1666 7.33335Z"
          stroke="#F0F8FF"
        />
      </g>
      <path
        d="M66.0001 30.5556C66.0001 28.5305 64.3585 26.8889 62.3334 26.8889H45.2223C43.1973 26.8889 41.5557 28.5305 41.5557 30.5556C41.5557 32.5806 43.1973 34.2223 45.2223 34.2223H62.3334C64.3585 34.2223 66.0001 32.5806 66.0001 30.5556Z"
        fill="#F0F8FF"
      />
      <path
        d="M66.0001 30.5556C66.0001 28.5305 64.3585 26.8889 62.3334 26.8889H45.2223C43.1973 26.8889 41.5557 28.5305 41.5557 30.5556C41.5557 32.5806 43.1973 34.2223 45.2223 34.2223H62.3334C64.3585 34.2223 66.0001 32.5806 66.0001 30.5556Z"
        fill="#B0C7EB"
        fillOpacity="0.25"
      />
      <path
        d="M66.0001 30.5556C66.0001 28.5305 64.3585 26.8889 62.3334 26.8889H45.2223C43.1973 26.8889 41.5557 28.5305 41.5557 30.5556C41.5557 32.5806 43.1973 34.2223 45.2223 34.2223H62.3334C64.3585 34.2223 66.0001 32.5806 66.0001 30.5556Z"
        fill="url(#paint2_linear_2_7778)"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1729 25.1604C38.1275 26.115 38.1275 27.6628 37.1729 28.6174L29.8396 35.9507C28.885 36.9053 27.3372 36.9053 26.3826 35.9507L22.716 32.284C21.7613 31.3294 21.7613 29.7817 22.716 28.8271C23.6706 27.8725 25.2183 27.8725 26.1729 28.8271L28.1111 30.7653L33.716 25.1604C34.6706 24.2058 36.2183 24.2058 37.1729 25.1604Z"
        fill="url(#paint3_linear_2_7778)"
      />
      <path
        d="M56.2223 47.6667C56.2223 45.6416 54.5807 44 52.5557 44H45.2223C43.1973 44 41.5557 45.6416 41.5557 47.6667C41.5557 49.6917 43.1973 51.3333 45.2223 51.3333H52.5557C54.5807 51.3333 56.2223 49.6917 56.2223 47.6667Z"
        fill="#F0F8FF"
      />
      <path
        d="M56.2223 47.6667C56.2223 45.6416 54.5807 44 52.5557 44H45.2223C43.1973 44 41.5557 45.6416 41.5557 47.6667C41.5557 49.6917 43.1973 51.3333 45.2223 51.3333H52.5557C54.5807 51.3333 56.2223 49.6917 56.2223 47.6667Z"
        fill="#B0C7EB"
        fillOpacity="0.25"
      />
      <path
        d="M56.2223 47.6667C56.2223 45.6416 54.5807 44 52.5557 44H45.2223C43.1973 44 41.5557 45.6416 41.5557 47.6667C41.5557 49.6917 43.1973 51.3333 45.2223 51.3333H52.5557C54.5807 51.3333 56.2223 49.6917 56.2223 47.6667Z"
        fill="url(#paint4_linear_2_7778)"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1729 42.2715C38.1275 43.2261 38.1275 44.7739 37.1729 45.7285L29.8396 53.0618C28.885 54.0164 27.3372 54.0164 26.3826 53.0618L22.716 49.3951C21.7613 48.4405 21.7613 46.8928 22.716 45.9382C23.6706 44.9836 25.2183 44.9836 26.1729 45.9382L28.1111 47.8764L33.716 42.2715C34.6706 41.3169 36.2183 41.3169 37.1729 42.2715Z"
        fill="url(#paint5_linear_2_7778)"
      />
      <path
        d="M63.5557 64.7778C63.5557 62.7528 61.914 61.1111 59.889 61.1111H45.2223C43.1973 61.1111 41.5557 62.7528 41.5557 64.7778C41.5557 66.8029 43.1973 68.4445 45.2223 68.4445H59.889C61.914 68.4445 63.5557 66.8029 63.5557 64.7778Z"
        fill="#F0F8FF"
      />
      <path
        d="M63.5557 64.7778C63.5557 62.7528 61.914 61.1111 59.889 61.1111H45.2223C43.1973 61.1111 41.5557 62.7528 41.5557 64.7778C41.5557 66.8029 43.1973 68.4445 45.2223 68.4445H59.889C61.914 68.4445 63.5557 66.8029 63.5557 64.7778Z"
        fill="#B0C7EB"
        fillOpacity="0.25"
      />
      <path
        d="M63.5557 64.7778C63.5557 62.7528 61.914 61.1111 59.889 61.1111H45.2223C43.1973 61.1111 41.5557 62.7528 41.5557 64.7778C41.5557 66.8029 43.1973 68.4445 45.2223 68.4445H59.889C61.914 68.4445 63.5557 66.8029 63.5557 64.7778Z"
        fill="url(#paint6_linear_2_7778)"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1729 59.3826C38.1275 60.3373 38.1275 61.885 37.1729 62.8396L29.8396 70.173C28.885 71.1276 27.3372 71.1276 26.3826 70.173L22.716 66.5063C21.7613 65.5517 21.7613 64.0039 22.716 63.0493C23.6706 62.0947 25.2183 62.0947 26.1729 63.0493L28.1111 64.9875L33.716 59.3826C34.6706 58.428 36.2183 58.428 37.1729 59.3826Z"
        fill="url(#paint7_linear_2_7778)"
      />
      <defs>
        <filter
          id="filter0_i_2_7778"
          x="7.77759"
          y="5.33331"
          width="70.4443"
          height="82.6667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7778" />
        </filter>
        <filter
          id="filter1_d_2_7778"
          x="15.1111"
          y="12.6667"
          width="61.7778"
          height="74"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.07 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7778" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7778"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_i_2_7778"
          x="23.6665"
          y="-2"
          width="38.6667"
          height="20.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7778" />
        </filter>
        <filter
          id="filter3_d_2_7778"
          x="38.3333"
          y="1.66669"
          width="15.3333"
          height="15.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.07 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7778" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7778"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_7778"
          x1="9.77759"
          y1="7.33331"
          x2="89.3675"
          y2="74.8642"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7778"
          x1="43.9999"
          y1="0"
          x2="43.9999"
          y2="18.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8EACDA" />
          <stop offset="1" stopColor="#8EACDA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_7778"
          x1="53.7779"
          y1="26.8889"
          x2="53.7779"
          y2="34.2223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8EACDA" />
          <stop offset="1" stopColor="#8EACDA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_7778"
          x1="22"
          y1="24.4445"
          x2="33.8133"
          y2="39.8017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2_7778"
          x1="48.889"
          y1="44"
          x2="48.889"
          y2="51.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8EACDA" />
          <stop offset="1" stopColor="#8EACDA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2_7778"
          x1="22"
          y1="41.5555"
          x2="33.8133"
          y2="56.9128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2_7778"
          x1="52.5557"
          y1="61.1111"
          x2="52.5557"
          y2="68.4445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8EACDA" />
          <stop offset="1" stopColor="#8EACDA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2_7778"
          x1="22"
          y1="58.6667"
          x2="33.8133"
          y2="74.024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconAssessmentComplete: React.FC = () => {
  return (
    <svg
      width="91"
      height="104"
      viewBox="0 0 91 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2_7734)">
        <g filter="url(#filter1_i_2_7734)">
          <path
            d="M9.77759 18.6666C9.77759 14.6166 13.0608 11.3333 17.1109 11.3333H70.8887C74.9388 11.3333 78.222 14.6166 78.222 18.6666V84.6666C78.222 88.7167 74.9388 92 70.8887 92H17.1109C13.0608 92 9.77759 88.7167 9.77759 84.6666V18.6666Z"
            fill="url(#paint0_linear_2_7734)"
          />
        </g>
        <g filter="url(#filter2_d_2_7734)">
          <path
            d="M70.8889 21.1111C70.8889 19.7611 69.7945 18.6667 68.4444 18.6667H19.5555C18.2055 18.6667 17.1111 19.7611 17.1111 21.1111V82.2223C17.1111 83.5723 18.2055 84.6667 19.5555 84.6667H68.4444C69.7945 84.6667 70.8889 83.5723 70.8889 82.2222V21.1111Z"
            fill="#C4C4C4"
          />
          <path
            d="M70.8889 21.1111C70.8889 19.7611 69.7945 18.6667 68.4444 18.6667H19.5555C18.2055 18.6667 17.1111 19.7611 17.1111 21.1111V82.2223C17.1111 83.5723 18.2055 84.6667 19.5555 84.6667H68.4444C69.7945 84.6667 70.8889 83.5723 70.8889 82.2222V21.1111Z"
            fill="url(#paint1_linear_2_7734)"
          />
        </g>
        <g filter="url(#filter3_i_2_7734)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.6477 7.66667C38.9156 5.47473 41.2856 4 43.9999 4C46.7143 4 49.0842 5.47473 50.3522 7.66667H57.4444C60.1444 7.66667 62.3333 9.8555 62.3333 12.5556V17.4444C62.3333 20.1445 60.1444 22.3333 57.4444 22.3333H30.5555C27.8554 22.3333 25.6666 20.1445 25.6666 17.4444V12.5556C25.6666 9.8555 27.8554 7.66667 30.5555 7.66667H37.6477Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.6477 7.66667C38.9156 5.47473 41.2856 4 43.9999 4C46.7143 4 49.0842 5.47473 50.3522 7.66667H57.4444C60.1444 7.66667 62.3333 9.8555 62.3333 12.5556V17.4444C62.3333 20.1445 60.1444 22.3333 57.4444 22.3333H30.5555C27.8554 22.3333 25.6666 20.1445 25.6666 17.4444V12.5556C25.6666 9.8555 27.8554 7.66667 30.5555 7.66667H37.6477Z"
            fill="#80AC96"
            fillOpacity="0.25"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.6477 7.66667C38.9156 5.47473 41.2856 4 43.9999 4C46.7143 4 49.0842 5.47473 50.3522 7.66667H57.4444C60.1444 7.66667 62.3333 9.8555 62.3333 12.5556V17.4444C62.3333 20.1445 60.1444 22.3333 57.4444 22.3333H30.5555C27.8554 22.3333 25.6666 20.1445 25.6666 17.4444V12.5556C25.6666 9.8555 27.8554 7.66667 30.5555 7.66667H37.6477Z"
            fill="url(#paint2_linear_2_7734)"
            fillOpacity="0.15"
          />
        </g>
        <g filter="url(#filter4_d_2_7734)">
          <ellipse cx="43.9999" cy="11.3334" rx="3.66667" ry="3.66667" fill="#C4C4C4" />
          <ellipse
            cx="43.9999"
            cy="11.3334"
            rx="3.66667"
            ry="3.66667"
            fill="url(#paint3_linear_2_7734)"
          />
          <path
            d="M47.1666 11.3334C47.1666 13.0823 45.7488 14.5 43.9999 14.5C42.251 14.5 40.8333 13.0823 40.8333 11.3334C40.8333 9.58445 42.251 8.16669 43.9999 8.16669C45.7488 8.16669 47.1666 9.58445 47.1666 11.3334Z"
            stroke="#C4C4C4"
          />
          <path
            d="M47.1666 11.3334C47.1666 13.0823 45.7488 14.5 43.9999 14.5C42.251 14.5 40.8333 13.0823 40.8333 11.3334C40.8333 9.58445 42.251 8.16669 43.9999 8.16669C45.7488 8.16669 47.1666 9.58445 47.1666 11.3334Z"
            stroke="url(#paint4_linear_2_7734)"
          />
        </g>
        <path
          d="M66.0001 34.5556C66.0001 32.5305 64.3585 30.8889 62.3334 30.8889H45.2223C43.1973 30.8889 41.5557 32.5305 41.5557 34.5556C41.5557 36.5806 43.1973 38.2223 45.2223 38.2223H62.3334C64.3585 38.2223 66.0001 36.5806 66.0001 34.5556Z"
          fill="white"
        />
        <path
          d="M66.0001 34.5556C66.0001 32.5305 64.3585 30.8889 62.3334 30.8889H45.2223C43.1973 30.8889 41.5557 32.5305 41.5557 34.5556C41.5557 36.5806 43.1973 38.2223 45.2223 38.2223H62.3334C64.3585 38.2223 66.0001 36.5806 66.0001 34.5556Z"
          fill="#80AC96"
          fillOpacity="0.25"
        />
        <path
          d="M66.0001 34.5556C66.0001 32.5305 64.3585 30.8889 62.3334 30.8889H45.2223C43.1973 30.8889 41.5557 32.5305 41.5557 34.5556C41.5557 36.5806 43.1973 38.2223 45.2223 38.2223H62.3334C64.3585 38.2223 66.0001 36.5806 66.0001 34.5556Z"
          fill="url(#paint5_linear_2_7734)"
          fillOpacity="0.15"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.1729 29.1604C38.1275 30.115 38.1275 31.6628 37.1729 32.6174L29.8396 39.9507C28.885 40.9053 27.3372 40.9053 26.3826 39.9507L22.716 36.284C21.7613 35.3294 21.7613 33.7817 22.716 32.8271C23.6706 31.8725 25.2183 31.8725 26.1729 32.8271L28.1111 34.7653L33.716 29.1604C34.6706 28.2058 36.2183 28.2058 37.1729 29.1604Z"
          fill="url(#paint6_linear_2_7734)"
        />
        <path
          d="M56.2223 51.6667C56.2223 49.6416 54.5807 48 52.5557 48H45.2223C43.1973 48 41.5557 49.6416 41.5557 51.6667C41.5557 53.6917 43.1973 55.3333 45.2223 55.3333H52.5557C54.5807 55.3333 56.2223 53.6917 56.2223 51.6667Z"
          fill="white"
        />
        <path
          d="M56.2223 51.6667C56.2223 49.6416 54.5807 48 52.5557 48H45.2223C43.1973 48 41.5557 49.6416 41.5557 51.6667C41.5557 53.6917 43.1973 55.3333 45.2223 55.3333H52.5557C54.5807 55.3333 56.2223 53.6917 56.2223 51.6667Z"
          fill="#80AC96"
          fillOpacity="0.25"
        />
        <path
          d="M56.2223 51.6667C56.2223 49.6416 54.5807 48 52.5557 48H45.2223C43.1973 48 41.5557 49.6416 41.5557 51.6667C41.5557 53.6917 43.1973 55.3333 45.2223 55.3333H52.5557C54.5807 55.3333 56.2223 53.6917 56.2223 51.6667Z"
          fill="url(#paint7_linear_2_7734)"
          fillOpacity="0.15"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.1729 46.2715C38.1275 47.2261 38.1275 48.7739 37.1729 49.7285L29.8396 57.0618C28.885 58.0164 27.3372 58.0164 26.3826 57.0618L22.716 53.3951C21.7613 52.4405 21.7613 50.8928 22.716 49.9382C23.6706 48.9836 25.2183 48.9836 26.1729 49.9382L28.1111 51.8764L33.716 46.2715C34.6706 45.3169 36.2183 45.3169 37.1729 46.2715Z"
          fill="url(#paint8_linear_2_7734)"
        />
        <path
          d="M63.5557 68.7778C63.5557 66.7528 61.914 65.1111 59.889 65.1111H45.2223C43.1973 65.1111 41.5557 66.7528 41.5557 68.7778C41.5557 70.8029 43.1973 72.4445 45.2223 72.4445H59.889C61.914 72.4445 63.5557 70.8029 63.5557 68.7778Z"
          fill="white"
        />
        <path
          d="M63.5557 68.7778C63.5557 66.7528 61.914 65.1111 59.889 65.1111H45.2223C43.1973 65.1111 41.5557 66.7528 41.5557 68.7778C41.5557 70.8029 43.1973 72.4445 45.2223 72.4445H59.889C61.914 72.4445 63.5557 70.8029 63.5557 68.7778Z"
          fill="#80AC96"
          fillOpacity="0.25"
        />
        <path
          d="M63.5557 68.7778C63.5557 66.7528 61.914 65.1111 59.889 65.1111H45.2223C43.1973 65.1111 41.5557 66.7528 41.5557 68.7778C41.5557 70.8029 43.1973 72.4445 45.2223 72.4445H59.889C61.914 72.4445 63.5557 70.8029 63.5557 68.7778Z"
          fill="url(#paint9_linear_2_7734)"
          fillOpacity="0.15"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.1729 63.3826C38.1275 64.3373 38.1275 65.885 37.1729 66.8396L29.8396 74.173C28.885 75.1276 27.3372 75.1276 26.3826 74.173L22.716 70.5063C21.7613 69.5517 21.7613 68.0039 22.716 67.0493C23.6706 66.0947 25.2183 66.0947 26.1729 67.0493L28.1111 68.9875L33.716 63.3826C34.6706 62.428 36.2183 62.428 37.1729 63.3826Z"
          fill="url(#paint10_linear_2_7734)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2_7734"
          x="-4"
          y="0"
          width="104"
          height="104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.329167 0 0 0 0 0.0329166 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7734" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7734"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_2_7734"
          x="7.77759"
          y="9.33331"
          width="70.4443"
          height="82.6667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7734" />
        </filter>
        <filter
          id="filter2_d_2_7734"
          x="15.1111"
          y="16.6667"
          width="61.7778"
          height="74"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7734" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7734"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_i_2_7734"
          x="23.6665"
          y="2"
          width="38.6667"
          height="20.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7734" />
        </filter>
        <filter
          id="filter4_d_2_7734"
          x="38.3333"
          y="5.66669"
          width="15.3333"
          height="15.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7734" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7734"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_7734"
          x1="9.77759"
          y1="11.3333"
          x2="89.3675"
          y2="78.8642"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7734"
          x1="44"
          y1="18.6667"
          x2="44"
          y2="84.6667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_7734"
          x1="43.9999"
          y1="4"
          x2="43.9999"
          y2="22.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_7734"
          x1="43.9999"
          y1="7.66669"
          x2="43.9999"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2_7734"
          x1="43.9999"
          y1="7.66669"
          x2="43.9999"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2_7734"
          x1="53.7779"
          y1="30.8889"
          x2="53.7779"
          y2="38.2223"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2_7734"
          x1="22"
          y1="28.4445"
          x2="33.8133"
          y2="43.8017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2_7734"
          x1="48.889"
          y1="48"
          x2="48.889"
          y2="55.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2_7734"
          x1="22"
          y1="45.5555"
          x2="33.8133"
          y2="60.9128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2_7734"
          x1="52.5557"
          y1="65.1111"
          x2="52.5557"
          y2="72.4445"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2_7734"
          x1="22"
          y1="62.6667"
          x2="33.8133"
          y2="78.024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconCake: React.FC = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 44C0 37.9249 4.92487 33 11 33H77C83.0751 33 88 37.9249 88 44V77C88 83.0751 83.0751 88 77 88H11C4.92487 88 0 83.0751 0 77V44Z"
        fill="url(#paint0_linear_2_8201)"
      />
      <g clipPath="url(#clip0_2_8201)">
        <g filter="url(#filter0_d_2_8201)">
          <path
            d="M8.8 22C3.93989 22 0 25.9399 0 30.8V46.9333C0 51.7934 3.93989 55.7334 8.8 55.7334C13.6601 55.7334 17.6 51.7935 17.6 46.9334V46.8773C17.6308 42.0431 21.6409 38.1335 26.5832 38.1335C30.0774 38.1335 33.1057 40.0878 34.5911 42.9414C35.0871 43.8942 35.2007 44.9857 35.2007 46.0599V46.8182L35.2 46.9335C35.2 51.7936 39.1399 55.7335 44 55.7335C48.8601 55.7335 52.8 51.7936 52.8 46.9335L52.8007 46.8503C52.8454 42.0285 56.768 38.1335 61.6004 38.1335C66.4337 38.1335 70.3569 42.0301 70.4 46.8532L70.4001 46.9334C70.4001 51.7936 74.3401 55.7335 79.2002 55.7335C84.0603 55.7335 88.0002 51.7936 88.0002 46.9335L88 46.8769V33.0001C88 26.925 83.0751 22.0001 77 22.0001L8.8 22Z"
            fill="#C4C4C4"
          />
          <path
            d="M8.8 22C3.93989 22 0 25.9399 0 30.8V46.9333C0 51.7934 3.93989 55.7334 8.8 55.7334C13.6601 55.7334 17.6 51.7935 17.6 46.9334V46.8773C17.6308 42.0431 21.6409 38.1335 26.5832 38.1335C30.0774 38.1335 33.1057 40.0878 34.5911 42.9414C35.0871 43.8942 35.2007 44.9857 35.2007 46.0599V46.8182L35.2 46.9335C35.2 51.7936 39.1399 55.7335 44 55.7335C48.8601 55.7335 52.8 51.7936 52.8 46.9335L52.8007 46.8503C52.8454 42.0285 56.768 38.1335 61.6004 38.1335C66.4337 38.1335 70.3569 42.0301 70.4 46.8532L70.4001 46.9334C70.4001 51.7936 74.3401 55.7335 79.2002 55.7335C84.0603 55.7335 88.0002 51.7936 88.0002 46.9335L88 46.8769V33.0001C88 26.925 83.0751 22.0001 77 22.0001L8.8 22Z"
            fill="url(#paint1_linear_2_8201)"
          />
        </g>
      </g>
      <path
        d="M8.8 22C3.93989 22 0 25.9399 0 30.8V46.9333C0 51.7934 3.93989 55.7334 8.8 55.7334C13.6601 55.7334 17.6 51.7935 17.6 46.9334V46.8773C17.6308 42.0431 21.6409 38.1335 26.5832 38.1335C30.0774 38.1335 33.1057 40.0878 34.5911 42.9414C35.0871 43.8942 35.2007 44.9857 35.2007 46.0599V46.8182L35.2 46.9335C35.2 51.7936 39.1399 55.7335 44 55.7335C48.8601 55.7335 52.8 51.7936 52.8 46.9335L52.8007 46.8503C52.8454 42.0285 56.768 38.1335 61.6004 38.1335C66.4337 38.1335 70.3569 42.0301 70.4 46.8532L70.4001 46.9334C70.4001 51.7936 74.3401 55.7335 79.2002 55.7335C84.0603 55.7335 88.0002 51.7936 88.0002 46.9335L88 46.8769V33.0001C88 26.925 83.0751 22.0001 77 22.0001L8.8 22Z"
        fill="#F0F8FF"
      />
      <path
        d="M38.5 18.3333C38.5 16.3082 40.1416 14.6666 42.1667 14.6666H45.8333C47.8584 14.6666 49.5 16.3082 49.5 18.3333V22H38.5V18.3333Z"
        fill="#F0F8FF"
      />
      <path
        d="M38.5 18.3333C38.5 16.3082 40.1416 14.6666 42.1667 14.6666H45.8333C47.8584 14.6666 49.5 16.3082 49.5 18.3333V22H38.5V18.3333Z"
        fill="#B0C7EB"
        fillOpacity="0.25"
      />
      <path
        d="M38.5 18.3333C38.5 16.3082 40.1416 14.6666 42.1667 14.6666H45.8333C47.8584 14.6666 49.5 16.3082 49.5 18.3333V22H38.5V18.3333Z"
        fill="url(#paint2_linear_2_8201)"
        fillOpacity="0.15"
      />
      <path
        d="M18.3333 18.3333C18.3333 16.3082 19.9749 14.6666 21.9999 14.6666H25.6666C27.6916 14.6666 29.3333 16.3082 29.3333 18.3333V22H18.3333V18.3333Z"
        fill="#F0F8FF"
      />
      <path
        d="M18.3333 18.3333C18.3333 16.3082 19.9749 14.6666 21.9999 14.6666H25.6666C27.6916 14.6666 29.3333 16.3082 29.3333 18.3333V22H18.3333V18.3333Z"
        fill="#B0C7EB"
        fillOpacity="0.25"
      />
      <path
        d="M18.3333 18.3333C18.3333 16.3082 19.9749 14.6666 21.9999 14.6666H25.6666C27.6916 14.6666 29.3333 16.3082 29.3333 18.3333V22H18.3333V18.3333Z"
        fill="url(#paint3_linear_2_8201)"
        fillOpacity="0.15"
      />
      <path
        d="M58.6667 18.3333C58.6667 16.3082 60.3084 14.6666 62.3334 14.6666H66.0001C68.0251 14.6666 69.6668 16.3082 69.6668 18.3333V22H58.6667V18.3333Z"
        fill="#F0F8FF"
      />
      <path
        d="M58.6667 18.3333C58.6667 16.3082 60.3084 14.6666 62.3334 14.6666H66.0001C68.0251 14.6666 69.6668 16.3082 69.6668 18.3333V22H58.6667V18.3333Z"
        fill="#B0C7EB"
        fillOpacity="0.25"
      />
      <path
        d="M58.6667 18.3333C58.6667 16.3082 60.3084 14.6666 62.3334 14.6666H66.0001C68.0251 14.6666 69.6668 16.3082 69.6668 18.3333V22H58.6667V18.3333Z"
        fill="url(#paint4_linear_2_8201)"
        fillOpacity="0.15"
      />
      <path
        d="M49.5 9.16667C49.5 12.2042 47.0376 14.6667 44 14.6667C40.9624 14.6667 38.5 12.2042 38.5 9.16667C38.5 6.1291 40.3333 1.83333 44 0C47.6667 1.83333 49.5 6.1291 49.5 9.16667Z"
        fill="url(#paint5_linear_2_8201)"
      />
      <path
        d="M29.3333 9.16667C29.3333 12.2042 26.8708 14.6667 23.8333 14.6667C20.7957 14.6667 18.3333 12.2042 18.3333 9.16667C18.3333 6.1291 20.1666 1.83333 23.8333 0C27.4999 1.83333 29.3333 6.1291 29.3333 9.16667Z"
        fill="url(#paint6_linear_2_8201)"
      />
      <path
        d="M69.6668 9.16667C69.6668 12.2042 67.2043 14.6667 64.1667 14.6667C61.1292 14.6667 58.6667 12.2042 58.6667 9.16667C58.6667 6.1291 60.5001 1.83333 64.1667 0C67.8334 1.83333 69.6668 6.1291 69.6668 9.16667Z"
        fill="url(#paint7_linear_2_8201)"
      />
      <defs>
        <filter
          id="filter0_d_2_8201"
          x="-2"
          y="20"
          width="96.0002"
          height="41.7335"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.07 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_8201" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_8201"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_8201"
          x1="0"
          y1="33"
          x2="49.4382"
          y2="112.101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_8201"
          x1="44.0001"
          y1="22"
          x2="44.0001"
          y2="55.7335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.88" />
          <stop offset="0.203125" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_8201"
          x1="44"
          y1="14.6666"
          x2="44"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8EACDA" />
          <stop offset="1" stopColor="#8EACDA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_8201"
          x1="23.8333"
          y1="14.6666"
          x2="23.8333"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8EACDA" />
          <stop offset="1" stopColor="#8EACDA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2_8201"
          x1="64.1667"
          y1="14.6666"
          x2="64.1667"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8EACDA" />
          <stop offset="1" stopColor="#8EACDA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2_8201"
          x1="38.5"
          y1="0"
          x2="52.58"
          y2="10.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2_8201"
          x1="18.3333"
          y1="0"
          x2="32.4133"
          y2="10.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2_8201"
          x1="58.6667"
          y1="0"
          x2="72.7467"
          y2="10.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <clipPath id="clip0_2_8201">
          <rect width="88" height="34" fill="white" transform="translate(0 33)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconCakeComplete: React.FC = () => {
  return (
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2_7860)">
        <path
          d="M4 48C4 41.9249 8.92487 37 15 37H81C87.0751 37 92 41.9249 92 48V81C92 87.0751 87.0751 92 81 92H15C8.92487 92 4 87.0751 4 81V48Z"
          fill="url(#paint0_linear_2_7860)"
        />
        <g clipPath="url(#clip0_2_7860)">
          <g filter="url(#filter1_d_2_7860)">
            <path
              d="M12.8 26C7.93989 26 4 29.9399 4 34.8V50.9333C4 55.7934 7.93989 59.7334 12.8 59.7334C17.6601 59.7334 21.6 55.7935 21.6 50.9334V50.8773C21.6308 46.0431 25.6409 42.1335 30.5832 42.1335C34.0774 42.1335 37.1057 44.0878 38.5911 46.9414C39.0871 47.8942 39.2007 48.9857 39.2007 50.0599V50.8182L39.2 50.9335C39.2 55.7936 43.1399 59.7335 48 59.7335C52.8601 59.7335 56.8 55.7936 56.8 50.9335L56.8007 50.8503C56.8454 46.0285 60.768 42.1335 65.6004 42.1335C70.4337 42.1335 74.3569 46.0301 74.4 50.8532L74.4001 50.9334C74.4001 55.7936 78.3401 59.7335 83.2002 59.7335C88.0603 59.7335 92.0002 55.7936 92.0002 50.9335L92 50.8769V37.0001C92 30.925 87.0751 26.0001 81 26.0001L12.8 26Z"
              fill="#C4C4C4"
            />
            <path
              d="M12.8 26C7.93989 26 4 29.9399 4 34.8V50.9333C4 55.7934 7.93989 59.7334 12.8 59.7334C17.6601 59.7334 21.6 55.7935 21.6 50.9334V50.8773C21.6308 46.0431 25.6409 42.1335 30.5832 42.1335C34.0774 42.1335 37.1057 44.0878 38.5911 46.9414C39.0871 47.8942 39.2007 48.9857 39.2007 50.0599V50.8182L39.2 50.9335C39.2 55.7936 43.1399 59.7335 48 59.7335C52.8601 59.7335 56.8 55.7936 56.8 50.9335L56.8007 50.8503C56.8454 46.0285 60.768 42.1335 65.6004 42.1335C70.4337 42.1335 74.3569 46.0301 74.4 50.8532L74.4001 50.9334C74.4001 55.7936 78.3401 59.7335 83.2002 59.7335C88.0603 59.7335 92.0002 55.7936 92.0002 50.9335L92 50.8769V37.0001C92 30.925 87.0751 26.0001 81 26.0001L12.8 26Z"
              fill="url(#paint1_linear_2_7860)"
            />
          </g>
        </g>
        <path
          d="M12.8 26C7.93989 26 4 29.9399 4 34.8V50.9333C4 55.7934 7.93989 59.7334 12.8 59.7334C17.6601 59.7334 21.6 55.7935 21.6 50.9334V50.8773C21.6308 46.0431 25.6409 42.1335 30.5832 42.1335C34.0774 42.1335 37.1057 44.0878 38.5911 46.9414C39.0871 47.8942 39.2007 48.9857 39.2007 50.0599V50.8182L39.2 50.9335C39.2 55.7936 43.1399 59.7335 48 59.7335C52.8601 59.7335 56.8 55.7936 56.8 50.9335L56.8007 50.8503C56.8454 46.0285 60.768 42.1335 65.6004 42.1335C70.4337 42.1335 74.3569 46.0301 74.4 50.8532L74.4001 50.9334C74.4001 55.7936 78.3401 59.7335 83.2002 59.7335C88.0603 59.7335 92.0002 55.7936 92.0002 50.9335L92 50.8769V37.0001C92 30.925 87.0751 26.0001 81 26.0001L12.8 26Z"
          fill="#C4C4C4"
        />
        <path
          d="M12.8 26C7.93989 26 4 29.9399 4 34.8V50.9333C4 55.7934 7.93989 59.7334 12.8 59.7334C17.6601 59.7334 21.6 55.7935 21.6 50.9334V50.8773C21.6308 46.0431 25.6409 42.1335 30.5832 42.1335C34.0774 42.1335 37.1057 44.0878 38.5911 46.9414C39.0871 47.8942 39.2007 48.9857 39.2007 50.0599V50.8182L39.2 50.9335C39.2 55.7936 43.1399 59.7335 48 59.7335C52.8601 59.7335 56.8 55.7936 56.8 50.9335L56.8007 50.8503C56.8454 46.0285 60.768 42.1335 65.6004 42.1335C70.4337 42.1335 74.3569 46.0301 74.4 50.8532L74.4001 50.9334C74.4001 55.7936 78.3401 59.7335 83.2002 59.7335C88.0603 59.7335 92.0002 55.7936 92.0002 50.9335L92 50.8769V37.0001C92 30.925 87.0751 26.0001 81 26.0001L12.8 26Z"
          fill="url(#paint2_linear_2_7860)"
        />
        <path
          d="M42.5 22.3333C42.5 20.3082 44.1416 18.6666 46.1667 18.6666H49.8333C51.8584 18.6666 53.5 20.3082 53.5 22.3333V26H42.5V22.3333Z"
          fill="white"
        />
        <path
          d="M42.5 22.3333C42.5 20.3082 44.1416 18.6666 46.1667 18.6666H49.8333C51.8584 18.6666 53.5 20.3082 53.5 22.3333V26H42.5V22.3333Z"
          fill="#80AC96"
          fillOpacity="0.25"
        />
        <path
          d="M42.5 22.3333C42.5 20.3082 44.1416 18.6666 46.1667 18.6666H49.8333C51.8584 18.6666 53.5 20.3082 53.5 22.3333V26H42.5V22.3333Z"
          fill="url(#paint3_linear_2_7860)"
          fillOpacity="0.15"
        />
        <path
          d="M22.3333 22.3333C22.3333 20.3082 23.9749 18.6666 25.9999 18.6666H29.6666C31.6916 18.6666 33.3333 20.3082 33.3333 22.3333V26H22.3333V22.3333Z"
          fill="white"
        />
        <path
          d="M22.3333 22.3333C22.3333 20.3082 23.9749 18.6666 25.9999 18.6666H29.6666C31.6916 18.6666 33.3333 20.3082 33.3333 22.3333V26H22.3333V22.3333Z"
          fill="#80AC96"
          fillOpacity="0.25"
        />
        <path
          d="M22.3333 22.3333C22.3333 20.3082 23.9749 18.6666 25.9999 18.6666H29.6666C31.6916 18.6666 33.3333 20.3082 33.3333 22.3333V26H22.3333V22.3333Z"
          fill="url(#paint4_linear_2_7860)"
          fillOpacity="0.15"
        />
        <path
          d="M62.6667 22.3333C62.6667 20.3082 64.3084 18.6666 66.3334 18.6666H70.0001C72.0251 18.6666 73.6668 20.3082 73.6668 22.3333V26H62.6667V22.3333Z"
          fill="white"
        />
        <path
          d="M62.6667 22.3333C62.6667 20.3082 64.3084 18.6666 66.3334 18.6666H70.0001C72.0251 18.6666 73.6668 20.3082 73.6668 22.3333V26H62.6667V22.3333Z"
          fill="#80AC96"
          fillOpacity="0.25"
        />
        <path
          d="M62.6667 22.3333C62.6667 20.3082 64.3084 18.6666 66.3334 18.6666H70.0001C72.0251 18.6666 73.6668 20.3082 73.6668 22.3333V26H62.6667V22.3333Z"
          fill="url(#paint5_linear_2_7860)"
          fillOpacity="0.15"
        />
        <path
          d="M53.5 13.1667C53.5 16.2042 51.0376 18.6667 48 18.6667C44.9624 18.6667 42.5 16.2042 42.5 13.1667C42.5 10.1291 44.3333 5.83333 48 4C51.6667 5.83333 53.5 10.1291 53.5 13.1667Z"
          fill="url(#paint6_linear_2_7860)"
        />
        <path
          d="M33.3333 13.1667C33.3333 16.2042 30.8708 18.6667 27.8333 18.6667C24.7957 18.6667 22.3333 16.2042 22.3333 13.1667C22.3333 10.1291 24.1666 5.83333 27.8333 4C31.4999 5.83333 33.3333 10.1291 33.3333 13.1667Z"
          fill="url(#paint7_linear_2_7860)"
        />
        <path
          d="M73.6668 13.1667C73.6668 16.2042 71.2043 18.6667 68.1667 18.6667C65.1292 18.6667 62.6667 16.2042 62.6667 13.1667C62.6667 10.1291 64.5001 5.83333 68.1667 4C71.8334 5.83333 73.6668 10.1291 73.6668 13.1667Z"
          fill="url(#paint8_linear_2_7860)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2_7860"
          x="0"
          y="0"
          width="104"
          height="104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.329167 0 0 0 0 0.0329166 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7860" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7860"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2_7860"
          x="2"
          y="24"
          width="96.0002"
          height="41.7335"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7860" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7860"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_7860"
          x1="4"
          y1="37"
          x2="53.4382"
          y2="116.101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7860"
          x1="48.0001"
          y1="26"
          x2="48.0001"
          y2="59.7335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.88" />
          <stop offset="0.203125" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_7860"
          x1="48.0001"
          y1="26"
          x2="48.0001"
          y2="59.7335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.88" />
          <stop offset="0.203125" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_7860"
          x1="48"
          y1="18.6666"
          x2="48"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2_7860"
          x1="27.8333"
          y1="18.6666"
          x2="27.8333"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2_7860"
          x1="68.1667"
          y1="18.6666"
          x2="68.1667"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2_7860"
          x1="42.5"
          y1="4"
          x2="56.58"
          y2="14.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2_7860"
          x1="22.3333"
          y1="4"
          x2="36.4133"
          y2="14.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2_7860"
          x1="62.6667"
          y1="4"
          x2="76.7467"
          y2="14.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <clipPath id="clip0_2_7860">
          <rect width="88" height="34" fill="white" transform="translate(4 37)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconListening: React.FC = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_2_7842)">
        <circle cx="44" cy="44" r="44" fill="url(#paint0_linear_2_7842)" />
      </g>
      <g filter="url(#filter1_d_2_7842)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44 77C62.2254 77 77 62.2254 77 44C77 25.7746 62.2254 11 44 11C25.7746 11 11 25.7746 11 44C11 62.2254 25.7746 77 44 77ZM61.4167 48.7636C65.0833 46.6466 65.0833 41.3542 61.4167 39.2373L39.4167 26.5356C35.75 24.4186 31.1667 27.0648 31.1667 31.2987L31.1667 56.7021C31.1667 60.936 35.75 63.5822 39.4167 61.4653L61.4167 48.7636Z"
          fill="#F0F8FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2_7842"
          x="-2"
          y="-2"
          width="90"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7842" />
        </filter>
        <filter
          id="filter1_d_2_7842"
          x="9"
          y="9"
          width="74"
          height="74"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.07 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7842" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7842"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_7842"
          x1="0"
          y1="0"
          x2="88"
          y2="88"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconListeningComplete: React.FC = () => {
  return (
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2_7830)">
        <g filter="url(#filter1_i_2_7830)">
          <circle cx="48" cy="48" r="44" fill="url(#paint0_linear_2_7830)" />
        </g>
        <g filter="url(#filter2_d_2_7830)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48 81C66.2254 81 81 66.2254 81 48C81 29.7746 66.2254 15 48 15C29.7746 15 15 29.7746 15 48C15 66.2254 29.7746 81 48 81ZM65.4167 52.7636C69.0833 50.6466 69.0833 45.3542 65.4167 43.2373L43.4167 30.5356C39.75 28.4186 35.1667 31.0648 35.1667 35.2987L35.1667 60.7021C35.1667 64.936 39.75 67.5822 43.4167 65.4653L65.4167 52.7636Z"
            fill="#C4C4C4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48 81C66.2254 81 81 66.2254 81 48C81 29.7746 66.2254 15 48 15C29.7746 15 15 29.7746 15 48C15 66.2254 29.7746 81 48 81ZM65.4167 52.7636C69.0833 50.6466 69.0833 45.3542 65.4167 43.2373L43.4167 30.5356C39.75 28.4186 35.1667 31.0648 35.1667 35.2987L35.1667 60.7021C35.1667 64.936 39.75 67.5822 43.4167 65.4653L65.4167 52.7636Z"
            fill="url(#paint1_linear_2_7830)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2_7830"
          x="0"
          y="0"
          width="104"
          height="104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.329167 0 0 0 0 0.0329166 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7830" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7830"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_2_7830"
          x="2"
          y="2"
          width="90"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7830" />
        </filter>
        <filter
          id="filter2_d_2_7830"
          x="13"
          y="13"
          width="74"
          height="74"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7830" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7830"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_7830"
          x1="4"
          y1="4"
          x2="92"
          y2="92"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7830"
          x1="48"
          y1="15"
          x2="48"
          y2="81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconLocal: React.FC = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_2_7820)">
        <path
          d="M80.6666 36.6667C80.6666 54.7975 71.8488 66.9155 51.5816 85.0433C47.2681 88.9015 40.7317 88.9015 36.4182 85.0433C16.151 66.9155 7.33325 54.7975 7.33325 36.6667C7.33325 16.4162 23.7495 0 43.9999 0C64.2504 0 80.6666 16.4162 80.6666 36.6667Z"
          fill="url(#paint0_linear_2_7820)"
        />
      </g>
      <g filter="url(#filter1_d_2_7820)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9999 62.3333C58.1752 62.3333 69.6666 50.842 69.6666 36.6667C69.6666 22.4914 58.1752 11 43.9999 11C29.8246 11 18.3333 22.4914 18.3333 36.6667C18.3333 50.842 29.8246 62.3333 43.9999 62.3333ZM46.1561 19.9014C44.8704 18.9664 43.1286 18.9664 41.8429 19.9014L25.4735 31.8064C24.1144 32.7949 23.8139 34.698 24.8024 36.0571C25.7908 37.4162 27.6939 37.7167 29.053 36.7282L30.3061 35.8169V47.9605C30.3061 51.3216 33.0309 54.0464 36.392 54.0464H51.6065C54.9676 54.0464 57.6923 51.3216 57.6923 47.9605V35.8165L58.9459 36.7282C60.3051 37.7167 62.2081 37.4162 63.1966 36.0571C64.1851 34.698 63.8846 32.7949 62.5254 31.8064L46.1561 19.9014ZM38.0131 36.4358C38.0131 35.4233 38.8339 34.6025 39.8464 34.6025H48.1527C49.1653 34.6025 49.9861 35.4233 49.9861 36.4358V44.7421C49.9861 45.7547 49.1652 46.5755 48.1527 46.5755H39.8464C38.8339 46.5755 38.0131 45.7547 38.0131 44.7421V36.4358Z"
          fill="#F0F8FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2_7820"
          x="5.33325"
          y="-2"
          width="75.3333"
          height="89.937"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7820" />
        </filter>
        <filter
          id="filter1_d_2_7820"
          x="16.3333"
          y="9"
          width="59.3333"
          height="59.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.07 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7820" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7820"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_7820"
          x1="7.33325"
          y1="0"
          x2="93.8398"
          y2="72.1405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconLocalComplete: React.FC = () => {
  return (
    <svg
      width="93"
      height="104"
      viewBox="0 0 93 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2_7800)">
        <g filter="url(#filter1_i_2_7800)">
          <path
            d="M80.6666 40.6667C80.6666 58.7975 71.8488 70.9155 51.5816 89.0433C47.2681 92.9015 40.7317 92.9015 36.4182 89.0433C16.151 70.9155 7.33325 58.7975 7.33325 40.6667C7.33325 20.4162 23.7495 4 43.9999 4C64.2504 4 80.6666 20.4162 80.6666 40.6667Z"
            fill="url(#paint0_linear_2_7800)"
          />
        </g>
        <g filter="url(#filter2_d_2_7800)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.9999 66.3333C58.1752 66.3333 69.6666 54.842 69.6666 40.6667C69.6666 26.4914 58.1752 15 43.9999 15C29.8246 15 18.3333 26.4914 18.3333 40.6667C18.3333 54.842 29.8246 66.3333 43.9999 66.3333ZM46.1561 23.9014C44.8704 22.9664 43.1286 22.9664 41.8429 23.9014L25.4735 35.8064C24.1144 36.7949 23.8139 38.698 24.8024 40.0571C25.7908 41.4162 27.6939 41.7167 29.053 40.7282L30.3061 39.8169V51.9605C30.3061 55.3216 33.0309 58.0464 36.392 58.0464H51.6065C54.9676 58.0464 57.6923 55.3216 57.6923 51.9605V39.8165L58.9459 40.7282C60.3051 41.7167 62.2081 41.4162 63.1966 40.0571C64.1851 38.698 63.8846 36.7949 62.5254 35.8064L46.1561 23.9014ZM38.0131 40.4358C38.0131 39.4233 38.8339 38.6025 39.8464 38.6025H48.1527C49.1653 38.6025 49.9861 39.4233 49.9861 40.4358V48.7421C49.9861 49.7547 49.1652 50.5755 48.1527 50.5755H39.8464C38.8339 50.5755 38.0131 49.7547 38.0131 48.7421V40.4358Z"
            fill="#C4C4C4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.9999 66.3333C58.1752 66.3333 69.6666 54.842 69.6666 40.6667C69.6666 26.4914 58.1752 15 43.9999 15C29.8246 15 18.3333 26.4914 18.3333 40.6667C18.3333 54.842 29.8246 66.3333 43.9999 66.3333ZM46.1561 23.9014C44.8704 22.9664 43.1286 22.9664 41.8429 23.9014L25.4735 35.8064C24.1144 36.7949 23.8139 38.698 24.8024 40.0571C25.7908 41.4162 27.6939 41.7167 29.053 40.7282L30.3061 39.8169V51.9605C30.3061 55.3216 33.0309 58.0464 36.392 58.0464H51.6065C54.9676 58.0464 57.6923 55.3216 57.6923 51.9605V39.8165L58.9459 40.7282C60.3051 41.7167 62.2081 41.4162 63.1966 40.0571C64.1851 38.698 63.8846 36.7949 62.5254 35.8064L46.1561 23.9014ZM38.0131 40.4358C38.0131 39.4233 38.8339 38.6025 39.8464 38.6025H48.1527C49.1653 38.6025 49.9861 39.4233 49.9861 40.4358V48.7421C49.9861 49.7547 49.1652 50.5755 48.1527 50.5755H39.8464C38.8339 50.5755 38.0131 49.7547 38.0131 48.7421V40.4358Z"
            fill="url(#paint1_linear_2_7800)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2_7800"
          x="-4"
          y="0"
          width="104"
          height="104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.329167 0 0 0 0 0.0329166 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7800" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7800"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_2_7800"
          x="5.33325"
          y="2"
          width="75.3333"
          height="89.937"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7800" />
        </filter>
        <filter
          id="filter2_d_2_7800"
          x="16.3333"
          y="13"
          width="59.3333"
          height="59.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7800" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7800"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_7800"
          x1="7.33325"
          y1="4"
          x2="93.8398"
          y2="76.1405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7800"
          x1="43.9999"
          y1="15"
          x2="43.9999"
          y2="66.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconPrimer: React.FC = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6667 66H77V80.6667H14.6667L11 73.3333L14.6667 66Z" fill="#C4C4C4" />
      <path
        d="M14.6667 66H77V80.6667H14.6667L11 73.3333L14.6667 66Z"
        fill="url(#paint0_linear_2_7689)"
      />
      <g filter="url(#filter0_i_2_7689)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.1666 0H21.9999C13.8997 0 7.33325 6.56649 7.33325 14.6667V73.3333C7.33325 77.2232 8.87848 80.9537 11.629 83.7042C14.3795 86.4548 18.1101 88 21.9999 88H79.1666C79.995 88 80.6666 87.3284 80.6666 86.5V82.1667C80.6666 81.3382 79.995 80.6667 79.1666 80.6667H21.9999C17.9498 80.6667 14.6666 77.3834 14.6666 73.3333C14.6666 69.2832 17.9498 66 21.9999 66H79.1666C79.995 66 80.6666 65.3284 80.6666 64.5V1.5C80.6666 0.671573 79.995 0 79.1666 0Z"
          fill="url(#paint1_linear_2_7689)"
        />
      </g>
      <mask
        id="mask0_2_7689"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="0"
        width="74"
        height="88"
      >
        <path
          d="M76.9999 0H21.9999C13.8997 0 7.33325 6.56649 7.33325 14.6667V73.3333C7.33325 77.2232 8.87848 80.9537 11.629 83.7042C14.3796 86.4548 18.1101 88 21.9999 88H76.9999C79.025 88 80.6666 86.3584 80.6666 84.3333C80.6666 82.3083 79.025 80.6667 76.9999 80.6667H21.9999C17.9498 80.6667 14.6666 77.3834 14.6666 73.3333C14.6666 69.2832 17.9498 66 21.9999 66H76.9999C79.025 66 80.6666 64.3584 80.6666 62.3333V3.66667C80.6666 1.64162 79.025 0 76.9999 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2_7689)">
        <rect
          opacity="0.07"
          x="14.6665"
          width="7.33333"
          height="73.3333"
          fill="url(#paint2_linear_2_7689)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2_7689"
          x="5.33325"
          y="-2"
          width="75.3333"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7689" />
        </filter>
        <linearGradient
          id="paint0_linear_2_7689"
          x1="44"
          y1="66"
          x2="44"
          y2="80.6667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7689"
          x1="7.33325"
          y1="0"
          x2="93.8906"
          y2="72.1312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_7689"
          x1="14.6665"
          y1="38.5965"
          x2="21.9998"
          y2="38.5965"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.520833" stopOpacity="0.5" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconPrimerComplete: React.FC = () => {
  return (
    <svg
      width="93"
      height="104"
      viewBox="0 0 93 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2_7666)">
        <path d="M14.6667 70H77V84.6667H14.6667L11 77.3333L14.6667 70Z" fill="#C4C4C4" />
        <path
          d="M14.6667 70H77V84.6667H14.6667L11 77.3333L14.6667 70Z"
          fill="url(#paint0_linear_2_7666)"
        />
        <g filter="url(#filter1_i_2_7666)">
          <path
            d="M76.9999 4H21.9999C13.8997 4 7.33325 10.5665 7.33325 18.6667V77.3333C7.33325 81.2232 8.87848 84.9537 11.629 87.7042C14.3796 90.4548 18.1101 92 21.9999 92H76.9999C79.025 92 80.6666 90.3584 80.6666 88.3333C80.6666 86.3083 79.025 84.6667 76.9999 84.6667H21.9999C17.9498 84.6667 14.6666 81.3834 14.6666 77.3333C14.6666 73.2832 17.9498 70 21.9999 70H76.9999C79.025 70 80.6666 68.3584 80.6666 66.3333V7.66667C80.6666 5.64162 79.025 4 76.9999 4Z"
            fill="url(#paint1_linear_2_7666)"
          />
        </g>
        <mask
          id="mask0_2_7666"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="7"
          y="4"
          width="74"
          height="88"
        >
          <path
            d="M76.9999 4H21.9999C13.8997 4 7.33325 10.5665 7.33325 18.6667V77.3333C7.33325 81.2232 8.87848 84.9537 11.629 87.7042C14.3796 90.4548 18.1101 92 21.9999 92H76.9999C79.025 92 80.6666 90.3584 80.6666 88.3333C80.6666 86.3083 79.025 84.6667 76.9999 84.6667H21.9999C17.9498 84.6667 14.6666 81.3834 14.6666 77.3333C14.6666 73.2832 17.9498 70 21.9999 70H76.9999C79.025 70 80.6666 68.3584 80.6666 66.3333V7.66667C80.6666 5.64162 79.025 4 76.9999 4Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2_7666)">
          <rect
            opacity="0.15"
            x="14.6665"
            y="4"
            width="7.33333"
            height="73.3333"
            fill="url(#paint2_linear_2_7666)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2_7666"
          x="-4"
          y="0"
          width="104"
          height="104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.329167 0 0 0 0 0.0329166 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7666" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7666"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_2_7666"
          x="5.33325"
          y="2"
          width="75.3333"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7666" />
        </filter>
        <linearGradient
          id="paint0_linear_2_7666"
          x1="44"
          y1="70"
          x2="44"
          y2="84.6667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7666"
          x1="7.33325"
          y1="4"
          x2="93.8906"
          y2="76.1312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_7666"
          x1="14.6665"
          y1="42.5965"
          x2="21.9998"
          y2="42.5965"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.520833" stopOpacity="0.5" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconScript: React.FC = () => {
  return (
    <svg width="88" height="90" viewBox="0 0 88 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_2_7724)">
        <path
          d="M77.0001 16.6666C77.0001 12.6166 73.7168 9.33331 69.6667 9.33331H37.4952C36.9647 9.33331 36.456 9.54403 36.081 9.9191L15.2525 30.7475C14.8775 31.1226 14.6667 31.6313 14.6667 32.1617V82.6666C14.6667 86.7167 17.95 90 22.0001 90H69.6667C73.7168 90 77.0001 86.7167 77.0001 82.6666V16.6666Z"
          fill="url(#paint0_linear_2_7724)"
        />
      </g>
      <g filter="url(#filter1_d_2_7724)">
        <path
          d="M69.6666 9.33333C69.6666 5.28324 66.3833 2 62.3333 2H30.1617C29.6312 2 29.1225 2.21071 28.7475 2.58579L7.91904 23.4142C7.54397 23.7893 7.33325 24.298 7.33325 24.8284V75.3333C7.33325 79.3834 10.6165 82.6667 14.6666 82.6667H62.3333C66.3833 82.6667 69.6666 79.3834 69.6666 75.3333V9.33333Z"
          fill="#F0F8FF"
        />
      </g>
      <path
        d="M32.9999 64.3334C32.9999 62.3083 31.3583 60.6667 29.3333 60.6667H21.9999C19.9749 60.6667 18.3333 62.3083 18.3333 64.3334C18.3333 66.3584 19.9749 68 21.9999 68H29.3333C31.3583 68 32.9999 66.3584 32.9999 64.3334Z"
        fill="#F0F8FF"
      />
      <path
        d="M32.9999 64.3334C32.9999 62.3083 31.3583 60.6667 29.3333 60.6667H21.9999C19.9749 60.6667 18.3333 62.3083 18.3333 64.3334C18.3333 66.3584 19.9749 68 21.9999 68H29.3333C31.3583 68 32.9999 66.3584 32.9999 64.3334Z"
        fill="#B0C7EB"
        fillOpacity="0.25"
      />
      <path
        d="M32.9999 64.3334C32.9999 62.3083 31.3583 60.6667 29.3333 60.6667H21.9999C19.9749 60.6667 18.3333 62.3083 18.3333 64.3334C18.3333 66.3584 19.9749 68 21.9999 68H29.3333C31.3583 68 32.9999 66.3584 32.9999 64.3334Z"
        fill="url(#paint1_linear_2_7724)"
        fillOpacity="0.15"
      />
      <path
        d="M58.6666 35C58.6666 32.9749 57.025 31.3333 54.9999 31.3333H21.9999C19.9749 31.3333 18.3333 32.9749 18.3333 35C18.3333 37.025 19.9749 38.6666 21.9999 38.6666H54.9999C57.025 38.6666 58.6666 37.025 58.6666 35Z"
        fill="#F0F8FF"
      />
      <path
        d="M58.6666 35C58.6666 32.9749 57.025 31.3333 54.9999 31.3333H21.9999C19.9749 31.3333 18.3333 32.9749 18.3333 35C18.3333 37.025 19.9749 38.6666 21.9999 38.6666H54.9999C57.025 38.6666 58.6666 37.025 58.6666 35Z"
        fill="#B0C7EB"
        fillOpacity="0.25"
      />
      <path
        d="M58.6666 35C58.6666 32.9749 57.025 31.3333 54.9999 31.3333H21.9999C19.9749 31.3333 18.3333 32.9749 18.3333 35C18.3333 37.025 19.9749 38.6666 21.9999 38.6666H54.9999C57.025 38.6666 58.6666 37.025 58.6666 35Z"
        fill="url(#paint2_linear_2_7724)"
        fillOpacity="0.15"
      />
      <path
        d="M43.9999 49.6667C43.9999 47.6416 42.3583 46 40.3333 46H21.9999C19.9749 46 18.3333 47.6416 18.3333 49.6667C18.3333 51.6917 19.9749 53.3333 21.9999 53.3333H40.3333C42.3583 53.3333 43.9999 51.6917 43.9999 49.6667Z"
        fill="#F0F8FF"
      />
      <path
        d="M43.9999 49.6667C43.9999 47.6416 42.3583 46 40.3333 46H21.9999C19.9749 46 18.3333 47.6416 18.3333 49.6667C18.3333 51.6917 19.9749 53.3333 21.9999 53.3333H40.3333C42.3583 53.3333 43.9999 51.6917 43.9999 49.6667Z"
        fill="#B0C7EB"
        fillOpacity="0.25"
      />
      <path
        d="M43.9999 49.6667C43.9999 47.6416 42.3583 46 40.3333 46H21.9999C19.9749 46 18.3333 47.6416 18.3333 49.6667C18.3333 51.6917 19.9749 53.3333 21.9999 53.3333H40.3333C42.3583 53.3333 43.9999 51.6917 43.9999 49.6667Z"
        fill="url(#paint3_linear_2_7724)"
        fillOpacity="0.15"
      />
      <g filter="url(#filter2_i_2_7724)">
        <path
          d="M29.3333 16.6667V2L7.33325 24H21.9999C26.05 24 29.3333 20.7168 29.3333 16.6667Z"
          fill="url(#paint4_linear_2_7724)"
        />
      </g>
      <g filter="url(#filter3_i_2_7724)">
        <path
          d="M39.7509 63.8052C37.7958 65.7603 36.4331 68.2284 35.8201 70.9246L34.9195 74.886C34.6203 76.202 35.7976 77.3793 37.1137 77.0801L41.075 76.1795C43.7713 75.5666 46.2393 74.2039 48.1945 72.2487L80.1116 40.332C82.4432 38.0004 82.4433 34.2201 80.1116 31.8885C77.78 29.5569 73.9997 29.5569 71.6681 31.8885L39.7509 63.8052Z"
          fill="url(#paint5_linear_2_7724)"
        />
      </g>
      <path
        opacity="0.07"
        d="M70.0854 33.4698L65.7818 37.7735L74.2253 46.217L78.529 41.9133L70.0854 33.4698Z"
        fill="url(#paint6_linear_2_7724)"
      />
      <defs>
        <filter
          id="filter0_i_2_7724"
          x="12.6667"
          y="7.33331"
          width="64.3333"
          height="82.6667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7724" />
        </filter>
        <filter
          id="filter1_d_2_7724"
          x="5.33325"
          y="0"
          width="70.3333"
          height="88.6667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.07 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7724" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7724"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_i_2_7724"
          x="5.33325"
          y="0"
          width="24"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7724" />
        </filter>
        <filter
          id="filter3_i_2_7724"
          x="32.8726"
          y="28.1398"
          width="48.9878"
          height="48.9873"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7724" />
        </filter>
        <linearGradient
          id="paint0_linear_2_7724"
          x1="40.9918"
          y1="51.6279"
          x2="75.8074"
          y2="86.4434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7724"
          x1="25.6666"
          y1="60.6667"
          x2="25.6666"
          y2="68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8EACDA" />
          <stop offset="1" stopColor="#8EACDA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_7724"
          x1="38.4999"
          y1="31.3333"
          x2="38.4999"
          y2="38.6666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8EACDA" />
          <stop offset="1" stopColor="#8EACDA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_7724"
          x1="31.1666"
          y1="46"
          x2="31.1666"
          y2="53.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8EACDA" />
          <stop offset="1" stopColor="#8EACDA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2_7724"
          x1="18.3333"
          y1="13"
          x2="27.4999"
          y2="22.1667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2_7724"
          x1="53.5732"
          y1="50.0491"
          x2="61.9879"
          y2="58.4638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2_7724"
          x1="74.5294"
          y1="37.9138"
          x2="70.2257"
          y2="42.2174"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.520833" stopOpacity="0.5" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconScriptComplete: React.FC = () => {
  return (
    <svg
      width="94"
      height="104"
      viewBox="0 0 94 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2_7704)">
        <g filter="url(#filter1_i_2_7704)">
          <path
            d="M77.0001 18.6666C77.0001 14.6166 73.7168 11.3333 69.6667 11.3333H37.4952C36.9647 11.3333 36.456 11.544 36.081 11.9191L15.2525 32.7475C14.8775 33.1226 14.6667 33.6313 14.6667 34.1617V84.6666C14.6667 88.7167 17.95 92 22.0001 92H69.6667C73.7168 92 77.0001 88.7167 77.0001 84.6666V18.6666Z"
            fill="url(#paint0_linear_2_7704)"
          />
        </g>
        <path
          d="M69.6666 11.3333C69.6666 7.28324 66.3833 4 62.3333 4H30.1617C29.6312 4 29.1225 4.21071 28.7475 4.58579L7.91904 25.4142C7.54397 25.7893 7.33325 26.298 7.33325 26.8284V77.3333C7.33325 81.3834 10.6165 84.6667 14.6666 84.6667H62.3333C66.3833 84.6667 69.6666 81.3834 69.6666 77.3333V11.3333Z"
          fill="url(#paint1_linear_2_7704)"
        />
        <path
          d="M32.9999 66.3334C32.9999 64.3083 31.3583 62.6667 29.3333 62.6667H21.9999C19.9749 62.6667 18.3333 64.3083 18.3333 66.3334C18.3333 68.3584 19.9749 70 21.9999 70H29.3333C31.3583 70 32.9999 68.3584 32.9999 66.3334Z"
          fill="white"
        />
        <path
          d="M32.9999 66.3334C32.9999 64.3083 31.3583 62.6667 29.3333 62.6667H21.9999C19.9749 62.6667 18.3333 64.3083 18.3333 66.3334C18.3333 68.3584 19.9749 70 21.9999 70H29.3333C31.3583 70 32.9999 68.3584 32.9999 66.3334Z"
          fill="#80AC96"
          fillOpacity="0.25"
        />
        <path
          d="M32.9999 66.3334C32.9999 64.3083 31.3583 62.6667 29.3333 62.6667H21.9999C19.9749 62.6667 18.3333 64.3083 18.3333 66.3334C18.3333 68.3584 19.9749 70 21.9999 70H29.3333C31.3583 70 32.9999 68.3584 32.9999 66.3334Z"
          fill="url(#paint2_linear_2_7704)"
          fillOpacity="0.15"
        />
        <path
          d="M58.6666 37C58.6666 34.9749 57.025 33.3333 54.9999 33.3333H21.9999C19.9749 33.3333 18.3333 34.9749 18.3333 37C18.3333 39.025 19.9749 40.6666 21.9999 40.6666H54.9999C57.025 40.6666 58.6666 39.025 58.6666 37Z"
          fill="white"
        />
        <path
          d="M58.6666 37C58.6666 34.9749 57.025 33.3333 54.9999 33.3333H21.9999C19.9749 33.3333 18.3333 34.9749 18.3333 37C18.3333 39.025 19.9749 40.6666 21.9999 40.6666H54.9999C57.025 40.6666 58.6666 39.025 58.6666 37Z"
          fill="#80AC96"
          fillOpacity="0.25"
        />
        <path
          d="M58.6666 37C58.6666 34.9749 57.025 33.3333 54.9999 33.3333H21.9999C19.9749 33.3333 18.3333 34.9749 18.3333 37C18.3333 39.025 19.9749 40.6666 21.9999 40.6666H54.9999C57.025 40.6666 58.6666 39.025 58.6666 37Z"
          fill="url(#paint3_linear_2_7704)"
          fillOpacity="0.15"
        />
        <path
          d="M43.9999 51.6667C43.9999 49.6416 42.3583 48 40.3333 48H21.9999C19.9749 48 18.3333 49.6416 18.3333 51.6667C18.3333 53.6917 19.9749 55.3333 21.9999 55.3333H40.3333C42.3583 55.3333 43.9999 53.6917 43.9999 51.6667Z"
          fill="white"
        />
        <path
          d="M43.9999 51.6667C43.9999 49.6416 42.3583 48 40.3333 48H21.9999C19.9749 48 18.3333 49.6416 18.3333 51.6667C18.3333 53.6917 19.9749 55.3333 21.9999 55.3333H40.3333C42.3583 55.3333 43.9999 53.6917 43.9999 51.6667Z"
          fill="#80AC96"
          fillOpacity="0.25"
        />
        <path
          d="M43.9999 51.6667C43.9999 49.6416 42.3583 48 40.3333 48H21.9999C19.9749 48 18.3333 49.6416 18.3333 51.6667C18.3333 53.6917 19.9749 55.3333 21.9999 55.3333H40.3333C42.3583 55.3333 43.9999 53.6917 43.9999 51.6667Z"
          fill="url(#paint4_linear_2_7704)"
          fillOpacity="0.15"
        />
        <g filter="url(#filter2_i_2_7704)">
          <path
            d="M29.3333 18.6667V4L7.33325 26H21.9999C26.05 26 29.3333 22.7168 29.3333 18.6667Z"
            fill="url(#paint5_linear_2_7704)"
          />
        </g>
        <g filter="url(#filter3_i_2_7704)">
          <path
            d="M39.7509 65.8052C37.7958 67.7603 36.4331 70.2284 35.8201 72.9246L34.9195 76.886C34.6203 78.202 35.7976 79.3793 37.1137 79.0801L41.075 78.1795C43.7713 77.5666 46.2393 76.2039 48.1945 74.2487L80.1116 42.332C82.4432 40.0004 82.4433 36.2201 80.1116 33.8885C77.78 31.5569 73.9997 31.5569 71.6681 33.8885L39.7509 65.8052Z"
            fill="url(#paint6_linear_2_7704)"
          />
        </g>
        <path
          opacity="0.15"
          d="M70.0854 35.4698L65.7818 39.7735L74.2253 48.217L78.529 43.9133L70.0854 35.4698Z"
          fill="url(#paint7_linear_2_7704)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2_7704"
          x="-4"
          y="0"
          width="104"
          height="104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.329167 0 0 0 0 0.0329166 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7704" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7704"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_2_7704"
          x="12.6667"
          y="9.33331"
          width="64.3333"
          height="82.6667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7704" />
        </filter>
        <filter
          id="filter2_i_2_7704"
          x="5.33325"
          y="2"
          width="24"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7704" />
        </filter>
        <filter
          id="filter3_i_2_7704"
          x="32.8726"
          y="30.1398"
          width="48.9878"
          height="48.9873"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_7704" />
        </filter>
        <linearGradient
          id="paint0_linear_2_7704"
          x1="40.9918"
          y1="53.6279"
          x2="75.8074"
          y2="88.4434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69FFB7" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7704"
          x1="56.6762"
          y1="84.6667"
          x2="29.4663"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.671875" stopColor="white" />
          <stop offset="1" stopColor="#D0DEE9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_7704"
          x1="25.6666"
          y1="62.6667"
          x2="25.6666"
          y2="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_7704"
          x1="38.4999"
          y1="33.3333"
          x2="38.4999"
          y2="40.6666"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2_7704"
          x1="31.1666"
          y1="48"
          x2="31.1666"
          y2="55.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2_7704"
          x1="18.3333"
          y1="15"
          x2="27.4999"
          y2="24.1667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69FFB7" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2_7704"
          x1="53.5732"
          y1="52.0491"
          x2="61.9879"
          y2="60.4638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69FFB7" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2_7704"
          x1="74.5294"
          y1="39.9138"
          x2="70.2257"
          y2="44.2174"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.520833" stopOpacity="0.5" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconVocabulary: React.FC = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 25C6 14.5066 14.5066 6 25 6H63C73.4934 6 82 14.5066 82 25V63C82 73.4934 73.4934 82 63 82H25C14.5066 82 6 73.4934 6 63V25Z"
        fill="url(#paint0_linear_2_7856)"
      />
      <g filter="url(#filter0_d_2_7856)">
        <path
          d="M37.0637 17C38.9612 17 40.4994 18.5571 40.4994 20.4778V22.13H54.6913C56.5888 22.13 58.127 23.6871 58.127 25.6078C58.127 27.5286 56.5888 29.0857 54.6913 29.0857H50.4439C49.8823 31.5082 48.8327 34.0446 47.6137 36.3786C46.0639 39.346 44.0578 42.3325 41.8768 44.8296L46.2448 49.2512C47.5865 50.6094 47.5864 52.8114 46.2447 54.1696C44.903 55.5278 42.7277 55.5278 41.386 54.1696L37.0635 49.794L27.082 59.8982C25.7403 61.2563 23.5634 61.2579 22.2217 59.8998C20.88 58.5416 20.8801 56.3395 22.2218 54.9813L32.2502 44.8296C30.1816 42.4612 28.2723 39.6548 26.7612 36.8461C25.8537 35.1593 26.4688 33.0472 28.1351 32.1285C29.8015 31.2098 31.888 31.8324 32.7956 33.5192C34.0077 35.7722 35.5023 37.9827 37.0635 39.8327C38.7171 37.8732 40.294 35.5116 41.5391 33.1276C42.3034 31.6642 42.8887 30.2954 43.2973 29.0857H37.0963L37.0637 29.0858L37.0311 29.0857H19.4357C17.5382 29.0857 16 27.5286 16 25.6078C16 23.6871 17.5382 22.13 19.4357 22.13H33.6281V20.4778C33.6281 18.5571 35.1663 17 37.0637 17Z"
          fill="#C4C4C4"
        />
        <path
          d="M37.0637 17C38.9612 17 40.4994 18.5571 40.4994 20.4778V22.13H54.6913C56.5888 22.13 58.127 23.6871 58.127 25.6078C58.127 27.5286 56.5888 29.0857 54.6913 29.0857H50.4439C49.8823 31.5082 48.8327 34.0446 47.6137 36.3786C46.0639 39.346 44.0578 42.3325 41.8768 44.8296L46.2448 49.2512C47.5865 50.6094 47.5864 52.8114 46.2447 54.1696C44.903 55.5278 42.7277 55.5278 41.386 54.1696L37.0635 49.794L27.082 59.8982C25.7403 61.2563 23.5634 61.2579 22.2217 59.8998C20.88 58.5416 20.8801 56.3395 22.2218 54.9813L32.2502 44.8296C30.1816 42.4612 28.2723 39.6548 26.7612 36.8461C25.8537 35.1593 26.4688 33.0472 28.1351 32.1285C29.8015 31.2098 31.888 31.8324 32.7956 33.5192C34.0077 35.7722 35.5023 37.9827 37.0635 39.8327C38.7171 37.8732 40.294 35.5116 41.5391 33.1276C42.3034 31.6642 42.8887 30.2954 43.2973 29.0857H37.0963L37.0637 29.0858L37.0311 29.0857H19.4357C17.5382 29.0857 16 27.5286 16 25.6078C16 23.6871 17.5382 22.13 19.4357 22.13H33.6281V20.4778C33.6281 18.5571 35.1663 17 37.0637 17Z"
          fill="url(#paint1_linear_2_7856)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.4364 39.7003C60.9081 38.3931 59.6513 37.5391 58.2561 37.5393C56.8608 37.5395 55.6043 38.3938 55.0763 39.7011L44.7766 65.2052C44.0586 66.9832 44.9004 69.0137 46.6567 69.7405C48.4131 70.4673 50.419 69.6152 51.137 67.8373L52.3752 64.7713H64.1442L65.3836 67.8381C66.102 69.6158 68.1081 70.4674 69.8643 69.7402C71.6205 69.0129 72.4617 66.9822 71.7433 65.2044L61.4364 39.7003ZM61.3332 57.8156H55.1842L58.2576 50.2051L61.3332 57.8156Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.4364 39.7003C60.9081 38.3931 59.6513 37.5391 58.2561 37.5393C56.8608 37.5395 55.6043 38.3938 55.0763 39.7011L44.7766 65.2052C44.0586 66.9832 44.9004 69.0137 46.6567 69.7405C48.4131 70.4673 50.419 69.6152 51.137 67.8373L52.3752 64.7713H64.1442L65.3836 67.8381C66.102 69.6158 68.1081 70.4674 69.8643 69.7402C71.6205 69.0129 72.4617 66.9822 71.7433 65.2044L61.4364 39.7003ZM61.3332 57.8156H55.1842L58.2576 50.2051L61.3332 57.8156Z"
          fill="url(#paint2_linear_2_7856)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2_7856"
          x="14"
          y="15"
          width="64"
          height="61"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.07 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7856" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7856"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_7856"
          x1="6"
          y1="6"
          x2="82"
          y2="82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7856"
          x1="44"
          y1="17"
          x2="44"
          y2="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_7856"
          x1="44"
          y1="17"
          x2="44"
          y2="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconVocabularyComplete: React.FC = () => {
  return (
    <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2_7848)">
        <path
          d="M6 25C6 14.5066 14.5066 6 25 6H63C73.4934 6 82 14.5066 82 25V63C82 73.4934 73.4934 82 63 82H25C14.5066 82 6 73.4934 6 63V25Z"
          fill="url(#paint0_linear_2_7848)"
        />
        <g filter="url(#filter1_d_2_7848)">
          <path
            d="M37.0637 17C38.9612 17 40.4994 18.5571 40.4994 20.4778V22.13H54.6913C56.5888 22.13 58.127 23.6871 58.127 25.6078C58.127 27.5286 56.5888 29.0857 54.6913 29.0857H50.4439C49.8823 31.5082 48.8327 34.0446 47.6137 36.3786C46.0639 39.346 44.0578 42.3325 41.8768 44.8296L46.2448 49.2512C47.5865 50.6094 47.5864 52.8114 46.2447 54.1696C44.903 55.5278 42.7277 55.5278 41.386 54.1696L37.0635 49.794L27.082 59.8982C25.7403 61.2563 23.5634 61.2579 22.2217 59.8998C20.88 58.5416 20.8801 56.3395 22.2218 54.9813L32.2502 44.8296C30.1816 42.4612 28.2724 39.6548 26.7612 36.8461C25.8537 35.1593 26.4688 33.0472 28.1351 32.1285C29.8015 31.2098 31.888 31.8324 32.7956 33.5192C34.0077 35.7722 35.5023 37.9827 37.0635 39.8327C38.7171 37.8732 40.294 35.5116 41.5391 33.1276C42.3034 31.6642 42.8887 30.2954 43.2973 29.0857H37.0963L37.0637 29.0858L37.0311 29.0857H19.4357C17.5382 29.0857 16 27.5286 16 25.6078C16 23.6871 17.5382 22.13 19.4357 22.13H33.6281V20.4778C33.6281 18.5571 35.1663 17 37.0637 17Z"
            fill="#C4C4C4"
          />
          <path
            d="M37.0637 17C38.9612 17 40.4994 18.5571 40.4994 20.4778V22.13H54.6913C56.5888 22.13 58.127 23.6871 58.127 25.6078C58.127 27.5286 56.5888 29.0857 54.6913 29.0857H50.4439C49.8823 31.5082 48.8327 34.0446 47.6137 36.3786C46.0639 39.346 44.0578 42.3325 41.8768 44.8296L46.2448 49.2512C47.5865 50.6094 47.5864 52.8114 46.2447 54.1696C44.903 55.5278 42.7277 55.5278 41.386 54.1696L37.0635 49.794L27.082 59.8982C25.7403 61.2563 23.5634 61.2579 22.2217 59.8998C20.88 58.5416 20.8801 56.3395 22.2218 54.9813L32.2502 44.8296C30.1816 42.4612 28.2724 39.6548 26.7612 36.8461C25.8537 35.1593 26.4688 33.0472 28.1351 32.1285C29.8015 31.2098 31.888 31.8324 32.7956 33.5192C34.0077 35.7722 35.5023 37.9827 37.0635 39.8327C38.7171 37.8732 40.294 35.5116 41.5391 33.1276C42.3034 31.6642 42.8887 30.2954 43.2973 29.0857H37.0963L37.0637 29.0858L37.0311 29.0857H19.4357C17.5382 29.0857 16 27.5286 16 25.6078C16 23.6871 17.5382 22.13 19.4357 22.13H33.6281V20.4778C33.6281 18.5571 35.1663 17 37.0637 17Z"
            fill="url(#paint1_linear_2_7848)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.4364 39.7003C60.9081 38.3931 59.6513 37.5391 58.2561 37.5393C56.8608 37.5395 55.6043 38.3938 55.0763 39.7011L44.7766 65.2052C44.0586 66.9832 44.9004 69.0137 46.6567 69.7405C48.4131 70.4673 50.419 69.6152 51.137 67.8373L52.3752 64.7713H64.1442L65.3836 67.8381C66.102 69.6158 68.1081 70.4674 69.8643 69.7402C71.6205 69.0129 72.4617 66.9822 71.7433 65.2044L61.4364 39.7003ZM61.3332 57.8156H55.1842L58.2576 50.2051L61.3332 57.8156Z"
            fill="#C4C4C4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.4364 39.7003C60.9081 38.3931 59.6513 37.5391 58.2561 37.5393C56.8608 37.5395 55.6043 38.3938 55.0763 39.7011L44.7766 65.2052C44.0586 66.9832 44.9004 69.0137 46.6567 69.7405C48.4131 70.4673 50.419 69.6152 51.137 67.8373L52.3752 64.7713H64.1442L65.3836 67.8381C66.102 69.6158 68.1081 70.4674 69.8643 69.7402C71.6205 69.0129 72.4617 66.9822 71.7433 65.2044L61.4364 39.7003ZM61.3332 57.8156H55.1842L58.2576 50.2051L61.3332 57.8156Z"
            fill="url(#paint2_linear_2_7848)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2_7848"
          x="-4"
          y="-4"
          width="104"
          height="104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.329167 0 0 0 0 0.0329166 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7848" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7848"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2_7848"
          x="14"
          y="15"
          width="64"
          height="61"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_7848" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_7848"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_7848"
          x1="6"
          y1="6"
          x2="82"
          y2="82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7848"
          x1="44"
          y1="17"
          x2="44"
          y2="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_7848"
          x1="44"
          y1="17"
          x2="44"
          y2="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export {
  IconAssessment,
  IconAssessmentComplete,
  IconCake,
  IconCakeComplete,
  IconListening,
  IconListeningComplete,
  IconLocal,
  IconLocalComplete,
  IconPrimer,
  IconPrimerComplete,
  IconScript,
  IconScriptComplete,
  IconVocabulary,
  IconVocabularyComplete,
};
