import React from 'react';
import { useDispatch } from 'react-redux';

import Icon from '../../../../assets/images/onboard-step04-mob.png';
import { closeModal } from '../../../../utils/modal';
import Button from '../../../atoms/Button';
import styles from './leaderboard_user_info.module.scss';

const LeaderBoardUserInfo: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.leaderBoardUserInfo}>
      <img src={Icon} alt={'leaderboardIcon'} />
      <p>You will appear on the leaderboard when you start collecting points for the week.</p>
      <Button onClick={() => closeModal(dispatch)} type="primary" value="OK" />
    </div>
  );
};

export default LeaderBoardUserInfo;
