import React from 'react';

import { STEP_CONTEXT } from './stepContext';

interface IStepProps {
  step: number;
}

const Step: React.FC<IStepProps> = ({ step }) => {
  return (
    <div className={`onboardSlider__item step0${step}`}>
      <div className="onboardSlider__info">{STEP_CONTEXT[step]}</div>
      <div className="onboardSlider__image">
        <span></span>
      </div>
    </div>
  );
};

export default Step;
