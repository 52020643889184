import { RefObject, useEffect, useState } from 'react';

export const useOnLoadImages = (ref: RefObject<HTMLElement>) => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const updateStatus = (images: Array<HTMLImageElement | null>) => {
      setStatus(images.map((image) => image?.complete).every((item) => item === true));
    };

    if (!ref?.current) return;

    const liLoaded = Array.from(ref.current.querySelectorAll('li'));
    const imagesLoaded = liLoaded.map((el) => el.querySelector('img'));

    if (imagesLoaded.length === 0) {
      setStatus(true);
      return;
    }
    imagesLoaded.forEach((image) => {
      image?.addEventListener('load', () => updateStatus(imagesLoaded));
      image?.addEventListener('error', () => updateStatus(imagesLoaded), {
        once: true,
      });
    });

    return;
  }, [ref]);

  return status;
};
