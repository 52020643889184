import StatImage5 from '../assets/images/stat-assessment.png';
import StatImage6 from '../assets/images/stat-script.png';
import StatImage4 from '../assets/images/stat-vocabulary.png';
import { IStatisticsItem } from '../types/study';

export const REVIEW_STATISTICS: Array<IStatisticsItem> = [
  {
    src: StatImage4,
    text: 'Vocabulary',
    code: 'vocab',
    count: 0,
  },
  {
    src: StatImage5,
    text: 'Assessment',
    code: 'assessment',
    count: 0,
  },
  {
    src: StatImage6,
    text: 'Script',
    code: 'script',
    count: 0,
  },
];

export const REVIEW_LIMIT = 20;
