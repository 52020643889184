import React from 'react';

const IconDropdown: React.FC = () => {
  return (
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0.999999L9.06066 6.93934C8.47487 7.52513 7.52513 7.52513 6.93934 6.93934L1 1"
        stroke="#D0DFE8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDropdown;
