import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../../components/Loader';
import { getGivenPoint, getIsPopupLoading } from '../../../../store/selectors/studySelectors';
import { setGivenPoint } from '../../../../store/slices/study';
import { setChangingCourse } from '../../../../store/slices/userCourses';
import { closeModal } from '../../../../utils/modal';
import { playSound } from '../../../../utils/study';
import Button from '../../../atoms/Button';
import mainStyles from '../Congratulation/congratulation.module.scss';
import styles from './wellDone.module.scss';

const WellDone: React.FC = () => {
  const dispatch = useDispatch();
  const point = useSelector(getGivenPoint);
  const isPopupLoading = useSelector(getIsPopupLoading);

  const cancelModal = () => {
    closeModal(dispatch);
    dispatch(setChangingCourse(''));
    dispatch(setGivenPoint(0));
  };
  useEffect(() => {
    playSound('praise');
  }, []);
  return (
    <div className={mainStyles.completeLesson}>
      {isPopupLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.image} />
          <h3>Well Done!</h3>
          <h5>Keep up the good work.</h5>
          {!!point && (
            <section className={mainStyles.award}>
              <p>Dedication</p>
              <strong>+{point}</strong>
            </section>
          )}
          <Button type={'primary'} value={'Continue'} onClick={cancelModal} />
        </>
      )}
    </div>
  );
};

export default WellDone;
