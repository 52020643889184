import React from 'react';

import {
  IconApprentice,
  IconApprenticeComplete,
  IconBeginner,
  IconBeginnerComplete,
  IconGrandMaster,
  IconGrandMasterComplete,
  IconSage,
  IconSageComplete,
  IconScholar,
  IconScholarComplete,
  IconTotalPoints,
  IconTotalPointsComplete,
} from '../assets/imgComponent/IconsSidebarLeadership';
import {
  IconAccount,
  IconBilling,
  IconCourses,
  IconCoursesMobile,
} from '../assets/imgComponent/IconsSidebarProfile';
import {
  IconAssessment,
  IconAssessmentComplete,
  IconCake,
  IconCakeComplete,
  IconListening,
  IconListeningComplete,
  IconLocal,
  IconLocalComplete,
  IconPrimer,
  IconPrimerComplete,
  IconScript,
  IconScriptComplete,
  IconVocabulary,
  IconVocabularyComplete,
} from '../assets/imgComponent/IconsSidebarStudy';
import NotificationPage from '../components/ProfileComponents/NotificationPage';
import ProfilePage from '../components/ProfileComponents/ProfilePage';
import UserAvatarImage from '../features/moleculs/UserAvatarImage';
import LeadershipBoard from '../features/views/LeadershipBoard';
import { TLeague } from '../types/study';

const BillingPage = React.lazy(() => import('../components/ProfileComponents/BillingPage'));
const CoursesPage = React.lazy(() => import('../components/ProfileComponents/CoursesPage'));

export interface ISidebarItem {
  Icon: any;
  IconActive?: any;
  text: string;
  url: string;
  id?: string;
  isComplete?: boolean;
}

export interface ILeaderBoardSidebarItem {
  Icon: any;
  IconActive: any;
  text: string;
}
export type Asd = 'as' | 'bs';

export const PROFILE_SIDEBAR_MENU: Array<ISidebarItem> = [
  {
    Icon: IconAccount,
    text: 'Profile',
    url: '/profile',
  },
  // {
  //   Icon: IconInvite,
  //   text: 'Invite a friend',
  //   url: '/',
  // },
  //todo remove icons

  // {
  //   Icon: IconReview,
  //   text: 'Review skills',
  //   url: '/',
  // },
  // {
  //   Icon: IconTest,
  //   text: 'Placement test',
  //   url: '/',
  // },
  {
    Icon: IconCourses,
    text: 'Courses',
    url: '/courses',
  },
  // {
  //   Icon: IconNotifications,
  //   text: 'Notifications',
  //   url: '/notification',
  // },
  {
    Icon: IconBilling,
    text: 'Billing',
    url: '/billing',
  },
];

export const STUDY_SIDEBAR_MENU: Array<ISidebarItem> = [
  {
    Icon: IconPrimer,
    IconActive: IconPrimerComplete,
    text: 'Primer',
    url: '/',
  },
  {
    Icon: IconAssessment,
    IconActive: IconAssessmentComplete,
    text: 'Assessment',
    url: '/',
  },
  {
    Icon: IconLocal,
    IconActive: IconLocalComplete,
    text: 'Like a local',
    url: '/',
  },
  {
    Icon: IconScript,
    IconActive: IconScriptComplete,
    text: 'Script',
    url: '/',
  },
  {
    Icon: IconVocabulary,
    IconActive: IconVocabularyComplete,
    text: 'Vocabulary',
    url: '/',
  },
  {
    Icon: IconListening,
    IconActive: IconListeningComplete,
    text: 'Listening',
    url: '/',
  },
  {
    Icon: IconCake,
    IconActive: IconCakeComplete,
    text: 'Cake',
    url: '/',
  },
];

export const LEADERBOARD_TYPES: Record<TLeague, string> = {
  'Beginner League': 'beginner',
  'Apprentice League': 'apprentice',
  'Scholar League': 'scholar',
  'Grand Master League': 'grand_master',
  'Total Points Champion': 'total_points_champion',
};

export const LEADERSHIP_SIDEBAR_MENU: Array<ILeaderBoardSidebarItem> = [
  {
    Icon: IconBeginner,
    IconActive: IconBeginnerComplete,
    text: 'Beginner',
  },
  {
    Icon: IconApprentice,
    IconActive: IconApprenticeComplete,
    text: 'Apprentice',
  },
  {
    Icon: IconScholar,
    IconActive: IconScholarComplete,
    text: 'Scholar',
  },
  {
    Icon: IconSage,
    IconActive: IconSageComplete,
    text: 'Sage',
  },
  {
    Icon: IconGrandMaster,
    IconActive: IconGrandMasterComplete,
    text: 'Grand Master',
  },
  {
    Icon: IconTotalPoints,
    IconActive: IconTotalPointsComplete,
    text: 'Total Points Champion',
  },
  // {
  //   Icon: IconLegend,
  //   IconActive: IconLegendComplete,
  //   text: 'Legend',
  // },
];
export const PROFILE_MOBILE_MENU: Array<ISidebarItem> = [
  {
    Icon: UserAvatarImage,
    text: 'Profile',
    url: '/profile',
  },
  // {
  //   Icon: IconInvite,
  //   text: 'Invite a friend',
  //   url: '/',
  // },
  {
    Icon: IconCoursesMobile,
    text: 'Courses',
    url: '/courses',
  },
  // {
  //   Icon: IconNotifications,
  //   text: 'Notifications',
  //   url: '/notification',
  // },
  {
    Icon: IconBilling,
    text: 'Billing',
    url: '/billing',
  },
];

export const PROFILE_COMPONENTS: { [key: string]: any } = {
  profile: ProfilePage,
  courses: CoursesPage,
  notification: NotificationPage,
  leadership: LeadershipBoard,
  billing: BillingPage,
};
