import './customStyles.scss';

import React, { useState } from 'react';

import AppHeader from '../../../../components/AppHeader';
import Button from '../../../atoms/Button';
import StepButton from '../../../atoms/StepButton';
import Step from '../../../moleculs/Step/Step';
import WelcomePage from '../../WelcomePage';
import styles from './onboarding.module.scss';

const STEPS = [1, 2, 3, 4];

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [showOnboarding, setShowOnboarding] = useState(true);

  return (
    <>
      <AppHeader />
      {!showOnboarding ? (
        <WelcomePage />
      ) : (
        <div className={styles.onboardingWrapper}>
          <div className={styles.onboardingFlow}>
            <div className={styles.stepContainer}>
              <ul>
                {STEPS.map((el, index) => {
                  return (
                    <li key={index}>
                      <div className={`${step - 1 > index ? styles.active : ''}`} />
                    </li>
                  );
                })}
              </ul>
              <span onClick={() => setShowOnboarding(false)} className={styles.onboardingClose} />
            </div>
            <Step step={step} />
            <div className={styles.onboardingCarouselArrows}>
              {step === 5 ? (
                <Button
                  type={'primary'}
                  value={'Let’s get started!'}
                  customClass={styles.btnClass}
                  onClick={() => setShowOnboarding(false)}
                />
              ) : (
                <>
                  {step > 1 ? (
                    <StepButton
                      type="prev"
                      text="Prev"
                      onHandleClick={() => setStep((prev) => --prev)}
                    />
                  ) : (
                    <div />
                  )}
                  <StepButton
                    type="next"
                    text="Next"
                    onHandleClick={() => setStep((prev) => ++prev)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Onboarding;
