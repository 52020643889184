import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getChangingCourse } from '../../../../store/selectors/courseSelectors';
import { resetCourse, setChangingCourse } from '../../../../store/slices/userCourses';
import { closeModal } from '../../../../utils/modal';
import StepButton from '../../../atoms/StepButton';
import styles from '../../../organisms/AppModal/modal.module.scss';

const ResetCourse: React.FC = () => {
  const dispatch = useDispatch();
  const resettingCourse = useSelector(getChangingCourse);

  const cancelModal = () => {
    closeModal(dispatch);
    dispatch(setChangingCourse(''));
  };

  const handleReset = () => {
    dispatch(resetCourse(resettingCourse));
  };
  return (
    <div className={styles.profileCoursesModal}>
      <p>Are you sure you want to reset your progress in this course?</p>
      <div className={styles.profileCoursesModalButtons}>
        <StepButton text="Cancel" onHandleClick={cancelModal} />
        <StepButton text="Reset" onHandleClick={handleReset} />
      </div>
    </div>
  );
};

export default ResetCourse;
