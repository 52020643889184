import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IconBeginnerComplete } from '../../assets/imgComponent/IconsSidebarLeadership';
import { LEADERSHIP_SIDEBAR_MENU } from '../../constants/userProfile';
import UserAvatarImage from '../../features/moleculs/UserAvatarImage';
import { getUserName, getUserStatistics } from '../../store/selectors/authSelectors';
import { setModal, setModalType } from '../../store/slices/modal';
import { MODAL_TYPES } from '../../types/modals';
import styles from './profiileHeader.module.scss';

const ProfileHeader: React.FC = () => {
  const { leaguePosition, leagueName } = useSelector(getUserStatistics);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const name = useSelector(getUserName);
  const handleClick = () => {
    dispatch(setModal(true));
    dispatch(setModalType(MODAL_TYPES.userProfile));
  };
  const handleLeagueClick = () => {
    navigate('/leaderboard');
  };

  const LeagueIcon = useMemo(() => {
    const el = LEADERSHIP_SIDEBAR_MENU.find((league) => league.text === leagueName);
    return el?.IconActive || <IconBeginnerComplete />;
  }, [leagueName]);
  return (
    <header className={styles.profileHeader}>
      <UserAvatarImage />
      <div className={styles.profileHeaderInfo}>
        <h3>
          Hi, <span>{name}</span>!
        </h3>
        {leagueName && (
          <div className={styles.profileHeaderInfoStat} onClick={handleLeagueClick}>
            <LeagueIcon />
            {leagueName}
            {leaguePosition && <span>#{leaguePosition}</span>}
          </div>
        )}
      </div>
      <div onClick={handleClick} className={styles.profileEditButton}>
        Edit Profile
      </div>
    </header>
  );
};

export default ProfileHeader;
