import React from 'react';

import { DESCRIPTION_LIST } from '../../constants/authFLow';
import styles from './description.module.scss';

const LinguaLiftDescription: React.FC = () => {
  return (
    <div className={`${styles.langToLearnDescription}`}>
      <div className={`${styles.langToLearnDescriptionImage}`} />
      <div className={`${styles.langToLearnDescriptionText}`}>
        <p>With LinguaLift you receive:</p>
        <ul>
          {DESCRIPTION_LIST.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LinguaLiftDescription;
