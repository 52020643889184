import React from 'react';
import { useSelector } from 'react-redux';

import { getUserSubscription } from '../../../store/selectors/authSelectors';
import ProfileHeader from '../../ProfileHeader';
import UserGoal from '../../UserGoal';
import UserPromotion from '../../UserPromotion';
import styles from './profile.module.scss';
import UserStatistics from './UserStatistics';

const ProfilePage: React.FC = () => {
  const { isSubscriptionActive } = useSelector(getUserSubscription);
  return (
    <div className={styles.profileContent}>
      <ProfileHeader />
      <UserGoal />
      <UserStatistics />
      {!isSubscriptionActive && <UserPromotion title="Enjoying your free trial?" />}{' '}
    </div>
  );
};

export default ProfilePage;
