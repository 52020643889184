import React, { memo, useEffect, useState } from 'react';

import Button from '../../features/atoms/Button';
import TestRow from '../../features/atoms/TestRow.tsx/TestRow';
import { ITestItemData } from '../../types/study';
import styles from '../TestComponents/TestContent/testContent.module.scss';
import TestKey from '../TestComponents/TestKey';

interface ITestContentProps {
  type: 'assessment' | 'vocab' | 'script';
  data: ITestItemData;
  step: number;
  // eslint-disable-next-line no-unused-vars
  setStep: (step: number) => void;
  setIsWrongAnswer: (step: boolean) => void;
}
const PlacementTestContent: React.FC<ITestContentProps> = ({
  type,
  data,
  setStep,
  step,
  setIsWrongAnswer,
}) => {
  const { _id, task, key, options, answer } = data;
  const [variant, setVariant] = useState('');
  const [isAnswered, setIsAnswered] = useState(false);
  const [optionsData, setOptionsData] = useState<Array<string>>([]);

  useEffect(() => {
    if (options.includes(answer)) {
      setOptionsData(options.sort(() => 0.5 - Math.random()));
    } else {
      const shuffledOptions = [...options, answer].sort(() => 0.5 - Math.random());
      setOptionsData(shuffledOptions);
    }
  }, [data]);

  const handleNextClick = () => {
    if (variant) {
      setStep(++step);
      setIsAnswered(false);
      setVariant('');
      if (variant !== answer) {
        setIsWrongAnswer(true);
      }
    }
  };

  return (
    <div key={_id} className={styles.testContent}>
      <p>{task}</p>
      <TestKey text={key} variant={variant} type={type} answer={answer} />
      <ul className={styles.testSectionList}>
        {optionsData.map((item) => {
          return (
            <TestRow
              isDisable={isAnswered}
              key={item}
              item={item}
              variant={variant}
              chooseAnswer={setVariant}
            />
          );
        })}
      </ul>
      <footer className={styles.assessmentFooter}>
        <div className={styles.assessmentFooterButton}>
          <Button type="primary" value="Next" disabled={!variant} onClick={handleNextClick} />
        </div>
      </footer>
    </div>
  );
};

export default memo(PlacementTestContent);
