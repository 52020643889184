import { createSelector } from 'reselect';

import { TState } from '../types';

const mainSelector = (state: TState) => state.userCourses;

export const getUserCourses = createSelector(mainSelector, (state) => state.courses);
export const getChangingCourse = createSelector(mainSelector, (state) => state.changingCourse);
export const getCurrentCourse = createSelector(mainSelector, (state) => state.course?.current);
export const getIsCourseLoading = createSelector(mainSelector, (state) => state.isCourseLoading);
