import { createSlice, Dispatch } from '@reduxjs/toolkit';
import auth from 'auth0-js';

import { TChangesPassword, TSignUpValues } from '../../constants/authFLow';
import $api from '../../Service/api/intercepter';
import { GetUserData, IUserStatistics } from '../../types/user';
import { logout, setToken } from '../../utils/auth';
import { closeModal } from '../../utils/modal';
import { AppDispatch } from '../store';
import { IAuthSlice } from '../types';
import { setIsModalLoading, setModal, setModalType } from './modal';
import { setNotification } from './notification';
import { setIsLanguageDataLoading } from './study';
import { setAllCourseData } from './userCourses';

const auth0 = new auth.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
});

const initialState: IAuthSlice = {
  isAuthenticated: false,
  authLoader: false,
  currentUser: {},
  errorMessage: '',
  isEmailSend: false,
  isFetchSuccess: false,
  showOnboarding: false,
  userGoal: '',
  statistics: {},
  redirectUrl: '',
  trial_unlocked: 0,
  subscription: {
    planId: '',
    currency: '',
    currentPeriodEnd: 0,
    isSubscriptionWillExpired: false,
    isSubscriptionActive: false,
    customer: '',
    amount: '',
  },
  priceDifference: {},
  billingHistory: {
    charges: null,
    payment_method: {},
  },
  isShowVerifiedContent: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    },
    setAuthLoader: (state, action) => {
      return {
        ...state,
        authLoader: action.payload,
      };
    },
    setErrorMessage: (state, action) => {
      return {
        ...state,
        errorMessage: action.payload,
      };
    },
    setCurrentUser: (state, action) => {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
    setIsEmailSend: (state, action) => {
      return {
        ...state,
        isEmailSend: action.payload,
      };
    },
    setIsFetchSuccess: (state, action) => {
      return {
        ...state,
        isFetchSuccess: action.payload,
      };
    },
    setShowOnboarding: (state, action) => {
      return {
        ...state,
        showOnboarding: action.payload,
      };
    },
    setUserFirstName: (state, action) => {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          name: action.payload,
        },
      };
    },
    setUserGoal: (state, action) => {
      return {
        ...state,
        userGoal: action.payload,
      };
    },
    setUserPicture: (state, action) => {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          picture: action.payload,
        },
      };
    },
    setStatistics: (state, action) => {
      return {
        ...state,
        statistics: action.payload,
      };
    },
    setSubscription: (state, action) => {
      return {
        ...state,
        subscription: action.payload,
      };
    },
    setBillingHistory: (state, action) => {
      return {
        ...state,
        billingHistory: action.payload,
      };
    },
    setRedirectUrl: (state, action) => {
      return {
        ...state,
        redirectUrl: action.payload,
      };
    },
    setPriceDifference: (state, action) => {
      return {
        ...state,
        priceDifference: action.payload,
      };
    },
    setIsShowVerifiedContent: (state, action) => {
      return {
        ...state,
        isShowVerifiedContent: action.payload,
      };
    },
  },
});

export const authLogout = () => {
  auth0.logout({
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
    returnTo: window.location.origin,
  });
};

const userDataRequest = (dispatch: Dispatch) => {
  $api
    .get('/profile/user')
    .then(({ data }: GetUserData) => {
      const {
        createdAt,
        email,
        displayName,
        progress,
        goal,
        picture,
        assessment_count,
        vocabulary_count,
        daily_streak,
        lesson_count,
        points_sum,
        script_count,
        study_days,
        place_in_league,
        leaderboardLeagueName,
        _id,
        subscription_currency,
        subscription_current_period_end,
        subscription_plan_id,
        should_subscription_cancel,
        trial_unlocked,
        is_email_verified,
        services,
        forceHasSubscription,
        subscription,
      } = data;

      dispatch(
        setCurrentUser({
          createdAt,
          email,
          name: displayName,
          picture,
          id: _id,
          trial_unlocked: trial_unlocked,
          is_email_verified,
          provider: services?.auth0?.identities[0]?.provider,
        })
      );
      dispatch(setAllCourseData(progress));
      dispatch(setUserGoal(goal));
      dispatch(
        setStatistics({
          assessment: assessment_count,
          vocabulary: vocabulary_count,
          scripts: script_count,
          dailyStreak: daily_streak,
          lessonsCount: lesson_count,
          sessionsCount: study_days,
          points: points_sum,
          leagueName: leaderboardLeagueName,
          leaguePosition: place_in_league,
        } as IUserStatistics)
      );
      dispatch(setAuth(true));
      if (forceHasSubscription) {
        dispatch(
          setSubscription({
            planId: subscription_plan_id || subscription?.plan?.id,
            customer: subscription?.customer ?? '',
            amount: subscription?.plan.amount_decimal ?? '',
            currency: subscription_currency || subscription?.plan?.currency,
            currentPeriodEnd:
              subscription_current_period_end * 1000 || subscription?.current_period_end * 1000,
            isSubscriptionWillExpired: should_subscription_cancel,
            isSubscriptionActive: forceHasSubscription,
          })
        );
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      dispatch(setIsFetchSuccess(true));
      dispatch(setAuthLoader(false));
    });
};

export const getUser =
  (isLogin = false) =>
  (dispatch: Dispatch) => {
    dispatch(setAuthLoader(true));
    if (isLogin) {
      $api
        .get('profile/permissions')
        .then(({ data }) => {
          if (data.email_verified) {
            dispatch(setIsLanguageDataLoading(true));
            userDataRequest(dispatch);
          } else {
            dispatch(setIsShowVerifiedContent(true));
            dispatch(setIsFetchSuccess(true));
            dispatch(setAuthLoader(false));
            logout(dispatch, false);
          }
        })
        .catch(() => {
          dispatch(setAuthLoader(false));
          dispatch(setIsFetchSuccess(true));
        });
    } else {
      userDataRequest(dispatch);
    }
  };

export const login =
  (email: string, password: string, isAfterSignUp = false, firstName = '') =>
  (dispatch: any) => {
    dispatch(setAuthLoader(true));
    auth0.client.login(
      {
        realm: process.env.REACT_APP_AUTH0_USER_DB ?? '',
        username: email,
        password,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE_URL,
        scope: 'openid profile',
      },
      async function (err, authResult) {
        if (err) {
          dispatch(setNotification({ message: err.description, type: 'error' }));
          dispatch(setAuthLoader(false));
        } else {
          setToken(authResult.accessToken);
          if (isAfterSignUp) {
            dispatch(updateFirstName(firstName));
            await $api
              .get('profile/permissions')
              .then(({ data }) => {
                if (data.email_verified) {
                  dispatch(setAuth(true));
                } else {
                  dispatch(setIsShowVerifiedContent(true));
                }
                dispatch(setIsFetchSuccess(true));
              })
              .finally(() => {
                dispatch(setAuthLoader(false));
              });
          } else {
            dispatch(getUser(true));
          }
        }
      }
    );
  };

export const registration =
  ({ email, password, firstName }: TSignUpValues, navigate: any) =>
  (dispatch: Dispatch | any) => {
    dispatch(setAuthLoader(true));
    dispatch(setIsFetchSuccess(false));
    auth0.signup(
      {
        email,
        password,
        connection: process.env.REACT_APP_AUTH0_USER_DB ?? '',
        username: firstName,
      },
      function (err, authResult) {
        if (err) {
          dispatch(setNotification({ message: err.description, type: 'error' }));
          dispatch(setAuthLoader(false));
          dispatch(setIsFetchSuccess(true));
        } else if (authResult) {
          dispatch(login(email, password, true, firstName));
          navigate('/profile');
        }
      }
    );
  };

export const socialSign = (socialType: string) => (dispatch: Dispatch | any) => {
  dispatch(setAuthLoader(true));
  auth0.authorize({
    redirectUri: window.location.origin,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE_URL,
    connection: socialType,
    responseType: 'token',
    scope: 'openid',
  });
};

export const resetPassword = (email: string) => (dispatch: Dispatch) => {
  dispatch(setAuthLoader(true));

  auth0.changePassword(
    {
      connection: process.env.REACT_APP_AUTH0_USER_DB ?? '',
      email,
    },
    function (error) {
      if (error) {
        console.log('error', error);
      } else {
        dispatch(setIsEmailSend(true));
      }
      dispatch(setAuthLoader(false));
    }
  );
};

export const setInitialSettings = (course: string, firstName: string) => (dispatch: any) => {
  $api
    .post('/profile/initial-settings', {
      first_name: firstName,
      course,
    })
    .then(() => {
      dispatch(setShowOnboarding(true));
      dispatch(getUser());
    })
    .catch((error) => {
      console.error(error);
    });
};

export const editUserGoal = (goal: string) => (dispatch: Dispatch) => {
  $api
    .post('/profile/update-goal', {
      goal,
    })
    .then(() => {
      dispatch(setUserGoal(goal));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const updateUserAvatar = (file: File) => (dispatch: Dispatch) => {
  const formData = new FormData(); // instantiate it
  formData.set('avatar', file);

  $api
    .post('/profile/update-avatar', formData)
    .then(() => {
      dispatch(setUserPicture(URL.createObjectURL(file)));
      closeModal(dispatch);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      dispatch(setIsModalLoading(false));
    });
};

export const clearUserAvatar = () => (dispatch: AppDispatch) => {
  $api
    .post('/profile/clear-avatar')
    .then(() => {
      dispatch(setUserPicture(null));
      dispatch(setIsModalLoading(false));
      closeModal(dispatch);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const unlockLesson = () => (dispatch: AppDispatch) => {
  $api
    .post('/courses/ru/unlock-lesson')
    .then(() => {
      dispatch(getUser());
    })
    .catch((error) => {
      console.error(error);
    });
};

export const updateFirstName = (firstName: string) => (dispatch: Dispatch) => {
  $api
    .patch('/profile/first-name', {
      first_name: firstName,
    })
    .then(() => {
      dispatch(setUserFirstName(firstName));
      dispatch(setModal(false));
      dispatch(setModalType(''));
      dispatch(setIsModalLoading(false));
      closeModal(dispatch);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getChangePlanInfo = (priceId: string) => (dispatch: Dispatch) => {
  $api
    .get(`subscriptions/change-plan/preview/${priceId}`)
    .then(({ data }) => {
      dispatch(setPriceDifference(data.data));
    })
    .catch(() => {
      dispatch(setModal(false));
      dispatch(setNotification({ message: 'Something went wrong', type: 'error' }));
    });
};

export const getBillingHistory = () => (dispatch: Dispatch) => {
  $api.get('subscriptions/billing-history').then(({ data }) => {
    dispatch(setBillingHistory(data.data));
  });
};

export const changePassword =
  ({ currentPassword, newPassword, confirmedPassword }: TChangesPassword) =>
  (dispatch: Dispatch) => {
    dispatch(setIsModalLoading(true));
    $api
      .patch('/profile/change-password', {
        old_password: currentPassword,
        new_password: newPassword,
        password_confirmation: confirmedPassword,
      })
      .then(() => {
        dispatch(
          setNotification({ message: 'Your password was successfully changed.', type: 'success' })
        );
        dispatch(setModal(false));
        dispatch(setModalType(''));
      })
      .catch((err) => {
        dispatch(setNotification({ message: err?.response?.data?.error?.message, type: 'error' }));
      })
      .finally(() => {
        dispatch(setIsModalLoading(false));
      });
  };

export const {
  setAuth,
  setAuthLoader,
  setCurrentUser,
  setErrorMessage,
  setIsEmailSend,
  setIsFetchSuccess,
  setShowOnboarding,
  setUserFirstName,
  setUserGoal,
  setUserPicture,
  setStatistics,
  setRedirectUrl,
  setSubscription,
  setPriceDifference,
  setBillingHistory,
  setIsShowVerifiedContent,
} = authSlice.actions;

export default authSlice.reducer;
