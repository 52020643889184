import React from 'react';

import styles from './progressLine.module.scss';

interface IProgressLineProps {
  value: number;
}
const ProgressLine: React.FC<IProgressLineProps> = ({ value }) => {
  return <progress className={styles.progressLine} max={100} value={value} />;
};

export default ProgressLine;
