import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../../features/atoms/Button';
import styles from '../../features/organisms/AuthViews/auth.module.scss';
import { getIsEmailSend } from '../../store/selectors/authSelectors';
import { setIsEmailSend } from '../../store/slices/auth';

interface ISendEmailNotificationProps {
  email: string;
}

const SendEmailNotification: React.FC<ISendEmailNotificationProps> = ({ email }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEmailSend = useSelector(getIsEmailSend);
  const handleOnclick = () => {
    navigate('/login');
    dispatch(setIsEmailSend(false));
  };
  return (
    <>
      {isEmailSend && (
        <div className={styles.checkEmailBlock}>
          <div className={styles.checkEmailImage} />
          <div className={styles.checkEmailText}>
            If account for <strong>{email}</strong> exist, an e-mail will be sent with further
            instructions.
          </div>
          <Button onClick={handleOnclick} type="primary" value="Back to Log In" />
        </div>
      )}
    </>
  );
};

export default SendEmailNotification;
