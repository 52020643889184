import React from 'react';

const IconErrorRed: React.FC = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_308_41224)">
        <circle cx="21" cy="21" r="21" fill="url(#paint0_linear_308_41224)" />
        <circle cx="21" cy="21" r="21" fill="url(#paint1_linear_308_41224)" />
      </g>
      <g filter="url(#filter1_d_308_41224)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3991 30.9331C28.3754 31.9094 29.9583 31.9094 30.9346 30.9331C31.9109 29.9568 31.9109 28.3739 30.9346 27.3976L24.5363 20.9993L30.9346 14.6011C31.9109 13.6248 31.9109 12.0419 30.9346 11.0656C29.9583 10.0893 28.3754 10.0893 27.3991 11.0656L21.0008 17.4638L14.6013 11.0643C13.625 10.088 12.042 10.088 11.0657 11.0643C10.0894 12.0406 10.0894 13.6235 11.0657 14.5998L17.4653 20.9993L11.0657 27.3989C10.0894 28.3752 10.0894 29.9581 11.0657 30.9344C12.042 31.9107 13.625 31.9107 14.6013 30.9344L21.0008 24.5349L27.3991 30.9331Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3991 30.9331C28.3754 31.9094 29.9583 31.9094 30.9346 30.9331C31.9109 29.9568 31.9109 28.3739 30.9346 27.3976L24.5363 20.9993L30.9346 14.6011C31.9109 13.6248 31.9109 12.0419 30.9346 11.0656C29.9583 10.0893 28.3754 10.0893 27.3991 11.0656L21.0008 17.4638L14.6013 11.0643C13.625 10.088 12.042 10.088 11.0657 11.0643C10.0894 12.0406 10.0894 13.6235 11.0657 14.5998L17.4653 20.9993L11.0657 27.3989C10.0894 28.3752 10.0894 29.9581 11.0657 30.9344C12.042 31.9107 13.625 31.9107 14.6013 30.9344L21.0008 24.5349L27.3991 30.9331Z"
          fill="url(#paint2_linear_308_41224)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_308_41224"
          x="-2"
          y="-2"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_41224" />
        </filter>
        <filter
          id="filter1_d_308_41224"
          x="8.3335"
          y="8.33203"
          width="29.3335"
          height="29.3359"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_41224" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_308_41224"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_308_41224"
          x1="0"
          y1="0"
          x2="42"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_308_41224"
          x1="5.25"
          y1="5.83333"
          x2="36.75"
          y2="37.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F56E32" />
          <stop offset="1" stopColor="#DD0D03" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_308_41224"
          x1="21.0002"
          y1="10.332"
          x2="21.0002"
          y2="31.6667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconErrorRed;
