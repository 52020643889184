import React from 'react';

import { SCREENS } from '../../constants/sccrens';
import useMediaQuery from '../../hooks/useMediaQuery';
import styles from './loader.module.scss';

interface ISpinnerProps {
  parentHeight?: number;
  color?: string;
}
const LOADER_CYCLES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Loader: React.FC<ISpinnerProps> = ({ parentHeight, color }) => {
  const isMobile = useMediaQuery(SCREENS.mobile);
  const isTablet = useMediaQuery(SCREENS.tablet);
  const height = parentHeight
    ? isMobile
      ? 0.5 * parentHeight
      : isTablet
      ? 0.8 * parentHeight
      : parentHeight
    : 90;

  return (
    <div
      className={styles.loaderParent}
      style={{
        height: `${height}${parentHeight ? 'px' : '%'}`,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={styles.ldsDefault}
        style={{
          transform: `scale(${isMobile ? 0.6 : isTablet ? 0.8 : 1})`,
        }}
      >
        {LOADER_CYCLES.map((el) => {
          return (
            <div
              style={{
                background: color || '#0185F3',
              }}
              key={el}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Loader;
