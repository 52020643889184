import { createSlice, Dispatch } from '@reduxjs/toolkit';

import $api from '../../Service/api/intercepter';
import { ICoursesData } from '../../types/courses';
import { MODAL_TYPES } from '../../types/modals';
import { closeModal } from '../../utils/modal';
import { store } from '../store';
import { IUserCourses } from '../types';
import { setModal, setModalType } from './modal';
import { setQuestionData, setReviewStatisticsData } from './review';
import { setLessons } from './study';

const initialState: IUserCourses = {
  course: {},
  courses: {},
  changingCourse: '',
  isCourseLoading: false,
};

export const coursesSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setAllCourseData: (state, action) => {
      return {
        ...action.payload,
        isCourseLoading: false,
      };
    },
    updateCourses: (state, action) => {
      return {
        ...state,
        courses: {
          ...state.courses,
          ...action.payload,
        },
      };
    },
    setChangingCourse: (state, action) => {
      return {
        ...state,
        changingCourse: action.payload,
      };
    },
    setInitialCourse: (state, action) => {
      return {
        ...state,
        course: {
          ...state.courses,
          initial: action.payload,
        },
      };
    },
    setCurrentCourse: (state, action) => {
      return {
        ...state,
        course: {
          ...state.courses,
          current: action.payload,
        },
      };
    },
    setIsCourseLoading: (state, action) => {
      return {
        ...state,
        isCourseLoading: action.payload,
      };
    },
    removeCourseData: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const addUserCourse = (course: string) => (dispatch: Dispatch) => {
  $api
    .post(`/profile/add-course/${course}`)
    .then(() => {
      const data = {
        [course]: {
          enabled: true,
        },
      };
      dispatch(updateCourses(data));
      dispatch(setChangingCourse(course));
      dispatch(setModal(true));
      dispatch(setModalType(MODAL_TYPES.selectCourse));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const removeCourse =
  (removingCourse: string, allCourses: { [key: string]: any }) => (dispatch: Dispatch) => {
    $api
      .post(`/profile/remove-course/${removingCourse}`)
      .then(() => {
        const course: ICoursesData = allCourses[removingCourse];
        const data = {
          [removingCourse]: {
            ...course,
            enabled: false,
          },
        };

        dispatch(updateCourses(data));
        dispatch(setChangingCourse(''));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        closeModal(dispatch);
      });
  };

export const resetCourse = (resettingCourse: string) => (dispatch: Dispatch) => {
  $api
    .post(`/profile/reset-course/${resettingCourse}`)
    .then(() => {
      dispatch(setLessons({}));
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      closeModal(dispatch);
    });
};

export const updateCurrentCourse = (course: string) => {
  return (dispatch: Dispatch, getState: typeof store.getState) => {
    $api
      .patch(
        '/profile/current-course',
        {},
        {
          params: {
            course: course,
          },
        }
      )
      .then(() => {
        dispatch(setCurrentCourse(course));
        if (getState().review.questionData) {
          dispatch(setQuestionData(null));
          dispatch(setReviewStatisticsData({}));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const {
  setAllCourseData,
  updateCourses,
  setChangingCourse,
  setCurrentCourse,
  setInitialCourse,
  setIsCourseLoading,
  removeCourseData,
} = coursesSlice.actions;

export default coursesSlice.reducer;
