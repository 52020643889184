import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconClose, IconSuccess } from '../../assets/imgComponent/IconNotifications';
import { setDefaultNotification } from '../../store/slices/notification';
import { INotification, TState } from '../../store/types';
import styles from './notification.module.scss';

const Notification: React.FC = () => {
  const { isOpen, message, type }: INotification = useSelector(
    (state: TState) => state.notification
  );
  const dispatch = useDispatch();
  const handleOnclick = () => {
    dispatch(setDefaultNotification());
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        handleOnclick();
      }, 5000);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className={`${styles.notification} ${styles[type]}`}>
          {type !== 'error' && (
            <span className={styles.notificationIcon}>
              <IconSuccess />
            </span>
          )}
          <p className={styles.notificationText}>{message}</p>
          <div className={styles.notificationClose} onClick={handleOnclick}>
            <IconClose />
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
