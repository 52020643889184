export const FLAG_ICONS_CHARS: Record<string, string> = {
  ru: 'ru',
  jp: 'jp',
  fr: 'fr',
  zh: 'cn',
  es: 'es',
  he: 'il',
  en: 'us',
  de: 'de',
  pt: 'pt',
  kr: 'kr',
  vn: 'vn',
  th: 'th',
  se: 'se',
  cz: 'cz',
  ir: 'ir',
  ar: 'sa',
};

// @todo remove
// export const COURSES_DATA: { [key: string]: ILangData } = {
//   ru: {
//     Icon: IconLangRu,
//     text: 'Russian',
//   },
//   jp: {
//     Icon: IconLangJp,
//     text: 'Japanese',
//   },
//   fr: {
//     Icon: IconLangFr,
//     text: 'French',
//   },
//   es: {
//     Icon: IconLangSp,
//     text: 'Spanish',
//   },
//   he: {
//     Icon: IconLangHb,
//     text: 'Hebrew',
//   },
// };
