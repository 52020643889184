import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import AppHeader from '../components/AppHeader';
import Loader from '../components/Loader';
import ChooseLanguage from '../features/organisms/ChooseLanguage';
import { useUserCourses } from '../hooks/useUserCourses';
import { getIsAuthenticated, getIsFetchSuccess } from '../store/selectors/authSelectors';
import { PRIVATE_ROUTES, ROUTES_FOR_UNSIGNED_USER, RoutType } from './privateRoutes';

const AllRoutes: React.FC = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isFetchSuccess = useSelector(getIsFetchSuccess);
  const { isUserHasCourses } = useUserCourses();

  const getComponentForSignedUser = useCallback(
    (route: RoutType) => {
      return isFetchSuccess ? (
        !isAuthenticated ? (
          <Navigate to={`/${route.redirectUrl}`} replace />
        ) : isUserHasCourses ? (
          <route.Component />
        ) : (
          <ChooseLanguage />
        )
      ) : (
        <>
          <AppHeader />
          <Loader />
        </>
      );
    },
    [isFetchSuccess, isUserHasCourses, isAuthenticated]
  );

  const getComponentForUnSignedUser = useCallback(
    (route: RoutType) => {
      return isFetchSuccess ? (
        !isAuthenticated ? (
          <route.Component />
        ) : (
          <Navigate to={`/${route.redirectUrl}`} replace />
        )
      ) : (
        <>
          <AppHeader />
          <Loader />
        </>
      );
    },
    [isFetchSuccess, isAuthenticated]
  );

  return (
    <Routes>
      {ROUTES_FOR_UNSIGNED_USER.map((route) => {
        return (
          <Route
            path={`/${route.path}`}
            key={route.path}
            element={getComponentForUnSignedUser(route)}
          />
        );
      })}
      {PRIVATE_ROUTES.map((route) => {
        return (
          <Route
            path={`/${route.path}`}
            key={route.path}
            element={getComponentForSignedUser(route)}
          />
        );
      })}
      <Route path="*" element={<Navigate to={'/login'} />} />
    </Routes>
  );
};

export default AllRoutes;
