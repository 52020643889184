import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { PROFILE_SIDEBAR_MENU } from '../../constants/userProfile';
import styles from './tablet-profile-nav.module.scss';

const TabletProfileNavigation: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <nav className={styles.tabletProfileMenu}>
      <ul>
        {PROFILE_SIDEBAR_MENU.map(({ text, url, Icon }) => (
          <li key={text}>
            <Link to={url} className={`${pathname === url ? styles.active : ''}`}>
              <Icon />
              <span>{text}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TabletProfileNavigation;
