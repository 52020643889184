import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import IconDropdown from '../../../assets/imgComponent/Dropdown';
import IconPlusBlue from '../../../assets/imgComponent/PlusBlue';
import { SCREENS } from '../../../constants/sccrens';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useOutsideDetect } from '../../../hooks/useOutsideDetect';
import { useUserCourses } from '../../../hooks/useUserCourses';
import { getIsReviewLoading } from '../../../store/selectors/review';
import styles from './dropdow.module.scss';

interface iDropdownProps {
  dropdownStyle?: 'basic' | 'header';
  customClass?: string;
  selectedItem?: any;
}

const Dropdown: React.FC<iDropdownProps> = ({
  dropdownStyle,
  customClass,
  selectedItem,
  children,
}) => {
  const [isActive, setActive] = useState(false);
  const ref = useOutsideDetect(setActive);
  const isReviewIsLoading = useSelector(getIsReviewLoading);
  const { availableCourses } = useUserCourses();

  const toggleState = () => {
    if (!isReviewIsLoading) {
      setActive(!isActive);
    }
    (window as any).Intercom('hide');
  };
  const isMobile = useMediaQuery(SCREENS.mobile);
  return (
    <dl
      ref={ref}
      className={`
            ${styles.dropdown} 
            ${customClass}
            ${isActive ? 'isActive' : ''}
            `}
      data-style={dropdownStyle}
    >
      <dt
        className={styles.dropdownLabel}
        style={{
          cursor: isReviewIsLoading ? 'progress' : 'pointer',
        }}
        onClick={toggleState}
      >
        <div>{selectedItem}</div>
        {!isMobile && <IconDropdown />}
      </dt>
      {isActive ? (
        <dd className={styles.dropdownBody}>
          <ul className={styles.dropdownItems} onClick={() => setActive(false)}>
            {children}
          </ul>
          {dropdownStyle === 'header' && !!availableCourses?.length && (
            <Link to="/courses" className={styles.addItem}>
              <i>
                <IconPlusBlue />
              </i>
              <span>Add more courses</span>
            </Link>
          )}
        </dd>
      ) : (
        ''
      )}
    </dl>
  );
};

export default Dropdown;
