import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GoalMobileIcon from '../../assets/imgComponent/GoalMobileIcons';
import { GOAL_INITIAL, TGoalValues } from '../../constants/authFLow';
import { SCREENS } from '../../constants/sccrens';
import Button from '../../features/atoms/Button';
import Input from '../../features/atoms/Input';
import useMediaQuery from '../../hooks/useMediaQuery';
import { getUserGoal } from '../../store/selectors/authSelectors';
import { editUserGoal } from '../../store/slices/auth';
import { GoalValidationScheme, handleSetFieldTouched } from '../../utils/authValidation';
import styles from './goal.module.scss';

const UserGoal: React.FC = () => {
  const [goal, setGoal] = useState(useSelector(getUserGoal));
  const [isEditGoal, setIsEditGoal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      ...GOAL_INITIAL,
    },
    validationSchema: GoalValidationScheme,
    onSubmit: (values: TGoalValues) => {
      dispatch(editUserGoal(values.goal));
      setGoal(values.goal);
      setIsEditGoal(false);
    },
  });

  useEffect(() => {
    formik.setFieldValue('goal', goal);
  }, [isEditGoal]);

  const isMobile = useMediaQuery(SCREENS.mobile);

  return (
    <section
      className={`${styles.profileGoal} ${isMobile && isEditGoal ? styles.profileGoalMobile : ''}`}
    >
      <div className={styles.profileGoalItem}>
        <div className={styles.profileGoalIcon} />
        <form onSubmit={formik.handleSubmit} action="" autoComplete={'nope'}>
          {isEditGoal ? (
            <Input
              htmlFor="goal"
              type={isMobile ? 'textarea' : 'text'}
              name="goal"
              label={''}
              placeHolder={isEditGoal ? 'Enter your goal' : 'Add a new goal for your motivation'}
              onBlur={(e: any) => handleSetFieldTouched(e, formik)}
              error={formik.touched.goal && formik.errors.goal}
              onChange={(e) => {
                formik.setFieldTouched(e.target.name, false);
                formik.handleChange(e);
              }}
              value={formik.values.goal}
              onClick={formik.setFieldTouched}
            />
          ) : (
            <div className={styles.profileGoalText}>
              {goal || 'Add a new goal for your motivation'}
            </div>
          )}

          {isEditGoal ? (
            <>
              <Button onClick={() => setIsEditGoal(false)} value={'Cancel'} type={'secondary'} />
              <Button submit type="primary" value={'Save'} />
            </>
          ) : (
            <>
              {isMobile ? (
                <div onClick={() => setIsEditGoal(true)} style={{ cursor: 'pointer' }}>
                  <GoalMobileIcon />
                </div>
              ) : (
                <Button
                  onClick={() => setIsEditGoal(true)}
                  value={goal ? 'Edit Goal' : 'Add Goal'}
                  type={'secondary'}
                />
              )}
            </>
          )}
        </form>
      </div>
    </section>
  );
};

export default UserGoal;
