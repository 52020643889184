import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EDIT_INITIAL, TEditValues } from '../../../../constants/authFLow';
import {
  getCurrentUser,
  getUserEmail,
  getUserImg,
  getUserName,
} from '../../../../store/selectors/authSelectors';
import { clearUserAvatar, updateFirstName, updateUserAvatar } from '../../../../store/slices/auth';
import { setIsModalLoading } from '../../../../store/slices/modal';
import { EditValidationScheme, handleSetFieldTouched } from '../../../../utils/authValidation';
import Button from '../../../atoms/Button';
import Input from '../../../atoms/Input';
import ModalUserPhoto from '../../../atoms/ModalUserPhoto';
import ChangePassword from './ChangePassword';
import styles from './edit-profile.module.scss';

const EditProfile: React.FC = () => {
  const dispatch = useDispatch();
  const firstName: string = useSelector(getUserName) || '';
  const email: string = useSelector(getUserEmail) || '';
  const { provider } = useSelector(getCurrentUser);
  const userImg = useSelector(getUserImg) || '';
  const [isChangePassword, setIsChangePassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      ...EDIT_INITIAL,
    },
    validateOnMount: false,
    validationSchema: EditValidationScheme,
    onSubmit: (values: TEditValues) => {
      if (isAllowSubmit) {
        dispatch(setIsModalLoading(true));

        if (values.first_name !== firstName) {
          dispatch(updateFirstName(values.first_name));
        }

        if (typeof values.image !== 'string') {
          if (values.image) {
            dispatch(updateUserAvatar(values.image));
          } else {
            dispatch(clearUserAvatar());
          }
        }
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      first_name: firstName,
      image: userImg,
    });
  }, []);

  const isAllowSubmit = useMemo(() => {
    const { first_name, image } = formik.values;

    // case: there are validation errors or any field is not touched
    if (!Object.values(formik.touched).length || Object.values(formik.errors).length) {
      return false;
    }

    if (first_name === firstName && typeof image === 'string') {
      return false;
    }

    return true;
  }, [formik, firstName]);

  const setUserImgFile = (file: null | File) => {
    formik.setFieldValue('image', file);
    formik.setFieldTouched('image');
  };

  return (
    <>
      {isChangePassword ? (
        <ChangePassword setIsChangePassword={setIsChangePassword} />
      ) : (
        <form className={styles.editProfile} onSubmit={formik.handleSubmit}>
          <ModalUserPhoto setUserImgFile={setUserImgFile} />
          <Input
            htmlFor="first_name"
            type="text"
            name="first_name"
            placeHolder="Enter your first name"
            label="First name"
            onClick={formik.setFieldTouched}
            onBlur={(e: any) => handleSetFieldTouched(e, formik)}
            error={formik.errors.first_name}
            onChange={(e) => {
              formik.setFieldTouched(e.target.name, false);
              formik.handleChange(e);
            }}
            value={formik.values.first_name}
          />
          <div className={styles.userEmail}>
            <span>Email</span>
            <p>{email}</p>
          </div>
          {provider === 'auth0' && (
            <button
              className={styles.changePassBtn}
              type={'button'}
              onClick={(e) => {
                setIsChangePassword((prev) => !prev);
              }}
            >
              Change password
            </button>
          )}
          <Button submit type={'primary'} value="Save changes" disabled={!isAllowSubmit} />
        </form>
      )}
    </>
  );
};

export default EditProfile;
