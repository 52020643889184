import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getChangingCourse } from '../../../../store/selectors/courseSelectors';
import { getLanguageDataFromStore } from '../../../../store/selectors/studySelectors';
import { setShowOnboarding } from '../../../../store/slices/auth';
import { getPlacementTest, setIsPlacementTest } from '../../../../store/slices/placementTest';
import { removeStudyData } from '../../../../store/slices/study';
import { setChangingCourse, updateCurrentCourse } from '../../../../store/slices/userCourses';
import { closeModal } from '../../../../utils/modal';
import WelcomePageInfoCard from '../../../views/WelcomePage/WelcomeInfoCard';
import styles from './select_course.module.scss';

const SelectCourse: React.FC = () => {
  const selectedCourse = useSelector(getChangingCourse) || '';
  const languageData = useSelector(getLanguageDataFromStore);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cancelModal = () => {
    closeModal(dispatch);
    dispatch(setChangingCourse(''));
  };

  const updateCourse = () => {
    dispatch(updateCurrentCourse(selectedCourse));
    dispatch(removeStudyData());
  };

  const goStudy = () => {
    updateCourse();
    navigate('/study');
    cancelModal();
  };

  const launchPlacementTest = () => {
    updateCourse();
    dispatch(getPlacementTest(selectedCourse));
    dispatch(setIsPlacementTest(true));
    dispatch(setShowOnboarding(false));
    navigate('/placementTest');
    cancelModal();
  };
  return (
    <div className={styles.selectCourseModal}>
      <div className={styles.flagContainer}>
        {languageData && (
          <>
            <div className={styles.flagIcon}>
              <img src={languageData[selectedCourse].imgUrl} />
            </div>
            {languageData[selectedCourse].name}
          </>
        )}
      </div>
      <div className={styles.sideBySideItems}>
        <div className={styles.colItem}>
          <WelcomePageInfoCard
            text="If you would like to do a placement test to determine the best level to start at, just click the button."
            buttonValue={'Launch placement test'}
            buttonClick={launchPlacementTest}
          />
        </div>
        <div className={styles.colItem}>
          <WelcomePageInfoCard
            text="Or you can jump right into the lessons:"
            buttonValue="Let’s go!"
            buttonClick={goStudy}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectCourse;
