import React from 'react';
import { useDispatch } from 'react-redux';

import { SIGN_METHODS } from '../../constants/authFLow';
import { socialSign } from '../../store/slices/auth';
import styles from './singMethods.module.scss';

const AdditionalSignMethods = () => {
  const dispatch = useDispatch();
  const handleOnclick = (type: string) => {
    const socialType = type === 'google' ? 'google-oauth2' : type;
    dispatch(socialSign(socialType));
  };
  return (
    <div className={styles.singMethodsWrapper}>
      {SIGN_METHODS.map((item) => (
        <div
          key={item}
          onClick={() => handleOnclick(item)}
          className={`${styles.singMethodsItem} ${styles[item]}`}
        >
          <span />
        </div>
      ))}
    </div>
  );
};

export default AdditionalSignMethods;
