import React from 'react';
import Iframe from 'react-iframe';

import { IBackendDataContent } from '../../../../types/study';
import styles from './video.module.scss';

interface ITextComponentProps {
  content: IBackendDataContent;
}

const VideoComponent: React.FC<ITextComponentProps> = ({ content }) => {
  return (
    <div className={styles.videoContent}>
      <Iframe
        url={`https://www.youtube-nocookie.com/embed/${content.video_id}`}
        width="600px"
        height="400px"
        className="myClassname"
        position="relative"
      />
    </div>
  );
};

export default VideoComponent;
