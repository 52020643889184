import React from 'react';

import Checkbox from '../../../features/atoms/Checkbox';
import Dropdown from '../../../features/moleculs/Dropdown';
import styles from './profile-notifications.module.scss';

const NotificationPage: React.FC = () => {
  return (
    <div className={styles.notificationWrapper}>
      <h2>Notifications about skills to review</h2>
      <div className={styles.notificationItem}>
        <p>Push-notifications</p>
        <Checkbox name={'review'} />
      </div>

      <div className={styles.notificationItem}>
        <p>Email notifications</p>
        <Checkbox name={'email'} />
      </div>

      <div className={styles.notificationItem}>
        <p>When to be notified:</p>
        <Dropdown dropdownStyle="basic" selectedItem="7 AM">
          <li>5 AM</li>
          <li>6 AM</li>
          <li>7 AM</li>
          <li>8 AM</li>
          <li>9 AM</li>
        </Dropdown>
      </div>
    </div>
  );
};

export default NotificationPage;
