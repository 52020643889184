import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { setCurrentLessonAndLevel } from '../../store/slices/study';
import styles from './placment.module.scss';

interface ICongratulation {
  level: number;
  isTestFinish: boolean;
}

const Congratulation: React.FC<ICongratulation> = ({ level, isTestFinish }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (level) {
      dispatch(
        setCurrentLessonAndLevel({
          level,
        })
      );
    }
  };
  return (
    <div className={styles.testComplete}>
      <div className={styles.testCompleteImage}></div>
      <h3>Congratulations!</h3>
      <p>
        You completed the placement test. <br />
        {isTestFinish
          ? 'Because you’ve done so well on your test, we recommend you explore our lesson titles and choose the topics that interest you the most.'
          : 'Judging by your performance, we recommend:'}
      </p>
      <Link to="/study" className="link-button" onClick={handleClick}>
        {isTestFinish ? 'Start learning' : `Starting at Level ${level}`}
      </Link>
      <span>
        {!isTestFinish &&
          'But of course, the decision is entirely up to you. Feel free to browse through the lessons to find what interests you.'}{' '}
        <strong>Good luck</strong>!
      </span>
    </div>
  );
};

export default Congratulation;
