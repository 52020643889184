import { useEffect, useRef } from 'react';

export const useOutsideDetect = (setterFunction?: (argument: boolean) => void) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node) && setterFunction) {
        setterFunction(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setterFunction]);

  return ref;
};
