import React, { memo } from 'react';

import IconCheckedGreen from '../../../assets/imgComponent/IconCheckedGreen';
import IconErrorRed from '../../../assets/imgComponent/IconErrorRed';
import styles from './result.module.scss';

interface ITestKeyProps {
  answer: string;
  isVisible: boolean;
  isCorrect: boolean;
}
const ResultComponent: React.FC<ITestKeyProps> = ({ answer, isVisible, isCorrect }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.assessmentNotification}>
      {isCorrect ? (
        <div className={styles.notifySuccess}>
          <IconCheckedGreen />
          <div>
            <h4>Awesome!</h4>
            <p>You’re correct!</p>
          </div>
        </div>
      ) : (
        <div className={styles.notifyError}>
          <header>
            <IconErrorRed />
            <div>
              <h4>Oops</h4>
              <p>That’s not correct</p>
            </div>
          </header>
          <footer>
            Right answer: <span>{answer}</span>
          </footer>
        </div>
      )}
    </div>
  );
};

export default memo(ResultComponent);
