import { createSlice, Dispatch } from '@reduxjs/toolkit';

import $api from '../../Service/api/intercepter';
import { IPriceItem } from '../../types/billing';
import { IBilling } from '../types';

const initialState: IBilling = {
  planData: null,
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setPlanData: (state, action) => {
      return {
        ...state,
        planData: action.payload,
      };
    },
  },
});

export const getPlanData = () => (dispatch: Dispatch) => {
  $api.get('/subscriptions/pricing').then(({ data }) => {
    const prices: Array<IPriceItem> = data.data.prices;
    dispatch(setPlanData(prices));
  });
};

export const { setPlanData } = billingSlice.actions;

export default billingSlice.reducer;
