import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from '../../features/moleculs/Dropdown';
import { useUserCourses } from '../../hooks/useUserCourses';
import { getLanguageDataFromStore } from '../../store/selectors/studySelectors';
import { removeStudyData } from '../../store/slices/study';
import { updateCurrentCourse } from '../../store/slices/userCourses';
import styles from '../ProfileComponents/CoursesPage/courses.module.scss';

interface IAppHeaderProps {
  type?: 'primary' | 'secondary';
  customClass?: string;
  isUserSingedIn?: boolean;
}

const ChooseCurrentCourse: React.FC<IAppHeaderProps> = () => {
  const { pickedOutCourses, currentCourse } = useUserCourses();
  const languageData = useSelector(getLanguageDataFromStore);

  const dispatch = useDispatch();

  const selectedData = () => {
    if (currentCourse && languageData) {
      const { name, imgUrl } = languageData[currentCourse];

      return (
        <>
          <div className={styles.flagIcon}>
            <img src={imgUrl} />
          </div>
          <span>{name}</span>
        </>
      );
    }
  };

  const handleUpdate = (course: string) => {
    dispatch(updateCurrentCourse(course));
    dispatch(removeStudyData());
  };

  if (!currentCourse) {
    return null;
  }
  return (
    <Dropdown dropdownStyle="header" selectedItem={selectedData()}>
      {languageData &&
        pickedOutCourses.map((course) => {
          const { name, imgUrl } = languageData[course];

          if (course !== currentCourse) {
            return (
              <li key={course} onClick={() => handleUpdate(course)}>
                <div className={styles.flagIcon}>
                  <img src={imgUrl} />
                </div>
                <span>{name}</span>
              </li>
            );
          }
        })}
    </Dropdown>
  );
};

export default ChooseCurrentCourse;
