import './styles.scss';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppHeader from '../../../components/AppHeader';
import ChooseLangIcon from '../../../components/ChooseLangIcon';
import LinguaLiftDescription from '../../../components/LinguaLiftDescription';
import Loader from '../../../components/Loader';
import { SCREENS } from '../../../constants/sccrens';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { getAuthLoading, getUserName } from '../../../store/selectors/authSelectors';
import { getLanguageDataFromStore } from '../../../store/selectors/studySelectors';
import { setInitialSettings } from '../../../store/slices/auth';
import Button from '../../atoms/Button';
import styles from './chooseLanguage.module.scss';

const ChooseLanguage: React.FC = () => {
  const dispatch = useDispatch();
  const [course, setCourse] = useState<string>('');
  const name = useSelector(getUserName);
  const isLoading = useSelector(getAuthLoading) || !name;
  const languageData = useSelector(getLanguageDataFromStore);

  const isMobile = useMediaQuery(SCREENS.mobile);

  const handleChange = (course: string) => {
    setCourse(course);
  };
  const handleClick = () => {
    dispatch(setInitialSettings(course, name || ''));
  };
  return (
    <>
      <AppHeader />
      {isLoading || !languageData ? (
        <Loader />
      ) : (
        <div className={`${styles.langToLearnWrapp}`}>
          {isMobile && <LinguaLiftDescription />}
          <h2 className={`${styles.langToLearnHeading}`}>Choose the language you want to learn:</h2>
          <div className={`${styles.langToLearn}`}>
            {languageData &&
              Object.keys(languageData).map((item) => {
                return (
                  <div className={`${styles.langToLearnItem}`} key={item}>
                    <input
                      onChange={() => handleChange(languageData[item].code)}
                      type="radio"
                      name="language"
                      value={languageData[item].code}
                      id={languageData[item].code}
                    />
                    <label htmlFor={languageData[item].code}>
                      <ChooseLangIcon
                        lang={languageData[item].name}
                        imgUrl={languageData[item].imgUrl}
                      />
                    </label>
                  </div>
                );
              })}
          </div>
          <Button
            disabled={!course}
            onClick={handleClick}
            submit
            type="primary"
            value="Let’s go!"
            customClass="langToLearnButton"
          />
          {!isMobile && <LinguaLiftDescription />}
        </div>
      )}
    </>
  );
};

export default ChooseLanguage;
