/* eslint-disable no-unused-vars */
import React from 'react';

import styles from './input.module.scss';

type isTruthyEqual = {
  touched: boolean;
  error: string;
  value: string;
};

interface IInputProps {
  type: 'text' | 'password' | 'textarea';
  isPassInput?: boolean;
  htmlFor: string;
  label: string;
  placeHolder: string;
  value: string;
  name: string;
  onClick: (current: string) => void;
  onBlur: any;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  error?: any;
  passShowMode?: boolean;
  setPassShowMode?: (arg: boolean) => void;
  repeatMode?: boolean;
  isTruthyEqual?: isTruthyEqual;
  customClass?: string;
}

const Input: React.FC<IInputProps> = ({
  htmlFor,
  label,
  placeHolder,
  value,
  onChange,
  name,
  error,
  type,
  onClick,
  isPassInput = false,
  passShowMode = false,
  setPassShowMode,
  repeatMode = false,
  isTruthyEqual,
  onBlur,
}) => {
  return (
    <>
      {!isPassInput ? (
        <div
          onClick={() => onClick(name)}
          className={`${styles.inputWrapper} ${error && styles.inputWrapperError}`}
        >
          <label htmlFor={htmlFor} id={label} className={`${styles.inputLabel}`}>
            {label}
          </label>
          {type === 'textarea' ? (
            <textarea
              name={name}
              id={htmlFor}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder={placeHolder}
              className={`${styles.inputField}`}
            />
          ) : (
            <input
              name={name}
              type={type}
              id={htmlFor}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder={placeHolder}
              className={`${styles.inputField}`}
            />
          )}

          {error && <p>{error}</p>}
        </div>
      ) : (
        <div
          onClick={() => onClick(name)}
          className={`${styles.inputWrapper} ${error && styles.inputWrapperError}`}
        >
          <label htmlFor={htmlFor} id={label} className={`${styles.inputLabel}`}>
            {label}
          </label>
          <div className={`${styles.inputPassword}`}>
            <input
              name={name}
              type={passShowMode ? 'text' : 'password'}
              id={htmlFor}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder={placeHolder}
              className={`${styles.inputField}`}
            />
            {setPassShowMode && (
              <button
                type="button"
                onClick={() => setPassShowMode(!passShowMode)}
                className={`${styles.inputPasswordIcon}`}
              />
            )}
            {repeatMode && isTruthyEqual?.touched && isTruthyEqual?.value && (
              <div
                style={{ background: `${isTruthyEqual.error !== '' ? '#981616' : '#2FAD4B'}` }}
              />
            )}
          </div>
          {error && <p>{error}</p>}
        </div>
      )}
    </>
  );
};

export default Input;
