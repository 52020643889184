import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentCourse, getUserCourses } from '../store/selectors/courseSelectors';
import { getLanguageDataFromStore } from '../store/selectors/studySelectors';
import { ICoursesData } from '../types/courses';

export const useUserCourses = () => {
  const userCourses = useSelector(getUserCourses) as { [key: string]: ICoursesData };
  const languageData = useSelector(getLanguageDataFromStore);

  // if user dont have current course yet
  const currentCourse =
    useSelector(getCurrentCourse) || (languageData ? Object.keys(languageData)[0] : '');

  const [pickedOutCourses, setPickedOutCourses] = useState<Array<string>>([]);
  const [availableCourses, setAvailableCourses] = useState<Array<string>>([]);
  const [isUserHasCourses, setIsUserHasCourses] = useState<boolean>(false);

  useEffect(() => {
    if (languageData) {
      const languageCodes = Object.keys(languageData);
      if (languageCodes.length) {
        if (userCourses && Object.keys(userCourses).length) {
          const userCoursesKey: Array<string> = [];
          for (const key in userCourses) {
            const data = userCourses[key] as ICoursesData;
            if (data.enabled && languageData[key]) {
              userCoursesKey.push(key);
            }
          }
          const projectCourses: Array<string> = languageCodes.filter(
            (item) => !userCoursesKey.includes(item)
          );

          const data = projectCourses.filter((item) => !userCoursesKey.includes(item));
          setPickedOutCourses(userCoursesKey);
          setAvailableCourses(data);
          setIsUserHasCourses(!!userCoursesKey.length);
        } else {
          setIsUserHasCourses(false);
        }
      }
    }
  }, [userCourses, languageData]);

  return {
    pickedOutCourses,
    availableCourses,
    isUserHasCourses,
    currentCourse: languageData
      ? languageData[currentCourse]
        ? currentCourse
        : Object.keys(languageData)[0]
      : '',
  };
};
