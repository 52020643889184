import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getActivitiesDataFromStore,
  getCurrentActivity,
} from '../../../../store/selectors/studySelectors';
import { ICurrentActivity, IScriptItemData } from '../../../../types/study';
import { getRandomQuestionsNumbersDataForScripts } from '../../../../utils/study';
import Loader from '../../../Loader';
import ScriptPrologue from './ScriptPrologue';
import ScriptQuiz from './ScriptQuiz';

const Script: React.FC = () => {
  const { id }: ICurrentActivity = useSelector(getCurrentActivity);
  const activities = useSelector(getActivitiesDataFromStore);
  const [data, setData] = useState<Array<IScriptItemData>>([]);
  const [stepsCount, setStepsCount] = useState<number>(0);
  const [isQuizStarted, setIsQuizStarted] = useState<boolean>(false);

  useEffect(() => {
    if (!data.length && id && activities[id]) {
      const value = activities[id].scripts;
      setStepsCount(value.length * 2);
      setData(value);
    }
  }, [id, activities]);

  const { questionsCoordinates, quizWordsData } = useMemo(() => {
    return getRandomQuestionsNumbersDataForScripts(data);
  }, [data]);

  const isShowSpeakerIconForJapanese = useMemo(() => {
    const values = Object.values(activities);
    const lastObj = values[values.length - 1];
    const lessonsHasIcon = [7, 8, 10, 11, 12];
    return (
      (lastObj.course === 'jp' &&
        lastObj.level === 'level-1' &&
        lessonsHasIcon.includes(lastObj.step)) ||
      lastObj.course !== 'jp'
    );
  }, []);

  const isShowLabelForLoading = () => {
    const values = Object.values(activities);
    const lastObj = values[values.length - 1];
    const lessonsHasIcon = [8, 12];
    return (
      lastObj.course === 'jp' &&
      lastObj.level === 'level-1' &&
      lessonsHasIcon.includes(lastObj.step)
    );
  };

  return (
    <>
      {data.length ? (
        isQuizStarted ? (
          <ScriptQuiz
            data={data}
            stepsCount={stepsCount}
            numOfQuestions={questionsCoordinates}
            quizWordsData={quizWordsData}
          />
        ) : (
          <ScriptPrologue
            isShowSpeaker={isShowSpeakerIconForJapanese}
            data={data}
            setIsQuizStarted={setIsQuizStarted}
          />
        )
      ) : (
        <>
          <Loader parentHeight={500} />
          {isShowLabelForLoading() && (
            <p
              style={{ fontSize: '18px', fontWeight: '400', color: '#2F353C', fontStyle: 'normal' }}
            >
              Quick Study Break! <br /> This lesson has a lot of script. <br /> So it takes about 30
              seconds for all information to load. &#128512;
            </p>
          )}
        </>
      )}
    </>
  );
};

export default Script;
