import React from 'react';

const Plan1MonthIcon: React.FC = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1023_69435)">
        <g filter="url(#filter1_i_1023_69435)">
          <path
            d="M17.6467 12.3534C14.7228 12.3534 12.3525 14.7237 12.3525 17.6475V41.471L22.0584 51.1769C29.4114 51.1769 36.7643 51.1769 44.1172 51.1769C48.0157 51.1769 51.1761 48.0166 51.1761 44.1181V17.6475C51.1761 16.495 50.8078 15.4285 50.1825 14.5593C49.2215 13.2233 46.9745 11.2539 46.9745 11.2539L17.6467 12.3534Z"
            fill="url(#paint0_linear_1023_69435)"
          />
          <path
            d="M17.6467 12.3534C14.7228 12.3534 12.3525 14.7237 12.3525 17.6475L48.529 19.4122L51.1761 22.0593V17.6475C51.1761 16.495 50.8078 15.4285 50.1825 14.5593C49.2215 13.2233 46.9745 11.2539 46.9745 11.2539L17.6467 12.3534Z"
            fill="url(#paint1_linear_1023_69435)"
          />
          <path
            d="M9.70508 14.9992C9.70508 12.0753 12.0753 9.70508 14.9992 9.70508H43.2345C46.1583 9.70508 48.5286 12.0753 48.5286 14.9992V19.411H9.70508V14.9992Z"
            fill="url(#paint2_linear_1023_69435)"
          />
          <path
            d="M9.70508 19.4121H48.5286V43.2356C48.5286 46.1595 46.1583 48.5298 43.2345 48.5298H19.411L9.70508 38.8239V19.4121Z"
            fill="#C4C4C4"
          />
          <path
            d="M9.70508 19.4121H48.5286V43.2356C48.5286 46.1595 46.1583 48.5298 43.2345 48.5298H19.411L9.70508 38.8239V19.4121Z"
            fill="url(#paint3_linear_1023_69435)"
          />
          <g filter="url(#filter2_d_1023_69435)">
            <path
              d="M9.70508 38.8242H19.411V48.5301L9.70508 38.8242Z"
              fill="url(#paint4_linear_1023_69435)"
            />
          </g>
          <rect
            opacity="0.5"
            x="9.70508"
            y="19.4121"
            width="38.8235"
            height="5.29412"
            fill="url(#paint5_linear_1023_69435)"
          />
          <circle cx="17.6461" cy="14.1178" r="2.64706" fill="url(#paint6_linear_1023_69435)" />
          <circle cx="29.1168" cy="14.1178" r="2.64706" fill="url(#paint7_linear_1023_69435)" />
          <circle cx="40.5875" cy="14.1178" r="2.64706" fill="url(#paint8_linear_1023_69435)" />
          <g filter="url(#filter3_d_1023_69435)">
            <rect
              x="16.3223"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="#C4C4C4"
            />
            <rect
              x="16.3223"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint9_linear_1023_69435)"
            />
          </g>
          <g filter="url(#filter4_d_1023_69435)">
            <rect
              x="39.2637"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="#C4C4C4"
            />
            <rect
              x="39.2637"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint10_linear_1023_69435)"
            />
          </g>
          <g filter="url(#filter5_d_1023_69435)">
            <rect x="27.793" y="7.5" width="2.64706" height="7.94118" rx="1.32353" fill="#C4C4C4" />
            <rect
              x="27.793"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint11_linear_1023_69435)"
            />
          </g>
          <path
            d="M32.9493 39.7051C32.9493 40.6798 32.1592 41.4698 31.1846 41.4698H30.2175C29.2429 41.4698 28.4528 40.6798 28.4528 39.7051V33.3716C28.4528 33.2187 28.4528 32.9434 28.4528 32.5457C28.4528 32.1481 28.4604 31.7046 28.4757 31.2151C28.4811 31.0431 28.4865 30.8776 28.4919 30.7188C28.501 30.4499 28.1613 30.3137 27.971 30.504C27.8181 30.6722 27.6575 30.8251 27.4893 30.9628L27.0024 31.3655C26.2469 31.9904 25.1268 31.8797 24.5083 31.1189L24.4479 31.0446C23.834 30.2895 23.9473 29.1798 24.7012 28.5643L28.4704 25.4875C28.7854 25.2303 29.1796 25.0898 29.5863 25.0898H31.1846C32.1592 25.0898 32.9493 25.8799 32.9493 26.8545V39.7051Z"
            fill="#002846"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1023_69435"
          x="5.70508"
          y="3.5"
          width="57.4707"
          height="59.6777"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.130906 0 0 0 0 0.475 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69435" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69435"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_1023_69435"
          x="7.70508"
          y="5.5"
          width="43.4707"
          height="45.6777"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1023_69435" />
        </filter>
        <filter
          id="filter2_d_1023_69435"
          x="5.70508"
          y="34.8242"
          width="19.7061"
          height="19.7051"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69435" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69435"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_1023_69435"
          x="14.5576"
          y="6.61765"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69435" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69435"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_1023_69435"
          x="37.499"
          y="6.61765"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69435" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69435"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_1023_69435"
          x="26.0283"
          y="6.61765"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69435" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69435"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1023_69435"
          x1="49.6238"
          y1="15.5037"
          x2="21.8668"
          y2="51.1769"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0263112" stopColor="#F0F8FF" />
          <stop offset="0.553393" stopColor="#D0E0ED" />
          <stop offset="1" stopColor="#BED3E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1023_69435"
          x1="12.3525"
          y1="11.2539"
          x2="17.9348"
          y2="31.311"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1023_69435"
          x1="9.70508"
          y1="9.70508"
          x2="14.2726"
          y2="27.975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1023_69435"
          x1="29.1168"
          y1="19.4121"
          x2="29.1168"
          y2="48.5298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1023_69435"
          x1="9.70508"
          y1="38.8242"
          x2="19.411"
          y2="48.5301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1023_69435"
          x1="31.5433"
          y1="19.4121"
          x2="31.5433"
          y2="24.7062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1023_69435"
          x1="17.6461"
          y1="11.4707"
          x2="17.6461"
          y2="16.7648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1023_69435"
          x1="29.1168"
          y1="11.4707"
          x2="29.1168"
          y2="16.7648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1023_69435"
          x1="40.5875"
          y1="11.4707"
          x2="40.5875"
          y2="16.7648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1023_69435"
          x1="17.6458"
          y1="7.5"
          x2="17.6458"
          y2="15.4412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1023_69435"
          x1="40.5872"
          y1="7.5"
          x2="40.5872"
          y2="15.4412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1023_69435"
          x1="29.1165"
          y1="7.5"
          x2="29.1165"
          y2="15.4412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Plan3MonthsIcon: React.FC = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1023_69438)">
        <g filter="url(#filter1_i_1023_69438)">
          <path
            d="M17.6467 12.3534C14.7228 12.3534 12.3525 14.7237 12.3525 17.6475V41.471L22.0584 51.1769C29.4114 51.1769 36.7643 51.1769 44.1172 51.1769C48.0157 51.1769 51.1761 48.0166 51.1761 44.1181V17.6475C51.1761 16.495 50.8078 15.4285 50.1825 14.5593C49.2215 13.2233 46.9745 11.2539 46.9745 11.2539L17.6467 12.3534Z"
            fill="url(#paint0_linear_1023_69438)"
          />
          <path
            d="M17.6467 12.3534C14.7228 12.3534 12.3525 14.7237 12.3525 17.6475L48.529 19.4122L51.1761 22.0593V17.6475C51.1761 16.495 50.8078 15.4285 50.1825 14.5593C49.2215 13.2233 46.9745 11.2539 46.9745 11.2539L17.6467 12.3534Z"
            fill="url(#paint1_linear_1023_69438)"
          />
          <path
            d="M9.70508 14.9992C9.70508 12.0753 12.0753 9.70508 14.9992 9.70508H43.2345C46.1583 9.70508 48.5286 12.0753 48.5286 14.9992V19.411H9.70508V14.9992Z"
            fill="url(#paint2_linear_1023_69438)"
          />
          <path
            d="M9.70508 19.4121H48.5286V43.2356C48.5286 46.1595 46.1583 48.5298 43.2345 48.5298H19.411L9.70508 38.8239V19.4121Z"
            fill="#C4C4C4"
          />
          <path
            d="M9.70508 19.4121H48.5286V43.2356C48.5286 46.1595 46.1583 48.5298 43.2345 48.5298H19.411L9.70508 38.8239V19.4121Z"
            fill="url(#paint3_linear_1023_69438)"
          />
          <g filter="url(#filter2_d_1023_69438)">
            <path
              d="M9.70508 38.8242H19.411V48.5301L9.70508 38.8242Z"
              fill="url(#paint4_linear_1023_69438)"
            />
          </g>
          <rect
            opacity="0.5"
            x="9.70508"
            y="19.4121"
            width="38.8235"
            height="5.29412"
            fill="url(#paint5_linear_1023_69438)"
          />
          <circle cx="17.6461" cy="14.1178" r="2.64706" fill="url(#paint6_linear_1023_69438)" />
          <circle cx="29.1168" cy="14.1178" r="2.64706" fill="url(#paint7_linear_1023_69438)" />
          <circle cx="40.5875" cy="14.1178" r="2.64706" fill="url(#paint8_linear_1023_69438)" />
          <g filter="url(#filter3_d_1023_69438)">
            <rect
              x="16.3223"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="#C4C4C4"
            />
            <rect
              x="16.3223"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint9_linear_1023_69438)"
            />
          </g>
          <g filter="url(#filter4_d_1023_69438)">
            <rect
              x="39.2637"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="#C4C4C4"
            />
            <rect
              x="39.2637"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint10_linear_1023_69438)"
            />
          </g>
          <g filter="url(#filter5_d_1023_69438)">
            <rect x="27.793" y="7.5" width="2.64706" height="7.94118" rx="1.32353" fill="#C4C4C4" />
            <rect
              x="27.793"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint11_linear_1023_69438)"
            />
          </g>
          <path
            d="M34.417 28.6466C34.417 29.4266 34.2487 30.0996 33.9123 30.6654C33.5758 31.216 33.1323 31.6749 32.5817 32.0419C32.0547 32.3882 31.4684 32.653 30.8228 32.8363C30.8048 32.8414 30.7923 32.8578 30.7923 32.8765C30.7923 32.8977 30.8083 32.9155 30.8293 32.9181C32.1265 33.0741 33.1236 33.4703 33.8205 34.1066C34.5393 34.7337 34.8987 35.6284 34.8987 36.7907C34.8987 37.6778 34.654 38.496 34.1646 39.2454C33.6905 39.9949 32.9487 40.5913 31.9393 41.0349C30.9452 41.4784 29.6528 41.7002 28.0623 41.7002C27.007 41.7002 26.1046 41.6237 25.3552 41.4707C24.9627 41.3826 24.5766 41.2799 24.1967 41.1624C23.5734 40.9696 23.1758 40.3761 23.1758 39.7237V39.4923C23.1758 38.4141 24.3309 37.7043 25.3781 37.9607C26.1275 38.129 26.8081 38.2131 27.4199 38.2131C28.5058 38.2131 29.2399 38.0602 29.6223 37.7543C30.0199 37.4484 30.2187 37.0354 30.2187 36.5154C30.2187 36.1484 30.1193 35.8349 29.9205 35.5749C29.737 35.2996 29.3928 35.0931 28.8881 34.9554C28.3987 34.8178 27.6799 34.749 26.7317 34.749C26.1742 34.749 25.7223 34.297 25.7223 33.7396V32.4549C25.7223 31.8847 26.1845 31.4225 26.7546 31.4225C28.0087 31.4225 28.8805 31.2619 29.3699 30.9407C29.8746 30.6196 30.127 30.2066 30.127 29.7019C30.127 29.2584 29.9587 28.9372 29.6223 28.7384C29.2858 28.5243 28.8423 28.4172 28.2917 28.4172C27.8023 28.4172 27.3358 28.486 26.8923 28.6237C26.464 28.7613 26.0893 28.9143 25.7681 29.0825C25.3548 29.2793 24.8369 29.1547 24.5957 28.7657L24.0893 27.9492C23.5914 27.1462 23.8123 26.0818 24.672 25.6896C24.9421 25.5663 25.2233 25.4508 25.5158 25.3431C26.4028 25.0219 27.5575 24.8613 28.9799 24.8613C30.6623 24.8613 31.9852 25.1978 32.9487 25.8707C33.9275 26.5437 34.417 27.469 34.417 28.6466Z"
            fill="#002846"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1023_69438"
          x="5.70508"
          y="3.5"
          width="57.4707"
          height="59.6777"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.130906 0 0 0 0 0.475 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69438" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69438"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_1023_69438"
          x="7.70508"
          y="5.5"
          width="43.4707"
          height="45.6777"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1023_69438" />
        </filter>
        <filter
          id="filter2_d_1023_69438"
          x="5.70508"
          y="34.8242"
          width="19.7061"
          height="19.7051"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69438" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69438"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_1023_69438"
          x="14.5576"
          y="6.61765"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69438" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69438"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_1023_69438"
          x="37.499"
          y="6.61765"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69438" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69438"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_1023_69438"
          x="26.0283"
          y="6.61765"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69438" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69438"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1023_69438"
          x1="49.6238"
          y1="15.5037"
          x2="21.8668"
          y2="51.1769"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0263112" stopColor="#F0F8FF" />
          <stop offset="0.553393" stopColor="#D0E0ED" />
          <stop offset="1" stopColor="#BED3E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1023_69438"
          x1="12.3525"
          y1="11.2539"
          x2="17.9348"
          y2="31.311"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1023_69438"
          x1="9.70508"
          y1="9.70508"
          x2="14.2726"
          y2="27.975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1023_69438"
          x1="29.1168"
          y1="19.4121"
          x2="29.1168"
          y2="48.5298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1023_69438"
          x1="9.70508"
          y1="38.8242"
          x2="19.411"
          y2="48.5301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1023_69438"
          x1="31.5433"
          y1="19.4121"
          x2="31.5433"
          y2="24.7062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1023_69438"
          x1="17.6461"
          y1="11.4707"
          x2="17.6461"
          y2="16.7648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1023_69438"
          x1="29.1168"
          y1="11.4707"
          x2="29.1168"
          y2="16.7648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1023_69438"
          x1="40.5875"
          y1="11.4707"
          x2="40.5875"
          y2="16.7648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1023_69438"
          x1="17.6458"
          y1="7.5"
          x2="17.6458"
          y2="15.4412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1023_69438"
          x1="40.5872"
          y1="7.5"
          x2="40.5872"
          y2="15.4412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1023_69438"
          x1="29.1165"
          y1="7.5"
          x2="29.1165"
          y2="15.4412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Plan6MonthsIcon: React.FC = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1023_69451)">
        <g filter="url(#filter1_i_1023_69451)">
          <path
            d="M17.6467 12.3534C14.7228 12.3534 12.3525 14.7237 12.3525 17.6475V41.471L22.0584 51.1769C29.4114 51.1769 36.7643 51.1769 44.1172 51.1769C48.0157 51.1769 51.1761 48.0166 51.1761 44.1181V17.6475C51.1761 16.495 50.8078 15.4285 50.1825 14.5593C49.2215 13.2233 46.9745 11.2539 46.9745 11.2539L17.6467 12.3534Z"
            fill="url(#paint0_linear_1023_69451)"
          />
          <path
            d="M17.6467 12.3534C14.7228 12.3534 12.3525 14.7237 12.3525 17.6475L48.529 19.4122L51.1761 22.0593V17.6475C51.1761 16.495 50.8078 15.4285 50.1825 14.5593C49.2215 13.2233 46.9745 11.2539 46.9745 11.2539L17.6467 12.3534Z"
            fill="url(#paint1_linear_1023_69451)"
          />
          <path
            d="M9.70508 14.9992C9.70508 12.0753 12.0753 9.70508 14.9992 9.70508H43.2345C46.1583 9.70508 48.5286 12.0753 48.5286 14.9992V19.411H9.70508V14.9992Z"
            fill="url(#paint2_linear_1023_69451)"
          />
          <path
            d="M9.70508 19.4121H48.5286V43.2356C48.5286 46.1595 46.1583 48.5298 43.2345 48.5298H19.411L9.70508 38.8239V19.4121Z"
            fill="#C4C4C4"
          />
          <path
            d="M9.70508 19.4121H48.5286V43.2356C48.5286 46.1595 46.1583 48.5298 43.2345 48.5298H19.411L9.70508 38.8239V19.4121Z"
            fill="url(#paint3_linear_1023_69451)"
          />
          <g filter="url(#filter2_d_1023_69451)">
            <path
              d="M9.70508 38.8242H19.411V48.5301L9.70508 38.8242Z"
              fill="url(#paint4_linear_1023_69451)"
            />
          </g>
          <rect
            opacity="0.5"
            x="9.70508"
            y="19.4121"
            width="38.8235"
            height="5.29412"
            fill="url(#paint5_linear_1023_69451)"
          />
          <circle cx="17.6461" cy="14.1178" r="2.64706" fill="url(#paint6_linear_1023_69451)" />
          <circle cx="29.1168" cy="14.1178" r="2.64706" fill="url(#paint7_linear_1023_69451)" />
          <circle cx="40.5875" cy="14.1178" r="2.64706" fill="url(#paint8_linear_1023_69451)" />
          <g filter="url(#filter3_d_1023_69451)">
            <rect
              x="16.3223"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="#C4C4C4"
            />
            <rect
              x="16.3223"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint9_linear_1023_69451)"
            />
          </g>
          <g filter="url(#filter4_d_1023_69451)">
            <rect
              x="39.2637"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="#C4C4C4"
            />
            <rect
              x="39.2637"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint10_linear_1023_69451)"
            />
          </g>
          <g filter="url(#filter5_d_1023_69451)">
            <rect x="27.793" y="7.5" width="2.64706" height="7.94118" rx="1.32353" fill="#C4C4C4" />
            <rect
              x="27.793"
              y="7.5"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint11_linear_1023_69451)"
            />
          </g>
          <path
            d="M23.1758 34.5425C23.1758 33.6096 23.2446 32.6766 23.3823 31.7437C23.5199 30.8107 23.7646 29.9313 24.1164 29.1054C24.4681 28.2796 24.9652 27.5531 25.6075 26.926C26.2499 26.2837 27.0681 25.779 28.0623 25.4119C29.0717 25.0449 30.3028 24.8613 31.7558 24.8613C32.0582 24.8613 32.3644 24.8718 32.6709 24.8914C33.4932 24.9439 34.0958 25.6544 34.0958 26.4784C34.0958 27.5289 33.1281 28.3301 32.0779 28.3069C31.9396 28.3039 31.8015 28.3025 31.664 28.3025C30.5628 28.3025 29.6911 28.4707 29.0487 28.8072C28.4064 29.1284 27.9399 29.6025 27.6493 30.2296C27.3833 30.8207 27.2244 31.5331 27.1726 32.367C27.1696 32.4147 27.2077 32.4549 27.2555 32.4549C27.2861 32.4549 27.3142 32.4381 27.3291 32.4113C27.6171 31.8951 27.9991 31.4584 28.4752 31.1013C28.9799 30.7343 29.6681 30.5507 30.5399 30.5507C31.9775 30.5507 33.1017 31.0172 33.9123 31.9502C34.7228 32.8678 35.1281 34.1754 35.1281 35.8731C35.1281 37.7084 34.6081 39.1384 33.5681 40.1631C32.5434 41.1878 31.1364 41.7002 29.347 41.7002C28.1846 41.7002 27.137 41.4478 26.204 40.9431C25.2711 40.4231 24.5293 39.6354 23.9787 38.5802C23.4434 37.5249 23.1758 36.179 23.1758 34.5425ZM29.2552 38.1902C29.7293 38.1902 30.1117 38.0143 30.4023 37.6625C30.6928 37.2954 30.8381 36.7296 30.8381 35.9649C30.8381 35.3378 30.7158 34.8484 30.4711 34.4966C30.2264 34.1449 29.8364 33.969 29.3011 33.969C28.7505 33.969 28.3223 34.1525 28.0164 34.5196C27.7258 34.8866 27.5805 35.2919 27.5805 35.7354C27.5805 36.1178 27.634 36.5002 27.7411 36.8825C27.8634 37.2649 28.047 37.5784 28.2917 37.8231C28.5517 38.0678 28.8728 38.1902 29.2552 38.1902Z"
            fill="#002846"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1023_69451"
          x="5.70508"
          y="3.5"
          width="57.4707"
          height="59.6777"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.130906 0 0 0 0 0.475 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69451" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69451"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_1023_69451"
          x="7.70508"
          y="5.5"
          width="43.4707"
          height="45.6777"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1023_69451" />
        </filter>
        <filter
          id="filter2_d_1023_69451"
          x="5.70508"
          y="34.8242"
          width="19.7061"
          height="19.7051"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69451" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69451"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_1023_69451"
          x="14.5576"
          y="6.61765"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69451" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69451"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_1023_69451"
          x="37.499"
          y="6.61765"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69451" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69451"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_1023_69451"
          x="26.0283"
          y="6.61765"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69451" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69451"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1023_69451"
          x1="49.6238"
          y1="15.5037"
          x2="21.8668"
          y2="51.1769"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0263112" stopColor="#F0F8FF" />
          <stop offset="0.553393" stopColor="#D0E0ED" />
          <stop offset="1" stopColor="#BED3E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1023_69451"
          x1="6.11304"
          y1="14.6292"
          x2="10.1283"
          y2="30.2991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD391" />
          <stop offset="1" stopColor="#C11190" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1023_69451"
          x1="3.46558"
          y1="12.7369"
          x2="6.74432"
          y2="26.9819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD391" />
          <stop offset="1" stopColor="#C11190" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1023_69451"
          x1="29.1168"
          y1="19.4121"
          x2="29.1168"
          y2="48.5298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1023_69451"
          x1="9.70508"
          y1="38.8242"
          x2="19.411"
          y2="48.5301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1023_69451"
          x1="31.5433"
          y1="19.4121"
          x2="31.5433"
          y2="24.7062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1023_69451"
          x1="17.6461"
          y1="11.4707"
          x2="17.6461"
          y2="16.7648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1023_69451"
          x1="29.1168"
          y1="11.4707"
          x2="29.1168"
          y2="16.7648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1023_69451"
          x1="40.5875"
          y1="11.4707"
          x2="40.5875"
          y2="16.7648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1023_69451"
          x1="17.6458"
          y1="7.5"
          x2="17.6458"
          y2="15.4412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1023_69451"
          x1="40.5872"
          y1="7.5"
          x2="40.5872"
          y2="15.4412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1023_69451"
          x1="29.1165"
          y1="7.5"
          x2="29.1165"
          y2="15.4412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Plan12MonthsIcon: React.FC = () => {
  return (
    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1023_69465)">
        <g filter="url(#filter1_i_1023_69465)">
          <path
            d="M17.6467 13.2343C14.7228 13.2343 12.3525 15.6045 12.3525 18.5284V42.3519L22.0584 52.0578C29.4114 52.0578 36.7643 52.0578 44.1172 52.0578C48.0157 52.0578 51.1761 48.8974 51.1761 44.999V18.5284C51.1761 17.3759 50.8078 16.3094 50.1825 15.4401C49.2215 14.1042 46.9745 12.1348 46.9745 12.1348L17.6467 13.2343Z"
            fill="url(#paint0_linear_1023_69465)"
          />
          <path
            d="M17.6467 13.2343C14.7228 13.2343 12.3525 15.6045 12.3525 18.5284L48.529 20.2931L51.1761 22.9401V18.5284C51.1761 17.3759 50.8078 16.3094 50.1825 15.4401C49.2215 14.1042 46.9745 12.1348 46.9745 12.1348L17.6467 13.2343Z"
            fill="url(#paint1_linear_1023_69465)"
          />
          <path
            d="M17.6467 13.2343C14.7228 13.2343 12.3525 15.6045 12.3525 18.5284L48.529 20.2931L51.1761 22.9401V18.5284C51.1761 17.3759 50.8078 16.3094 50.1825 15.4401C49.2215 14.1042 46.9745 12.1348 46.9745 12.1348L17.6467 13.2343Z"
            fill="url(#paint2_linear_1023_69465)"
          />
          <path
            d="M9.70508 15.8801C9.70508 12.9562 12.0753 10.5859 14.9992 10.5859H43.2345C46.1583 10.5859 48.5286 12.9562 48.5286 15.8801V20.2918H9.70508V15.8801Z"
            fill="url(#paint3_linear_1023_69465)"
          />
          <path
            d="M9.70508 15.8801C9.70508 12.9562 12.0753 10.5859 14.9992 10.5859H43.2345C46.1583 10.5859 48.5286 12.9562 48.5286 15.8801V20.2918H9.70508V15.8801Z"
            fill="url(#paint4_linear_1023_69465)"
          />
          <path
            d="M9.70508 20.293H48.5286V44.1165C48.5286 47.0404 46.1583 49.4106 43.2345 49.4106H19.411L9.70508 39.7047V20.293Z"
            fill="#C4C4C4"
          />
          <path
            d="M9.70508 20.293H48.5286V44.1165C48.5286 47.0404 46.1583 49.4106 43.2345 49.4106H19.411L9.70508 39.7047V20.293Z"
            fill="url(#paint5_linear_1023_69465)"
          />
          <g filter="url(#filter2_d_1023_69465)">
            <path
              d="M9.70508 39.7051H19.411V49.411L9.70508 39.7051Z"
              fill="url(#paint6_linear_1023_69465)"
            />
          </g>
          <rect
            opacity="0.5"
            x="9.70508"
            y="20.293"
            width="38.8235"
            height="5.29412"
            fill="url(#paint7_linear_1023_69465)"
          />
          <circle cx="17.6461" cy="14.9986" r="2.64706" fill="url(#paint8_linear_1023_69465)" />
          <circle cx="29.1168" cy="14.9986" r="2.64706" fill="url(#paint9_linear_1023_69465)" />
          <circle cx="40.5875" cy="14.9986" r="2.64706" fill="url(#paint10_linear_1023_69465)" />
          <g filter="url(#filter3_d_1023_69465)">
            <rect
              x="16.3223"
              y="8.38086"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="#C4C4C4"
            />
            <rect
              x="16.3223"
              y="8.38086"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint11_linear_1023_69465)"
            />
          </g>
          <g filter="url(#filter4_d_1023_69465)">
            <rect
              x="39.2637"
              y="8.38086"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="#C4C4C4"
            />
            <rect
              x="39.2637"
              y="8.38086"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint12_linear_1023_69465)"
            />
          </g>
          <g filter="url(#filter5_d_1023_69465)">
            <rect
              x="27.793"
              y="8.38086"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="#C4C4C4"
            />
            <rect
              x="27.793"
              y="8.38086"
              width="2.64706"
              height="7.94118"
              rx="1.32353"
              fill="url(#paint13_linear_1023_69465)"
            />
          </g>
          <g filter="url(#filter6_d_1023_69465)">
            <g filter="url(#filter7_i_1023_69465)">
              <path
                d="M26.2286 40.5849C26.2286 41.5596 25.4385 42.3496 24.4639 42.3496H23.4968C22.5222 42.3496 21.7321 41.5596 21.7321 40.5849V34.2514C21.7321 34.0985 21.7321 33.8232 21.7321 33.4255C21.7321 33.0279 21.7397 32.5844 21.755 32.0949C21.7604 31.9229 21.7658 31.7574 21.7712 31.5986C21.7803 31.3297 21.4406 31.1935 21.2503 31.3838C21.0974 31.552 20.9368 31.7049 20.7686 31.8426L20.2817 32.2453C19.5262 32.8702 18.4061 32.7595 17.7876 31.9987L17.7272 31.9244C17.1133 31.1693 17.2266 30.0596 17.9805 29.4441L21.7497 26.3673C22.0647 26.1101 22.4589 25.9696 22.8656 25.9696H24.4639C25.4385 25.9696 26.2286 26.7597 26.2286 27.7344V40.5849Z"
                fill="url(#paint14_linear_1023_69465)"
              />
              <path
                d="M41.6895 40.5849C41.6895 41.5596 40.8994 42.3496 39.9248 42.3496H31.5707C30.596 42.3496 29.806 41.5596 29.806 40.5849V40.1612C29.806 39.6966 29.9891 39.2508 30.3157 38.9205L33.7977 35.3985C34.6236 34.5267 35.266 33.8308 35.7248 33.3108C36.1989 32.7908 36.5277 32.3473 36.7112 31.9802C36.8948 31.6132 36.9865 31.2308 36.9865 30.8332C36.9865 30.3438 36.826 29.992 36.5048 29.7779C36.1989 29.5638 35.8318 29.4567 35.4036 29.4567C34.8836 29.4567 34.3636 29.602 33.8436 29.8926C33.7206 29.9613 33.5933 30.0382 33.4618 30.1232C32.6402 30.654 31.5373 30.5955 30.9017 29.852L30.7364 29.6586C30.116 28.9329 30.181 27.8382 30.9461 27.2672C31.0243 27.2089 31.103 27.1511 31.1824 27.0938C31.7483 26.6961 32.406 26.3749 33.1554 26.1302C33.9048 25.8702 34.8224 25.7402 35.9083 25.7402C37.0248 25.7402 37.9883 25.9391 38.7989 26.3367C39.6095 26.7191 40.2365 27.2467 40.6801 27.9196C41.1389 28.5926 41.3683 29.3573 41.3683 30.2138C41.3683 31.162 41.1924 32.0108 40.8407 32.7602C40.5042 33.4944 39.9842 34.2361 39.2807 34.9855C38.5924 35.7196 37.7283 36.5608 36.6883 37.5091L35.5215 38.5636C35.5049 38.5787 35.4954 38.6001 35.4954 38.6226C35.4954 38.6664 35.5309 38.702 35.5748 38.702H39.9248C40.8994 38.702 41.6895 39.4921 41.6895 40.4667V40.5849Z"
                fill="url(#paint15_linear_1023_69465)"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1023_69465"
          x="5.70508"
          y="4.38086"
          width="57.4707"
          height="59.6777"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.130906 0 0 0 0 0.475 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69465" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69465"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_1023_69465"
          x="7.70508"
          y="6.38086"
          width="43.4707"
          height="45.6777"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1023_69465" />
        </filter>
        <filter
          id="filter2_d_1023_69465"
          x="5.70508"
          y="35.7051"
          width="19.7061"
          height="19.7051"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.24033 0 0 0 0 0.32369 0 0 0 0 0.454167 0 0 0 0.1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69465" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69465"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_1023_69465"
          x="14.5576"
          y="7.49851"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69465" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69465"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_1023_69465"
          x="37.499"
          y="7.49851"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69465" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69465"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_1023_69465"
          x="26.0283"
          y="7.49851"
          width="6.17687"
          height="11.4708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0 0.2125 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69465" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69465"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_1023_69465"
          x="16.7144"
          y="25.2108"
          width="24.9751"
          height="17.1388"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.441176" dy="-0.352941" />
          <feGaussianBlur stdDeviation="0.0882353" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.3125 0 0 0 0 0.213161 0 0 0 0 0.0898438 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1023_69465" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1023_69465"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_i_1023_69465"
          x="16.4497"
          y="24.8579"
          width="25.2398"
          height="17.4917"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.882353" dy="-0.882353" />
          <feGaussianBlur stdDeviation="0.882353" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1023_69465" />
        </filter>
        <linearGradient
          id="paint0_linear_1023_69465"
          x1="49.6238"
          y1="16.3846"
          x2="21.8668"
          y2="52.0578"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0263112" stopColor="#F0F8FF" />
          <stop offset="0.553393" stopColor="#D0E0ED" />
          <stop offset="1" stopColor="#BED3E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1023_69465"
          x1="12.3525"
          y1="12.1348"
          x2="17.9348"
          y2="32.1919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1023_69465"
          x1="-3.24384"
          y1="15.2637"
          x2="2.67529"
          y2="37.4315"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.138385" stopColor="#FFED91" />
          <stop offset="0.810027" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1023_69465"
          x1="9.70508"
          y1="10.5859"
          x2="14.2726"
          y2="28.8558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1023_69465"
          x1="-5.89131"
          y1="13.3965"
          x2="-1.05329"
          y2="33.5679"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.138385" stopColor="#FFED91" />
          <stop offset="0.810027" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1023_69465"
          x1="29.1168"
          y1="20.293"
          x2="29.1168"
          y2="49.4106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1023_69465"
          x1="9.70508"
          y1="39.7051"
          x2="19.411"
          y2="49.411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1023_69465"
          x1="31.5433"
          y1="20.293"
          x2="31.5433"
          y2="25.5871"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1023_69465"
          x1="17.6461"
          y1="12.3516"
          x2="17.6461"
          y2="17.6457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1023_69465"
          x1="29.1168"
          y1="12.3516"
          x2="29.1168"
          y2="17.6457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1023_69465"
          x1="40.5875"
          y1="12.3516"
          x2="40.5875"
          y2="17.6457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002846" />
          <stop offset="1" stopColor="#002846" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1023_69465"
          x1="17.6458"
          y1="8.38086"
          x2="17.6458"
          y2="16.322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1023_69465"
          x1="40.5872"
          y1="8.38086"
          x2="40.5872"
          y2="16.322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1023_69465"
          x1="29.1165"
          y1="8.38086"
          x2="29.1165"
          y2="16.322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#CBD5D9" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1023_69465"
          x1="-0.000285842"
          y1="-3.09134"
          x2="8.95632"
          y2="53.0026"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.540355" stopColor="#FFED91" />
          <stop offset="0.920104" stopColor="#CF690A" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1023_69465"
          x1="-0.000285842"
          y1="-3.09134"
          x2="8.95632"
          y2="53.0026"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.540355" stopColor="#FFED91" />
          <stop offset="0.920104" stopColor="#CF690A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { Plan1MonthIcon, Plan3MonthsIcon, Plan6MonthsIcon, Plan12MonthsIcon };
