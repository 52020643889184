import React, { useState } from 'react';

import { IBackendDataContent } from '../../../../types/study';
import AudioSound from '../../../atoms/AudioSound';
import styles from './audio.module.scss';

interface ITextComponentProps {
  content: IBackendDataContent;
}

const AudioComponent: React.FC<ITextComponentProps> = ({ content }) => {
  const { original, text, url } = content;
  const [showTranslation, setShowTranslation] = useState(false);

  return (
    <div className={styles.audioContent}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: original }} />
        {!showTranslation && !!text ? (
          <span
            className={styles.audioContentTranslate}
            onClick={() => setShowTranslation(!showTranslation)}
          >
            Show translation
          </span>
        ) : (
          <p>{text}</p>
        )}
      </div>
      {url && <AudioSound sound={url} />}
    </div>
  );
};

export default AudioComponent;
