import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Icon from '../../../../assets/images/pointsScale.svg';
import { closeModal } from '../../../../utils/modal';
import Button from '../../../atoms/Button';
import styles from './info_modal.module.scss';

const PointsInfo: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBtnClick = () => {
    navigate('/study');
    closeModal(dispatch);
  };
  return (
    <>
      <div className={styles.pointsContentWrapper}>
        <div className={styles.pointsContentText}>
          <p>
            You earn dedication points by completing sections in the course, and for being
            consistent with your studies.
          </p>
          <div className={styles.listText}>
            <ul>
              <li>
                Primer, Like a local, Cake, Listening: <strong>5 points</strong>
              </li>
              <li>
                Vocabulary, Assessment, Script: <strong>15 points</strong>
              </li>
              <li>
                Finish one lesson: <strong>25 points</strong>
              </li>
            </ul>
            <ul>
              <li>
                Finish a level: <strong>1000 points</strong>
              </li>
              <li>
                <strong>Study streak:</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.pointsContentImage}>
          <img src={Icon} />
        </div>
        <Button
          customClass="modalInfoButton"
          onClick={handleBtnClick}
          type={'primary'}
          value={'Let’s get started!'}
        />
      </div>
    </>
  );
};

export default PointsInfo;
