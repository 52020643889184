export type TSignUpValues = {
  firstName: string;
  email: string;
  password: string;
};

export type TLoginValues = {
  email: string;
  password: string;
};
export type TEditValues = {
  image: null | File | string;
  first_name: string;
};

export type TChangesPassword = {
  currentPassword: string;
  newPassword: string;
  confirmedPassword: string;
};

export type TForgotValues = {
  email: string;
};

export type TGoalValues = {
  goal: string;
};

export type TLanguage = {
  lang: string;
  code: string;
};

export type TLanguages = Array<TLanguage>;
export type TList = Array<string>;

export const SIGN_UP_INITIAL: TSignUpValues = {
  firstName: '',
  email: '',
  password: '',
};

export const LOGIN_INITIAL: TLoginValues = {
  email: '',
  password: '',
};

export const FORGOT_INITIAL: TForgotValues = {
  email: '',
};

export const EDIT_INITIAL: TEditValues = {
  image: null,
  first_name: '',
};

export const CHANGE_PASSWORD_INITIAL: TChangesPassword = {
  currentPassword: '',
  newPassword: '',
  confirmedPassword: '',
};

export const GOAL_INITIAL: TGoalValues = {
  goal: '',
};

export const LANGUAGES: TLanguages = [
  {
    lang: 'russian',
    code: 'ru',
  },
  {
    lang: 'french',
    code: 'fr',
  },
  {
    lang: 'spanish',
    code: 'es',
  },
  {
    lang: 'hebrew',
    code: 'he',
  },
  {
    lang: 'japanese',
    code: 'jp',
  },
];

export const DESCRIPTION_LIST: TList = [
  'Language Learning Secrets Book',
  'Customized study plan',
  'Dedicated tutor support',
  'Interactive language app',
];

export const SIGN_METHODS: TList = ['apple', 'google', 'facebook'];
