import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getCurrentCourse } from '../../../store/selectors/courseSelectors';
import { setShowOnboarding } from '../../../store/slices/auth';
import { getPlacementTest, setIsPlacementTest } from '../../../store/slices/placementTest';
import WelcomePageInfoCard from './WelcomeInfoCard';
import styles from './welcomePage.module.scss';

const WelcomePage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course = useSelector(getCurrentCourse);

  const launchPlacementTest = () => {
    dispatch(getPlacementTest(course));
    dispatch(setIsPlacementTest(true));
    dispatch(setShowOnboarding(false));
    navigate('/placementTest');
  };

  const launchStudyPage = () => {
    dispatch(setShowOnboarding(false));
    navigate('/study');
  };
  return (
    <div className={styles.welcomePageWrapper}>
      <div className={styles.welcomePageMain}>
        <div className={styles.welcomePageLogo}>
          <div className={styles.welcomePageLogoImage} />
        </div>
        <WelcomePageInfoCard
          text="If you would like to do a placement test to determine the best level to start at, just click the button."
          buttonValue={'Launch placement test'}
          buttonClick={launchPlacementTest}
        />
        <WelcomePageInfoCard
          text="Or you can jump right into the lessons:"
          buttonValue="Let’s go!"
          buttonClick={launchStudyPage}
        />
      </div>
    </div>
  );
};

export default WelcomePage;
