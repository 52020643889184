import React from 'react';
import { Link } from 'react-router-dom';

import { PROFILE_SIDEBAR_MENU } from '../../../constants/userProfile';
import styles from './profile_sidebar.module.scss';

interface ISidebarProps {
  path: string;
}

const ProfileSidebar: React.FC<ISidebarProps> = ({ path }) => {
  return (
    <aside className={styles.asideMenu}>
      {PROFILE_SIDEBAR_MENU.map(({ text, url, Icon }) => (
        <Link
          key={text}
          to={url}
          className={`${styles.asideMenuItem} ${path === url ? styles.active : ''}`}
        >
          <Icon />
          <span>{text}</span>
        </Link>
      ))}
    </aside>
  );
};

export default ProfileSidebar;
