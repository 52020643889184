import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Icon from '../../../../assets/images/onboard-step05.png';
import { closeModal } from '../../../../utils/modal';
import Button from '../../../atoms/Button';
import styles from './info_modal.module.scss';

const StatisticsInfo: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBtnClick = () => {
    navigate('/study');
    closeModal(dispatch);
  };

  return (
    <>
      <div className={styles.statisticsContentWrapper}>
        <div className={styles.contentText}>
          <p>
            <strong>Study Sessions: </strong>total number of sessions
          </p>
          <p>
            <strong>Completed Lessons: </strong>total number of completed lessons for all languages,
            all levels
          </p>
          <p>
            <strong>Daily Streak: </strong>number of days in a row you have completed a task
          </p>
          <p>
            <strong>Vocabulary: </strong>
            number of vocab words studied for all languages, all levels
          </p>
          <p>
            <strong>Assessment: </strong>
            number of completed questions in each Assessment for all languages, all levels
          </p>
          <p>
            <strong>Script: </strong>number of characters studied for all languages, all levels
          </p>
        </div>
        <div className={styles.contentImage}>
          <img src={Icon} />
        </div>
      </div>
      <Button
        customClass="modalInfoButton"
        onClick={handleBtnClick}
        type={'primary'}
        value={'Let’s get started!'}
      />
    </>
  );
};

export default StatisticsInfo;
