import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { ISidebarItem } from '../constants/userProfile';
import { useStudySidebar } from '../hooks/useStudySidebar';
import { getCurrentActivity } from '../store/selectors/studySelectors';
import {
  ICurrentActivity,
  IQuizWordData,
  IReviewItemData,
  IScriptItemData,
  IVocabItemData,
  TAudioTypes,
} from '../types/study';

export const getLevelKey = (level: number): string => `level-${level}`;
export const getLessonKey = (lesson: number): string => `lesson-${lesson}`;

export const getHintVocab = (text: string): string => {
  let hint = removeBracketsInString(text);
  const keyWords = text.match(/[^{}]+(?=})/g);
  uniqArray(keyWords)?.forEach((word) => {
    hint = hint.replace(word, '...');
  });

  return hint;
};

export const getReviewImageOptions = (
  meanings: string,
  image: string | number
): Record<string, string> => {
  const imageUrl =
    typeof image === 'string' ? image : `https://app.lingualift.com/images/vocab/${image}.jpg`;
  let imagesData = {
    [imageUrl]: meanings,
  };
  const randomImages = [image];

  for (let i = 0; i < 3; i++) {
    const el = Math.floor(Math.random() * 138 + 1);
    const item = `https://app.lingualift.com/images/vocab/${el}.jpg`;
    if (randomImages.includes(item)) {
      i--;
    } else {
      imagesData = {
        ...imagesData,
        [item]: item,
      };
    }
  }

  return imagesData;
};

export const getOptionsForVocab = (
  data: Array<IVocabItemData | IReviewItemData>,
  meanings: string,
  image?: number | string
): Record<string, string> => {
  if (image) {
    const imageValue =
      typeof image === 'string' ? image : `https://app.lingualift.com/images/vocab/${image}.jpg`;
    let imagesData = {
      [imageValue]: meanings,
    };

    const randomImages = [image];

    for (let i = 0; i < 3; i++) {
      const el = Math.floor(Math.random() * 138 + 1);
      const item = `https://app.lingualift.com/images/vocab/${el}.jpg`;
      if (randomImages.includes(item)) {
        i--;
      } else {
        imagesData = {
          ...imagesData,
          [item]: item,
        };
      }
    }
    return imagesData;
  } else {
    const filteredData = data
      .filter((item) => item.meanings !== meanings)
      .sort(() => 0.5 - Math.random())
      .slice(0, 3);
    const optionsData = filteredData.reduce((acc, item) => {
      if (item) {
        return {
          ...acc,
          [item.meanings]: item.meanings,
        };
      }
      return acc;
    }, {});

    return optionsData;
  }
};

export const getOptionsForScript = (
  data: Array<IScriptItemData | IReviewItemData>,
  answer: string
): Array<string> => {
  const newData = data
    .map((item) => item.chr)
    .filter((chr) => chr !== answer)
    .slice(0, 5);

  return [...newData, answer];
};

export const getVariantForVocabPage = (key: string) => {
  const keyWords = key.match(/[^{}]+(?=})/g);
  return keyWords?.join(',') || '';
};

function getMultipleRandom(arrLength: number, num: number) {
  const arr: Array<number> = [];
  for (let i = 0; i < arrLength; i++) {
    arr.push(i);
  }
  const shuffled = [...arr].sort(() => 0.5 - Math.random());

  return shuffled.slice(0, num);
}

export const getRandomNumber = (arrLength: number, childArr: Array<number>) => {
  const parent: Array<number> = [];
  for (let i = 0; i < arrLength; i++) {
    parent.push(i);
  }

  const filteredArr = parent.filter((el) => !childArr.includes(el));
  const shuffled = filteredArr.sort(() => 0.5 - Math.random());

  return shuffled[0];
};

export const getRandomQuestionsNumbersDataForScripts = (data: Array<IScriptItemData>) => {
  const numOfScripts = data.length;
  const numOfQuestions = [];
  const quizWordsData: Record<string, IQuizWordData> = {};

  for (let i = 0; i < numOfScripts; i++) {
    const randomNumbers = getMultipleRandom(data[i].words.length, 2);
    if (randomNumbers[0] !== undefined) {
      numOfQuestions[i] = [i, randomNumbers[0]];
    }
    if (randomNumbers[1] !== undefined) {
      numOfQuestions[i + numOfScripts] = [i, randomNumbers[1]];
    }
  }

  numOfQuestions.forEach((el) => {
    const word: string = data[el[0]].words[el[1]].spelling;

    if (quizWordsData[word]) {
      quizWordsData[word] = {
        chars: [...quizWordsData[word].chars, data[el[0]].chr],
        positions: [...quizWordsData[word].positions, el],
      };
    } else {
      quizWordsData[word] = {
        chars: [data[el[0]].chr],
        positions: [el],
      };
    }
  });
  const questionsCoordinates = [];

  for (const xKey in quizWordsData) {
    const wordsChars = [];
    if (quizWordsData[xKey].positions.length > 1) {
      const quizWordsDataPositionClone = [...quizWordsData[xKey].positions];
      const temp1 = quizWordsDataPositionClone.sort((a, b) => {
        const chr1 = data[a[0]].chr;
        const chr2 = data[b[0]].chr;
        return xKey.indexOf(chr1) - xKey.indexOf(chr2);
      });
      questionsCoordinates.push(...temp1);
    } else {
      questionsCoordinates.push(...quizWordsData[xKey].positions);
    }

    if (quizWordsData[xKey].chars.length > 1) {
      const quizWordsDataCharsClone = [...quizWordsData[xKey].chars];
      const temp2 = quizWordsDataCharsClone.sort((a, b) => {
        return xKey.indexOf(a) - xKey.indexOf(b);
      });
      wordsChars.push(...temp2);
    } else {
      wordsChars.push(...quizWordsData[xKey].chars);
    }
    quizWordsData[xKey].chars = wordsChars;
  }

  return {
    questionsCoordinates,
    quizWordsData,
  };
};

export const getNextActivity = (): ICurrentActivity | undefined => {
  const { sidebarData } = useStudySidebar();
  const currentActivity: ICurrentActivity = useSelector(getCurrentActivity);
  let nextActivity: ICurrentActivity | undefined = undefined;
  const index = sidebarData.findIndex((el) => el.id === currentActivity.id);
  const checkOrder = [
    'Primer',
    'Assessment',
    'Like a local',
    'Script',
    'Vocabulary',
    'Listening',
    'Cake',
  ];

  function compareByText(a: { text: string }, b: { text: string }) {
    const indexA = checkOrder.indexOf(a.text);
    const indexB = checkOrder.indexOf(b.text);
    return indexA - indexB;
  }

  if (index !== -1) {
    for (let i = 0; i < sidebarData.sort(compareByText).length; i++) {
      const el: ISidebarItem = sidebarData.sort(compareByText)[i];
      if (!el.isComplete && el.id !== currentActivity.id) {
        nextActivity = {
          id: el.id,
          text: el.text,
        };
        break;
      }
    }

    if (!nextActivity) {
      const element = sidebarData.find((item) => {
        return item.id !== currentActivity.id && !item.isComplete;
      });
      if (element) {
        nextActivity = {
          id: element.id,
          text: element.text,
        };
      }
    }

    return nextActivity;
  }
};

export const sendReportMessage = (
  course: string,
  level: number | undefined,
  lesson: number | undefined,
  activity: string,
  task: string | ReactNode,
  key: any,
  options: Array<string>,
  hint: string
) => {
  const strippedHint = hint.replace(/(<([^>]+)>)/gi, '');

  const message = `QUIZ ERROR REPORT:
 Course: ${course}
 Level: ${level || 'Review Skills'}
 Lesson: ${lesson || 'Review Skills'}
 Type: ${activity}
 Task: ${task}
 Key: ${key || 'empty key'} 
 Options: ${options.join(', ')} 
 Hint: ${strippedHint}
 -----------------------
 Please describe what's wrong with this quiz prompt:`;

  (window as any).Intercom('showNewMessage', message);
};

export const percentParser = (percent: number | null) => Math.round(Number(percent));

export const removeBracketsInString = (text: string): string => {
  return text.replaceAll('{', '').replaceAll('}', '');
};

export const playSound = (type: TAudioTypes): void => {
  new Audio(`https://ll-app.storage.googleapis.com/audio/fx/${type}.mp3`).play();
};

export function uniqArray<T>(arr: Array<T> | null): Array<T> {
  return arr ? Array.from(new Set(arr)) : [];
}
