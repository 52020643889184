import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CHANGE_PASSWORD_INITIAL, TChangesPassword } from '../../../../constants/authFLow';
import { changePassword } from '../../../../store/slices/auth';
import {
  ChangePasswordValidationScheme,
  handleSetFieldTouched,
} from '../../../../utils/authValidation';
import Button from '../../../atoms/Button';
import Input from '../../../atoms/Input';
import ModalUserPhoto from '../../../atoms/ModalUserPhoto';
import styles from './edit-profile.module.scss';

interface IChangePassword {
  setIsChangePassword: any;
}

const ChangePassword: React.FC<IChangePassword> = ({ setIsChangePassword }) => {
  const [isShowCurrentPass, setShowCurrentPass] = useState<boolean>(false);
  const [isShowNewPass, setIsShowNewPass] = useState<boolean>(false);
  const [isShowConfirmedPass, setIsShowConfirmedPass] = useState<boolean>(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...CHANGE_PASSWORD_INITIAL,
    },
    validateOnMount: false,
    validationSchema: ChangePasswordValidationScheme,
    onSubmit: (values: TChangesPassword) => {
      dispatch(changePassword(values));
    },
  });
  const isAllowSubmit = useMemo(() => {
    if (!Object.values(formik.touched).length || Object.values(formik.errors).length) {
      return false;
    }
    return true;
  }, [formik]);

  return (
    <form className={styles.editProfile} onSubmit={formik.handleSubmit}>
      <ModalUserPhoto setUserImgFile={() => console.log('lll')} isEditable={false} />
      <div className={styles.changePassHeader}>
        <p>Please complete all fields to change password.</p>
        <button onClick={() => setIsChangePassword((prev: boolean) => !prev)} type={'button'}>
          Cancel
        </button>
      </div>
      <Input
        htmlFor="currentPassword"
        type="password"
        isPassInput
        name="currentPassword"
        placeHolder="Enter Current password"
        label="Current password"
        onClick={formik.setFieldTouched}
        onBlur={(e: any) => handleSetFieldTouched(e, formik)}
        error={
          formik.touched.currentPassword &&
          formik.values.currentPassword &&
          formik.errors.currentPassword
        }
        onChange={(e) => {
          formik.setFieldTouched(e.target.name, false);
          formik.handleChange(e);
        }}
        passShowMode={isShowCurrentPass}
        setPassShowMode={setShowCurrentPass}
        value={formik.values.currentPassword}
      />
      <Input
        htmlFor="newPassword"
        type="password"
        isPassInput
        name="newPassword"
        placeHolder="Enter your new password"
        label="New password"
        onClick={formik.setFieldTouched}
        onBlur={(e: any) => handleSetFieldTouched(e, formik)}
        error={formik.touched.newPassword && formik.values.newPassword && formik.errors.newPassword}
        onChange={(e) => {
          formik.setFieldTouched(e.target.name, false);
          formik.handleChange(e);
        }}
        passShowMode={isShowNewPass}
        setPassShowMode={setIsShowNewPass}
        value={formik.values.newPassword}
      />
      <Input
        htmlFor="confirmedPassword"
        type="password"
        isPassInput
        name="confirmedPassword"
        placeHolder="Enter your new password again"
        label="Confirm a new password"
        onClick={formik.setFieldTouched}
        onBlur={(e: any) => handleSetFieldTouched(e, formik)}
        error={
          formik.touched.confirmedPassword &&
          formik.values.confirmedPassword &&
          formik.errors.confirmedPassword
        }
        onChange={(e) => {
          formik.setFieldTouched(e.target.name, false);
          formik.handleChange(e);
        }}
        passShowMode={isShowConfirmedPass}
        setPassShowMode={setIsShowConfirmedPass}
        value={formik.values.confirmedPassword}
      />
      <Button submit type={'primary'} value="Save changes" disabled={!isAllowSubmit} />
    </form>
  );
};
export default ChangePassword;
