import React from 'react';

const GoalMobileIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="2" transform="rotate(90 12 12)" stroke="#0185F3" strokeWidth="2" />
      <circle cx="12" cy="21" r="2" transform="rotate(90 12 21)" stroke="#0185F3" strokeWidth="2" />
      <circle cx="12" cy="3" r="2" transform="rotate(90 12 3)" stroke="#0185F3" strokeWidth="2" />
    </svg>
  );
};

export default GoalMobileIcon;
