import React from 'react';

import styles from './checkbox.module.scss';

interface ICheckboxProps {
  name?: string;
}
const Checkbox: React.FC<ICheckboxProps> = ({ name }) => {
  return (
    <label className={styles.checkboxLabel} htmlFor={name}>
      <input className={styles.checkboxInput} type="checkbox" name={name} id={name} />
      <ul className={styles.checkbox}>
        <li></li>
      </ul>
    </label>
  );
};

export default Checkbox;
