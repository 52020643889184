import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AppHeader from '../../../components/AppHeader';
import { SCREENS } from '../../../constants/sccrens';
import { PROFILE_COMPONENTS } from '../../../constants/userProfile';
import ProfileSidebar from '../../../features/organisms/ProfileSidebar';
import Onboarding from '../../../features/views/AuthFlow/Onboarding';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { getRedirectUrl, getShowOnboarding } from '../../../store/selectors/authSelectors';
import { setRedirectUrl } from '../../../store/slices/auth';
import styles from './user_profile.module.scss';

const UserProfile: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const redirectUrl = useSelector(getRedirectUrl);
  const navigate = useNavigate();

  const isShowOnboarding = useSelector(getShowOnboarding);

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl);
      dispatch(setRedirectUrl(''));
    }
  }, [redirectUrl]);

  const Component = useMemo(() => {
    const page: string = pathname.slice(1);

    return PROFILE_COMPONENTS[page];
  }, [pathname]);
  const isTablet = useMediaQuery(SCREENS.tablet);

  return (
    <>
      {isShowOnboarding ? (
        <Onboarding />
      ) : (
        <div className={`App-content ${styles.userProfile}`}>
          <AppHeader isUserSingedIn={true} />
          <div
            className={`${styles.profileWrapper} ${
              pathname === '/profile' ? styles.profilePage : ''
            }`}
          >
            {!isTablet && <ProfileSidebar path={pathname} />}
            <Component />
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
