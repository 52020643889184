import { createSelector } from 'reselect';

import { TState } from '../types';

const mainSelector = (state: TState) => state.auth;

export const getAuthLoading = createSelector(mainSelector, (state) => state.authLoader);
export const getIsEmailSend = createSelector(mainSelector, (state) => state.isEmailSend);
export const getIsAuthenticated = createSelector(mainSelector, (state) => state.isAuthenticated);
export const getCurrentUser = createSelector(mainSelector, (state) => state.currentUser);
export const getUserName = createSelector(mainSelector, (state) => state?.currentUser?.name);
export const getUserTrial = createSelector(
  mainSelector,
  (state) => state?.currentUser?.trial_unlocked
);
export const getUserImg = createSelector(mainSelector, (state) => state.currentUser.picture);
export const getUserEmail = createSelector(mainSelector, (state) => state.currentUser.email);
export const getIsFetchSuccess = createSelector(mainSelector, (state) => state.isFetchSuccess);
export const getShowOnboarding = createSelector(mainSelector, (state) => state.showOnboarding);
export const getUserGoal = createSelector(mainSelector, (state) => state.userGoal);
export const getUserStatistics = createSelector(mainSelector, (state) => state.statistics);
export const getRedirectUrl = createSelector(mainSelector, (state) => state.redirectUrl);
export const getUserSubscription = createSelector(mainSelector, (state) => state.subscription);
export const getPriceDifference = createSelector(mainSelector, (state) => state.priceDifference);
export const getBillingHistoryStore = createSelector(mainSelector, (state) => state.billingHistory);
export const getIsUserVerified = createSelector(
  mainSelector,
  (state) => state.currentUser.is_email_verified
);
export const getIsShowVerifiedContent = createSelector(
  mainSelector,
  (state) => state.isShowVerifiedContent
);
export const getStripeCustomerId = createSelector(
  mainSelector,
  (state) => state.subscription.customer
);
