import React from 'react';

const IconUnlock: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="23" stroke="white" strokeWidth="2" />
      <circle cx="19.5" cy="27.5" r="5.5" stroke="white" strokeWidth="2" />
      <path
        d="M23.5 23.5L33.5 13.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 18L32 15L29 18L32 21L35 18Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconUnlock;
