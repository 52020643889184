import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ProgressLine from '../../../../features/atoms/ProgressLine';
import {
  getActivitiesDataFromStore,
  getCurrentActivity,
} from '../../../../store/selectors/studySelectors';
import { ICurrentActivity, ITestItemData } from '../../../../types/study';
import Loader from '../../../Loader';
import TestContent from '../../../TestComponents/TestContent';
import styles from './assigments.module.scss';

const Assessment: React.FC = () => {
  const { text, id }: ICurrentActivity = useSelector(getCurrentActivity);
  const activities = useSelector(getActivitiesDataFromStore);
  const [data, setData] = useState<Array<ITestItemData>>([]);
  const [step, setStep] = useState<number>(0);
  const [checkedItemsCount, setCheckedItemsCount] = useState<number>(0);
  const [stepsCount, setStepsCount] = useState<number>(0);
  const isLastQuestion = step === data.length - 1;

  useEffect(() => {
    if (!data.length && id && activities[id]) {
      setStepsCount(activities[id].assessments.length);
      setData(activities[id].assessments);
    }
  }, [id, activities]);

  return (
    <div className={styles.assessment}>
      {data.length && stepsCount ? (
        <>
          {!!checkedItemsCount && <ProgressLine value={(checkedItemsCount / stepsCount) * 100} />}
          <h1>{text}</h1>
          <TestContent
            type={'assessment'}
            data={data[step]}
            step={step}
            setStep={setStep}
            stepsCount={stepsCount}
            setCheckedItemsCount={setCheckedItemsCount}
            id={id}
            isLastQuestion={isLastQuestion}
            setAllData={setData}
            allData={data}
          />
        </>
      ) : (
        <Loader parentHeight={500} />
      )}
    </div>
  );
};

export default Assessment;
