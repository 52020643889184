import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePlanData } from '../../../hooks/usePlanData';
import { getUserSubscription } from '../../../store/selectors/authSelectors';
import { getChangePlanInfo } from '../../../store/slices/auth';
import { setCurrentPayment, setModal, setModalType } from '../../../store/slices/modal';
import { IBillingItem } from '../../../types/billing';
import { MODAL_TYPES } from '../../../types/modals';
import styles from './billing.module.scss';

interface IPlanCards {
  isModal?: boolean;
  itemClassName?: string;
}

const PlanCards: React.FC<IPlanCards> = ({ itemClassName, isModal }) => {
  const dispatch = useDispatch();
  const planData = usePlanData();
  const { isSubscriptionActive, planId } = useSelector(getUserSubscription);

  const [data, setData] = useState<Array<IBillingItem> | null>(null);

  useEffect(() => {
    if (planData) {
      if (isSubscriptionActive && isModal) {
        setData(planData.filter((item) => item.id !== planId));
      } else {
        setData(planData);
      }
    }
  }, [planId, planData]);
  const openModal = (id: string, month: number) => {
    isSubscriptionActive && dispatch(getChangePlanInfo(id));
    dispatch(setCurrentPayment({ month, id }));
    dispatch(setModal(true));
    dispatch(setModalType(MODAL_TYPES.billing));
  };

  return (
    <div className={styles.billingPlans}>
      {data?.map(({ id, perMonthPrice, title, price, month, Icon, popular }) => {
        return (
          <div
            className={`${styles.billingPlansItem} ${itemClassName ? styles[itemClassName] : ''}`}
            key={id}
          >
            {popular && <div className={styles.billingShevron} />}
            <Icon />
            <div className={styles.billingPlansItemContent}>
              <h2>{title}</h2>
              <p>{price}</p>
              <span>{perMonthPrice} per month</span>
            </div>
            <span onClick={() => openModal(id, month)} className={styles.selectBTN}>
              Select
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default PlanCards;
