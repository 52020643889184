import { createSlice } from '@reduxjs/toolkit';

import { INotification } from '../types';

const initialState: INotification = {
  isOpen: false,
  message: '',
  type: '',
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      return {
        ...state,
        isOpen: true,
        message: action.payload.message,
        type: action.payload.type,
      };
    },
    setDefaultNotification: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const { setNotification, setDefaultNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
