import React from 'react';

import { ArrowLeft, ArrowRight } from '../../../assets/imgComponent/IconArrows';

interface IButtonProps {
  type?: 'next' | 'prev' | '';
  text: string;
  onHandleClick: any;
}

const StepButton: React.FC<IButtonProps> = ({ type, onHandleClick, text }) => {
  return (
    <button
      onClick={onHandleClick}
      className={`onboardSlider-button onboardSlider-button-next${type}`}
    >
      {type === 'prev' && (
        <>
          <ArrowLeft />
        </>
      )}
      <span>{text}</span>

      {type === 'next' && (
        <>
          <ArrowRight />
        </>
      )}
    </button>
  );
};

export default StepButton;
