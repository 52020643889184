import React from 'react';

const ArrowLeft: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 19L9.06066 13.0607C8.47487 12.4749 8.47487 11.5251 9.06066 10.9393L15 5"
        stroke="#0185F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowRight: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 5L14.9393 10.9393C15.5251 11.5251 15.5251 12.4749 14.9393 13.0607L9 19"
        stroke="#0185F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowPointerTransparent: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25.413 25.209">
      <path
        id="arrow_upward_FILL0_wght600_GRAD0_opsz48"
        d="M18.228,31.7V13.259l-8.467,8.5L7.7,19.7l12-12,12,12-2.025,2.062-8.5-8.5V31.7Z"
        transform="translate(-6.993 -6.993)"
        stroke="transparent"
        strokeWidth="1"
        opacity="1"
      />
    </svg>
  );
};

const PointArrowRight: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4L17 9M17 9L12 14M17 9H7"
        stroke="#0185F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { ArrowLeft, ArrowPointerTransparent, ArrowRight, PointArrowRight };
