import StatImage5 from '../assets/images/stat-assessment.png';
import StatImage2 from '../assets/images/stat-lessons.png';
import StatImage6 from '../assets/images/stat-script.png';
import StatImage1 from '../assets/images/stat-sessions.png';
import StatImage3 from '../assets/images/stat-streak.png';
import StatImage4 from '../assets/images/stat-vocabulary.png';
import { setAuth, setBillingHistory, setCurrentUser, setSubscription } from '../store/slices/auth';
import { setQuestionData, setReviewStatisticsData } from '../store/slices/review';
import { removeStudyData, setLanguagesData } from '../store/slices/study';
import { removeCourseData } from '../store/slices/userCourses';
import { IStatisticsItem } from '../types/study';
import { IUserStatistics } from '../types/user';

export const getToken = () => localStorage.getItem('access_token');
export const setToken = (token: string) => localStorage.setItem('access_token', token);
export const removeToken = () => localStorage.removeItem('access_token');
export const getUserStatisticsData = (userStatistics: IUserStatistics): Array<IStatisticsItem> => {
  return [
    {
      src: StatImage1,
      text: 'Study Sessions',
      count: userStatistics.sessionsCount || 0,
    },
    {
      src: StatImage2,
      text: 'Completed Lessons',
      count: userStatistics.lessonsCount || 0,
    },
    {
      src: StatImage3,
      text: 'Daily Streak',
      count: userStatistics.dailyStreak || 0,
    },
    {
      src: StatImage4,
      text: 'Vocabulary',
      count: userStatistics.vocabulary || 0,
    },
    {
      src: StatImage5,
      text: 'Assessment',
      count: userStatistics.assessment || 0,
    },
    {
      src: StatImage6,
      text: 'Script',
      count: userStatistics.scripts || 0,
    },
  ];
};

export const logout = (dispatch: any, isRemoveToken = true) => {
  // authLogout();
  dispatch(setCurrentUser({}));
  dispatch(removeCourseData());
  dispatch(setAuth(false));
  dispatch(removeStudyData());
  dispatch(setLanguagesData(null));
  dispatch(
    setBillingHistory({
      charges: null,
      payment_method: {},
    })
  );
  dispatch(setQuestionData(null));
  dispatch(setReviewStatisticsData({}));
  dispatch(
    setSubscription({
      planId: '',
      currency: '',
      currentPeriodEnd: 0,
      isSubscriptionWillExpired: false,
      isSubscriptionActive: false,
    })
  );
  if (isRemoveToken) {
    removeToken();
  }
  localStorage.setItem('refCode', '');
  (window as any).Intercom('shutdown');
};
