import React from 'react';

import styles from './icon.module.scss';

interface IChooseLangIconProps {
  lang?: string;
  imgUrl?: string;
}

const ChooseLangIcon: React.FC<IChooseLangIconProps> = ({ lang, imgUrl }) => {
  return (
    <div className={styles.icon}>
      <div className={styles.flagIcon}>
        <img src={imgUrl} />
      </div>
      <span className={`${styles.iconText}`}>{lang}</span>
    </div>
  );
};

export default ChooseLangIcon;
