import { createSelector } from 'reselect';

import { TState } from '../types';

const mainSelector = (state: TState) => state.study;

export const getStudy = createSelector(mainSelector, (state) => state.studyCourse);
export const getLessons = createSelector(mainSelector, (state) => state.allLessons);
export const getActivities = createSelector(mainSelector, (state) => state.activities);
export const getCurrentLevel = createSelector(mainSelector, (state) => state.currentLevel);
export const getCurrentActivity = createSelector(mainSelector, (state) => state.currentActivity);
export const getLeaderboardData = createSelector(mainSelector, (state) => state.leaderboard);
export const getGivenPoint = createSelector(mainSelector, (state) => state.givenPoint);
export const getIsPopupLoading = createSelector(mainSelector, (state) => state.isPopupLoading);
export const getKanjiesData = createSelector(mainSelector, (state) => state.kanjiesData);
export const getIsLanguageDataLoading = createSelector(
  mainSelector,
  (state) => state.isLanguageDataLoading
);
export const getLanguageDataFromStore = createSelector(
  mainSelector,
  (state) => state.languagesData
);
export const getActivitiesDataFromStore = createSelector(
  mainSelector,
  (state) => state?.activitiesData
);
