import React from 'react';

import { IBackendDataContent } from '../../../../types/study';
import styles from './button.module.scss';

interface ITextComponentProps {
  content: IBackendDataContent;
}

const ButtonComponent: React.FC<ITextComponentProps> = ({ content }) => {
  return (
    <a className={styles.lessonLink} href={content.href} target="_blank" rel="noreferrer">
      {content.label}
    </a>
  );
};

export default ButtonComponent;
