import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AdditionalSignMethods from '../../../components/AdditionalSignMethods';
import AppHeader from '../../../components/AppHeader';
import Loader from '../../../components/Loader';
import SendEmailNotification from '../../../components/SendEmailNotification';
import EmailVerification from '../../../components/SendEmailNotification/EmailVerification';
import $api from '../../../Service/api/intercepter';
import { getAuthLoading, getIsShowVerifiedContent } from '../../../store/selectors/authSelectors';
import { setNotification } from '../../../store/slices/notification';
import Button from '../../atoms/Button';
import styles from './auth.module.scss';

type TLinkedText = {
  title: string;
  href: string;
};

interface IAuthViewProps {
  type: 'log in' | 'sign up' | 'forgot';
  headerText: string;
  bottomText?: string;
  bottomLinkedText?: TLinkedText;
  aboveButtonText?: TLinkedText;
  buttonText: string;
  submitHandler: () => void;
  allowSubmit: boolean;
  setIsPoliceChecked?: any;
  email?: string;
}
const AuthView: React.FC<IAuthViewProps> = ({
  type,
  headerText,
  bottomText,
  bottomLinkedText,
  aboveButtonText,
  buttonText,
  children,
  allowSubmit,
  submitHandler,
  setIsPoliceChecked,
  email = '',
}) => {
  const navigate = useNavigate();
  const isLoading = useSelector(getAuthLoading);
  const dispatch = useDispatch();
  const isShowVerifiedContent = useSelector(getIsShowVerifiedContent);
  const isUserVerifiedInLoginProcess = isShowVerifiedContent && type === 'log in';
  const [isSendAgainRequestLoading, setIsSendAgainRequestLoading] = useState<boolean>(false);

  const handleBottomBtnClick = () => {
    if (isUserVerifiedInLoginProcess) {
      setIsSendAgainRequestLoading(true);

      $api
        .post('/profile/resend-email')
        .then(() => {
          dispatch(setNotification({ message: 'Email was sent', type: 'success' }));
        })
        .catch(() => {
          dispatch(setNotification({ message: 'Something went wrong', type: 'error' }));
        })
        .finally(() => {
          setIsSendAgainRequestLoading(false);
        });
    } else {
      navigate(`/${bottomLinkedText?.href}`);
    }
  };

  return (
    <>
      <AppHeader isUserSingedIn={false} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className={`${styles.authWrapper}`}>
          {isShowVerifiedContent ? (
            <EmailVerification />
          ) : (
            <>
              <section className={`${styles.authSection}`}>
                {type === 'forgot' && <SendEmailNotification email={email} />}
                <form onSubmit={submitHandler} action="" autoComplete={'nope'}>
                  <h2 className={styles.authSectionTitle}>{headerText}</h2>
                  {type === 'forgot' && (
                    <p className={`${styles.authRecoverPassText}`}>
                      Please enter your LinguaLift account email to proceed.
                    </p>
                  )}
                  {children}
                  {aboveButtonText && (
                    <div>
                      <span>{aboveButtonText.title}</span>
                    </div>
                  )}
                  {type === 'sign up' && setIsPoliceChecked && (
                    <div className={`${styles.authAgreements}`}>
                      <input
                        onClick={() => {
                          setIsPoliceChecked((prev: boolean) => !prev);
                        }}
                        id="checkAgree"
                        type="checkbox"
                      />
                      <label htmlFor="checkAgree">
                        <span>I agree with LinguaLift</span>
                        <a
                          href="https://www.lingualift.com/privacy-policy/"
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          Privacy & Terms
                        </a>
                      </label>
                    </div>
                  )}
                  {type === 'log in' && (
                    <div className={`${styles.authForgotPass}`}>
                      <span onClick={() => navigate('/forgotPassword')}>Forgot password?</span>
                    </div>
                  )}
                  <Button submit type="primary" value={buttonText} disabled={!allowSubmit} />
                </form>
                {type !== 'forgot' && (
                  <div className={styles.authMethods}>
                    {type === 'log in' && (
                      <p className={styles.loginInfoText}>
                        Log in the same way you did last time to avoid creating a second LinguaLift
                        account.
                      </p>
                    )}
                    <p className={styles.authMethodsText}>
                      <span>or {type} with</span>
                    </p>
                    <AdditionalSignMethods />
                  </div>
                )}
              </section>
            </>
          )}
          {type !== 'forgot' && (
            <div className={`${styles.authFooter} ${styles.sendAgainTxt}`}>
              {isSendAgainRequestLoading ? (
                <Loader />
              ) : (
                <p>
                  <span>
                    {isUserVerifiedInLoginProcess ? 'If you don’t receive the code' : bottomText}
                  </span>
                  <span onClick={handleBottomBtnClick}>
                    {isUserVerifiedInLoginProcess ? 'Send again' : bottomLinkedText?.title}
                  </span>
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AuthView;
