import React, { memo } from 'react';

import IconChecked from '../../../assets/imgComponent/IconChecked';
import styles from './testRow.module.scss';

interface ITestRowProps {
  item: string;
  variant: string;
  chooseAnswer: (arg: string) => void;
  isDisable: boolean;
}

const TestRow: React.FC<ITestRowProps> = ({ item, variant, chooseAnswer, isDisable }) => {
  const handleClick = () => {
    if (!isDisable) {
      chooseAnswer(item);
    }
  };
  return (
    <li className={styles.testRow}>
      <input
        type="radio"
        id={item}
        name={'item'}
        disabled={isDisable}
        checked={variant == item}
        onChange={() => console.log('change')}
      />
      <label htmlFor={item} onClick={handleClick}>
        {item}
        <IconChecked />
      </label>
    </li>
  );
};

export default memo(TestRow);
