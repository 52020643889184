import React from 'react';

const BillingSecureIcon: React.FC = () => {
  return (
    <svg
      width="117"
      height="118"
      viewBox="0 0 117 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_184_26174)">
        <path
          d="M59.6526 105.216C82.0097 94.2035 96 70.8497 96 45.5328V28.9991C96 27.4016 95.0495 25.9573 93.5823 25.3254L59.5823 10.6815C58.5723 10.2465 57.4277 10.2465 56.4177 10.6815L22.4177 25.3254C20.9505 25.9573 20 27.4016 20 28.9991V45.5328C20 71.5192 34.5654 94.4781 56.348 105.216C57.3894 105.729 58.6111 105.729 59.6526 105.216Z"
          fill="url(#paint0_linear_184_26174)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88 45.5333C88 67.2166 76.4056 87.1022 58.0015 97.066C40.0354 87.3248 28 67.7559 28 45.5333V31.632L58 18.7109L88 31.632L88 45.5333Z"
        fill="url(#paint1_linear_184_26174)"
      />
      <g filter="url(#filter1_d_184_26174)">
        <path
          d="M56.7042 70.2659C54.5076 72.578 50.9463 72.578 48.7498 70.2659L39.5443 60.5757C37.4852 58.4083 37.4852 54.8942 39.5443 52.7268C41.6033 50.5594 44.9416 50.5594 47.0007 52.7268L52.727 58.7546L68.9993 41.6256C71.0584 39.4581 74.3967 39.4581 76.4557 41.6256C78.5148 43.793 78.5148 47.3071 76.4557 49.4745L56.7042 70.2659Z"
          fill="#C4C4C4"
        />
        <path
          d="M56.7042 70.2659C54.5076 72.578 50.9463 72.578 48.7498 70.2659L39.5443 60.5757C37.4852 58.4083 37.4852 54.8942 39.5443 52.7268C41.6033 50.5594 44.9416 50.5594 47.0007 52.7268L52.727 58.7546L68.9993 41.6256C71.0584 39.4581 74.3967 39.4581 76.4557 41.6256C78.5148 43.793 78.5148 47.3071 76.4557 49.4745L56.7042 70.2659Z"
          fill="url(#paint2_linear_184_26174)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_184_26174"
          x="16"
          y="6.35547"
          width="92"
          height="111.244"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.130906 0 0 0 0 0.475 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_26174" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_184_26174"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_184_26174"
          x="36"
          y="38"
          width="48"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.295833 0 0 0 0 0.295833 0 0 0 0 0.295833 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_26174" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_184_26174"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_184_26174"
          x1="20"
          y1="10"
          x2="113.439"
          y2="83.9723"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_184_26174"
          x1="28"
          y1="18.7109"
          x2="103.645"
          y2="76.6354"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.024142" stopColor="#51FFD5" />
          <stop offset="0.901306" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_184_26174"
          x1="58"
          y1="40"
          x2="58"
          y2="72"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BillingSecureIcon;
