import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BACKEND_COMPONENTS } from '../../../../constants/study';
import Button from '../../../../features/atoms/Button';
import { getCurrentCourse } from '../../../../store/selectors/courseSelectors';
import {
  getActivities,
  getActivitiesDataFromStore,
  getCurrentActivity,
  getCurrentLevel,
  getLessons,
} from '../../../../store/selectors/studySelectors';
import { setModal, setModalType } from '../../../../store/slices/modal';
import { getLevelData, markAsDone, setCurrentActivity } from '../../../../store/slices/study';
import { MODAL_TYPES } from '../../../../types/modals';
import { ICurrentActivity } from '../../../../types/study';
import { getLessonKey, getLevelKey, getNextActivity } from '../../../../utils/study';
import Loader from '../../../Loader';
import styles from '../studyPage.module.scss';

//for Primer, Like a Local, Cake pages
const BlankTest: React.FC = () => {
  const { id, text }: ICurrentActivity = useSelector(getCurrentActivity);
  const activities = useSelector(getActivitiesDataFromStore);
  const allActivities = useSelector(getActivities);

  const { level, lesson } = useSelector(getCurrentLevel);
  const levels = useSelector(getLessons);
  const course = useSelector(getCurrentCourse);
  const [data, setData] = useState<Array<any>>([]);
  const [title, setTitle] = useState<string>('');
  const [activitiesType, setActivitiesType] = useState<string>('');
  const dispatch = useDispatch();
  const nextActivity = getNextActivity();

  useEffect(() => {
    if (id && activities[id]) {
      setData(activities[id].content);
      setTitle(activities[id].title);
      setActivitiesType(activities[id].type);
    }
    window.scrollTo({
      top: 0,
    });
  }, [id, activities]);
  const { lessonText } = useSelector(getCurrentLevel);

  const getComponent = useCallback((type, content) => {
    if (type && content) {
      const Component = BACKEND_COMPONENTS[type];
      return <Component content={content} />;
    }
  }, []);

  const isComplete = useMemo(() => {
    const levelKey = getLevelKey(level!);
    const lessonKey = getLessonKey(lesson!);
    const currentLessonActivities: Array<any> = allActivities[levelKey][lessonKey].data;
    return currentLessonActivities.find((el) => el['_id'] === id)['is_completed'];
  }, [allActivities, id]);

  const handleClick = () => {
    if (id && course && level && lesson && activities) {
      if (isComplete) {
        if (nextActivity) {
          dispatch(setCurrentActivity(nextActivity));
        } else {
          dispatch(
            setCurrentActivity({
              id: 0,
              text: '',
            })
          );
        }
      } else {
        dispatch(markAsDone(id, course, level, lesson, activities, nextActivity));
        dispatch(setModal(true));
        dispatch(setModalType(MODAL_TYPES.wellDone));
        dispatch(getLevelData(course, level, levels, false));
      }
    }
  };

  return (
    <>
      <h1>{title || text}</h1>
      {activitiesType === 'primer' && lessonText !== title && <p>{lessonText}</p>}
      {data.length ? (
        <div className={styles.lessonContent}>
          {data.map((item: any, index) => {
            return (
              <section key={index} className={item?.type}>
                {getComponent(item?.type, item.content)}
              </section>
            );
          })}
          <Button type={'primary'} value={'Mark as done'} onClick={handleClick} />
        </div>
      ) : (
        <Loader parentHeight={500} />
      )}
    </>
  );
};

export default memo(BlankTest);
