import React, { useCallback, useEffect, useRef, useState } from 'react';

import KanjiTooltip from '../../../../components/KanjiTooltip';
import { KANJI_TOOLTIP_MARGIN_X, KANJI_TOOLTIP_MARGIN_Y } from '../../../../constants/study';
import { IBackendDataContent } from '../../../../types/study';
import styles from './text.module.scss';

interface ITextComponentProps {
  content: IBackendDataContent;
}

const TextComponent: React.FC<ITextComponentProps> = ({ content }) => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [character, setCharacter] = useState('');
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const textContentRef = useRef(null);

  const mouseClick = useCallback(
    (e: any) => {
      const positionX = e.target.offsetLeft;
      const positionY = e.target.offsetTop;

      setIsShowPopup((prev) => {
        if (!e.target.classList.contains('hanChar')) {
          return false;
        }
        if (
          prev &&
          positionX === x - KANJI_TOOLTIP_MARGIN_X &&
          positionY === y - KANJI_TOOLTIP_MARGIN_Y
        ) {
          setCharacter('');
          setX(0);
          setY(0);
          return false;
        } else {
          setCharacter(e.target.textContent);
          if (prev) {
            e.stopPropagation();
          }
          setX(positionX + KANJI_TOOLTIP_MARGIN_X);
          setY(positionY + KANJI_TOOLTIP_MARGIN_Y);
          return true;
        }
      });
    },
    [character, isShowPopup, x, y]
  );

  useEffect(() => {
    const clickListener = () => {
      setIsShowPopup(false);
      setCharacter('');
    };

    if (isShowPopup) {
      setTimeout(() => {
        document.addEventListener('click', clickListener);
      }, 0);
    }
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [isShowPopup]);

  useEffect(() => {
    const parentDiv: HTMLDivElement = textContentRef.current!;
    const isHasHanChars = parentDiv?.querySelectorAll('.hanChar').length;

    if (isHasHanChars) {
      parentDiv.addEventListener('click', mouseClick);
    }

    return () => {
      if (isHasHanChars) {
        parentDiv.removeEventListener('click', mouseClick);
      }
    };
  }, [character, content.html]);
  return (
    <>
      {isShowPopup && <KanjiTooltip character={character} positionX={x} positionY={y} />}
      <div
        className={styles.textContent}
        ref={textContentRef}
        dangerouslySetInnerHTML={{ __html: content.html }}
      />
    </>
  );
};

export default TextComponent;
