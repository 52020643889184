import React from 'react';

import CloseIcon from '../../../assets/imgComponent/CloseIcon';
import styles from './closeButton.module.scss';

interface ICloseButtonProps {
  onClick: () => void;
}

const CloseButton: React.FC<ICloseButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className={styles.closeBtn}>
      <CloseIcon />
    </button>
  );
};

export default CloseButton;
