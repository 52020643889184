import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { FLAG_ICONS_CHARS } from '../../constants/courses';
import $api from '../../Service/api/intercepter';
import {
  ICurrentActivity,
  ILanguageData,
  ILeaderboardData,
  ILessonData,
  IStudyCourse,
} from '../../types/study';
import { getLessonKey, getLevelKey } from '../../utils/study';
import { IAnswer, IStudy } from '../types';
import { getUser } from './auth';
import { setIsCourseLoading } from './userCourses';

const initialState: IStudy = {
  studyCourse: {},
  allLessons: {},
  activities: {},
  activitiesData: {},
  currentLevel: {},
  currentActivity: {},
  kanjiesData: {},
  leaderboard: null,
  givenPoint: 0,
  isPopupLoading: false,
  languagesData: null,
  isLanguageDataLoading: false,
};

export const coursesSlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    setStudyCourse: (state, action) => {
      return {
        ...state,
        studyCourse: { ...action.payload },
      };
    },
    setLessons: (state, action) => {
      return {
        ...state,
        allLessons: { ...action.payload },
      };
    },
    setActivities: (state, action) => {
      return {
        ...state,
        activities: {
          ...state.activities,
          ...action.payload,
        },
      };
    },
    setActivitiesData: (state, action) => {
      return {
        ...state,
        activitiesData: {
          ...state.activitiesData,
          ...action.payload,
        },
      };
    },
    setCurrentLessonAndLevel: (state, action) => {
      return {
        ...state,

        currentLevel: { ...action.payload },
      };
    },
    setCurrentActivity: (state, action) => {
      return {
        ...state,
        currentActivity: { ...action.payload },
      };
    },
    setGivenPoint: (state, action) => {
      return {
        ...state,
        givenPoint: action.payload,
      };
    },
    setLeaderboard: (state, action) => {
      return {
        ...state,
        leaderboard: action.payload,
      };
    },
    setKanjiesData: (state, action) => {
      return {
        ...state,
        kanjiesData: { ...state.kanjiesData, ...action.payload },
      };
    },
    setLanguagesData: (state, action) => {
      return {
        ...state,
        languagesData: action.payload,
      };
    },
    setIsPopupLoading: (state, action) => {
      return {
        ...state,
        isPopupLoading: action.payload,
      };
    },
    setIsLanguageDataLoading: (state, action) => {
      return {
        ...state,
        isLanguageDataLoading: action.payload,
      };
    },
    removeStudyData: (state) => {
      return {
        ...initialState,
        languagesData: state.languagesData,
      };
    },
  },
});

export const getStudyCourse = (course: string) => (dispatch: Dispatch) => {
  dispatch(setIsCourseLoading(true));

  $api
    .get(`/courses/${course}/levels`)
    .then((response) => {
      const data: IStudyCourse = response.data;
      dispatch(setStudyCourse(data));
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      dispatch(setIsCourseLoading(false));
    });
};

export const getLanguagesData = () => (dispatch: Dispatch) => {
  $api
    .get('/courses')
    .then((response) => {
      const data: Array<ILanguageData> = response.data;
      const newData = data.reduce((acc: Record<string, ILanguageData>, lang) => {
        if (!lang.enabled) {
          acc[lang.code] = {
            ...lang,
            imgUrl: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.2.1/flags/4x3/${
              FLAG_ICONS_CHARS[lang.code]
            }.svg`,
          };
        }
        return acc;
      }, {});
      dispatch(setLanguagesData(newData));
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      dispatch(setIsLanguageDataLoading(false));
    });
};

export const getLevelData =
  (
    course: string,
    level: number,
    allLevels: {
      [key: string]: Array<ILessonData>;
    },
    isGetImages?: boolean
  ) =>
  (dispatch: Dispatch) => {
    const lessonUrl = `/courses/${course}/level/level-${level}/lessons`;
    const iconUrl = `/activities/course/${course}/level/level-${level}/image`;
    const newKey = getLevelKey(level);

    const promise1 = $api.get(lessonUrl);

    if (!isGetImages) {
      promise1
        .then((res) => {
          const newData = {
            ...allLevels,
            [newKey]: {
              ...allLevels[newKey],
              ...res.data,
            },
          };
          dispatch(setLessons(newData));
        })
        .catch((err) => {
          console.log('error', err);
        });

      return;
    }

    const promise2 = $api.get(iconUrl);

    Promise.all([promise1, promise2])
      .then(function (values) {
        const lessonsData = values[0].data;
        const imageData = values[1].data;
        const iconsData: {
          [key: string]: string;
        } = {};
        imageData.forEach((item: any) => {
          iconsData[item['_id']] = item.image;
        });
        const newData = {
          ...allLevels,
          [newKey]: {
            ...lessonsData,
            images: iconsData,
          },
        };
        dispatch(setLessons(newData));
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

export const getLessonActivities =
  (
    course: string,
    level: number,
    step: number,
    activities: {
      [key: string]: any;
    },
    isFirstTime?: boolean
  ) =>
  (dispatch: Dispatch) => {
    $api
      .get(`/courses/${course}/level/level-${level}/lesson/${step}/activities`)
      .then((response) => {
        const levelKey = getLevelKey(level);
        const lessonKey = getLessonKey(step);
        const newData = {
          [levelKey]: {
            ...activities[levelKey],
            [lessonKey]: response.data,
          },
        };
        dispatch(setActivities(newData));

        if (isFirstTime) {
          const primerActivity = response.data.data.find(
            (element: any) => element.type === 'primer'
          );
          dispatch(
            setCurrentActivity({
              id: primerActivity['_id'],
              text: primerActivity.name,
            })
          );
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

export const getActivitiesData = (activityId: string) => (dispatch: Dispatch) => {
  $api
    .get(`/activities/${activityId}`)
    .then((response) => {
      dispatch(
        setActivitiesData({
          [activityId]: response.data,
        })
      );
    })
    .catch((err) => {
      console.log('error', err);
    });
};

export const markAsDone =
  (
    activityId: string,
    course: string,
    level: number,
    step: number,
    activities: {
      [key: string]: any;
    },
    nextActivity: ICurrentActivity | undefined
  ) =>
  (dispatch: any) => {
    dispatch(setIsPopupLoading(true));
    $api
      .post(`/activities/${activityId}/mark-as-done`)
      .then(
        ({
          data: {
            data: { total },
          },
        }) => {
          dispatch(setGivenPoint(total));
          dispatch(getLessonActivities(course, level, step, activities));
          dispatch(getUser());
          dispatch(getLeaderBord());
          if (nextActivity) {
            dispatch(setCurrentActivity(nextActivity));
          } else {
            dispatch(
              setCurrentActivity({
                id: 0,
                text: '',
              })
            );
          }
        }
      )
      .catch((err) => {
        console.log('error', err);
      })
      .finally(() => {
        dispatch(setIsPopupLoading(false));
      });
  };

export const getLeaderBord = () => (dispatch: Dispatch) => {
  dispatch(setIsCourseLoading(true));
  $api
    .get('/leaderboard')
    .then((response) => {
      const data: ILeaderboardData = response.data;
      dispatch(setLeaderboard(data));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getIsAnswerCorrect = async (
  answer: string,
  quizId: string,
  activityId: string | undefined,
  isReviewPage: boolean | undefined
) => {
  const response = await $api.post(`/reviews/${isReviewPage ? 'evaluate' : 'answer'}`, {
    answer: answer,
    activity: activityId,
    quiz: quizId,
  });
  const data: IAnswer = response.data;
  return data.correct;
};
export const {
  setStudyCourse,
  setLessons,
  setActivities,
  setCurrentLessonAndLevel,
  setActivitiesData,
  setCurrentActivity,
  removeStudyData,
  setLeaderboard,
  setGivenPoint,
  setKanjiesData,
  setLanguagesData,
  setIsPopupLoading,
  setIsLanguageDataLoading,
} = coursesSlice.actions;

export default coursesSlice.reducer;
