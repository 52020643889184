import * as yup from 'yup';

export const signUpValidationScheme = yup.object({
  firstName: yup
    .string()
    .required('Please check this field')
    .min(2, 'First name must be min 2 characters')
    .max(32, 'First name must be max 32 characters'),
  email: yup.string().email('Please check this field').required('Please check this field'),
  password: yup
    .string()
    .min(8, 'Set up password (min 8 characters)')
    .required('Please check this field'),
});

export const loginValidationScheme = yup.object({
  email: yup.string().email('Invalid Email').required('Please check this field'),
  password: yup
    .string()
    .min(8, 'Set up password (min 8 characters)')
    .required('Please check this field'),
});

export const EditValidationScheme = yup.object({
  first_name: yup
    .string()
    .min(2, 'First name must be min 2 characters')
    .max(32, 'First name must be max 32 characters')
    .required('Please check this field'),
});

export const ChangePasswordValidationScheme = yup.object({
  currentPassword: yup
    .string()
    .trim()
    .min(8, 'Password must be min 8 characters')
    .required('Please check this field'),

  newPassword: yup
    .string()
    .min(8, 'Set up password (min 8 characters)')
    .required('Please check this field')
    .test('match', "Passwords can't be same", function lom(newPassword) {
      return this.parent.currentPassword !== newPassword;
    }),

  confirmedPassword: yup
    .string()
    .min(8, 'Set up password (min 8 characters)')
    .required('Please check this field')
    .test('match', "Password doesn't match", function lom(confirmedPassword) {
      return this.parent.newPassword === confirmedPassword;
    }),
});

export const ForgotValidationScheme = yup.object({
  email: yup.string().email('Invalid Email').required('Please check this field'),
});

export const GoalValidationScheme = yup.object({
  goal: yup
    .string()
    .min(2, 'Set up goal (min 2 characters)')
    .max(200, 'Set up goal (max 200 characters)')
    .required('Please check this field'),
});
export const handleSetFieldTouched = (e: any, formik: any) => {
  formik.setFieldTouched(e.target.name);
};
