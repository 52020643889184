import React from 'react';
import { useNavigate } from 'react-router-dom';

import IconUnlock from '../../assets/imgComponent/IconUnlock';
import styles from './user-promotion.module.scss';

interface IUserPromotionProps {
  title?: string;
  className?: string;
}

const UserPromotion: React.FC<IUserPromotionProps> = ({ title, className }) => {
  const navigate = useNavigate();
  return (
    <section className={`${styles.profilePromo} ${className}`} onClick={() => navigate('/billing')}>
      {title && <h2>{title}</h2>}
      <div className="link-button">
        <p>
          <strong>Unlock the full course</strong>
          <span>Over 500 hours of content</span>
        </p>
        <IconUnlock />
      </div>
    </section>
  );
};

export default UserPromotion;
