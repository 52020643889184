import React, { useMemo } from 'react';

import { LEVELS_ICONS } from '../../../../constants/study';
import { IBackendDataContent } from '../../../../types/study';
import styles from './box.module.scss';

interface ITextComponentProps {
  content: IBackendDataContent;
}

const BoxComponent: React.FC<ITextComponentProps> = ({ content }) => {
  const ComponentIcon = useMemo(() => {
    return LEVELS_ICONS[content.icon];
  }, [content]);

  return (
    <div className={`${styles.boxContent} ${content.color ? styles[content.color] : ''}`}>
      {!!content.icon && content.icon !== 'visibility_off' && !!content.title && (
        <h4>
          {LEVELS_ICONS[content.icon] ? <ComponentIcon /> : content.icon}
          {<p>{content.title}</p>}
        </h4>
      )}
      <div dangerouslySetInnerHTML={{ __html: content.html }} />
    </div>
  );
};

export default BoxComponent;
