import { createSelector } from 'reselect';

import { TState } from '../types';

const mainSelector = (state: TState) => state.modal;

export const getModal = createSelector(mainSelector, (state) => state.isOpen);
export const getModalType = createSelector(mainSelector, (state) => state.type);
export const getIsModalLoading = createSelector(mainSelector, (state) => state.isLoading);
export const getCurrentPayment = createSelector(mainSelector, (state) => state.currentPayment);
