import './App.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Notification from './components/Notification/Notification';
import AppModal from './features/organisms/AppModal/AppModal';
import AllRoutes from './routes/routes';
import { getIsAuthenticated } from './store/selectors/authSelectors';
import { getUser, setIsFetchSuccess } from './store/slices/auth';
import { getLanguagesData } from './store/slices/study';
import { getToken, setToken } from './utils/auth';

const App = () => {
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    const token = getToken();
    if (!isAuthenticated && hash && hash.includes('access_token=')) {
      const token = hash.split('&')[0].slice(14);
      dispatch(getUser());
      setToken(token);
    } else if (token) {
      dispatch(getUser(true));
    } else {
      dispatch(setIsFetchSuccess(true));
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getLanguagesData());
    }
  }, [isAuthenticated]);
  return (
    <div className="App">
      <AppModal />
      <Notification />
      <AllRoutes />
    </div>
  );
};

export default App;
