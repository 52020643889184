import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CloseIcon from '../../assets/imgComponent/CloseIcon';
import IconDropdown from '../../assets/imgComponent/Dropdown';
import { PROFILE_MOBILE_MENU } from '../../constants/userProfile';
import Logout from '../../features/moleculs/Logout';
import { getIsAuthenticated } from '../../store/selectors/authSelectors';
import HeaderMenu from '../AppHeader/HeaderMenu';
import stylesLogo from '../AppHeader/logo.module.scss';
import styles from './mobile-main-nav.module.scss';

interface IMobileMainNavProps {
  path?: string;
  closeMenu: () => void;
  handleLogoClick: () => void;
  className?: string;
}

const MobileMainNavigation: React.FC<IMobileMainNavProps> = ({
  path,
  className,
  closeMenu,
  handleLogoClick,
}) => {
  const isAuthenticated = useSelector(getIsAuthenticated);

  return (
    <div className={`${styles.mobileNavigation} ${className}`}>
      <header>
        <Link
          to={isAuthenticated ? '/profile' : '/login'}
          className={`${stylesLogo.logo}`}
          onClick={handleLogoClick}
        >
          <div className={`${stylesLogo.logoImage}`} />
          <div className={`${stylesLogo.logoName}`} />
        </Link>
        <div onClick={closeMenu}>
          <CloseIcon />
        </div>
      </header>
      <section className={styles.navigationMain}>
        <HeaderMenu />
      </section>
      <section className={styles.navigationProfile}>
        {PROFILE_MOBILE_MENU.map(({ text, url, Icon }) => (
          <Link
            key={text}
            to={url}
            className={`
                ${styles.navigationProfileItem} 
                ${path === url ? styles.active : ''} 
                ${text === 'Profile' ? styles.profile : ''}
            `}
            onClick={closeMenu}
          >
            <Icon />
            <span>{text}</span>
            {text === 'Profile' && <IconDropdown />}
          </Link>
        ))}
        <Logout text={'Log Out'} customClass={styles.buttonLogout} />
      </section>
      )
    </div>
  );
};

export default memo(MobileMainNavigation);
