import React from 'react';

const IconHint: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.98271 14.6103L7.36417 15.396L7.98271 14.6103ZM17.5 9.5C17.5 11.2539 16.6801 12.8158 15.3987 13.8245L16.6358 15.396C18.3782 14.0244 19.5 11.8929 19.5 9.5H17.5ZM12 4C15.0376 4 17.5 6.46243 17.5 9.5H19.5C19.5 5.35786 16.1421 2 12 2V4ZM6.5 9.5C6.5 6.46243 8.96243 4 12 4V2C7.85786 2 4.5 5.35786 4.5 9.5H6.5ZM8.60126 13.8245C7.31986 12.8158 6.5 11.2539 6.5 9.5H4.5C4.5 11.8929 5.62179 14.0244 7.36417 15.396L8.60126 13.8245ZM10 19V16.4807H8V19H10ZM13 20H11V22H13V20ZM14 16.4807V19H16V16.4807H14ZM8 19C8 20.6569 9.34315 22 11 22V20C10.4477 20 10 19.5523 10 19H8ZM7.36417 15.396C7.79289 15.7335 8 16.1347 8 16.4807H10C10 15.3359 9.344 14.4092 8.60126 13.8245L7.36417 15.396ZM13 22C14.6569 22 16 20.6569 16 19H14C14 19.5523 13.5523 20 13 20V22ZM15.3987 13.8245C14.656 14.4092 14 15.3359 14 16.4807H16C16 16.1347 16.2071 15.7335 16.6358 15.396L15.3987 13.8245Z"
        fill="#0185F3"
      />
      <path
        d="M19.8504 14.8519L21.0871 15.695M4.15246 4.14872L2.91455 3.30469"
        stroke="#0185F3"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.15104 14.8519L2.91441 15.695M19.849 4.14872L21.0869 3.30469"
        stroke="#0185F3"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M9 16H15" stroke="#0185F3" strokeWidth="2" />
      <path
        d="M14 21C14 21.5304 13.7893 22.0391 13.4142 22.4142C13.0391 22.7893 12.5304 23 12 23C11.4696 23 10.9609 22.7893 10.5858 22.4142C10.2107 22.0391 10 21.5304 10 21L12 21H14Z"
        fill="#0185F3"
      />
      <path
        d="M9.5 9.5C9.5 8.11929 10.6193 7 12 7"
        stroke="#0185F3"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M2.5 9.5H1" stroke="#0185F3" strokeWidth="2" strokeLinecap="round" />
      <path d="M21.5 9.5H23" stroke="#0185F3" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default IconHint;
