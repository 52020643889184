import React from 'react';

import {
  Plan1MonthIcon,
  Plan3MonthsIcon,
  Plan6MonthsIcon,
  Plan12MonthsIcon,
} from '../assets/imgComponent/BillingPlanIcons';
import {
  IconCardAmex,
  IconCardDiners,
  IconCardDiscover,
  IconCardJCB,
  IconCardMC,
  IconCardUniPay,
  IconCardUnknown,
  IconCardVisa,
} from '../assets/imgComponent/IconPaymentCards';
import { CardTypes } from '../types/billing';

export const TEST_PLAN_ID = {
  month: '1020-1month',
  three_month: '1020-3months',
  six_month: '1020-6months',
  year: '1020-year',
};

export const CURRENCY: Record<string, string> = {
  usd: '$',
  euro: '€',
};

export const BILLING_ICONS: Record<string, React.FC> = {
  1: Plan1MonthIcon,
  3: Plan3MonthsIcon,
  6: Plan6MonthsIcon,
  12: Plan12MonthsIcon,
};

export const CARD_ICONS: Record<CardTypes, React.FC> = {
  visa: IconCardVisa,
  mastercard: IconCardMC,
  amex: IconCardAmex,
  discover: IconCardDiscover,
  diners: IconCardDiners,
  unknown: IconCardUnknown,
  jcb: IconCardJCB,
  unionpay: IconCardUniPay,
};

export const SHOWEDCHARGESCOUNT = 10;
