import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserSubscription } from '../../../../store/selectors/authSelectors';
import { getModalType } from '../../../../store/selectors/modalSelectors';
import { setChangingCourse } from '../../../../store/slices/userCourses';
import { closeModal } from '../../../../utils/modal';
import Button from '../../../atoms/Button';
import mainStyles from '../Congratulation/congratulation.module.scss';
import styles from './billing_modal.module.scss';

const BillingResponse: React.FC = () => {
  const dispatch = useDispatch();
  const currentModal: string = useSelector(getModalType);
  const isSuccess = currentModal === 'billingSuccess';
  const { customer, amount, currency } = useSelector(getUserSubscription);
  const priceAmount = parseFloat(amount) / 100;

  useEffect(() => {
    if (isSuccess) {
      tap('create', '1440-a4bd2c', { integration: 'stripe' });
      tap('trial', customer);
      tap('conversion', {
        amount: priceAmount,
        currency: currency.toUpperCase(),
        referral_code: localStorage.getItem('refCode'),
      });
    }
  }, []);

  const cancelModal = () => {
    closeModal(dispatch);
    dispatch(setChangingCourse(''));
    window.history.replaceState('', '', '/billing');
  };
  return (
    <div className={mainStyles.completeLesson}>
      <div className={isSuccess ? styles.successImage : styles.cancelImage} />
      <h3>Payment {isSuccess ? 'Successful' : 'Failed'}!</h3>
      <h5>Your payment has been {isSuccess ? 'processed' : 'failed'}.</h5>
      <Button type={'primary'} value={isSuccess ? 'Continue' : 'Try Again'} onClick={cancelModal} />
    </div>
  );
};

export default BillingResponse;
