import React from 'react';

import Button from '../../atoms/Button';
import styles from './welcomePage.module.scss';

interface IWelcomePage {
  text: string;
  buttonValue: string;
  buttonClick: () => void;
}

const WelcomePageInfoCard: React.FC<IWelcomePage> = ({ text, buttonClick, buttonValue }) => {
  return (
    <div className={styles.welcomePageInfo}>
      <p>{text}</p>
      <Button onClick={buttonClick} type={'primary'} value={buttonValue} />
    </div>
  );
};

export default WelcomePageInfoCard;
