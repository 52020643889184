import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppHeader from '../../../../components/AppHeader';
import Loader from '../../../../components/Loader';
import { LOGIN_INITIAL } from '../../../../constants/authFLow';
import { useAllowSubmit } from '../../../../hooks/useAllowSubmitForm';
import { getAuthLoading } from '../../../../store/selectors/authSelectors';
import { getIsLanguageDataLoading } from '../../../../store/selectors/studySelectors';
import { login } from '../../../../store/slices/auth';
import { handleSetFieldTouched, loginValidationScheme } from '../../../../utils/authValidation';
import Input from '../../../atoms/Input';
import AuthView from '../../../organisms/AuthViews';

const Login: React.FC = () => {
  const [showPass, setShowPass] = useState<boolean>(false);
  const isLoading = useSelector(getAuthLoading);
  const isLanguageDataLoading = useSelector(getIsLanguageDataLoading);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...LOGIN_INITIAL,
    },
    validationSchema: loginValidationScheme,
    onSubmit: (values: any) => {
      dispatch(login(values.email, values.password));
    },
  });
  const allowSubmit = useAllowSubmit(formik, LOGIN_INITIAL);

  return (
    <>
      {isLoading || isLanguageDataLoading ? (
        <>
          <AppHeader />
          <Loader />
        </>
      ) : (
        <AuthView
          type="log in"
          submitHandler={formik.handleSubmit}
          allowSubmit={allowSubmit}
          headerText="Welcome Back!"
          bottomText="Don’t have an account?"
          bottomLinkedText={{ href: 'signup', title: 'Register here!' }}
          buttonText="Log in"
        >
          <Input
            htmlFor="email"
            type="text"
            name="email"
            placeHolder="Enter your email"
            label="Email"
            onBlur={(e: any) => handleSetFieldTouched(e, formik)}
            error={formik.touched.email && formik.values.email && formik.errors.email}
            onChange={(e) => {
              formik.setFieldTouched(e.target.name, false);
              formik.handleChange(e);
            }}
            value={formik.values.email}
            onClick={formik.setFieldTouched}
          />
          <Input
            htmlFor="password"
            type="text"
            isPassInput
            name="password"
            placeHolder="Set up password (min 8 characters)"
            label="Password"
            onBlur={(e: any) => handleSetFieldTouched(e, formik)}
            onClick={formik.setFieldTouched}
            error={formik.touched.password && formik.values.password && formik.errors.password}
            onChange={(e) => {
              formik.setFieldTouched(e.target.name, false);
              formik.handleChange(e);
            }}
            passShowMode={showPass}
            setPassShowMode={setShowPass}
            value={formik.values.password}
          />
        </AuthView>
      )}
    </>
  );
};

export default Login;
