import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../../components/Loader';
import $api from '../../../../Service/api/intercepter';
import { getUserSubscription } from '../../../../store/selectors/authSelectors';
import { setSubscription } from '../../../../store/slices/auth';
import { setModalType } from '../../../../store/slices/modal';
import { setNotification } from '../../../../store/slices/notification';
import { MODAL_TYPES } from '../../../../types/modals';
import { closeModal } from '../../../../utils/modal';
import StepButton from '../../../atoms/StepButton';
import styles from '../../../organisms/AppModal/modal.module.scss';
import billingStyles from './billing_modal.module.scss';

const CancelSubscription: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userSubscription = useSelector(getUserSubscription);
  const nextSubscriptionDate = new Date(userSubscription.currentPeriodEnd).toLocaleDateString();

  const cancelModal = () => {
    closeModal(dispatch);
  };

  const handleDelete = () => {
    setIsLoading(true);
    $api
      .post('subscriptions/cancel')
      .then(() => {
        dispatch(
          setSubscription({
            ...userSubscription,
            isSubscriptionWillExpired: true,
          })
        );
        dispatch(setModalType(MODAL_TYPES.successfulCancellation));
      })
      .catch((err) => {
        setNotification({
          message: err?.response?.data?.error?.message || 'Something went wrong',
          type: 'error',
        });
        closeModal(dispatch);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.profileCoursesModal}>
      <p>
        If you confirm and end your subscription now, you can still access it until{' '}
        {nextSubscriptionDate}
      </p>
      <div className={styles.profileCoursesModalButtons}>
        <StepButton type="prev" text="Back" onHandleClick={cancelModal} />
        <StepButton text="Confirm" onHandleClick={handleDelete} />
      </div>
      {isLoading && (
        <div className={billingStyles.loader}>
          <Loader parentHeight={400} />
        </div>
      )}
    </div>
  );
};

export default CancelSubscription;
