import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ISidebarItem, STUDY_SIDEBAR_MENU } from '../constants/userProfile';
import { getActivities, getCurrentLevel } from '../store/selectors/studySelectors';
import { ICurrentLevel } from '../types/study';
import { getLessonKey, getLevelKey } from '../utils/study';

export const useStudySidebar = () => {
  const currentLevel: ICurrentLevel = useSelector(getCurrentLevel);
  const { level = 0, lesson = 0 } = currentLevel;
  const activities = useSelector(getActivities);
  const [sidebarData, setSidebarData] = useState<Array<ISidebarItem>>([]);

  useEffect(() => {
    const levelKey = getLevelKey(level);
    const leveData = activities[levelKey];
    if (leveData) {
      const lessonKey = getLessonKey(lesson);
      const lessonData = leveData[lessonKey];
      if (lessonData) {
        const lessonNames = lessonData.data.map((item: any) => {
          return item.name;
        });
        const data = STUDY_SIDEBAR_MENU.filter((item) => lessonNames.includes(item.text));

        const newDataWithId = data.map((item) => {
          const element = lessonData.data.find((el: any) => el.name === item.text);
          return {
            ...item,
            id: element['_id'],
            isComplete: element['is_completed'],
          };
        });

        setSidebarData(newDataWithId);
      }
    }
  }, [level, lesson, activities]);
  return {
    sidebarData,
    isSidebarLoaded: !!sidebarData.length,
  };
};
