import { createSlice, Dispatch } from '@reduxjs/toolkit';

import $api from '../../Service/api/intercepter';
import { IPlacementTest } from '../types';

const initialState: IPlacementTest = {
  placementTest: null,
  isPlacementTest: false,
};

export const modalSlice = createSlice({
  name: 'placementTest',
  initialState,
  reducers: {
    setPlacementTest: (state, action) => {
      return {
        ...state,
        placementTest: action.payload,
      };
    },
    setIsPlacementTest: (state, action) => {
      return {
        ...state,
        isPlacementTest: action.payload,
      };
    },
  },
});

export const getPlacementTest = (course?: string) => (dispatch: Dispatch) => {
  $api
    .get(`/courses/${course}/placement`)
    .then((response) => {
      dispatch(setPlacementTest(response.data));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const { setPlacementTest, setIsPlacementTest } = modalSlice.actions;

export default modalSlice.reducer;
