import React, { useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../components/Loader';
import { MODALS } from '../../../constants/modal';
import { getIsModalLoading, getModal, getModalType } from '../../../store/selectors/modalSelectors';
import { setPriceDifference } from '../../../store/slices/auth';
import { setCurrentPayment } from '../../../store/slices/modal';
import { setGivenPoint } from '../../../store/slices/study';
import { ModalType } from '../../../types/modals';
import { closeModal } from '../../../utils/modal';
import CloseButton from '../../atoms/CloseButton';
import styles from './modal.module.scss';

Modal.setAppElement('#root');

const AppModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getModal);
  const currentModal: string = useSelector(getModalType);
  const isLoading = useSelector(getIsModalLoading);

  const currentModalComponent: ModalType | undefined = useMemo(() => {
    if (currentModal) {
      const modal = MODALS[currentModal];
      return modal;
    }
  }, [currentModal]);

  useEffect(() => {
    const APP: HTMLDivElement | null = document?.querySelector('.App');
    if (APP) {
      if (isOpen) {
        APP.style.overflow = 'hidden';
      } else {
        APP.removeAttribute('style');
      }
    }
  }, [isOpen]);

  const handleClose = () => {
    closeModal(dispatch);
    if (currentModal === 'billingCancel' || currentModal === 'billingSuccess') {
      window.history.replaceState('', '', '/billing');
    }
    if (currentModal === 'billing') {
      dispatch(setPriceDifference({}));
      dispatch(setCurrentPayment({}));
    }

    if (currentModal === 'wellDone') {
      dispatch(setGivenPoint(0));
    }
  };

  const Component = useMemo(() => {
    return currentModalComponent?.component;
  }, [currentModalComponent]);
  return (
    <Modal
      onRequestClose={handleClose}
      overlayClassName={styles.overlay}
      portalClassName="modal_react"
      isOpen={isOpen}
      className={`${styles.main} ${currentModalComponent?.className}`}
      contentLabel="Example Modal"
    >
      {currentModalComponent && (
        <div className={styles.wrapper}>
          {isLoading ? (
            <Loader parentHeight={450} />
          ) : (
            <>
              <div className={styles.modalHeader}>
                <p>{currentModalComponent.title}</p>
                <CloseButton onClick={handleClose} />
              </div>
              <Component />
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default AppModal;
