import BillingModal from '../features/moleculs/Modals/Billing';
import BillingSuccess from '../features/moleculs/Modals/Billing/BillingResponse';
import CancelSubscription from '../features/moleculs/Modals/Billing/CancelSubscription';
import SuccessfulCancellation from '../features/moleculs/Modals/Billing/SuccessfulCancellation';
import UpdatePlan from '../features/moleculs/Modals/Billing/UpdatePlan';
import Congratulation from '../features/moleculs/Modals/Congratulation';
import DeleteCourse from '../features/moleculs/Modals/DeleteCourse';
import EditProfile from '../features/moleculs/Modals/EditProfile';
import LeaderboardInfo from '../features/moleculs/Modals/InfoModals/LeaderboardInfo';
import PointsInfo from '../features/moleculs/Modals/InfoModals/PointsInfo';
import StatisticsInfo from '../features/moleculs/Modals/InfoModals/StatisticsInfo';
import LeaderBoardUserInfo from '../features/moleculs/Modals/LeaderBoardUserInfo';
import ResetCourse from '../features/moleculs/Modals/ResetCourse';
import SelectCourse from '../features/moleculs/Modals/SelectCourse';
import WellDone from '../features/moleculs/Modals/WellDone';
import { ModalType } from '../types/modals';

export const MODALS: { [key: string]: ModalType } = {
  userProfile: {
    title: 'Edit Profile',
    component: EditProfile,
    className: '',
  },
  deleteCourse: {
    title: 'Course deleting',
    component: DeleteCourse,
    className: '',
  },
  resetCourse: {
    title: 'Reset course',
    component: ResetCourse,
    className: '',
  },
  selectCourse: {
    title: '',
    component: SelectCourse,
    className: 'SelectCourse',
  },
  congratulation: {
    title: '',
    component: Congratulation,
    className: '',
  },
  wellDone: {
    title: '',
    component: WellDone,
    className: '',
  },
  billing: {
    title: 'Subscribe',
    component: BillingModal,
    className: '',
  },
  billingSuccess: {
    title: '',
    component: BillingSuccess,
    className: '',
  },
  billingCancel: {
    title: '',
    component: BillingSuccess,
    className: '',
  },
  cancelSubscription: {
    title: 'Cancel Plan',
    component: CancelSubscription,
    className: '',
  },
  updatePlan: {
    title: 'Update Plan',
    component: UpdatePlan,
    className: '',
  },
  successfulCancellation: {
    title: '',
    component: SuccessfulCancellation,
    className: '',
  },
  statisticsInfo: {
    title: 'Statistics',
    component: StatisticsInfo,
    className: 'statisticsInfo',
  },
  pointsInfo: {
    title: 'Total dedication points',
    component: PointsInfo,
    className: 'pointsInfo',
  },
  leaderboardInfo: {
    title: 'Leaderboard',
    component: LeaderboardInfo,
    className: 'leaderbordInfo',
  },
  leaderboardUserInfo: {
    title: '',
    component: LeaderBoardUserInfo,
    className: '',
  },
};
