import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { IconBurgerMenu } from '../../assets/imgComponent/IconsHeader';
import { SCREENS } from '../../constants/sccrens';
import Logout from '../../features/moleculs/Logout/Logout';
import UserAvatarImage from '../../features/moleculs/UserAvatarImage';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useOutsideDetect } from '../../hooks/useOutsideDetect';
import {
  getCurrentUser,
  getIsAuthenticated,
  getUserName,
} from '../../store/selectors/authSelectors';
import { getPlacementTestData } from '../../store/selectors/placementTest';
import { getCurrentActivity } from '../../store/selectors/studySelectors';
import { setCurrentActivity } from '../../store/slices/study';
import { ICurrentActivity } from '../../types/study';
import MobileMainNavigation from '../MobileMainNavigation';
import TabletProfileNavigation from '../TabletProfileNavigation';
import ChooseCurrentCourse from './ChooseCurrentCourse';
import stylesHeader from './header.module.scss';
import HeaderMenu from './HeaderMenu';
import stylesLogo from './logo.module.scss';

interface IAppHeaderProps {
  type?: 'primary' | 'secondary';
  customClass?: string;
  isUserSingedIn?: boolean;
  path?: string;
  closeMenu?: boolean;
}

const AppHeader: React.FC<IAppHeaderProps> = ({ type, customClass = '', isUserSingedIn }) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isTablet = useMediaQuery(SCREENS.tablet);
  const isMobile = useMediaQuery(SCREENS.mobile);
  const placementTestData = useSelector(getPlacementTestData);
  const navigate = useNavigate();
  const [isActive, setActive] = useState(false);
  const ref = useOutsideDetect(setActive);
  const name = useSelector(getUserName);

  const dispatch = useDispatch();
  const { id }: ICurrentActivity = useSelector(getCurrentActivity);

  const { email, id: user_id, createdAt } = useSelector(getCurrentUser);

  useEffect(() => {
    if (isAuthenticated) {
      (window as any).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        name,
        email,
        user_id,
        created_at: createdAt,
      });
    }
  }, []);

  const handleLogoClick = () => {
    if (id) {
      dispatch(
        setCurrentActivity({
          id: 0,
          text: '',
        })
      );
    }
  };

  const toggleDeviceMenuState = () => {
    if (!isMobile && !isTablet) {
      navigate('/profile');
    } else {
      setActive(!isActive);
    }
  };

  return (
    <>
      {isMobile && isAuthenticated && (
        <MobileMainNavigation
          className={`${isActive ? 'isActive' : ''}`}
          closeMenu={toggleDeviceMenuState}
          handleLogoClick={handleLogoClick}
        />
      )}

      <header className={`${[type]} ${stylesHeader.header} ${customClass}`}>
        <section>
          {isMobile && isAuthenticated && (
            <div onClick={toggleDeviceMenuState}>
              <IconBurgerMenu />
            </div>
          )}
          <Link
            to={isAuthenticated ? '/profile' : '/login'}
            className={`${stylesLogo.logo}`}
            onClick={handleLogoClick}
          >
            <div className={`${stylesLogo.logoImage}`} />
            {!isTablet && <div className={stylesLogo.logoName} />}
          </Link>
          {isAuthenticated && !isUserSingedIn && <Logout />}
          {isUserSingedIn && (
            <>
              {!isMobile && <HeaderMenu />}
              {!placementTestData && <ChooseCurrentCourse />}
              {!isMobile && (
                <div className={stylesHeader.headerProfileMenu}>
                  <div
                    ref={ref}
                    className={`
                  ${isActive ? 'isActive' : ''}
                  ${stylesHeader.headerProfileInfo}
                  `}
                    onClick={toggleDeviceMenuState}
                  >
                    <UserAvatarImage />
                    <p>{name}</p>
                    {isTablet && isActive && <TabletProfileNavigation />}
                  </div>
                  <Logout />
                </div>
              )}
            </>
          )}
        </section>
      </header>
    </>
  );
};

export default memo(AppHeader);
