import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TEST_ITEM_DATA_INITIAL } from '../../constants/study';
import { getIsPlacementTest, getPlacementTestData } from '../../store/selectors/placementTest';
import { setIsPlacementTest, setPlacementTest } from '../../store/slices/placementTest';
import { ITestItemData } from '../../types/study';
import AppHeader from '../AppHeader';
import Loader from '../Loader';
import Congratulation from './Congratulation';
import PlacementTestContent from './PlacementTestContent';
import styles from './placment.module.scss';

const PlacementTest: React.FC = () => {
  const isPlacementTest = useSelector(getIsPlacementTest);
  const placementTestData = useSelector(getPlacementTestData);
  const [step, setStep] = useState<number>(0);
  const [level, setLevel] = useState<number>(1);
  const [isWrongAnswer, setIsWrongAnswer] = useState(false);
  const [isTestFinish, setIsTestFinish] = useState(false);
  const dispatch = useDispatch();
  const [testData, setTestData] = useState<ITestItemData>(TEST_ITEM_DATA_INITIAL);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPlacementTest) {
      navigate('/profile');
    }

    return () => {
      dispatch(setIsPlacementTest(false));
      dispatch(setPlacementTest(null));
    };
  }, [isPlacementTest]);

  useEffect(() => {
    if (placementTestData) {
      const levelData = placementTestData[`level-${level}`];
      if (levelData) {
        const questionData = levelData[step];
        if (questionData) {
          setTestData({ ...questionData, options: [...questionData.options, questionData.answer] });
        } else {
          setLevel((prev) => prev + 1);
          setStep(0);
        }
      } else {
        if (!isWrongAnswer) {
          setIsTestFinish(true);
        }
      }
    }
  }, [step, placementTestData, level]);

  return (
    <>
      <AppHeader isUserSingedIn={true} />
      <div className={styles.testWrapper}>
        <div className={styles.testContent}>
          {placementTestData ? (
            (isWrongAnswer && step === 0) || isTestFinish ? (
              <Congratulation level={level - 1} isTestFinish={isTestFinish} />
            ) : (
              <>
                <h2>Placement test</h2>
                <PlacementTestContent
                  type={'assessment'}
                  data={testData}
                  step={step}
                  setStep={setStep}
                  setIsWrongAnswer={setIsWrongAnswer}
                />
              </>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default PlacementTest;
