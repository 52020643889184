import React from 'react';

const IconHintCOlorized: React.FC = () => {
  return (
    <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_605_4812)">
        <g filter="url(#filter1_i_605_4812)">
          <path
            d="M16 20C16 18.0999 17.1316 16.4235 18.3029 14.9273C19.3662 13.5691 20 11.8586 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 11.8586 4.6338 13.5691 5.69706 14.9273C6.86835 16.4235 8 18.0999 8 20H16Z"
            fill="#C4C4C4"
          />
          <path
            d="M16 20C16 18.0999 17.1316 16.4235 18.3029 14.9273C19.3662 13.5691 20 11.8586 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 11.8586 4.6338 13.5691 5.69706 14.9273C6.86835 16.4235 8 18.0999 8 20H16Z"
            fill="url(#paint0_linear_605_4812)"
          />
          <path
            d="M9.87598 20.5236C9.99094 20.5197 10.0775 20.4175 10.0626 20.3035L9.50497 13.045C9.46341 12.7276 9.82709 12.519 10.0801 12.7152L10.3697 12.9398C10.5406 13.0723 10.789 13.0245 10.8985 12.838L11.4583 11.8843C11.59 11.66 11.9092 11.6461 12.0597 11.8582L12.6892 12.7446C12.8118 12.9171 13.0563 12.9465 13.2162 12.808L13.4174 12.6337C13.6659 12.4184 14.0489 12.6233 14.0078 12.9495L13.457 20.3177C13.4421 20.4354 13.5361 20.5384 13.6547 20.5343C13.7499 20.5311 13.8284 20.4588 13.8395 20.3642L14.4612 12.0477C14.4992 11.7232 14.1187 11.5212 13.8712 11.7344L13.2839 12.2404C13.1236 12.3785 12.879 12.3484 12.757 12.1754L12.0351 11.1519C11.8853 10.9395 11.5663 10.9521 11.4338 11.1757L10.7861 12.2685C10.6758 12.4546 10.427 12.5013 10.2567 12.3678L9.57982 11.8374C9.32732 11.6395 8.96195 11.8475 9.00321 12.1656L9.67608 20.3539C9.68901 20.4535 9.77556 20.527 9.87598 20.5236Z"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
          />
          <rect x="8" y="20" width="8" height="1" fill="url(#paint1_linear_605_4812)" />
          <rect x="8" y="21" width="8" height="1" fill="#729BB4" />
          <rect x="8" y="22" width="8" height="1" fill="url(#paint2_linear_605_4812)" />
          <rect x="8" y="23" width="8" height="1" fill="#729BB4" />
          <path
            d="M8 24H16C16 24.5523 15.5523 25 15 25H9C8.44772 25 8 24.5523 8 24Z"
            fill="url(#paint3_linear_605_4812)"
          />
          <path
            d="M10 25H14C14 25.5523 13.5523 26 13 26H11C10.4477 26 10 25.5523 10 25Z"
            fill="#2F353C"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_605_4812"
          x="-2"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.295833 0 0 0 0 0.295833 0 0 0 0 0.295833 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_605_4812" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_605_4812"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_605_4812"
          x="2"
          y="0"
          width="18"
          height="26"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_605_4812" />
        </filter>
        <linearGradient
          id="paint0_linear_605_4812"
          x1="4"
          y1="2"
          x2="17.8058"
          y2="22.6798"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.161539" stopColor="#FFED91" />
          <stop offset="0.822515" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_605_4812"
          x1="8"
          y1="20"
          x2="8.24615"
          y2="21.9692"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_605_4812"
          x1="8"
          y1="22"
          x2="8.24615"
          y2="23.9692"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_605_4812"
          x1="8"
          y1="24"
          x2="8.24615"
          y2="25.9692"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#B0C7EB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconHintCOlorized;
