import React from 'react';

const IconChecked: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 6L10.0607 15.9393C9.47487 16.5251 8.52513 16.5251 7.93934 15.9393L4 12"
        stroke="#0185F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconChecked;
