import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../features/atoms/Button';
import styles from '../../features/organisms/AuthViews/auth.module.scss';
import { setIsShowVerifiedContent } from '../../store/slices/auth';
import { removeToken } from '../../utils/auth';

const EmailVerification: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setIsShowVerifiedContent(false));
    removeToken();
  };
  return (
    <>
      <div className={styles.checkEmailBlock}>
        <div className={styles.checkEmailImage} />
        <div className={`${styles.checkEmailText} ${styles.verifyEmailText}`}>
          We have sent an email with a confirmation link to your email address. In order to complete
          the sign-up process, please click the confirmation link.
          <br />
          <br />
          If you do not receive a confirmation email, please check your spam folder. Also, please
          verify that you entered a valid email address in our sign-up form.
        </div>
        <Button onClick={handleClick} type="primary" value="Back to Log In" />
      </div>
    </>
  );
};

export default EmailVerification;
