import {
  ActiveIconProfileLeaderboard,
  ActiveIconProfileSkills,
  ActiveIconProfileStudy,
  IconProfileLeaderboard,
  IconProfileSkills,
  IconProfileStudy,
} from '../assets/imgComponent/IconsHeader';

export const HEADER_ITEMS = [
  {
    Icon: IconProfileStudy,
    ActiveIcon: ActiveIconProfileStudy,
    text: 'Study',
    url: '/study',
  },
  {
    Icon: IconProfileSkills,
    ActiveIcon: ActiveIconProfileSkills,
    text: 'Review Skills',
    url: '/review-skills',
  },
  {
    Icon: IconProfileLeaderboard,
    ActiveIcon: ActiveIconProfileLeaderboard,
    text: 'Leaderboard',
    url: '/leaderboard',
  },
];
