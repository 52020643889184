import React, { memo, useState } from 'react';

import IconHint from '../../../assets/imgComponent/iconHint';
import IconHintCOlorized from '../../../assets/imgComponent/iconHintColorized';
import styles from './hint.module.scss';

interface ITestKeyProps {
  text: string;
  isVisible: boolean;
}
const HintComponent: React.FC<ITestKeyProps> = ({ text, isVisible }) => {
  const [isShowHint, setIsShowHint] = useState(false);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {isShowHint ? (
        <div className={styles.notifyHint}>
          <h3>
            <IconHintCOlorized /> Hint:
          </h3>
          <p dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      ) : (
        <div className={styles.giveHint} onClick={() => setIsShowHint(true)}>
          <IconHint />
          <span>Give me a hint</span>
        </div>
      )}
    </>
  );
};

export default memo(HintComponent);
