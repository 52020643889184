import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TEST_ITEM_DATA_INITIAL } from '../../../../constants/study';
import ProgressLine from '../../../../features/atoms/ProgressLine';
import { getCurrentActivity } from '../../../../store/selectors/studySelectors';
import { ICurrentActivity, ITestItemData, IVocabItemData } from '../../../../types/study';
import { getHintVocab, getOptionsForVocab } from '../../../../utils/study';
import TestContent from '../../../TestComponents/TestContent';
import main from '../Assessment/assigments.module.scss';
import styles from './vocabulary.module.scss';

interface IVocabularyQuizProps {
  data: Array<IVocabItemData>;
  stepsCount: number;
}

const VocabularyQuiz: React.FC<IVocabularyQuizProps> = ({ data, stepsCount }) => {
  const { text, id }: ICurrentActivity = useSelector(getCurrentActivity);

  const [step, setStep] = useState<number>(0);
  const [vocabData, setVocabData] = useState<Array<IVocabItemData>>([...data]);
  const [testData, setTestData] = useState<ITestItemData>(TEST_ITEM_DATA_INITIAL);
  const [checkedItemsCount, setCheckedItemsCount] = useState<number>(0);
  const isLastQuestion = step === vocabData.length - 1;

  useEffect(() => {
    const {
      meanings,
      course,
      sentence,
      _id,
      sentence_en,
      word,
      audio,
      image,
      currentOptions,
      spelling,
      pronunciation,
      url,
    } = vocabData[step];
    const vocabOptionsData = getOptionsForVocab(data, meanings, image);

    if (!currentOptions) {
      vocabData[step] = {
        ...vocabData[step],
        currentOptions: Object.keys(vocabOptionsData),
      };
    }

    const value: ITestItemData = {
      answer: image
        ? typeof image === 'string'
          ? image
          : `https://app.lingualift.com/images/vocab/${image}.jpg`
        : meanings,
      resultAnswer: meanings,
      course: course,
      hint: getHintVocab(sentence_en),
      key: sentence,
      options: vocabData[step]['currentOptions'] || [],
      task: 'Choose the right translation:',
      _id,
      word: spelling || word,
      sound: audio,
      url: url,
      image,
      transliterationWord: word.includes('_') ? pronunciation : '',
      meaningsData: vocabOptionsData,
    };
    setTestData(value);
  }, [step]);

  return (
    <div className={`${main.assessment} ${styles.vocabularyQuiz}`}>
      <ProgressLine value={(checkedItemsCount / stepsCount) * 100} />
      <h1>{text}</h1>
      <TestContent
        type={'vocab'}
        data={testData}
        step={step}
        setStep={setStep}
        stepsCount={stepsCount}
        setCheckedItemsCount={setCheckedItemsCount}
        id={id}
        setAllData={setVocabData}
        isLastQuestion={isLastQuestion}
        allData={vocabData}
      />
    </div>
  );
};

export default VocabularyQuiz;
