import React, { memo, useEffect, useRef, useState } from 'react';

import Loader from '../../../components/Loader';
import { useOnLoadImages } from '../../../hooks/useOnLoadImages';
import styles from './image_test_options.module.scss';

interface IImageTestOptions {
  setVariant: (arg: string) => void;
  isDisable: boolean;
  optionsData: Array<string>;
  variant: string;
}

const ImageTestOptions: React.FC<IImageTestOptions> = ({
  isDisable,
  optionsData,
  setVariant,
  variant,
}) => {
  const wrapperRef = useRef<HTMLUListElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);
  const [isShowImages, setIsShowImages] = useState<boolean>(false);

  const handleClick = (variant: string) => {
    if (!isDisable) {
      setVariant(variant);
    }
  };

  useEffect(() => {
    if (imagesLoaded) {
      const timer = setTimeout(() => setIsShowImages(true), 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer);
      };
    } else {
      setIsShowImages(false);
    }
  }, [optionsData, imagesLoaded]);
  return (
    <>
      {!isShowImages && <Loader parentHeight={100} />}
      <ul
        className={styles.imgTestSectionList}
        ref={wrapperRef}
        style={{
          visibility: isShowImages ? 'visible' : 'hidden',
        }}
      >
        {optionsData.map((item: string) => {
          return (
            <li key={item} className={styles.optionImage}>
              <input
                type="radio"
                id={item}
                name={'img_input'}
                disabled={isDisable}
                checked={variant === item}
              />
              <label htmlFor={item} onClick={() => handleClick(item)}>
                <img alt={'img'} src={item} />
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default memo(ImageTestOptions);
