import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserImg } from '../../../store/selectors/authSelectors';
import DefaultUserPhoto from './DefaultUserPhoto';
import styles from './modal-user-photo.module.scss';

interface IModalUserPhoto {
  setUserImgFile?: any;
  isEditable?: boolean;
}

const ModalUserPhoto: React.FC<IModalUserPhoto> = ({ setUserImgFile, isEditable = true }) => {
  const [userNewImg, setUserNewImg] = useState<string>('');
  const [isOverSize, setIsOverSize] = useState<boolean>(false);
  const [userImg, setUserImg] = useState<string | undefined>(useSelector(getUserImg));

  const handleOnChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const files = target.files as FileList;
    if (files[0].size < 1000000) {
      setUserImgFile(files[0]);
      const imgUrl = URL.createObjectURL(files[0]);
      setUserNewImg(imgUrl);
      isOverSize && setIsOverSize(false);
    } else {
      setIsOverSize(true);
    }
    target.value = '';
  };

  useEffect(() => {
    if (isOverSize) {
      const timer = setTimeout(() => {
        setIsOverSize(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isOverSize]);
  const removeImg = () => {
    isOverSize && setIsOverSize(false);

    setUserNewImg('');
    setUserImg('');
    setUserImgFile(null);
  };

  return (
    <div className={styles.modalIMage}>
      {isEditable && (
        <input
          type="file"
          name=""
          id="edit-photo"
          onChange={(e) => {
            handleOnChange(e);
          }}
          accept="image/jpeg, image/png, image/jpg, image/pdf, image/svg"
        />
      )}
      <div className={styles.imageContainer}>
        {isEditable && (userNewImg || userImg) && <button type="button" onClick={removeImg} />}
        <label htmlFor="edit-photo">
          {userNewImg || userImg ? (
            <img src={userNewImg || userImg} alt="" />
          ) : (
            <DefaultUserPhoto />
          )}
          {isEditable && <p>Change photo</p>}
        </label>
      </div>
      {isOverSize && <p className={styles.errorMessage}>Maximum allowed file size is 1 MB</p>}
    </div>
  );
};

export default ModalUserPhoto;
