import React from 'react';

import PlacmentTest from '../components/PlacmentTest';
import ForgotPassword from '../features/views/AuthFlow/ForgotPassword';
import Login from '../features/views/AuthFlow/Login';
import Onboarding from '../features/views/AuthFlow/Onboarding';
import SignUp from '../features/views/AuthFlow/SignUp';
import UserProfile from '../features/views/UserProfile';

const Leaderboard = React.lazy(() => import('../features/views/LeadershipBoard/Leaderboard'));
const Study = React.lazy(() => import('../features/views/Study'));
const ReviewSkills = React.lazy(() => import('../features/views/ReviewSkills'));

export interface RoutType {
  path: string;
  redirectUrl: string;
  Component: any;
}

export const ROUTES_FOR_UNSIGNED_USER: Array<RoutType> = [
  {
    path: 'login',
    redirectUrl: 'profile',
    Component: Login,
  },
  {
    path: 'signup',
    redirectUrl: 'profile',
    Component: SignUp,
  },
  {
    path: 'forgotPassword',
    redirectUrl: 'profile',
    Component: ForgotPassword,
  },
];

export const PRIVATE_ROUTES: Array<RoutType> = [
  {
    path: 'onboarding',
    redirectUrl: 'login',
    Component: Onboarding,
  },
  {
    path: 'profile',
    redirectUrl: 'login',
    Component: UserProfile,
  },
  {
    path: 'courses',
    redirectUrl: 'login',
    Component: UserProfile,
  },
  {
    path: 'notification',
    redirectUrl: 'login',
    Component: UserProfile,
  },
  {
    path: 'billing',
    redirectUrl: 'login',
    Component: UserProfile,
  },
  {
    path: 'leaderboard',
    redirectUrl: 'login',
    Component: Leaderboard,
  },
  {
    path: 'study',
    redirectUrl: 'login',
    Component: Study,
  },
  {
    path: 'placementTest',
    redirectUrl: 'login',
    Component: PlacmentTest,
  },
  {
    path: '/review-skills',
    redirectUrl: 'login',
    Component: ReviewSkills,
  },
];
