import React from 'react';

import { IBackendDataContent } from '../../../../types/study';

interface ITextComponentProps {
  content: IBackendDataContent;
}

const ImageComponent: React.FC<ITextComponentProps> = ({ content }) => {
  return (
    <div>
      <img src={content.src} />
    </div>
  );
};

export default ImageComponent;
