import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BILLING_ICONS, CURRENCY } from '../constants/billing';
import { getPlanDataFromStore } from '../store/selectors/billing';
import { getPlanData } from '../store/slices/billing';
import { IBillingItem } from '../types/billing';

export const usePlanData = () => {
  const data = useSelector(getPlanDataFromStore);
  const [planData, setPlanData] = useState<Array<IBillingItem> | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const newData: Array<IBillingItem> = data.map(
        ({ interval, currency, amount, id, period }) => {
          const month = period === 'yearly' ? interval * 12 : interval;
          return {
            Icon: BILLING_ICONS[String(month)],
            title: `${month} ${month > 1 ? 'Months' : 'Month'}`,
            perMonthPrice: `${CURRENCY[currency]}${amount / 100 / month}`,
            price: `${CURRENCY[currency]}${amount / 100}`,
            id: id,
            month: month,
            popular: month === 12,
            currency: currency,
          };
        }
      );
      setPlanData(newData);
    } else {
      dispatch(getPlanData());
    }
  }, [data]);

  return planData;
};
