import React, { memo } from 'react';

import { getVariantForVocabPage, removeBracketsInString, uniqArray } from '../../../utils/study';
import styles from './testKey.module.scss';

interface ITestKeyProps {
  type: 'assessment' | 'vocab' | 'script';
  text: string;
  variant: string;
  setKeyText?: (str: string) => void;
  answer?: string;
  transliterationWord?: string;
}
const TestKey: React.FC<ITestKeyProps> = ({
  text,
  variant,
  type,
  answer = '',
  setKeyText,
  transliterationWord,
}) => {
  const isVocabPage = type === 'vocab';

  const keyWord: string = isVocabPage ? getVariantForVocabPage(text) : variant;

  const getTestKey = (key: string) => {
    let text = key;
    const withoutBracketsText = removeBracketsInString(text);
    if (type === 'script' || type === 'assessment') {
      text = withoutBracketsText.replace(
        new RegExp(`(?:^|\\s|{)${answer}(?=\\s|$|})`, 'g'),
        `<span>${variant || ' '}</span>`
      );

      if (text === withoutBracketsText) {
        text = withoutBracketsText.replace(
          new RegExp(`\\s*${answer}\\s*`, 'g'),
          `<span>${variant || ' '}</span>`
        );
      }

      text = text.replace(/\s\s+/g, ' ').trim();
      return <p className={styles.textInPtag} dangerouslySetInnerHTML={{ __html: text }} />;
    } else {
      if (text.includes('{')) {
        let withoutBracketsText = removeBracketsInString(text);
        setKeyText && setKeyText(removeBracketsInString(withoutBracketsText));

        const keyWords = text.match(/[^{}]+(?=})/g);
        uniqArray(keyWords).forEach((word) => {
          if (withoutBracketsText.includes(word)) {
            withoutBracketsText = withoutBracketsText.replace(
              word,
              `<div class="transliterationWord_block"><span>${word}</span><p class="wordAfter">${
                transliterationWord || ''
              }</p></div>`
            );
          }
        });
        return (
          <p
            className={styles.textWrapper}
            dangerouslySetInnerHTML={{
              __html: `<div class="wordLine">${withoutBracketsText}</div>`,
            }}
          />
        );
      } else {
        return <p>{text}</p>;
      }
    }
  };
  return (
    <div className={styles.testKeyContent}>
      {text ? (
        getTestKey(text)
      ) : (
        <div className={styles.textWrapper}>
          <div>
            <span>{keyWord}</span>
            {transliterationWord && <p className="wordAfter">${transliterationWord}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(TestKey);
