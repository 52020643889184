import React from 'react';

import css from '../../features/organisms/LeadershipSidebar/leadership-sidebar.module.scss';

const IconApprentice: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_12100)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.9996 17C40.9996 20.3051 40.0564 23.3899 38.4244 26H41.8859C42.0419 26 42.1958 26.0365 42.3352 26.1066L43.0223 26.4521L43.7094 26.1066C43.8488 26.0365 44.0026 26 44.1586 26C46.2799 26 47.9996 27.7196 47.9996 29.8409V33.1591C47.9996 35.2804 46.2799 37 44.1586 37C44.0026 37 43.8488 36.9635 43.7094 36.8934L43.0223 36.5479L42.3352 36.8934C42.1958 36.9635 42.0419 37 41.8859 37H44.1586H46.9996C47.5518 37 47.9996 37.4477 47.9996 38C47.9996 38.5523 47.5518 39 46.9996 39H46.5873C45.8218 41.2299 45.8322 43.9194 46.5651 46H46.9996C47.5518 46 47.9996 46.4477 47.9996 47C47.9996 47.5523 47.5518 48 46.9996 48H18.1132C17.9572 48 17.8033 47.9635 17.6639 47.8934L16.9768 47.5479L16.2897 47.8934C16.1503 47.9635 15.9965 48 15.8405 48C13.7192 48 11.9996 46.2804 11.9996 44.1591V40.8409C11.9996 38.7196 13.7192 37 15.8405 37C15.9965 37 16.1503 37.0365 16.2897 37.1066L16.9768 37.4521L17.6639 37.1066C17.8033 37.0365 17.9572 37 18.1132 37H16.9996C16.4473 37 15.9996 36.5523 15.9996 36C15.9996 35.4477 16.4473 35 16.9996 35H17.434C17.6658 34.342 17.8253 33.6231 17.9109 32.8772C15.1828 31.8303 12.7931 30.1006 10.951 27.8973L12.1025 46.4419C12.1367 46.9931 11.7176 47.4677 11.1664 47.502L3.18178 47.9977C2.63055 48.032 2.15595 47.6129 2.12173 47.0616L0.014651 13.127C-0.0195756 12.5758 0.399533 12.1012 0.950756 12.0669L7.86254 11.6378C10.1082 4.87647 16.4844 0 23.9996 0C33.3884 0 40.9996 7.61116 40.9996 17Z"
          fill="white"
        />
        <g opacity="0.32" filter="url(#filter0_i_1549_12100)">
          <circle cx="24" cy="17" r="15.5" fill="#C4C4C4" />
          <circle cx="24" cy="17" r="15.5" fill="url(#paint0_linear_1549_12100)" />
          <circle
            cx="24"
            cy="17"
            r="15.5"
            stroke="url(#paint1_linear_1549_12100)"
            strokeWidth="3"
          />
          <path
            d="M24 10L24 17L30 22"
            stroke="#00768F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="24" cy="17" r="2" fill="#C4C4C4" />
          <circle cx="24" cy="17" r="2" fill="url(#paint2_radial_1549_12100)" />
          <path
            d="M24 4.5V5.5"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 28.5V29.5"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.5 17L35.5 17"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 17L11.5 17"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.8254 10.75L33.9594 11.25"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.0408 22.75L13.1747 23.25"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.25 6.17578L29.75 7.04181"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.25 26.9609L17.75 27.827"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.75 6.17578L18.25 7.04181"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.75 26.9609L30.25 27.827"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.1746 10.75L14.0406 11.25"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.9592 22.75L34.8253 23.25"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.625 27H17C18.3343 29.7307 18.2929 33.5581 17 36L43.625 35.875L46.875 34.8906V27.9844L43.625 27Z"
            fill="white"
          />
          <path
            d="M43.625 27H17C18.3343 29.7307 18.2929 33.5581 17 36L43.625 35.875L46.875 34.8906V27.9844L43.625 27Z"
            fill="url(#paint3_linear_1549_12100)"
            fillOpacity="0.15"
          />
          <path
            d="M17 27H41.8864L43.0227 27.5714L44.1591 27V27C45.7281 27 47 28.2719 47 29.8409V33.1591C47 34.7281 45.7281 36 44.1591 36V36L43.0227 35.4286L41.8864 36H17"
            stroke="url(#paint4_linear_1549_12100)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.375 38H47C45.6657 40.7307 45.7071 44.5581 47 47L16.375 46.875L13.125 45.8906V38.9844L16.375 38Z"
            fill="white"
          />
          <path
            d="M16.375 38H47C45.6657 40.7307 45.7071 44.5581 47 47L16.375 46.875L13.125 45.8906V38.9844L16.375 38Z"
            fill="url(#paint5_linear_1549_12100)"
            fillOpacity="0.15"
          />
          <path
            d="M47 38H18.1136L16.9773 38.5714L15.8409 38V38C14.2719 38 13 39.2719 13 40.8409V44.1591C13 45.7281 14.2719 47 15.8409 47V47L16.9773 46.4286L18.1136 47H47"
            stroke="url(#paint6_linear_1549_12100)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x="-0.046875"
            y="12.1289"
            width="10"
            height="36"
            rx="1"
            transform="rotate(-3.55306 -0.046875 12.1289)"
            fill="#C4C4C4"
          />
          <rect
            x="-0.046875"
            y="12.1289"
            width="10"
            height="36"
            rx="1"
            transform="rotate(-3.55306 -0.046875 12.1289)"
            fill="url(#paint7_linear_1549_12100)"
          />
          <ellipse
            opacity="0.75"
            cx="5.47038"
            cy="20.3035"
            rx="3"
            ry="4.5"
            transform="rotate(-3.55306 5.47038 20.3035)"
            fill="url(#paint8_linear_1549_12100)"
          />
          <rect
            opacity="0.25"
            x="1.58105"
            y="38.0781"
            width="9.96607"
            height="2"
            transform="rotate(-3.55306 1.58105 38.0781)"
            fill="white"
          />
          <rect
            opacity="0.25"
            x="1.82275"
            y="42.0703"
            width="9.97955"
            height="2"
            transform="rotate(-3.55306 1.82275 42.0703)"
            fill="white"
          />
        </g>
        <g className={css.svgBlend}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41 17C41 20.3051 40.0568 23.3899 38.4249 26H41.8864C42.0424 26 42.1963 26.0365 42.3357 26.1066L43.0228 26.4521L43.7099 26.1066C43.8493 26.0365 44.0031 26 44.1591 26C46.2804 26 48 27.7196 48 29.8409V33.1591C48 35.2804 46.2804 37 44.1591 37C44.0031 37 43.8493 36.9635 43.7099 36.8934L43.0228 36.5479L42.3357 36.8934C42.1963 36.9635 42.0424 37 41.8864 37H44.1591H47C47.5523 37 48 37.4477 48 38C48 38.5523 47.5523 39 47 39H46.5878C45.8223 41.2299 45.8327 43.9194 46.5655 46H47C47.5523 46 48 46.4477 48 47C48 47.5523 47.5523 48 47 48H18.1137C17.9577 48 17.8038 47.9635 17.6644 47.8934L16.9773 47.5479L16.2902 47.8934C16.1508 47.9635 15.997 48 15.841 48C13.7197 48 12 46.2804 12 44.1591V40.8409C12 38.7196 13.7197 37 15.841 37C15.997 37 16.1508 37.0365 16.2902 37.1066L16.9773 37.4521L17.6644 37.1066C17.8038 37.0365 17.9577 37 18.1137 37H17C16.4478 37 16 36.5523 16 36C16 35.4477 16.4478 35 17 35H17.4345C17.6663 34.342 17.8258 33.6231 17.9114 32.8772C15.1833 31.8303 12.7936 30.1006 10.9515 27.8973L12.103 46.4419C12.1372 46.9931 11.7181 47.4677 11.1669 47.502L3.18226 47.9977C2.63104 48.032 2.15644 47.6129 2.12221 47.0616L0.0151393 13.127C-0.0190873 12.5758 0.400021 12.1012 0.951244 12.0669L7.86303 11.6378C10.1086 4.87647 16.4849 0 24 0C33.3889 0 41 7.61116 41 17Z"
            fill="url(#paint9_linear_1549_12100)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12100"
          x="-1.98682"
          y="-2"
          width="49.9868"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12100" />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12100"
          x1="24"
          y1="0"
          x2="24"
          y2="34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1549_12100"
          x1="15.2424"
          y1="2.06061"
          x2="32.7576"
          y2="31.4242"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAA061" />
          <stop offset="1" stopColor="#C21190" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_1549_12100"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.5 16.5) rotate(45) scale(2.82843)"
        >
          <stop offset="0.119792" stopColor="#F89964" />
          <stop offset="0.906236" stopColor="#C8208C" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_1549_12100"
          x1="31.9375"
          y1="27"
          x2="31.9375"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1549_12100"
          x1="34.5"
          y1="21.5"
          x2="28.5"
          y2="42.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="0.892645" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1549_12100"
          x1="30.0625"
          y1="38"
          x2="30.0625"
          y2="47"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1549_12100"
          x1="34.5"
          y1="33.5"
          x2="29.5"
          y2="53"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="0.837524" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1549_12100"
          x1="-0.046875"
          y1="12.1289"
          x2="18.5205"
          y2="17.2865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BAD6FF" />
          <stop offset="1" stopColor="#C11190" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1549_12100"
          x1="3.38927"
          y1="17.3659"
          x2="7.48727"
          y2="23.1318"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.24" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1549_12100"
          x1="0.0131836"
          y1="0"
          x2="48.0132"
          y2="47.9869"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#589EBD" />
        </linearGradient>
        <clipPath id="clip0_1549_12100">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconApprenticeComplete: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_12064)">
        <g filter="url(#filter0_i_1549_12064)">
          <circle cx="24" cy="17" r="15.5" fill="#C4C4C4" />
          <circle cx="24" cy="17" r="15.5" fill="url(#paint0_linear_1549_12064)" />
          <circle
            cx="24"
            cy="17"
            r="15.5"
            stroke="url(#paint1_linear_1549_12064)"
            strokeWidth="3"
          />
          <path
            d="M24 10L24 17L30 22"
            stroke="#00768F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="24" cy="17" r="2" fill="#C4C4C4" />
          <circle cx="24" cy="17" r="2" fill="url(#paint2_radial_1549_12064)" />
          <path
            d="M24 4.5V5.5"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 28.5V29.5"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.5 17L35.5 17"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 17L11.5 17"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.8254 10.75L33.9594 11.25"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.0408 22.75L13.1747 23.25"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.25 6.17578L29.75 7.04181"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.25 26.9609L17.75 27.827"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.75 6.17578L18.25 7.04181"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.75 26.9609L30.25 27.827"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.1746 10.75L14.0406 11.25"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.9592 22.75L34.8253 23.25"
            stroke="#589EBD"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.625 27H17C18.3343 29.7307 18.2929 33.5581 17 36L43.625 35.875L46.875 34.8906V27.9844L43.625 27Z"
            fill="white"
          />
          <path
            d="M43.625 27H17C18.3343 29.7307 18.2929 33.5581 17 36L43.625 35.875L46.875 34.8906V27.9844L43.625 27Z"
            fill="url(#paint3_linear_1549_12064)"
            fillOpacity="0.15"
          />
          <path
            d="M17 27H41.8864L43.0227 27.5714L44.1591 27V27C45.7281 27 47 28.2719 47 29.8409V33.1591C47 34.7281 45.7281 36 44.1591 36V36L43.0227 35.4286L41.8864 36H17"
            stroke="url(#paint4_linear_1549_12064)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.375 38H47C45.6657 40.7307 45.7071 44.5581 47 47L16.375 46.875L13.125 45.8906V38.9844L16.375 38Z"
            fill="white"
          />
          <path
            d="M16.375 38H47C45.6657 40.7307 45.7071 44.5581 47 47L16.375 46.875L13.125 45.8906V38.9844L16.375 38Z"
            fill="url(#paint5_linear_1549_12064)"
            fillOpacity="0.15"
          />
          <path
            d="M47 38H18.1136L16.9773 38.5714L15.8409 38V38C14.2719 38 13 39.2719 13 40.8409V44.1591C13 45.7281 14.2719 47 15.8409 47V47L16.9773 46.4286L18.1136 47H47"
            stroke="url(#paint6_linear_1549_12064)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x="-0.046875"
            y="12.1289"
            width="10"
            height="36"
            rx="1"
            transform="rotate(-3.55306 -0.046875 12.1289)"
            fill="#C4C4C4"
          />
          <rect
            x="-0.046875"
            y="12.1289"
            width="10"
            height="36"
            rx="1"
            transform="rotate(-3.55306 -0.046875 12.1289)"
            fill="url(#paint7_linear_1549_12064)"
          />
          <ellipse
            opacity="0.75"
            cx="5.47038"
            cy="20.3035"
            rx="3"
            ry="4.5"
            transform="rotate(-3.55306 5.47038 20.3035)"
            fill="url(#paint8_linear_1549_12064)"
          />
          <rect
            opacity="0.25"
            x="1.58105"
            y="38.0781"
            width="9.96607"
            height="2"
            transform="rotate(-3.55306 1.58105 38.0781)"
            fill="white"
          />
          <rect
            opacity="0.25"
            x="1.82275"
            y="42.0703"
            width="9.97955"
            height="2"
            transform="rotate(-3.55306 1.82275 42.0703)"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12064"
          x="-1.98682"
          y="-2"
          width="49.9868"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12064" />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12064"
          x1="24"
          y1="0"
          x2="24"
          y2="34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1549_12064"
          x1="15.2424"
          y1="2.06061"
          x2="32.7576"
          y2="31.4242"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAA061" />
          <stop offset="1" stopColor="#C21190" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_1549_12064"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.5 16.5) rotate(45) scale(2.82843)"
        >
          <stop offset="0.119792" stopColor="#F89964" />
          <stop offset="0.906236" stopColor="#C8208C" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_1549_12064"
          x1="31.9375"
          y1="27"
          x2="31.9375"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1549_12064"
          x1="34.5"
          y1="21.5"
          x2="28.5"
          y2="42.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="0.892645" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1549_12064"
          x1="30.0625"
          y1="38"
          x2="30.0625"
          y2="47"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1549_12064"
          x1="34.5"
          y1="33.5"
          x2="29.5"
          y2="53"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="0.837524" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1549_12064"
          x1="-0.046875"
          y1="12.1289"
          x2="18.5205"
          y2="17.2865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BAD6FF" />
          <stop offset="1" stopColor="#C11190" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1549_12064"
          x1="3.38927"
          y1="17.3659"
          x2="7.48727"
          y2="23.1318"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.24" />
        </linearGradient>
        <clipPath id="clip0_1549_12064">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconBeginner: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_12123)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.3315 2.08486C45.8555 1.7875 46.0392 1.12165 45.7419 0.597636C45.4445 0.0736203 44.7787 -0.110126 44.2546 0.187228L2.66882 23.7852C2.14481 24.0825 1.96106 24.7484 2.25842 25.2724C2.55577 25.7964 3.22162 25.9802 3.74564 25.6828L3.95846 25.562C4.75949 25.1701 5.26125 25.9946 5.26125 25.9946L9.45921 23.6103C9.54405 25.5351 9.56896 27.9753 9.27311 29.4538C9.20505 29.7939 9.13862 30.0982 9.0764 30.3833C8.66841 32.2523 8.44095 33.2943 9.11673 38.1814H2.63672C2.08443 38.1814 1.63672 38.6291 1.63672 39.1814V39.3632C1.63672 39.9155 2.08443 40.3632 2.63672 40.3632H2.72754V44.0004C2.72754 46.2096 4.5184 48.0004 6.72754 48.0004H41.2739C43.483 48.0004 45.2739 46.2096 45.2739 44.0005L45.274 20.7282H40.5596C41.2357 15.8398 41.0082 14.798 40.6002 12.9288C40.538 12.6438 40.4715 12.3393 40.4035 11.9991C40.1068 10.5168 40.1326 8.06782 40.218 6.14074L44.9467 3.45507C44.9467 3.45507 44.6291 2.64633 45.1564 2.18421L45.3315 2.08486ZM38.0655 20.7282C37.3894 15.8394 37.6168 14.7976 38.0249 12.9283C38.0871 12.6432 38.1535 12.3389 38.2216 11.9987C38.4684 10.7653 38.492 8.86275 38.4438 7.14841L24.9479 14.8134C24.8607 16.7485 24.8316 19.2292 25.131 20.7253C25.1991 21.0655 25.2655 21.3698 25.3277 21.6549C25.7358 23.5245 25.9633 24.5663 25.2868 29.4568H31.0914V30.5455L31.0918 22.9101H31.001C30.4487 22.9101 30.001 22.4624 30.001 21.9101V21.7282C30.001 21.176 30.4487 20.7282 31.001 20.7282H38.0655ZM16.8184 31.6386H16.9097V38.1814H11.6111C12.2869 33.2943 12.0595 32.2525 11.6515 30.3835C11.5893 30.0984 11.5228 29.7939 11.4547 29.4538C11.101 27.6863 11.2058 24.5443 11.3233 22.5516L23.1706 15.8229C23.2208 17.5509 23.1993 19.4801 22.95 20.7262C22.8819 21.0664 22.8155 21.3708 22.7532 21.6559C22.3451 23.5253 22.1177 24.5671 22.794 29.4568H16.8184C16.2661 29.4568 15.8184 29.9045 15.8184 30.4568V30.6386C15.8184 31.1909 16.2661 31.6386 16.8184 31.6386Z"
          fill="white"
        />
        <g opacity="0.25">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3639 20.7266H9.27321C9.27321 20.7266 9.81897 26.7267 9.27321 29.454C9.20513 29.7942 9.13869 30.0986 9.07645 30.3837C8.63974 32.3843 8.40993 33.4371 9.27321 39.2724H10.3639H10.3641H11.4548C12.3181 33.4371 12.0883 32.3843 11.6516 30.3837C11.5894 30.0986 11.5229 29.7942 11.4548 29.454C10.9091 26.7267 11.4548 20.7266 11.4548 20.7266H10.3641H10.3639Z"
            fill="url(#paint0_linear_1549_12123)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.0401 11.998H25.131C25.131 11.998 24.5852 17.9982 25.131 20.7255C25.1991 21.0657 25.2655 21.3701 25.3277 21.6552C25.7645 23.6558 25.9943 24.7086 25.131 30.5439H24.0409V30.5448H22.95C22.0867 24.7095 22.3165 23.6567 22.7532 21.6562C22.8154 21.371 22.8819 21.0667 22.95 20.7265C23.3531 18.7117 23.1606 14.9108 23.0325 13.0623L24.0401 11.9999V11.998Z"
            fill="#0185F3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.0401 11.998H25.131C25.131 11.998 24.5852 17.9982 25.131 20.7255C25.1991 21.0657 25.2655 21.3701 25.3277 21.6552C25.7645 23.6558 25.9943 24.7086 25.131 30.5439H24.0409V30.5448H22.95C22.0867 24.7095 22.3165 23.6567 22.7532 21.6562C22.8154 21.371 22.8819 21.0667 22.95 20.7265C23.3531 18.7117 23.1606 14.9108 23.0325 13.0623L24.0401 11.9999V11.998Z"
            fill="url(#paint1_linear_1549_12123)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.3126 3.27197V3.27148L38.3336 4.77827C38.4577 6.739 38.5949 10.1341 38.2217 11.9989C38.1536 12.3391 38.0872 12.6435 38.0249 12.9286C37.5882 14.9292 37.3584 15.982 38.2217 21.8173H39.3126V21.8178H40.4035C41.2668 15.9825 41.037 14.9297 40.6003 12.9291C40.538 12.644 40.4716 12.3396 40.4035 11.9994C39.8578 9.27209 40.4035 3.27197 40.4035 3.27197H39.3126Z"
            fill="#0185F3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.3126 3.27197V3.27148L38.3336 4.77827C38.4577 6.739 38.5949 10.1341 38.2217 11.9989C38.1536 12.3391 38.0872 12.6435 38.0249 12.9286C37.5882 14.9292 37.3584 15.982 38.2217 21.8173H39.3126V21.8178H40.4035C41.2668 15.9825 41.037 14.9297 40.6003 12.9291C40.538 12.644 40.4716 12.3396 40.4035 11.9994C39.8578 9.27209 40.4035 3.27197 40.4035 3.27197H39.3126Z"
            fill="url(#paint2_linear_1549_12123)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.3308 2.0634C44.5656 2.48577 44.9466 3.45581 44.9466 3.45581L5.26114 25.9953C5.26114 25.9953 4.72178 25.1091 3.86872 25.6111C3.59033 25.1205 3.76236 24.4971 4.25297 24.2187L43.9384 1.67915C44.429 1.40075 45.0524 1.57278 45.3308 2.0634Z"
            fill="url(#paint3_linear_1549_12123)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.7419 0.597447C46.0392 1.12146 45.8555 1.78731 45.3315 2.08467L3.74564 25.6826C3.22162 25.98 2.55577 25.7962 2.25842 25.2722C1.96106 24.7482 2.14481 24.0823 2.66882 23.785L44.2546 0.187039C44.7787 -0.110315 45.4445 0.0734309 45.7419 0.597447Z"
            fill="#0185F3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.7419 0.597447C46.0392 1.12146 45.8555 1.78731 45.3315 2.08467L3.74564 25.6826C3.22162 25.98 2.55577 25.7962 2.25842 25.2722C1.96106 24.7482 2.14481 24.0823 2.66882 23.785L44.2546 0.187039C44.7787 -0.110315 45.4445 0.0734309 45.7419 0.597447Z"
            fill="url(#paint4_linear_1549_12123)"
          />
          <g filter="url(#filter0_i_1549_12123)">
            <path
              d="M45.2739 21.8184H31.0918V30.5458H16.9097V39.2733H2.72754V44.0007C2.72754 46.2099 4.5184 48.0007 6.72754 48.0007H41.2739C43.483 48.0007 45.2739 46.2099 45.2739 44.0007V21.8184Z"
              fill="url(#paint5_linear_1549_12123)"
            />
          </g>
          <path
            d="M1.63647 39.1816C1.63647 38.6294 2.08419 38.1816 2.63647 38.1816H16.9095V40.3635H2.63648C2.08419 40.3635 1.63647 39.9158 1.63647 39.3635V39.1816Z"
            fill="url(#paint6_linear_1549_12123)"
          />
          <path
            d="M15.8186 30.457C15.8186 29.9047 16.2663 29.457 16.8186 29.457H31.0916V31.6389H16.8186C16.2663 31.6389 15.8186 31.1912 15.8186 30.6389V30.457Z"
            fill="url(#paint7_linear_1549_12123)"
          />
          <path
            d="M30.0007 21.7285C30.0007 21.1762 30.4484 20.7285 31.0007 20.7285H45.2738V22.9104H31.0007C30.4484 22.9104 30.0007 22.4627 30.0007 21.9104V21.7285Z"
            fill="url(#paint8_linear_1549_12123)"
          />
        </g>
        <g className={css.svgBlend}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.3315 2.08486C45.8555 1.7875 46.0392 1.12165 45.7419 0.597636C45.4445 0.0736203 44.7787 -0.110126 44.2546 0.187228L2.66882 23.7852C2.14481 24.0825 1.96106 24.7484 2.25842 25.2724C2.55577 25.7964 3.22162 25.9802 3.74564 25.6828L3.95846 25.562C4.75949 25.1701 5.26125 25.9946 5.26125 25.9946L9.45921 23.6103C9.54405 25.5351 9.56896 27.9753 9.27311 29.4538C9.20505 29.7939 9.13862 30.0982 9.0764 30.3833C8.66841 32.2523 8.44095 33.2943 9.11673 38.1814H2.63672C2.08443 38.1814 1.63672 38.6291 1.63672 39.1814V39.3632C1.63672 39.9155 2.08443 40.3632 2.63672 40.3632H2.72754V44.0004C2.72754 46.2096 4.5184 48.0004 6.72754 48.0004H41.2739C43.483 48.0004 45.2739 46.2096 45.2739 44.0005L45.274 20.7282H40.5596C41.2357 15.8398 41.0082 14.798 40.6002 12.9288C40.538 12.6438 40.4715 12.3393 40.4035 11.9991C40.1068 10.5168 40.1326 8.06782 40.218 6.14074L44.9467 3.45507C44.9467 3.45507 44.6291 2.64633 45.1564 2.18421L45.3315 2.08486ZM38.0655 20.7282C37.3894 15.8394 37.6168 14.7976 38.0249 12.9283C38.0871 12.6432 38.1535 12.3389 38.2216 11.9987C38.4684 10.7653 38.492 8.86275 38.4438 7.14841L24.9479 14.8134C24.8607 16.7485 24.8316 19.2292 25.131 20.7253C25.1991 21.0655 25.2655 21.3698 25.3277 21.6549C25.7358 23.5245 25.9633 24.5663 25.2868 29.4568H31.0914V30.5455L31.0918 22.9101H31.001C30.4487 22.9101 30.001 22.4624 30.001 21.9101V21.7282C30.001 21.176 30.4487 20.7282 31.001 20.7282H38.0655ZM16.8184 31.6386H16.9097V38.1814H11.6111C12.2869 33.2943 12.0595 32.2525 11.6515 30.3835C11.5893 30.0984 11.5228 29.7939 11.4547 29.4538C11.101 27.6863 11.2058 24.5443 11.3233 22.5516L23.1706 15.8229C23.2208 17.5509 23.1993 19.4801 22.95 20.7262C22.8819 21.0664 22.8155 21.3708 22.7532 21.6559C22.3451 23.5253 22.1177 24.5671 22.794 29.4568H16.8184C16.2661 29.4568 15.8184 29.9045 15.8184 30.4568V30.6386C15.8184 31.1909 16.2661 31.6386 16.8184 31.6386Z"
            fill="url(#paint9_linear_1549_12123)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12123"
          x="0.727539"
          y="19.8184"
          width="44.5464"
          height="28.1816"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12123" />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12123"
          x1="10.364"
          y1="20.7266"
          x2="10.364"
          y2="39.2724"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.432292" stopColor="#E18441" />
          <stop offset="0.963542" stopColor="#904319" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1549_12123"
          x1="24.0405"
          y1="11.998"
          x2="24.0405"
          y2="30.5448"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.432292" stopColor="#E18441" />
          <stop offset="0.963542" stopColor="#904319" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1549_12123"
          x1="39.3126"
          y1="3.27148"
          x2="39.3126"
          y2="21.8178"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.432292" stopColor="#E18441" />
          <stop offset="0.963542" stopColor="#904319" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1549_12123"
          x1="15.1069"
          y1="19.2362"
          x2="15.6006"
          y2="20.1138"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.223958" stopColor="#8F4318" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1549_12123"
          x1="13.8067"
          y1="17.1876"
          x2="14.989"
          y2="19.3214"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1549_12123"
          x1="2.72754"
          y1="21.8184"
          x2="37.692"
          y2="56.7828"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.202477" stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1549_12123"
          x1="5.62075"
          y1="39.4471"
          x2="5.65567"
          y2="40.3689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#AB5524" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1549_12123"
          x1="19.8029"
          y1="30.7225"
          x2="19.8378"
          y2="31.6443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#AB5524" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1549_12123"
          x1="33.985"
          y1="21.994"
          x2="34.0199"
          y2="22.9157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#AB5524" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1549_12123"
          x1="1.63672"
          y1="0.0449219"
          x2="49.4374"
          y2="44.1495"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#589EBD" />
        </linearGradient>
        <clipPath id="clip0_1549_12123">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconBeginnerComplete: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_12077)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3639 20.7266H9.27321C9.27321 20.7266 9.81897 26.7267 9.27321 29.454C9.20513 29.7942 9.13869 30.0986 9.07645 30.3837C8.63974 32.3843 8.40993 33.4371 9.27321 39.2724H10.3639H10.3641H11.4548C12.3181 33.4371 12.0883 32.3843 11.6516 30.3837C11.5894 30.0986 11.5229 29.7942 11.4548 29.454C10.9091 26.7267 11.4548 20.7266 11.4548 20.7266H10.3641H10.3639Z"
          fill="url(#paint0_linear_1549_12077)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0401 11.998H25.131C25.131 11.998 24.5852 17.9982 25.131 20.7255C25.1991 21.0657 25.2655 21.3701 25.3277 21.6552C25.7645 23.6558 25.9943 24.7086 25.131 30.5439H24.0409V30.5448H22.95C22.0867 24.7095 22.3165 23.6567 22.7532 21.6562C22.8154 21.371 22.8819 21.0667 22.95 20.7265C23.3531 18.7117 23.1606 14.9108 23.0325 13.0623L24.0401 11.9999V11.998Z"
          fill="#0185F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0401 11.998H25.131C25.131 11.998 24.5852 17.9982 25.131 20.7255C25.1991 21.0657 25.2655 21.3701 25.3277 21.6552C25.7645 23.6558 25.9943 24.7086 25.131 30.5439H24.0409V30.5448H22.95C22.0867 24.7095 22.3165 23.6567 22.7532 21.6562C22.8154 21.371 22.8819 21.0667 22.95 20.7265C23.3531 18.7117 23.1606 14.9108 23.0325 13.0623L24.0401 11.9999V11.998Z"
          fill="url(#paint1_linear_1549_12077)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.3126 3.27197V3.27148L38.3336 4.77827C38.4577 6.739 38.5949 10.1341 38.2217 11.9989C38.1536 12.3391 38.0872 12.6435 38.0249 12.9286C37.5882 14.9292 37.3584 15.982 38.2217 21.8173H39.3126V21.8178H40.4035C41.2668 15.9825 41.037 14.9297 40.6003 12.9291C40.538 12.644 40.4716 12.3396 40.4035 11.9994C39.8578 9.27209 40.4035 3.27197 40.4035 3.27197H39.3126Z"
          fill="#0185F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.3126 3.27197V3.27148L38.3336 4.77827C38.4577 6.739 38.5949 10.1341 38.2217 11.9989C38.1536 12.3391 38.0872 12.6435 38.0249 12.9286C37.5882 14.9292 37.3584 15.982 38.2217 21.8173H39.3126V21.8178H40.4035C41.2668 15.9825 41.037 14.9297 40.6003 12.9291C40.538 12.644 40.4716 12.3396 40.4035 11.9994C39.8578 9.27209 40.4035 3.27197 40.4035 3.27197H39.3126Z"
          fill="url(#paint2_linear_1549_12077)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.3308 2.0634C44.5656 2.48577 44.9466 3.45581 44.9466 3.45581L5.26114 25.9953C5.26114 25.9953 4.72178 25.1091 3.86872 25.6111C3.59033 25.1205 3.76236 24.4971 4.25297 24.2187L43.9384 1.67915C44.429 1.40075 45.0524 1.57278 45.3308 2.0634Z"
          fill="url(#paint3_linear_1549_12077)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.7419 0.597447C46.0392 1.12146 45.8555 1.78731 45.3315 2.08467L3.74564 25.6826C3.22162 25.98 2.55577 25.7962 2.25842 25.2722C1.96106 24.7482 2.14481 24.0823 2.66882 23.785L44.2546 0.187039C44.7787 -0.110315 45.4445 0.0734309 45.7419 0.597447Z"
          fill="#0185F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.7419 0.597447C46.0392 1.12146 45.8555 1.78731 45.3315 2.08467L3.74564 25.6826C3.22162 25.98 2.55577 25.7962 2.25842 25.2722C1.96106 24.7482 2.14481 24.0823 2.66882 23.785L44.2546 0.187039C44.7787 -0.110315 45.4445 0.0734309 45.7419 0.597447Z"
          fill="url(#paint4_linear_1549_12077)"
        />
        <g filter="url(#filter0_i_1549_12077)">
          <path
            d="M45.2739 21.8184H31.0918V30.5458H16.9097V39.2733H2.72754V44.0007C2.72754 46.2099 4.5184 48.0007 6.72754 48.0007H41.2739C43.483 48.0007 45.2739 46.2099 45.2739 44.0007V21.8184Z"
            fill="url(#paint5_linear_1549_12077)"
          />
        </g>
        <path
          d="M1.63647 39.1816C1.63647 38.6294 2.08419 38.1816 2.63647 38.1816H16.9095V40.3635H2.63648C2.08419 40.3635 1.63647 39.9158 1.63647 39.3635V39.1816Z"
          fill="url(#paint6_linear_1549_12077)"
        />
        <path
          d="M15.8186 30.457C15.8186 29.9047 16.2663 29.457 16.8186 29.457H31.0916V31.6389H16.8186C16.2663 31.6389 15.8186 31.1912 15.8186 30.6389V30.457Z"
          fill="url(#paint7_linear_1549_12077)"
        />
        <path
          d="M30.0007 21.7285C30.0007 21.1762 30.4484 20.7285 31.0007 20.7285H45.2738V22.9104H31.0007C30.4484 22.9104 30.0007 22.4627 30.0007 21.9104V21.7285Z"
          fill="url(#paint8_linear_1549_12077)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12077"
          x="0.727539"
          y="19.8184"
          width="44.5464"
          height="28.1816"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12077" />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12077"
          x1="10.364"
          y1="20.7266"
          x2="10.364"
          y2="39.2724"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.432292" stopColor="#E18441" />
          <stop offset="0.963542" stopColor="#904319" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1549_12077"
          x1="24.0405"
          y1="11.998"
          x2="24.0405"
          y2="30.5448"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.432292" stopColor="#E18441" />
          <stop offset="0.963542" stopColor="#904319" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1549_12077"
          x1="39.3126"
          y1="3.27148"
          x2="39.3126"
          y2="21.8178"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.432292" stopColor="#E18441" />
          <stop offset="0.963542" stopColor="#904319" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1549_12077"
          x1="15.1069"
          y1="19.2362"
          x2="15.6006"
          y2="20.1138"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.223958" stopColor="#8F4318" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1549_12077"
          x1="13.8067"
          y1="17.1876"
          x2="14.989"
          y2="19.3214"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1549_12077"
          x1="2.72754"
          y1="21.8184"
          x2="37.692"
          y2="56.7828"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.202477" stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1549_12077"
          x1="5.62075"
          y1="39.4471"
          x2="5.65567"
          y2="40.3689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#AB5524" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1549_12077"
          x1="19.8029"
          y1="30.7225"
          x2="19.8378"
          y2="31.6443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#AB5524" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1549_12077"
          x1="33.985"
          y1="21.994"
          x2="34.0199"
          y2="22.9157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#AB5524" />
        </linearGradient>
        <clipPath id="clip0_1549_12077">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconGrandMaster: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.8945 11.7578C27.5547 12.3418 27.0869 12.8438 26.5303 13.2227L26.7812 14.1738C31.2686 14.7383 35.1133 16.6484 37.4805 19.3027C38.293 18.7988 39.0273 18.1055 39.623 17.2109L39.7607 17.0039C39.2803 16.2871 39 15.4258 39 14.5C39 12.0156 41.0146 10 43.5 10C45.9854 10 48 12.0156 48 14.5C48 15.791 47.4561 16.9551 46.585 17.7754C45.8711 18.4492 44.9365 18.8906 43.9014 18.9824L40.0352 35C40.3564 35.0098 40.6396 35.1738 40.8145 35.4199C40.9316 35.584 41 35.7832 41 36V40C41 40.1484 40.9678 40.2891 40.9102 40.4141C40.877 40.4863 40.835 40.5547 40.7861 40.6172C40.7451 40.6699 40.6992 40.7188 40.6484 40.7617C40.541 40.8535 40.4121 40.9238 40.2705 40.9629C40.1846 40.9883 40.0938 41 40 41H8C7.77637 41 7.57031 40.9258 7.4043 40.8027C7.15918 40.6211 7 40.3301 7 40V36C7 35.459 7.42871 35.0176 7.96484 35L4.09863 18.9824C3.00195 18.8848 2.01855 18.3945 1.29004 17.6543L1.125 17.4785L1.04395 17.3848C0.957031 17.2793 0.875 17.1699 0.797852 17.0586C0.651367 16.8477 0.522461 16.623 0.413086 16.3867C0.147461 15.8125 0 15.1738 0 14.5C0 12.0156 2.01465 10 4.5 10C6.98535 10 9 12.0156 9 14.5C9 15.5723 8.625 16.5566 7.99902 17.3301C8.22559 17.6602 8.47168 17.9629 8.73438 18.2383C9.22461 18.748 9.77148 19.1641 10.3564 19.4883C12.6377 16.8203 16.3916 14.8711 20.8047 14.2285L21.1748 13.002C20.9844 12.8496 20.8066 12.6797 20.6436 12.498C20.3857 12.209 20.165 11.8848 19.9873 11.5371C19.6758 10.9258 19.5 10.2324 19.5 9.5C19.5 7.01562 21.5146 5 24 5C26.4854 5 28.5 7.01562 28.5 9.5C28.5 10.3223 28.2793 11.0938 27.8945 11.7578Z"
        fill="white"
      />
      <g opacity="0.35" filter="url(#filter0_i_1549_12352)">
        <ellipse cx="24" cy="25.5" rx="16" ry="11.5" fill="#C4C4C4" />
        <ellipse cx="24" cy="25.5" rx="16" ry="11.5" fill="url(#paint0_linear_1549_12352)" />
        <path
          d="M8.00044 35.1445H40.0004L44.9487 14.6445L42.3338 13.1445L39.6228 17.211C36.2763 22.2307 28.5714 20.9487 27.0306 15.1158L25.1891 8.14453H22.6419L20.4598 15.3729C18.7215 21.1309 11.0103 22.1603 7.82252 17.0599L5.68794 13.6445L3.17285 15.1445L8.00044 35.1445Z"
          fill="#36CA3C"
        />
        <path
          d="M8.00044 35.1445H40.0004L44.9487 14.6445L42.3338 13.1445L39.6228 17.211C36.2763 22.2307 28.5714 20.9487 27.0306 15.1158L25.1891 8.14453H22.6419L20.4598 15.3729C18.7215 21.1309 11.0103 22.1603 7.82252 17.0599L5.68794 13.6445L3.17285 15.1445L8.00044 35.1445Z"
          fill="url(#paint1_linear_1549_12352)"
        />
        <path
          d="M8.00044 35.1445H40.0004L44.9487 14.6445L42.3338 13.1445L39.6228 17.211C36.2763 22.2307 28.5714 20.9487 27.0306 15.1158L25.1891 8.14453H22.6419L20.4598 15.3729C18.7215 21.1309 11.0103 22.1603 7.82252 17.0599L5.68794 13.6445L3.17285 15.1445L8.00044 35.1445Z"
          fill="url(#paint2_radial_1549_12352)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 35H8C7.44772 35 7 35.4477 7 36V40C7 40.5523 7.44772 41 8 41H24H40C40.5523 41 41 40.5523 41 40V36C41 35.4477 40.5523 35 40 35H24Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 35H8C7.44772 35 7 35.4477 7 36V40C7 40.5523 7.44772 41 8 41H24H40C40.5523 41 41 40.5523 41 40V36C41 35.4477 40.5523 35 40 35H24Z"
          fill="url(#paint3_linear_1549_12352)"
        />
        <path
          d="M28.5 9.5C28.5 11.9853 26.4853 14 24 14C21.5147 14 19.5 11.9853 19.5 9.5C19.5 7.01472 21.5147 5 24 5C26.4853 5 28.5 7.01472 28.5 9.5Z"
          fill="#C4C4C4"
        />
        <path
          d="M28.5 9.5C28.5 11.9853 26.4853 14 24 14C21.5147 14 19.5 11.9853 19.5 9.5C19.5 7.01472 21.5147 5 24 5C26.4853 5 28.5 7.01472 28.5 9.5Z"
          fill="url(#paint4_radial_1549_12352)"
        />
        <circle cx="43.5" cy="14.5" r="4.5" fill="#C4C4C4" />
        <circle cx="43.5" cy="14.5" r="4.5" fill="url(#paint5_radial_1549_12352)" />
        <circle cx="43.5" cy="14.5" r="4.5" fill="url(#paint6_radial_1549_12352)" />
        <circle cx="4.5" cy="14.5" r="4.5" fill="url(#paint7_radial_1549_12352)" />
        <circle cx="4.5" cy="14.5" r="4.5" fill="url(#paint8_radial_1549_12352)" />
        <path d="M24 19L29 26L24 33L19 26L24 19Z" fill="url(#paint9_linear_1549_12352)" />
        <path
          opacity="0.5"
          d="M29 26L24 33L24 29L26 26L29 26Z"
          fill="url(#paint10_linear_1549_12352)"
        />
        <path opacity="0.25" d="M29 26L24 19V23L26 26H29Z" fill="url(#paint11_linear_1549_12352)" />
        <path d="M19 26L24 33L24 29L22 26L19 26Z" fill="url(#paint12_linear_1549_12352)" />
        <path
          d="M33.9998 25L36.9996 29L33.9998 33L31 29L33.9998 25Z"
          fill="url(#paint13_linear_1549_12352)"
        />
        <path
          d="M33.9998 25L36.9996 29L33.9998 33L31 29L33.9998 25Z"
          fill="url(#paint14_linear_1549_12352)"
        />
        <path d="M33.9998 29H31L33.9998 33V29Z" fill="url(#paint15_linear_1549_12352)" />
        <path
          opacity="0.5"
          d="M34.0002 29H37L34.0002 33V29Z"
          fill="url(#paint16_linear_1549_12352)"
        />
        <path opacity="0.25" d="M37 29L34.0002 25V29H37Z" fill="url(#paint17_linear_1549_12352)" />
        <path
          d="M33.9995 31.1326L32.3679 28.9999L33.9995 26.8672L35.6311 28.9999L33.9995 31.1326Z"
          fill="#56ACDF"
        />
        <path
          d="M33.9995 31.1326L32.3679 28.9999L33.9995 26.8672L35.6311 28.9999L33.9995 31.1326Z"
          fill="url(#paint18_linear_1549_12352)"
        />
        <path
          d="M13.9998 25L16.9996 29L13.9998 33L11 29L13.9998 25Z"
          fill="url(#paint19_linear_1549_12352)"
        />
        <path
          d="M13.9998 25L16.9996 29L13.9998 33L11 29L13.9998 25Z"
          fill="url(#paint20_linear_1549_12352)"
        />
        <path d="M13.9998 29H11L13.9998 33V29Z" fill="url(#paint21_linear_1549_12352)" />
        <path
          opacity="0.5"
          d="M14.0002 29H17L14.0002 33V29Z"
          fill="url(#paint22_linear_1549_12352)"
        />
        <path opacity="0.25" d="M17 29L14.0002 25V29H17Z" fill="url(#paint23_linear_1549_12352)" />
        <path
          d="M13.9995 31.1326L12.3679 28.9999L13.9995 26.8672L15.6311 28.9999L13.9995 31.1326Z"
          fill="#56ACDF"
        />
        <path
          d="M13.9995 31.1326L12.3679 28.9999L13.9995 26.8672L15.6311 28.9999L13.9995 31.1326Z"
          fill="url(#paint24_linear_1549_12352)"
        />
        <path
          d="M23.9996 22.1602L26.8281 26.0006L23.9996 29.8411L21.1711 26.0006L23.9996 22.1602Z"
          fill="url(#paint25_linear_1549_12352)"
        />
        <path
          d="M23.9996 22.1602L26.8281 26.0006L23.9996 29.8411L21.1711 26.0006L23.9996 22.1602Z"
          fill="#56ACDF"
        />
        <path
          d="M23.9996 22.1602L26.8281 26.0006L23.9996 29.8411L21.1711 26.0006L23.9996 22.1602Z"
          fill="url(#paint26_linear_1549_12352)"
        />
      </g>
      <g className={css.svgBlend}>
        <path
          d="M27.8945 11.7578C27.5547 12.3418 27.0869 12.8438 26.5303 13.2227L26.7812 14.1738C31.2686 14.7383 35.1133 16.6484 37.4805 19.3027C38.293 18.7988 39.0273 18.1055 39.623 17.2109L39.7607 17.0039C39.2803 16.2871 39 15.4258 39 14.5C39 12.0156 41.0146 10 43.5 10C45.9854 10 48 12.0156 48 14.5C48 15.791 47.4561 16.9551 46.585 17.7754C45.8711 18.4492 44.9365 18.8906 43.9014 18.9824L40.0352 35C40.3564 35.0098 40.6396 35.1738 40.8145 35.4199C40.9316 35.584 41 35.7832 41 36V40C41 40.1484 40.9678 40.2891 40.9102 40.4141C40.877 40.4863 40.835 40.5547 40.7861 40.6172C40.7451 40.6699 40.6992 40.7188 40.6484 40.7617C40.541 40.8535 40.4121 40.9238 40.2705 40.9629C40.1846 40.9883 40.0938 41 40 41H8C7.77637 41 7.57031 40.9258 7.4043 40.8027C7.15918 40.6211 7 40.3301 7 40V36C7 35.459 7.42871 35.0176 7.96484 35L4.09863 18.9824C3.00195 18.8848 2.01855 18.3945 1.29004 17.6543L1.125 17.4785L1.04395 17.3848C0.957031 17.2793 0.875 17.1699 0.797852 17.0586C0.651367 16.8477 0.522461 16.623 0.413086 16.3867C0.147461 15.8125 0 15.1738 0 14.5C0 12.0156 2.01465 10 4.5 10C6.98535 10 9 12.0156 9 14.5C9 15.5723 8.625 16.5566 7.99902 17.3301C8.22559 17.6602 8.47168 17.9629 8.73438 18.2383C9.22461 18.748 9.77148 19.1641 10.3564 19.4883C12.6377 16.8203 16.3916 14.8711 20.8047 14.2285L21.1748 13.002C20.9844 12.8496 20.8066 12.6797 20.6436 12.498C20.3857 12.209 20.165 11.8848 19.9873 11.5371C19.6758 10.9258 19.5 10.2324 19.5 9.5C19.5 7.01562 21.5146 5 24 5C26.4854 5 28.5 7.01562 28.5 9.5C28.5 10.3223 28.2793 11.0938 27.8945 11.7578Z"
          fill="url(#paint27_linear_1549_12352)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12352"
          x="-2"
          y="3"
          width="50"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12352" />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12352"
          x1="8"
          y1="14"
          x2="29.8004"
          y2="44.331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F56E32" />
          <stop offset="1" stopColor="#DD0D03" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1549_12352"
          x1="3.17285"
          y1="8.14453"
          x2="19.481"
          y2="45.0724"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.358355" stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_1549_12352"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24 35) rotate(-90) scale(24 32.1904)"
        >
          <stop offset="0.689725" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.898852" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_1549_12352"
          x1="19.5"
          y1="35"
          x2="20"
          y2="41.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED91" />
          <stop offset="0.517383" stopColor="#F5BC5D" />
          <stop offset="0.863907" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_1549_12352"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.5 7) rotate(45) scale(8.48528 8.47799)"
        >
          <stop offset="0.140256" stopColor="#FFED91" />
          <stop offset="1" stopColor="#CD6505" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_1549_12352"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41 12) rotate(45) scale(8.48528 8.47799)"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_1549_12352"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41 12) rotate(45) scale(8.48528 8.47799)"
        >
          <stop offset="0.140256" stopColor="#FFED91" />
          <stop offset="0.832858" stopColor="#CD6505" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_1549_12352"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(2 12) rotate(45) scale(8.48528 8.47799)"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_1549_12352"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(2 12) rotate(45) scale(8.48528 8.47799)"
        >
          <stop offset="0.140256" stopColor="#FFED91" />
          <stop offset="0.989071" stopColor="#CD6505" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_1549_12352"
          x1="19"
          y1="19"
          x2="32.2432"
          y2="28.4595"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0803038" stopColor="#B1FFEC" />
          <stop offset="0.86468" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1549_12352"
          x1="27"
          y1="29"
          x2="24.5"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1549_12352"
          x1="24.5"
          y1="21.5"
          x2="27.5"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#59B9FF" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1549_12352"
          x1="23.5"
          y1="30.5"
          x2="20.5"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#3467EA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1549_12352"
          x1="33.9998"
          y1="25"
          x2="33.9998"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3F2FF" />
          <stop offset="0.269999" stopColor="#60CCFB" />
          <stop offset="1" stopColor="#2F88AE" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1549_12352"
          x1="31"
          y1="25"
          x2="38.6798"
          y2="30.7595"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0803038" stopColor="#B1FFEC" />
          <stop offset="0.86468" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1549_12352"
          x1="33.9998"
          y1="33"
          x2="30.2347"
          y2="29.9423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#3467EA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1549_12352"
          x1="35.8001"
          y1="31.2857"
          x2="34.3211"
          y2="31.9069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1549_12352"
          x1="34.3002"
          y1="26.4286"
          x2="35.981"
          y2="29.0756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#59B9FF" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1549_12352"
          x1="34.9998"
          y1="30"
          x2="33.0988"
          y2="27.9099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1549_12352"
          x1="13.9998"
          y1="25"
          x2="13.9998"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3F2FF" />
          <stop offset="0.269999" stopColor="#60CCFB" />
          <stop offset="1" stopColor="#2F88AE" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1549_12352"
          x1="11"
          y1="25"
          x2="18.6798"
          y2="30.7595"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0803038" stopColor="#B1FFEC" />
          <stop offset="0.86468" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1549_12352"
          x1="13.9998"
          y1="33"
          x2="10.2347"
          y2="29.9423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#3467EA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1549_12352"
          x1="15.8001"
          y1="31.2857"
          x2="14.3211"
          y2="31.9069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1549_12352"
          x1="14.3002"
          y1="26.4286"
          x2="15.981"
          y2="29.0756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#59B9FF" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1549_12352"
          x1="14.9998"
          y1="30"
          x2="13.0988"
          y2="27.9099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1549_12352"
          x1="23.9996"
          y1="22.1602"
          x2="23.9996"
          y2="29.8411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3F2FF" />
          <stop offset="0.269999" stopColor="#60CCFB" />
          <stop offset="1" stopColor="#2F88AE" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_1549_12352"
          x1="25.7336"
          y1="27.8016"
          x2="22.3005"
          y2="24.1679"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_1549_12352"
          x1="0"
          y1="5"
          x2="34.56"
          y2="51.08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#589EBD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconGrandMasterComplete: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_1549_12325)">
        <ellipse cx="24" cy="25.5" rx="16" ry="11.5" fill="#C4C4C4" />
        <ellipse cx="24" cy="25.5" rx="16" ry="11.5" fill="url(#paint0_linear_1549_12325)" />
        <path
          d="M8.00044 35.1445H40.0004L44.9487 14.6445L42.3338 13.1445L39.6228 17.211C36.2763 22.2307 28.5714 20.9487 27.0306 15.1158L25.1891 8.14453H22.6419L20.4598 15.3729C18.7215 21.1309 11.0103 22.1603 7.82252 17.0599L5.68794 13.6445L3.17285 15.1445L8.00044 35.1445Z"
          fill="url(#paint1_linear_1549_12325)"
        />
        <path
          d="M8.00044 35.1445H40.0004L44.9487 14.6445L42.3338 13.1445L39.6228 17.211C36.2763 22.2307 28.5714 20.9487 27.0306 15.1158L25.1891 8.14453H22.6419L20.4598 15.3729C18.7215 21.1309 11.0103 22.1603 7.82252 17.0599L5.68794 13.6445L3.17285 15.1445L8.00044 35.1445Z"
          fill="url(#paint2_radial_1549_12325)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 35H8C7.44772 35 7 35.4477 7 36V40C7 40.5523 7.44772 41 8 41H24H40C40.5523 41 41 40.5523 41 40V36C41 35.4477 40.5523 35 40 35H24Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 35H8C7.44772 35 7 35.4477 7 36V40C7 40.5523 7.44772 41 8 41H24H40C40.5523 41 41 40.5523 41 40V36C41 35.4477 40.5523 35 40 35H24Z"
          fill="url(#paint3_linear_1549_12325)"
        />
        <path
          d="M28.5 9.5C28.5 11.9853 26.4853 14 24 14C21.5147 14 19.5 11.9853 19.5 9.5C19.5 7.01472 21.5147 5 24 5C26.4853 5 28.5 7.01472 28.5 9.5Z"
          fill="#C4C4C4"
        />
        <path
          d="M28.5 9.5C28.5 11.9853 26.4853 14 24 14C21.5147 14 19.5 11.9853 19.5 9.5C19.5 7.01472 21.5147 5 24 5C26.4853 5 28.5 7.01472 28.5 9.5Z"
          fill="url(#paint4_radial_1549_12325)"
        />
        <circle cx="43.5" cy="14.5" r="4.5" fill="#C4C4C4" />
        <circle cx="43.5" cy="14.5" r="4.5" fill="url(#paint5_radial_1549_12325)" />
        <circle cx="43.5" cy="14.5" r="4.5" fill="url(#paint6_radial_1549_12325)" />
        <circle cx="4.5" cy="14.5" r="4.5" fill="url(#paint7_radial_1549_12325)" />
        <circle cx="4.5" cy="14.5" r="4.5" fill="url(#paint8_radial_1549_12325)" />
        <path d="M24 19L29 26L24 33L19 26L24 19Z" fill="url(#paint9_linear_1549_12325)" />
        <path
          opacity="0.5"
          d="M29 26L24 33L24 29L26 26L29 26Z"
          fill="url(#paint10_linear_1549_12325)"
        />
        <path opacity="0.25" d="M29 26L24 19V23L26 26H29Z" fill="url(#paint11_linear_1549_12325)" />
        <path d="M19 26L24 33L24 29L22 26L19 26Z" fill="url(#paint12_linear_1549_12325)" />
        <path
          d="M33.9998 25L36.9996 29L33.9998 33L31 29L33.9998 25Z"
          fill="url(#paint13_linear_1549_12325)"
        />
        <path
          d="M33.9998 25L36.9996 29L33.9998 33L31 29L33.9998 25Z"
          fill="url(#paint14_linear_1549_12325)"
        />
        <path d="M33.9998 29H31L33.9998 33V29Z" fill="url(#paint15_linear_1549_12325)" />
        <path
          opacity="0.5"
          d="M34.0002 29H37L34.0002 33V29Z"
          fill="url(#paint16_linear_1549_12325)"
        />
        <path opacity="0.25" d="M37 29L34.0002 25V29H37Z" fill="url(#paint17_linear_1549_12325)" />
        <path
          d="M33.9995 31.1326L32.3679 28.9999L33.9995 26.8672L35.6311 28.9999L33.9995 31.1326Z"
          fill="#56ACDF"
        />
        <path
          d="M33.9995 31.1326L32.3679 28.9999L33.9995 26.8672L35.6311 28.9999L33.9995 31.1326Z"
          fill="url(#paint18_linear_1549_12325)"
        />
        <path
          d="M13.9998 25L16.9996 29L13.9998 33L11 29L13.9998 25Z"
          fill="url(#paint19_linear_1549_12325)"
        />
        <path
          d="M13.9998 25L16.9996 29L13.9998 33L11 29L13.9998 25Z"
          fill="url(#paint20_linear_1549_12325)"
        />
        <path d="M13.9998 29H11L13.9998 33V29Z" fill="url(#paint21_linear_1549_12325)" />
        <path
          opacity="0.5"
          d="M14.0002 29H17L14.0002 33V29Z"
          fill="url(#paint22_linear_1549_12325)"
        />
        <path opacity="0.25" d="M17 29L14.0002 25V29H17Z" fill="url(#paint23_linear_1549_12325)" />
        <path
          d="M13.9995 31.1326L12.3679 28.9999L13.9995 26.8672L15.6311 28.9999L13.9995 31.1326Z"
          fill="#56ACDF"
        />
        <path
          d="M13.9995 31.1326L12.3679 28.9999L13.9995 26.8672L15.6311 28.9999L13.9995 31.1326Z"
          fill="url(#paint24_linear_1549_12325)"
        />
        <path
          d="M23.9996 22.1602L26.8281 26.0006L23.9996 29.8411L21.1711 26.0006L23.9996 22.1602Z"
          fill="url(#paint25_linear_1549_12325)"
        />
        <path
          d="M23.9996 22.1602L26.8281 26.0006L23.9996 29.8411L21.1711 26.0006L23.9996 22.1602Z"
          fill="#56ACDF"
        />
        <path
          d="M23.9996 22.1602L26.8281 26.0006L23.9996 29.8411L21.1711 26.0006L23.9996 22.1602Z"
          fill="url(#paint26_linear_1549_12325)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12325"
          x="-2"
          y="3"
          width="50"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12325" />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12325"
          x1="8"
          y1="14"
          x2="29.8004"
          y2="44.331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F56E32" />
          <stop offset="1" stopColor="#DD0D03" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1549_12325"
          x1="3.17285"
          y1="8.14453"
          x2="19.481"
          y2="45.0724"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.358355" stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_1549_12325"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24 35) rotate(-90) scale(24 32.1904)"
        >
          <stop offset="0.689725" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.898852" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_1549_12325"
          x1="19.5"
          y1="35"
          x2="20"
          y2="41.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED91" />
          <stop offset="0.517383" stopColor="#F5BC5D" />
          <stop offset="0.863907" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_1549_12325"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.5 7) rotate(45) scale(8.48528 8.47799)"
        >
          <stop offset="0.140256" stopColor="#FFED91" />
          <stop offset="1" stopColor="#CD6505" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_1549_12325"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41 12) rotate(45) scale(8.48528 8.47799)"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_1549_12325"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41 12) rotate(45) scale(8.48528 8.47799)"
        >
          <stop offset="0.140256" stopColor="#FFED91" />
          <stop offset="0.832858" stopColor="#CD6505" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_1549_12325"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(2 12) rotate(45) scale(8.48528 8.47799)"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_1549_12325"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(2 12) rotate(45) scale(8.48528 8.47799)"
        >
          <stop offset="0.140256" stopColor="#FFED91" />
          <stop offset="0.989071" stopColor="#CD6505" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_1549_12325"
          x1="19"
          y1="19"
          x2="32.2432"
          y2="28.4595"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0803038" stopColor="#B1FFEC" />
          <stop offset="0.86468" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1549_12325"
          x1="27"
          y1="29"
          x2="24.5"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1549_12325"
          x1="24.5"
          y1="21.5"
          x2="27.5"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#59B9FF" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1549_12325"
          x1="23.5"
          y1="30.5"
          x2="20.5"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#3467EA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1549_12325"
          x1="33.9998"
          y1="25"
          x2="33.9998"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3F2FF" />
          <stop offset="0.269999" stopColor="#60CCFB" />
          <stop offset="1" stopColor="#2F88AE" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1549_12325"
          x1="31"
          y1="25"
          x2="38.6798"
          y2="30.7595"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0803038" stopColor="#B1FFEC" />
          <stop offset="0.86468" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1549_12325"
          x1="33.9998"
          y1="33"
          x2="30.2347"
          y2="29.9423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#3467EA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1549_12325"
          x1="35.8001"
          y1="31.2857"
          x2="34.3211"
          y2="31.9069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1549_12325"
          x1="34.3002"
          y1="26.4286"
          x2="35.981"
          y2="29.0756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#59B9FF" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1549_12325"
          x1="34.9998"
          y1="30"
          x2="33.0988"
          y2="27.9099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1549_12325"
          x1="13.9998"
          y1="25"
          x2="13.9998"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3F2FF" />
          <stop offset="0.269999" stopColor="#60CCFB" />
          <stop offset="1" stopColor="#2F88AE" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1549_12325"
          x1="11"
          y1="25"
          x2="18.6798"
          y2="30.7595"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0803038" stopColor="#B1FFEC" />
          <stop offset="0.86468" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1549_12325"
          x1="13.9998"
          y1="33"
          x2="10.2347"
          y2="29.9423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#3467EA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1549_12325"
          x1="15.8001"
          y1="31.2857"
          x2="14.3211"
          y2="31.9069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1549_12325"
          x1="14.3002"
          y1="26.4286"
          x2="15.981"
          y2="29.0756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3467EA" />
          <stop offset="1" stopColor="#59B9FF" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1549_12325"
          x1="14.9998"
          y1="30"
          x2="13.0988"
          y2="27.9099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1549_12325"
          x1="23.9996"
          y1="22.1602"
          x2="23.9996"
          y2="29.8411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3F2FF" />
          <stop offset="0.269999" stopColor="#60CCFB" />
          <stop offset="1" stopColor="#2F88AE" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_1549_12325"
          x1="25.7336"
          y1="27.8016"
          x2="22.3005"
          y2="24.1679"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#9EECF9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconLegend: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1551_13103)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9996 4C10.895 4 9.99959 4.89543 9.99959 6C9.99959 6.76825 10.4327 7.43532 11.0682 7.77033C11.1054 8.51967 11.1576 9.26349 11.2244 10H7.99986C6.36363 10 4.95075 11.3408 5.06399 13.0651C5.22635 15.5376 5.69647 17.4785 6.46753 19.0916C7.24177 20.7114 8.29365 21.9465 9.54237 23.0375C10.7286 24.0739 12.1159 24.9984 13.5989 25.9866L13.7665 26.0983C15.2481 27.086 16.8583 28.1668 18.588 29.5495C19.7404 30.6452 20.9684 31.3896 22.2338 31.7499C22.2333 31.8272 22.2378 31.9055 22.2477 31.9846L22.6702 35.3649C22.8451 36.7641 21.7541 38 20.3439 38H18.9996C17.895 38 16.9996 38.8954 16.9996 40V44H15.9996C15.4473 44 14.9996 44.4477 14.9996 45C14.9996 45.5523 15.4473 46 15.9996 46H31.9996C32.5519 46 32.9996 45.5523 32.9996 45C32.9996 44.4477 32.5519 44 31.9996 44H30.9996V40C30.9996 38.8954 30.1042 38 28.9996 38H27.6552C26.2451 38 25.1541 36.7641 25.329 35.3649L25.7515 31.9846C25.7614 31.9055 25.7659 31.8272 25.7654 31.7499C27.0308 31.3896 28.2588 30.6452 29.4111 29.5495C31.1409 28.1668 32.751 27.086 34.2327 26.0983L34.4003 25.9866C35.8832 24.9984 37.2706 24.0739 38.4568 23.0375C39.7055 21.9465 40.7574 20.7114 41.5317 19.0916C42.3027 17.4785 42.7728 15.5376 42.9352 13.0651C43.0484 11.3408 41.6356 10 39.9993 10H36.7748C36.8416 9.26349 36.8938 8.51967 36.931 7.77033C37.5664 7.43532 37.9996 6.76825 37.9996 6C37.9996 4.89543 37.1042 4 35.9996 4H11.9996ZM36.5551 12C35.969 16.5344 34.8181 20.7145 33.192 24.0919C33.1202 24.241 33.0476 24.3883 32.9743 24.5336L33.2345 24.3601C34.7653 23.3398 36.055 22.4802 37.1409 21.5314C38.2351 20.5754 39.0961 19.5493 39.7272 18.2291C40.3615 16.9021 40.7891 15.2241 40.9395 12.9341C40.971 12.454 40.5722 12 39.9993 12H36.5551ZM15.0249 24.5336C14.9516 24.3883 14.879 24.241 14.8072 24.0919C13.181 20.7145 12.0302 16.5344 11.4441 12H7.99986C7.42695 12 7.02816 12.454 7.05969 12.9341C7.21008 15.2241 7.63772 16.9021 8.27198 18.2291C8.90305 19.5493 9.76404 20.5754 10.8583 21.5314C11.9442 22.4801 13.2338 23.3397 14.7646 24.36L15.0249 24.5336Z"
          fill="white"
        />
        <g opacity="0.5" filter="url(#filter0_i_1551_13103)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7514 31.9846C25.8832 30.9308 25.0615 30 23.9995 30C22.9375 30 22.1159 30.9308 22.2476 31.9846L22.6701 35.3649C22.845 36.7641 21.754 38 20.3439 38H19.4995C18.6711 38 17.9995 38.6716 17.9995 39.5C17.9995 40.3284 18.6711 41 19.4995 41H28.4995C29.3279 41 29.9995 40.3284 29.9995 39.5C29.9995 38.6716 29.3279 38 28.4995 38H27.6552C26.245 38 25.154 36.7641 25.3289 35.3649L25.7514 31.9846Z"
            fill="url(#paint0_linear_1551_13103)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7514 31.9846C25.8832 30.9308 25.0615 30 23.9995 30C22.9375 30 22.1159 30.9308 22.2476 31.9846L22.6701 35.3649C22.845 36.7641 21.754 38 20.3439 38H19.4995C18.6711 38 17.9995 38.6716 17.9995 39.5C17.9995 40.3284 18.6711 41 19.4995 41H28.4995C29.3279 41 29.9995 40.3284 29.9995 39.5C29.9995 38.6716 29.3279 38 28.4995 38H27.6552C26.245 38 25.154 36.7641 25.3289 35.3649L25.7514 31.9846Z"
            fill="url(#paint1_radial_1551_13103)"
          />
          <path
            d="M16.9995 40C16.9995 38.8954 17.8949 38 18.9995 38H28.9995C30.1041 38 30.9995 38.8954 30.9995 40V44H16.9995V40Z"
            fill="url(#paint2_linear_1551_13103)"
          />
          <rect
            x="14.9995"
            y="44"
            width="18"
            height="2"
            rx="1"
            fill="url(#paint3_linear_1551_13103)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9992 12H35.8454V10H39.9992C41.6355 10 43.0484 11.3408 42.9351 13.0651C42.7727 15.5376 42.3026 17.4785 41.5316 19.0916C40.7573 20.7114 39.7055 21.9465 38.4567 23.0375C37.2705 24.0739 35.8832 24.9984 34.4002 25.9866C34.3445 26.0237 34.2886 26.061 34.2326 26.0983C32.671 27.1393 30.9666 28.2838 29.1298 29.7761L27.8687 28.2239C29.7854 26.6665 31.5593 25.4767 33.1233 24.4342C33.1605 24.4094 33.1975 24.3847 33.2344 24.3601C34.7652 23.3398 36.0549 22.4802 37.1408 21.5314C38.2351 20.5754 39.096 19.5493 39.7271 18.2291C40.3614 16.9021 40.789 15.2241 40.9394 12.9341C40.9709 12.454 40.5722 12 39.9992 12Z"
            fill="#36CA3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9992 12H35.8454V10H39.9992C41.6355 10 43.0484 11.3408 42.9351 13.0651C42.7727 15.5376 42.3026 17.4785 41.5316 19.0916C40.7573 20.7114 39.7055 21.9465 38.4567 23.0375C37.2705 24.0739 35.8832 24.9984 34.4002 25.9866C34.3445 26.0237 34.2886 26.061 34.2326 26.0983C32.671 27.1393 30.9666 28.2838 29.1298 29.7761L27.8687 28.2239C29.7854 26.6665 31.5593 25.4767 33.1233 24.4342C33.1605 24.4094 33.1975 24.3847 33.2344 24.3601C34.7652 23.3398 36.0549 22.4802 37.1408 21.5314C38.2351 20.5754 39.096 19.5493 39.7271 18.2291C40.3614 16.9021 40.789 15.2241 40.9394 12.9341C40.9709 12.454 40.5722 12 39.9992 12Z"
            fill="url(#paint4_linear_1551_13103)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99978 12H12.1536V10H7.99978C6.36355 10 4.95067 11.3408 5.06391 13.0651C5.22627 15.5376 5.69639 17.4785 6.46745 19.0916C7.24169 20.7114 8.29357 21.9465 9.54229 23.0375C10.7285 24.0739 12.1158 24.9984 13.5988 25.9866C13.6545 26.0237 13.7104 26.061 13.7664 26.0983C15.3281 27.1393 17.0325 28.2838 18.8692 29.7761L20.1304 28.2239C18.2136 26.6665 16.4398 25.4767 14.8757 24.4342C14.8386 24.4094 14.8015 24.3847 14.7646 24.3601C13.2338 23.3398 11.9441 22.4802 10.8582 21.5314C9.76396 20.5754 8.90298 19.5493 8.2719 18.2291C7.63765 16.9021 7.21 15.2241 7.05961 12.9341C7.02808 12.454 7.42687 12 7.99978 12Z"
            fill="#36CA3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99978 12H12.1536V10H7.99978C6.36355 10 4.95067 11.3408 5.06391 13.0651C5.22627 15.5376 5.69639 17.4785 6.46745 19.0916C7.24169 20.7114 8.29357 21.9465 9.54229 23.0375C10.7285 24.0739 12.1158 24.9984 13.5988 25.9866C13.6545 26.0237 13.7104 26.061 13.7664 26.0983C15.3281 27.1393 17.0325 28.2838 18.8692 29.7761L20.1304 28.2239C18.2136 26.6665 16.4398 25.4767 14.8757 24.4342C14.8386 24.4094 14.8015 24.3847 14.7646 24.3601C13.2338 23.3398 11.9441 22.4802 10.8582 21.5314C9.76396 20.5754 8.90298 19.5493 8.2719 18.2291C7.63765 16.9021 7.21 15.2241 7.05961 12.9341C7.02808 12.454 7.42687 12 7.99978 12Z"
            fill="url(#paint5_linear_1551_13103)"
          />
          <path
            d="M36.9995 5C36.9995 12.1608 35.6299 19.0284 33.1919 24.0919C30.7539 29.1554 27.4473 32 23.9995 32C20.5517 32 17.2451 29.1554 14.8071 24.0919C12.3692 19.0284 10.9995 12.1609 10.9995 5L23.9995 5H36.9995Z"
            fill="#C4C4C4"
          />
          <path
            d="M36.9995 5C36.9995 12.1608 35.6299 19.0284 33.1919 24.0919C30.7539 29.1554 27.4473 32 23.9995 32C20.5517 32 17.2451 29.1554 14.8071 24.0919C12.3692 19.0284 10.9995 12.1609 10.9995 5L23.9995 5H36.9995Z"
            fill="url(#paint6_linear_1551_13103)"
          />
          <path
            d="M36.9995 5C36.9995 12.1608 35.6299 19.0284 33.1919 24.0919C30.7539 29.1554 27.4473 32 23.9995 32C20.5517 32 17.2451 29.1554 14.8071 24.0919C12.3692 19.0284 10.9995 12.1609 10.9995 5L23.9995 5H36.9995Z"
            fill="url(#paint7_radial_1551_13103)"
          />
          <path
            d="M36.9995 5C36.9995 12.1608 35.6299 19.0284 33.1919 24.0919C30.7539 29.1554 27.4473 32 23.9995 32C20.5517 32 17.2451 29.1554 14.8071 24.0919C12.3692 19.0284 10.9995 12.1609 10.9995 5L23.9995 5H36.9995Z"
            fill="url(#paint8_radial_1551_13103)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9995 4H11.9995C10.8949 4 9.99951 4.89543 9.99951 6C9.99951 7.10457 10.8949 8 11.9995 8H23.9995H35.9995C37.1041 8 37.9995 7.10457 37.9995 6C37.9995 4.89543 37.1041 4 35.9995 4H23.9995Z"
            fill="#C4C4C4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9995 4H11.9995C10.8949 4 9.99951 4.89543 9.99951 6C9.99951 7.10457 10.8949 8 11.9995 8H23.9995H35.9995C37.1041 8 37.9995 7.10457 37.9995 6C37.9995 4.89543 37.1041 4 35.9995 4H23.9995Z"
            fill="url(#paint9_linear_1551_13103)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9995 4H11.9995C10.8949 4 9.99951 4.89543 9.99951 6C9.99951 7.10457 10.8949 8 11.9995 8H23.9995H35.9995C37.1041 8 37.9995 7.10457 37.9995 6C37.9995 4.89543 37.1041 4 35.9995 4H23.9995Z"
            fill="url(#paint10_radial_1551_13103)"
            fillOpacity="0.5"
          />
          <rect x="18.9995" y="40" width="10" height="3" fill="#C4C4C4" />
          <rect x="18.9995" y="40" width="10" height="3" fill="url(#paint11_linear_1551_13103)" />
          <g filter="url(#filter1_i_1551_13103)">
            <path
              d="M23.0485 11.9271C23.3478 11.0057 24.6512 11.0057 24.9506 11.9271L25.7956 14.5279C25.9295 14.9399 26.3135 15.2188 26.7467 15.2188H29.4813C30.4501 15.2188 30.8528 16.4585 30.0691 17.0279L27.8567 18.6353C27.5063 18.8899 27.3596 19.3413 27.4935 19.7533L28.3385 22.3541C28.6379 23.2754 27.5834 24.0415 26.7997 23.4721L24.5873 21.8647C24.2368 21.6101 23.7622 21.6101 23.4117 21.8647L21.1993 23.4721C20.4156 24.0415 19.3611 23.2754 19.6605 22.3541L20.5056 19.7533C20.6394 19.3413 20.4928 18.8899 20.1423 18.6353L17.9299 17.0279C17.1462 16.4585 17.549 15.2188 18.5177 15.2188H21.2523C21.6856 15.2188 22.0695 14.9399 22.2034 14.5279L23.0485 11.9271Z"
              fill="#0185F3"
            />
            <path
              d="M23.0485 11.9271C23.3478 11.0057 24.6512 11.0057 24.9506 11.9271L25.7956 14.5279C25.9295 14.9399 26.3135 15.2188 26.7467 15.2188H29.4813C30.4501 15.2188 30.8528 16.4585 30.0691 17.0279L27.8567 18.6353C27.5063 18.8899 27.3596 19.3413 27.4935 19.7533L28.3385 22.3541C28.6379 23.2754 27.5834 24.0415 26.7997 23.4721L24.5873 21.8647C24.2368 21.6101 23.7622 21.6101 23.4117 21.8647L21.1993 23.4721C20.4156 24.0415 19.3611 23.2754 19.6605 22.3541L20.5056 19.7533C20.6394 19.3413 20.4928 18.8899 20.1423 18.6353L17.9299 17.0279C17.1462 16.4585 17.549 15.2188 18.5177 15.2188H21.2523C21.6856 15.2188 22.0695 14.9399 22.2034 14.5279L23.0485 11.9271Z"
              fill="url(#paint12_radial_1551_13103)"
            />
            <path
              d="M23.0485 11.9271C23.3478 11.0057 24.6512 11.0057 24.9506 11.9271L25.7956 14.5279C25.9295 14.9399 26.3135 15.2188 26.7467 15.2188H29.4813C30.4501 15.2188 30.8528 16.4585 30.0691 17.0279L27.8567 18.6353C27.5063 18.8899 27.3596 19.3413 27.4935 19.7533L28.3385 22.3541C28.6379 23.2754 27.5834 24.0415 26.7997 23.4721L24.5873 21.8647C24.2368 21.6101 23.7622 21.6101 23.4117 21.8647L21.1993 23.4721C20.4156 24.0415 19.3611 23.2754 19.6605 22.3541L20.5056 19.7533C20.6394 19.3413 20.4928 18.8899 20.1423 18.6353L17.9299 17.0279C17.1462 16.4585 17.549 15.2188 18.5177 15.2188H21.2523C21.6856 15.2188 22.0695 14.9399 22.2034 14.5279L23.0485 11.9271Z"
              fill="url(#paint13_linear_1551_13103)"
            />
            <path
              d="M23.0485 11.9271C23.3478 11.0057 24.6512 11.0057 24.9506 11.9271L25.7956 14.5279C25.9295 14.9399 26.3135 15.2188 26.7467 15.2188H29.4813C30.4501 15.2188 30.8528 16.4585 30.0691 17.0279L27.8567 18.6353C27.5063 18.8899 27.3596 19.3413 27.4935 19.7533L28.3385 22.3541C28.6379 23.2754 27.5834 24.0415 26.7997 23.4721L24.5873 21.8647C24.2368 21.6101 23.7622 21.6101 23.4117 21.8647L21.1993 23.4721C20.4156 24.0415 19.3611 23.2754 19.6605 22.3541L20.5056 19.7533C20.6394 19.3413 20.4928 18.8899 20.1423 18.6353L17.9299 17.0279C17.1462 16.4585 17.549 15.2188 18.5177 15.2188H21.2523C21.6856 15.2188 22.0695 14.9399 22.2034 14.5279L23.0485 11.9271Z"
              stroke="url(#paint14_linear_1551_13103)"
              strokeWidth="0.8"
            />
          </g>
        </g>
        <g className={css.svgBlend}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 4C10.8955 4 10.0001 4.89543 10.0001 6C10.0001 6.76825 10.4332 7.43532 11.0686 7.77033C11.1059 8.51967 11.158 9.26349 11.2249 10H8.00035C6.36412 10 4.95124 11.3408 5.06448 13.0651C5.22684 15.5376 5.69696 17.4785 6.46801 19.0916C7.24226 20.7114 8.29414 21.9465 9.54286 23.0375C10.7291 24.0739 12.1164 24.9984 13.5993 25.9866L13.767 26.0983C15.2486 27.086 16.8588 28.1668 18.5885 29.5495C19.7409 30.6452 20.9689 31.3896 22.2343 31.7499C22.2338 31.8272 22.2383 31.9055 22.2481 31.9846L22.6707 35.3649C22.8456 36.7641 21.7546 38 20.3444 38H19.0001C17.8955 38 17.0001 38.8954 17.0001 40V44H16.0001C15.4478 44 15.0001 44.4477 15.0001 45C15.0001 45.5523 15.4478 46 16.0001 46H32.0001C32.5524 46 33.0001 45.5523 33.0001 45C33.0001 44.4477 32.5524 44 32.0001 44H31.0001V40C31.0001 38.8954 30.1046 38 29.0001 38H27.6557C26.2456 38 25.1546 36.7641 25.3295 35.3649L25.752 31.9846C25.7619 31.9055 25.7664 31.8272 25.7659 31.7499C27.0313 31.3896 28.2593 30.6452 29.4116 29.5495C31.1414 28.1668 32.7515 27.086 34.2332 26.0983L34.4008 25.9866C35.8837 24.9984 37.2711 24.0739 38.4573 23.0375C39.706 21.9465 40.7579 20.7114 41.5321 19.0916C42.3032 17.4785 42.7733 15.5376 42.9357 13.0651C43.0489 11.3408 41.636 10 39.9998 10H36.7753C36.8421 9.26349 36.8943 8.51967 36.9315 7.77033C37.5669 7.43532 38.0001 6.76825 38.0001 6C38.0001 4.89543 37.1046 4 36.0001 4H12.0001ZM36.5556 12C35.9695 16.5344 34.8186 20.7145 33.1925 24.0919C33.1206 24.241 33.0481 24.3883 32.9748 24.5336L33.235 24.3601C34.7658 23.3398 36.0555 22.4802 37.1414 21.5314C38.2356 20.5754 39.0966 19.5493 39.7277 18.2291C40.3619 16.9021 40.7896 15.2241 40.94 12.9341C40.9715 12.454 40.5727 12 39.9998 12H36.5556ZM15.0254 24.5336C14.9521 24.3883 14.8795 24.241 14.8077 24.0919C13.1815 20.7145 12.0307 16.5344 11.4445 12H8.00035C7.42744 12 7.02865 12.454 7.06018 12.9341C7.21057 15.2241 7.63821 16.9021 8.27247 18.2291C8.90354 19.5493 9.76453 20.5754 10.8588 21.5314C11.9447 22.4801 13.2343 23.3397 14.7651 24.36L15.0254 24.5336Z"
            fill="url(#paint15_linear_1551_13103)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_1551_13103"
          x="4.05762"
          y="3"
          width="38.8838"
          height="43"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1551_13103" />
        </filter>
        <filter
          id="filter1_i_1551_13103"
          x="15.115"
          y="8.83594"
          width="15.769"
          height="15.2344"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1551_13103" />
        </filter>
        <linearGradient
          id="paint0_linear_1551_13103"
          x1="16.9995"
          y1="30"
          x2="22.2154"
          y2="44.5371"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.138653" stopColor="#FFED91" />
          <stop offset="0.991631" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_1551_13103"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.9821 40.9411) rotate(-89.9042) scale(10.4411 9.87393)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.740332" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.934727" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_1551_13103"
          x1="30.9995"
          y1="44"
          x2="16.4174"
          y2="40.2849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#804F31" />
          <stop offset="1" stopColor="#E07A3C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1551_13103"
          x1="33.9995"
          y1="47.5"
          x2="20.972"
          y2="32.2634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#804F31" />
          <stop offset="1" stopColor="#E07A3C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1551_13103"
          x1="43.4995"
          y1="17"
          x2="32.9995"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1551_13103"
          x1="4.49951"
          y1="17"
          x2="14.9995"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1551_13103"
          x1="10.9995"
          y1="-22"
          x2="52.5569"
          y2="7.28293"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.358355" stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_1551_13103"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.4995 14) rotate(38.1572) scale(26.7067 26.4336)"
        >
          <stop offset="0.192708" stopColor="#FFED91" />
          <stop offset="0.574826" stopColor="#E5A749" />
          <stop offset="0.754059" stopColor="#CD6505" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_1551_13103"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.9617 31.7109) rotate(-90) scale(48 64.8523)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.451039" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.614179" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_1551_13103"
          x1="20.4995"
          y1="5.22871e-08"
          x2="20.6737"
          y2="10.0057"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.222627" stopColor="#F5BC5D" />
          <stop offset="0.544001" stopColor="#F9E479" />
          <stop offset="0.73361" stopColor="#F5BC5D" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint10_radial_1551_13103"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.9995 6) scale(19.5 5.81713)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.536216" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.867768" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint11_linear_1551_13103"
          x1="21.9995"
          y1="38.5"
          x2="23.9995"
          y2="44.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3B04F" />
          <stop offset="0.380306" stopColor="#FFED91" />
          <stop offset="1" stopColor="#F1A645" />
        </linearGradient>
        <radialGradient
          id="paint12_radial_1551_13103"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.9995 22.5) rotate(-120.7) scale(18.6078 17.1964)"
        >
          <stop offset="0.202512" stopColor="#E7AC4E" />
          <stop offset="0.651981" stopColor="#CD6505" />
        </radialGradient>
        <linearGradient
          id="paint13_linear_1551_13103"
          x1="14.9995"
          y1="9"
          x2="28.7037"
          y2="29.0558"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.358355" stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1551_13103"
          x1="28.9995"
          y1="24"
          x2="19.4995"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D37414" />
          <stop offset="1" stopColor="#F0BF62" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1551_13103"
          x1="5.05811"
          y1="4"
          x2="46.8357"
          y2="41.6833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#589EBD" />
        </linearGradient>
        <clipPath id="clip0_1551_13103">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconLegendComplete: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_12378)">
        <g filter="url(#filter0_i_1549_12378)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7514 31.9846C25.8832 30.9308 25.0615 30 23.9995 30C22.9375 30 22.1159 30.9308 22.2476 31.9846L22.6701 35.3649C22.845 36.7641 21.754 38 20.3439 38H19.4995C18.6711 38 17.9995 38.6716 17.9995 39.5C17.9995 40.3284 18.6711 41 19.4995 41H28.4995C29.3279 41 29.9995 40.3284 29.9995 39.5C29.9995 38.6716 29.3279 38 28.4995 38H27.6552C26.245 38 25.154 36.7641 25.3289 35.3649L25.7514 31.9846Z"
            fill="url(#paint0_linear_1549_12378)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7514 31.9846C25.8832 30.9308 25.0615 30 23.9995 30C22.9375 30 22.1159 30.9308 22.2476 31.9846L22.6701 35.3649C22.845 36.7641 21.754 38 20.3439 38H19.4995C18.6711 38 17.9995 38.6716 17.9995 39.5C17.9995 40.3284 18.6711 41 19.4995 41H28.4995C29.3279 41 29.9995 40.3284 29.9995 39.5C29.9995 38.6716 29.3279 38 28.4995 38H27.6552C26.245 38 25.154 36.7641 25.3289 35.3649L25.7514 31.9846Z"
            fill="url(#paint1_radial_1549_12378)"
          />
          <path
            d="M16.9995 40C16.9995 38.8954 17.8949 38 18.9995 38H28.9995C30.1041 38 30.9995 38.8954 30.9995 40V44H16.9995V40Z"
            fill="url(#paint2_linear_1549_12378)"
          />
          <rect
            x="14.9995"
            y="44"
            width="18"
            height="2"
            rx="1"
            fill="url(#paint3_linear_1549_12378)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9992 12H35.8454V10H39.9992C41.6355 10 43.0484 11.3408 42.9351 13.0651C42.7727 15.5376 42.3026 17.4785 41.5316 19.0916C40.7573 20.7114 39.7055 21.9465 38.4567 23.0375C37.2705 24.0739 35.8832 24.9984 34.4002 25.9866C34.3445 26.0237 34.2886 26.061 34.2326 26.0983C32.671 27.1393 30.9666 28.2838 29.1298 29.7761L27.8687 28.2239C29.7854 26.6665 31.5593 25.4767 33.1233 24.4342C33.1605 24.4094 33.1975 24.3847 33.2344 24.3601C34.7652 23.3398 36.0549 22.4802 37.1408 21.5314C38.2351 20.5754 39.096 19.5493 39.7271 18.2291C40.3614 16.9021 40.789 15.2241 40.9394 12.9341C40.9709 12.454 40.5722 12 39.9992 12Z"
            fill="#36CA3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9992 12H35.8454V10H39.9992C41.6355 10 43.0484 11.3408 42.9351 13.0651C42.7727 15.5376 42.3026 17.4785 41.5316 19.0916C40.7573 20.7114 39.7055 21.9465 38.4567 23.0375C37.2705 24.0739 35.8832 24.9984 34.4002 25.9866C34.3445 26.0237 34.2886 26.061 34.2326 26.0983C32.671 27.1393 30.9666 28.2838 29.1298 29.7761L27.8687 28.2239C29.7854 26.6665 31.5593 25.4767 33.1233 24.4342C33.1605 24.4094 33.1975 24.3847 33.2344 24.3601C34.7652 23.3398 36.0549 22.4802 37.1408 21.5314C38.2351 20.5754 39.096 19.5493 39.7271 18.2291C40.3614 16.9021 40.789 15.2241 40.9394 12.9341C40.9709 12.454 40.5722 12 39.9992 12Z"
            fill="url(#paint4_linear_1549_12378)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99978 12H12.1536V10H7.99978C6.36355 10 4.95067 11.3408 5.06391 13.0651C5.22627 15.5376 5.69639 17.4785 6.46745 19.0916C7.24169 20.7114 8.29357 21.9465 9.54229 23.0375C10.7285 24.0739 12.1158 24.9984 13.5988 25.9866C13.6545 26.0237 13.7104 26.061 13.7664 26.0983C15.3281 27.1393 17.0325 28.2838 18.8692 29.7761L20.1304 28.2239C18.2136 26.6665 16.4398 25.4767 14.8757 24.4342C14.8386 24.4094 14.8015 24.3847 14.7646 24.3601C13.2338 23.3398 11.9441 22.4802 10.8582 21.5314C9.76396 20.5754 8.90298 19.5493 8.2719 18.2291C7.63765 16.9021 7.21 15.2241 7.05961 12.9341C7.02808 12.454 7.42687 12 7.99978 12Z"
            fill="#36CA3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99978 12H12.1536V10H7.99978C6.36355 10 4.95067 11.3408 5.06391 13.0651C5.22627 15.5376 5.69639 17.4785 6.46745 19.0916C7.24169 20.7114 8.29357 21.9465 9.54229 23.0375C10.7285 24.0739 12.1158 24.9984 13.5988 25.9866C13.6545 26.0237 13.7104 26.061 13.7664 26.0983C15.3281 27.1393 17.0325 28.2838 18.8692 29.7761L20.1304 28.2239C18.2136 26.6665 16.4398 25.4767 14.8757 24.4342C14.8386 24.4094 14.8015 24.3847 14.7646 24.3601C13.2338 23.3398 11.9441 22.4802 10.8582 21.5314C9.76396 20.5754 8.90298 19.5493 8.2719 18.2291C7.63765 16.9021 7.21 15.2241 7.05961 12.9341C7.02808 12.454 7.42687 12 7.99978 12Z"
            fill="url(#paint5_linear_1549_12378)"
          />
          <path
            d="M36.9995 5C36.9995 12.1608 35.6299 19.0284 33.1919 24.0919C30.7539 29.1554 27.4473 32 23.9995 32C20.5517 32 17.2451 29.1554 14.8071 24.0919C12.3692 19.0284 10.9995 12.1609 10.9995 5L23.9995 5H36.9995Z"
            fill="#C4C4C4"
          />
          <path
            d="M36.9995 5C36.9995 12.1608 35.6299 19.0284 33.1919 24.0919C30.7539 29.1554 27.4473 32 23.9995 32C20.5517 32 17.2451 29.1554 14.8071 24.0919C12.3692 19.0284 10.9995 12.1609 10.9995 5L23.9995 5H36.9995Z"
            fill="url(#paint6_linear_1549_12378)"
          />
          <path
            d="M36.9995 5C36.9995 12.1608 35.6299 19.0284 33.1919 24.0919C30.7539 29.1554 27.4473 32 23.9995 32C20.5517 32 17.2451 29.1554 14.8071 24.0919C12.3692 19.0284 10.9995 12.1609 10.9995 5L23.9995 5H36.9995Z"
            fill="url(#paint7_radial_1549_12378)"
          />
          <path
            d="M36.9995 5C36.9995 12.1608 35.6299 19.0284 33.1919 24.0919C30.7539 29.1554 27.4473 32 23.9995 32C20.5517 32 17.2451 29.1554 14.8071 24.0919C12.3692 19.0284 10.9995 12.1609 10.9995 5L23.9995 5H36.9995Z"
            fill="url(#paint8_radial_1549_12378)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9995 4H11.9995C10.8949 4 9.99951 4.89543 9.99951 6C9.99951 7.10457 10.8949 8 11.9995 8H23.9995H35.9995C37.1041 8 37.9995 7.10457 37.9995 6C37.9995 4.89543 37.1041 4 35.9995 4H23.9995Z"
            fill="#C4C4C4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9995 4H11.9995C10.8949 4 9.99951 4.89543 9.99951 6C9.99951 7.10457 10.8949 8 11.9995 8H23.9995H35.9995C37.1041 8 37.9995 7.10457 37.9995 6C37.9995 4.89543 37.1041 4 35.9995 4H23.9995Z"
            fill="url(#paint9_linear_1549_12378)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9995 4H11.9995C10.8949 4 9.99951 4.89543 9.99951 6C9.99951 7.10457 10.8949 8 11.9995 8H23.9995H35.9995C37.1041 8 37.9995 7.10457 37.9995 6C37.9995 4.89543 37.1041 4 35.9995 4H23.9995Z"
            fill="url(#paint10_radial_1549_12378)"
            fillOpacity="0.5"
          />
          <rect x="18.9995" y="40" width="10" height="3" fill="#C4C4C4" />
          <rect x="18.9995" y="40" width="10" height="3" fill="url(#paint11_linear_1549_12378)" />
          <g filter="url(#filter1_i_1549_12378)">
            <path
              d="M23.0485 11.9271C23.3478 11.0057 24.6512 11.0057 24.9506 11.9271L25.7956 14.5279C25.9295 14.9399 26.3135 15.2188 26.7467 15.2188H29.4813C30.4501 15.2188 30.8528 16.4585 30.0691 17.0279L27.8567 18.6353C27.5063 18.8899 27.3596 19.3413 27.4935 19.7533L28.3385 22.3541C28.6379 23.2754 27.5834 24.0415 26.7997 23.4721L24.5873 21.8647C24.2368 21.6101 23.7622 21.6101 23.4117 21.8647L21.1993 23.4721C20.4156 24.0415 19.3611 23.2754 19.6605 22.3541L20.5056 19.7533C20.6394 19.3413 20.4928 18.8899 20.1423 18.6353L17.9299 17.0279C17.1462 16.4585 17.549 15.2188 18.5177 15.2188H21.2523C21.6856 15.2188 22.0695 14.9399 22.2034 14.5279L23.0485 11.9271Z"
              fill="#0185F3"
            />
            <path
              d="M23.0485 11.9271C23.3478 11.0057 24.6512 11.0057 24.9506 11.9271L25.7956 14.5279C25.9295 14.9399 26.3135 15.2188 26.7467 15.2188H29.4813C30.4501 15.2188 30.8528 16.4585 30.0691 17.0279L27.8567 18.6353C27.5063 18.8899 27.3596 19.3413 27.4935 19.7533L28.3385 22.3541C28.6379 23.2754 27.5834 24.0415 26.7997 23.4721L24.5873 21.8647C24.2368 21.6101 23.7622 21.6101 23.4117 21.8647L21.1993 23.4721C20.4156 24.0415 19.3611 23.2754 19.6605 22.3541L20.5056 19.7533C20.6394 19.3413 20.4928 18.8899 20.1423 18.6353L17.9299 17.0279C17.1462 16.4585 17.549 15.2188 18.5177 15.2188H21.2523C21.6856 15.2188 22.0695 14.9399 22.2034 14.5279L23.0485 11.9271Z"
              fill="url(#paint12_radial_1549_12378)"
            />
            <path
              d="M23.0485 11.9271C23.3478 11.0057 24.6512 11.0057 24.9506 11.9271L25.7956 14.5279C25.9295 14.9399 26.3135 15.2188 26.7467 15.2188H29.4813C30.4501 15.2188 30.8528 16.4585 30.0691 17.0279L27.8567 18.6353C27.5063 18.8899 27.3596 19.3413 27.4935 19.7533L28.3385 22.3541C28.6379 23.2754 27.5834 24.0415 26.7997 23.4721L24.5873 21.8647C24.2368 21.6101 23.7622 21.6101 23.4117 21.8647L21.1993 23.4721C20.4156 24.0415 19.3611 23.2754 19.6605 22.3541L20.5056 19.7533C20.6394 19.3413 20.4928 18.8899 20.1423 18.6353L17.9299 17.0279C17.1462 16.4585 17.549 15.2188 18.5177 15.2188H21.2523C21.6856 15.2188 22.0695 14.9399 22.2034 14.5279L23.0485 11.9271Z"
              fill="url(#paint13_linear_1549_12378)"
            />
            <path
              d="M23.0485 11.9271C23.3478 11.0057 24.6512 11.0057 24.9506 11.9271L25.7956 14.5279C25.9295 14.9399 26.3135 15.2188 26.7467 15.2188H29.4813C30.4501 15.2188 30.8528 16.4585 30.0691 17.0279L27.8567 18.6353C27.5063 18.8899 27.3596 19.3413 27.4935 19.7533L28.3385 22.3541C28.6379 23.2754 27.5834 24.0415 26.7997 23.4721L24.5873 21.8647C24.2368 21.6101 23.7622 21.6101 23.4117 21.8647L21.1993 23.4721C20.4156 24.0415 19.3611 23.2754 19.6605 22.3541L20.5056 19.7533C20.6394 19.3413 20.4928 18.8899 20.1423 18.6353L17.9299 17.0279C17.1462 16.4585 17.549 15.2188 18.5177 15.2188H21.2523C21.6856 15.2188 22.0695 14.9399 22.2034 14.5279L23.0485 11.9271Z"
              stroke="url(#paint14_linear_1549_12378)"
              strokeWidth="0.8"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12378"
          x="4.05762"
          y="3"
          width="38.8838"
          height="43"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12378" />
        </filter>
        <filter
          id="filter1_i_1549_12378"
          x="15.115"
          y="8.83594"
          width="15.769"
          height="15.2344"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12378" />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12378"
          x1="16.9995"
          y1="30"
          x2="22.2154"
          y2="44.5371"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.138653" stopColor="#FFED91" />
          <stop offset="0.991631" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_1549_12378"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.9821 40.9411) rotate(-89.9042) scale(10.4411 9.87393)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.740332" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.934727" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_1549_12378"
          x1="30.9995"
          y1="44"
          x2="16.4174"
          y2="40.2849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#804F31" />
          <stop offset="1" stopColor="#E07A3C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1549_12378"
          x1="33.9995"
          y1="47.5"
          x2="20.972"
          y2="32.2634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#804F31" />
          <stop offset="1" stopColor="#E07A3C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1549_12378"
          x1="43.4995"
          y1="17"
          x2="32.9995"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1549_12378"
          x1="4.49951"
          y1="17"
          x2="14.9995"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1549_12378"
          x1="10.9995"
          y1="-22"
          x2="52.5569"
          y2="7.28293"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.358355" stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_1549_12378"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.4995 14) rotate(38.1572) scale(26.7067 26.4336)"
        >
          <stop offset="0.192708" stopColor="#FFED91" />
          <stop offset="0.574826" stopColor="#E5A749" />
          <stop offset="0.754059" stopColor="#CD6505" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_1549_12378"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.9617 31.7109) rotate(-90) scale(48 64.8523)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.451039" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.614179" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_1549_12378"
          x1="20.4995"
          y1="5.22871e-08"
          x2="20.6737"
          y2="10.0057"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.222627" stopColor="#F5BC5D" />
          <stop offset="0.544001" stopColor="#F9E479" />
          <stop offset="0.73361" stopColor="#F5BC5D" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint10_radial_1549_12378"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.9995 6) scale(19.5 5.81713)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.536216" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.867768" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint11_linear_1549_12378"
          x1="21.9995"
          y1="38.5"
          x2="23.9995"
          y2="44.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3B04F" />
          <stop offset="0.380306" stopColor="#FFED91" />
          <stop offset="1" stopColor="#F1A645" />
        </linearGradient>
        <radialGradient
          id="paint12_radial_1549_12378"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.9995 22.5) rotate(-120.7) scale(18.6078 17.1964)"
        >
          <stop offset="0.202512" stopColor="#E7AC4E" />
          <stop offset="0.651981" stopColor="#CD6505" />
        </radialGradient>
        <linearGradient
          id="paint13_linear_1549_12378"
          x1="14.9995"
          y1="9"
          x2="28.7037"
          y2="29.0558"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.358355" stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1549_12378"
          x1="28.9995"
          y1="24"
          x2="19.4995"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D37414" />
          <stop offset="1" stopColor="#F0BF62" />
        </linearGradient>
        <clipPath id="clip0_1549_12378">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconSage: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1549_12313)">
        <path
          d="M0.000326157 15L24.0003 5L48.0003 15V19L46.0004 19.8333V29.2676C46.5981 29.6134 47.0003 30.2597 47.0003 31C47.0003 31.5117 46.8082 31.9785 46.4921 32.3322C47.3933 33.9436 48.0003 36.5608 48.0003 37.5306C48.0003 39.4898 42.0003 39.4898 42.0003 37.5306C42.0003 36.5608 42.6073 33.9436 43.5086 32.3322C43.1925 31.9785 43.0003 31.5117 43.0003 31C43.0003 30.2597 43.4025 29.6133 44.0004 29.2675V20.6666L39.0003 22.75V33C39.0003 37.4183 32.2846 41 24.0003 41C15.7161 41 9.00033 37.4183 9.00033 33V22.7501L0 19L0.000326157 15Z"
          fill="white"
        />
        <g opacity="0.18" filter="url(#filter0_i_1549_12313)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39 15H9V33C9 37.4183 15.7157 41 24 41C32.2843 41 39 37.4183 39 33V15Z"
            fill="url(#paint0_linear_1549_12313)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39 15H9V33C9 37.4183 15.7157 41 24 41C32.2843 41 39 37.4183 39 33V15Z"
            fill="url(#paint1_linear_1549_12313)"
          />
          <g filter="url(#filter1_i_1549_12313)">
            <path
              d="M0 15L24 15.3607L48 15V19L24 29L0 19V15Z"
              fill="url(#paint2_linear_1549_12313)"
            />
            <path d="M0 15L24 15.3607L48 15V19L24 29L0 19V15Z" fill="white" fillOpacity="0.1" />
          </g>
          <path d="M0 15L24 5L48 15L24 25L0 15Z" fill="url(#paint3_linear_1549_12313)" />
          <path d="M0 15L24 5L48 15L24 25L0 15Z" fill="url(#paint4_linear_1549_12313)" />
          <mask
            id="mask0_1549_12313"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="9"
            y="15"
            width="30"
            height="26"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9 15H39V15.3613H23.9997H9V15ZM9 22.7501V33C9 37.4183 15.7157 41 24 41C32.2843 41 39 37.4183 39 33V22.7499L23.9997 29L9 22.7501Z"
              fill="url(#paint5_linear_1549_12313)"
            />
          </mask>
          <g mask="url(#mask0_1549_12313)">
            <g filter="url(#filter2_d_1549_12313)">
              <path
                d="M-0.000244141 15.3613H23.9998H47.9998V19L23.9998 29L-0.000244141 19V15.3613Z"
                fill="url(#paint6_linear_1549_12313)"
              />
            </g>
          </g>
          <path
            d="M24 15L42.2137 16.301C43.7837 16.4131 45 17.7194 45 19.2934V31"
            stroke="url(#paint7_linear_1549_12313)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48 37.5306C48 39.4898 42 39.4898 42 37.5306C42 36.0879 43.3431 31 45 31C46.6569 31 48 36.0879 48 37.5306Z"
            fill="#C4C4C4"
          />
          <path
            d="M48 37.5306C48 39.4898 42 39.4898 42 37.5306C42 36.0879 43.3431 31 45 31C46.6569 31 48 36.0879 48 37.5306Z"
            fill="url(#paint8_radial_1549_12313)"
          />
          <circle cx="45" cy="31" r="2" fill="url(#paint9_radial_1549_12313)" />
        </g>
        <g className={css.svgBlend}>
          <path
            d="M8.2016e-05 15L24.0001 5L48.0001 15V19L46.0001 19.8333V29.2676C46.5979 29.6134 47.0001 30.2597 47.0001 31C47.0001 31.5117 46.8079 31.9785 46.4919 32.3322C47.3931 33.9436 48.0001 36.5608 48.0001 37.5306C48.0001 39.4898 42.0001 39.4898 42.0001 37.5306C42.0001 36.5608 42.6071 33.9436 43.5083 32.3322C43.1922 31.9785 43.0001 31.5117 43.0001 31C43.0001 30.2597 43.4023 29.6133 44.0001 29.2675V20.6666L39.0001 22.75V33C39.0001 37.4183 32.2844 41 24.0001 41C15.7158 41 9.00008 37.4183 9.00008 33V22.7501L-0.000244141 19L8.2016e-05 15Z"
            fill="url(#paint10_linear_1549_12313)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12313"
          x="-2"
          y="3"
          width="50"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12313" />
        </filter>
        <filter
          id="filter1_i_1549_12313"
          x="-2"
          y="13"
          width="50"
          height="16"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12313" />
        </filter>
        <filter
          id="filter2_d_1549_12313"
          x="-2.00024"
          y="13.3613"
          width="56"
          height="21.6387"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1549_12313" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1549_12313"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12313"
          x1="9"
          y1="15"
          x2="33.5902"
          y2="44.5082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1549_12313"
          x1="16.5"
          y1="15.5"
          x2="31.5"
          y2="44.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.233255" stopColor="#00768F" />
          <stop offset="0.89285" stopColor="#002846" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1549_12313"
          x1="0"
          y1="15"
          x2="7.5264"
          y2="40.8048"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00768F" />
          <stop offset="1" stopColor="#002846" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1549_12313"
          x1="0"
          y1="5"
          x2="14.2012"
          y2="39.0828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1549_12313"
          x1="8"
          y1="4.5"
          x2="36"
          y2="35.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00768F" />
          <stop offset="1" stopColor="#002846" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1549_12313"
          x1="9"
          y1="15"
          x2="34.736"
          y2="44.6954"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1549_12313"
          x1="-0.000244141"
          y1="15.3613"
          x2="7.17132"
          y2="40.601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1549_12313"
          x1="24.5"
          y1="15"
          x2="44.5"
          y2="29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F56E32" stopOpacity="0.54" />
          <stop offset="0.510417" stopColor="#E93C1A" />
          <stop offset="1" stopColor="#DD0D03" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_1549_12313"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42 32.5) rotate(47.2906) scale(8.8459 6.25688)"
        >
          <stop offset="0.334487" stopColor="#FFB185" />
          <stop offset="0.838423" stopColor="#DD0D03" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_1549_12313"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.5 30.5) rotate(45) scale(2.82843)"
        >
          <stop offset="0.119792" stopColor="#FFA776" />
          <stop offset="0.906236" stopColor="#DD0D03" />
        </radialGradient>
        <linearGradient
          id="paint10_linear_1549_12313"
          x1="-0.000244141"
          y1="5"
          x2="34.5597"
          y2="51.0802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#589EBD" />
        </linearGradient>
        <clipPath id="clip0_1549_12313">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconSageComplete: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_1549_12278)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39 15H9V33C9 37.4183 15.7157 41 24 41C32.2843 41 39 37.4183 39 33V15Z"
          fill="url(#paint0_linear_1549_12278)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39 15H9V33C9 37.4183 15.7157 41 24 41C32.2843 41 39 37.4183 39 33V15Z"
          fill="url(#paint1_linear_1549_12278)"
        />
        <g filter="url(#filter1_i_1549_12278)">
          <path
            d="M0 15L24 15.3607L48 15V19L24 29L0 19V15Z"
            fill="url(#paint2_linear_1549_12278)"
          />
          <path d="M0 15L24 15.3607L48 15V19L24 29L0 19V15Z" fill="white" fillOpacity="0.1" />
        </g>
        <path d="M0 15L24 5L48 15L24 25L0 15Z" fill="url(#paint3_linear_1549_12278)" />
        <path d="M0 15L24 5L48 15L24 25L0 15Z" fill="url(#paint4_linear_1549_12278)" />
        <mask
          id="mask0_1549_12278"
          className={css.svgBlend}
          maskUnits="userSpaceOnUse"
          x="9"
          y="15"
          width="30"
          height="26"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 15H39V15.3613H23.9997H9V15ZM9 22.7501V33C9 37.4183 15.7157 41 24 41C32.2843 41 39 37.4183 39 33V22.7499L23.9997 29L9 22.7501Z"
            fill="url(#paint5_linear_1549_12278)"
          />
        </mask>
        <g mask="url(#mask0_1549_12278)">
          <g filter="url(#filter2_d_1549_12278)">
            <path
              d="M-0.000244141 15.3613H23.9998H47.9998V19L23.9998 29L-0.000244141 19V15.3613Z"
              fill="url(#paint6_linear_1549_12278)"
            />
          </g>
        </g>
        <path
          d="M24 15L42.2137 16.301C43.7837 16.4131 45 17.7194 45 19.2934V31"
          stroke="url(#paint7_linear_1549_12278)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48 37.5306C48 39.4898 42 39.4898 42 37.5306C42 36.0879 43.3431 31 45 31C46.6569 31 48 36.0879 48 37.5306Z"
          fill="#C4C4C4"
        />
        <path
          d="M48 37.5306C48 39.4898 42 39.4898 42 37.5306C42 36.0879 43.3431 31 45 31C46.6569 31 48 36.0879 48 37.5306Z"
          fill="url(#paint8_radial_1549_12278)"
        />
        <circle cx="45" cy="31" r="2" fill="url(#paint9_radial_1549_12278)" />
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12278"
          x="-2"
          y="3"
          width="50"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12278" />
        </filter>
        <filter
          id="filter1_i_1549_12278"
          x="-2"
          y="13"
          width="50"
          height="16"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12278" />
        </filter>
        <filter
          id="filter2_d_1549_12278"
          x="-2.00024"
          y="13.3613"
          width="56"
          height="21.6387"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1549_12278" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1549_12278"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12278"
          x1="9"
          y1="15"
          x2="33.5902"
          y2="44.5082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1549_12278"
          x1="16.5"
          y1="15.5"
          x2="31.5"
          y2="44.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.233255" stopColor="#00768F" />
          <stop offset="0.89285" stopColor="#002846" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1549_12278"
          x1="0"
          y1="15"
          x2="7.5264"
          y2="40.8048"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00768F" />
          <stop offset="1" stopColor="#002846" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1549_12278"
          x1="0"
          y1="5"
          x2="14.2012"
          y2="39.0828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1549_12278"
          x1="8"
          y1="4.5"
          x2="36"
          y2="35.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00768F" />
          <stop offset="1" stopColor="#002846" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1549_12278"
          x1="9"
          y1="15"
          x2="34.736"
          y2="44.6954"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1549_12278"
          x1="-0.000244141"
          y1="15.3613"
          x2="7.17132"
          y2="40.601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1549_12278"
          x1="24.5"
          y1="15"
          x2="44.5"
          y2="29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F56E32" stopOpacity="0.54" />
          <stop offset="0.510417" stopColor="#E93C1A" />
          <stop offset="1" stopColor="#DD0D03" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_1549_12278"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42 32.5) rotate(47.2906) scale(8.8459 6.25688)"
        >
          <stop offset="0.334487" stopColor="#FFB185" />
          <stop offset="0.838423" stopColor="#DD0D03" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_1549_12278"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.5 30.5) rotate(45) scale(2.82843)"
        >
          <stop offset="0.119792" stopColor="#FFA776" />
          <stop offset="0.906236" stopColor="#DD0D03" />
        </radialGradient>
      </defs>
    </svg>
  );
};

const IconScholar: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 0C20.8954 0 20 0.89543 20 2V4H4C1.79086 4 0 5.79086 0 8V34C0 36.2091 1.79086 38 4 38H8L2.9087 46.4855C2.50878 47.152 2.9889 48 3.76619 48H7.73524C9.14029 48 10.4423 47.2628 11.1652 46.058L12.4 44H35.6L36.8348 46.058C37.5577 47.2628 38.8597 48 40.2648 48H44.2338C45.0111 48 45.4912 47.152 45.0913 46.4855L40 38H44C46.2091 38 48 36.2091 48 34V8C48 5.79086 46.2091 4 44 4H28V2C28 0.895431 27.1046 0 26 0H22ZM13.6 42L16 38H32L34.4 42H13.6Z"
        fill="white"
      />
      <g opacity="0.27" filter="url(#filter0_i_1549_12206)">
        <rect x="10" y="42" width="29" height="2" fill="url(#paint0_linear_1549_12206)" />
        <path
          d="M17 23H25L11.1652 46.058C10.4423 47.2628 9.14029 48 7.73524 48H3.76619C2.9889 48 2.50878 47.152 2.9087 46.4855L17 23Z"
          fill="#C4C4C4"
        />
        <path
          d="M17 23H25L11.1652 46.058C10.4423 47.2628 9.14029 48 7.73524 48H3.76619C2.9889 48 2.50878 47.152 2.9087 46.4855L17 23Z"
          fill="url(#paint1_linear_1549_12206)"
        />
        <path
          d="M31 23H23L36.8348 46.058C37.5577 47.2628 38.8597 48 40.2648 48H44.2338C45.0111 48 45.4912 47.152 45.0913 46.4855L31 23Z"
          fill="#C4C4C4"
        />
        <path
          d="M31 23H23L36.8348 46.058C37.5577 47.2628 38.8597 48 40.2648 48H44.2338C45.0111 48 45.4912 47.152 45.0913 46.4855L31 23Z"
          fill="url(#paint2_linear_1549_12206)"
        />
        <path
          d="M20 2C20 0.89543 20.8954 0 22 0H26C27.1046 0 28 0.895431 28 2V5H20V2Z"
          fill="url(#paint3_linear_1549_12206)"
        />
        <path
          d="M48 8C48 5.79086 46.2091 4 44 4H4C1.79086 4 0 5.79086 0 8V34C0 36.2091 1.79086 38 4 38C17.3333 38 30.6667 38 44 38C46.2091 38 48 36.2091 48 34V8Z"
          fill="url(#paint4_linear_1549_12206)"
        />
        <path
          d="M48 8C48 5.79086 46.2091 4 44 4H4C1.79086 4 0 5.79086 0 8V34C0 36.2091 1.79086 38 4 38C17.3333 38 30.6667 38 44 38C46.2091 38 48 36.2091 48 34V8Z"
          fill="url(#paint5_linear_1549_12206)"
        />
        <path
          d="M48 8C48 5.79086 46.2091 4 44 4H4C1.79086 4 0 5.79086 0 8V34C0 36.2091 1.79086 38 4 38C17.3333 38 30.6667 38 44 38C46.2091 38 48 36.2091 48 34V8Z"
          fill="url(#paint6_linear_1549_12206)"
        />
        <path
          d="M48 8C48 5.79086 46.2091 4 44 4H4C1.79086 4 0 5.79086 0 8V34C0 36.2091 1.79086 38 4 38C17.3333 38 30.6667 38 44 38C46.2091 38 48 36.2091 48 34V8Z"
          fill="white"
          fillOpacity="0.15"
        />
        <path
          d="M2 8C2 6.89543 2.89543 6 4 6H44C45.1046 6 46 6.89543 46 8V34C46 35.1046 45.1046 36 44 36H4C2.89543 36 2 35.1046 2 34V8Z"
          fill="url(#paint7_linear_1549_12206)"
        />
        <path
          d="M2 8C2 6.89543 2.89543 6 4 6H44C45.1046 6 46 6.89543 46 8V34C46 35.1046 45.1046 36 44 36H4C2.89543 36 2 35.1046 2 34V8Z"
          fill="url(#paint8_linear_1549_12206)"
        />
        <path
          d="M14 12C14 10.8954 14.8954 10 16 10H40C41.1046 10 42 10.8954 42 12C42 13.1046 41.1046 14 40 14H16C14.8954 14 14 13.1046 14 12Z"
          fill="white"
        />
        <path
          d="M14 12C14 10.8954 14.8954 10 16 10H40C41.1046 10 42 10.8954 42 12C42 13.1046 41.1046 14 40 14H16C14.8954 14 14 13.1046 14 12Z"
          fill="#3175DC"
          fillOpacity="0.25"
        />
        <path
          d="M14 12C14 10.8954 14.8954 10 16 10H40C41.1046 10 42 10.8954 42 12C42 13.1046 41.1046 14 40 14H16C14.8954 14 14 13.1046 14 12Z"
          fill="url(#paint9_linear_1549_12206)"
          fillOpacity="0.15"
        />
        <path
          d="M14 12C14 10.8954 14.8954 10 16 10H40C41.1046 10 42 10.8954 42 12C42 13.1046 41.1046 14 40 14H16C14.8954 14 14 13.1046 14 12Z"
          fill="url(#paint10_linear_1549_12206)"
        />
        <path
          d="M14 19C14 17.8954 14.8954 17 16 17H27C28.1046 17 29 17.8954 29 19C29 20.1046 28.1046 21 27 21H16C14.8954 21 14 20.1046 14 19Z"
          fill="url(#paint11_linear_1549_12206)"
        />
        <path
          d="M14 26C14 24.8954 14.8954 24 16 24H20C21.1046 24 22 24.8954 22 26C22 27.1046 21.1046 28 20 28H16C14.8954 28 14 27.1046 14 26Z"
          fill="url(#paint12_linear_1549_12206)"
        />
        <path
          d="M6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12C10 13.1046 9.10457 14 8 14C6.89543 14 6 13.1046 6 12Z"
          fill="white"
        />
        <path
          d="M6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12C10 13.1046 9.10457 14 8 14C6.89543 14 6 13.1046 6 12Z"
          fill="#3175DC"
          fillOpacity="0.25"
        />
        <path
          d="M6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12C10 13.1046 9.10457 14 8 14C6.89543 14 6 13.1046 6 12Z"
          fill="url(#paint13_linear_1549_12206)"
          fillOpacity="0.15"
        />
        <path
          d="M6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12C10 13.1046 9.10457 14 8 14C6.89543 14 6 13.1046 6 12Z"
          fill="url(#paint14_radial_1549_12206)"
        />
        <path
          d="M6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19C10 20.1046 9.10457 21 8 21C6.89543 21 6 20.1046 6 19Z"
          fill="white"
        />
        <path
          d="M6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19C10 20.1046 9.10457 21 8 21C6.89543 21 6 20.1046 6 19Z"
          fill="#3175DC"
          fillOpacity="0.25"
        />
        <path
          d="M6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19C10 20.1046 9.10457 21 8 21C6.89543 21 6 20.1046 6 19Z"
          fill="url(#paint15_linear_1549_12206)"
          fillOpacity="0.15"
        />
        <path
          d="M6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19C10 20.1046 9.10457 21 8 21C6.89543 21 6 20.1046 6 19Z"
          fill="url(#paint16_radial_1549_12206)"
        />
        <path
          d="M6 26C6 24.8954 6.89543 24 8 24C9.10457 24 10 24.8954 10 26C10 27.1046 9.10457 28 8 28C6.89543 28 6 27.1046 6 26Z"
          fill="white"
        />
        <path
          d="M6 26C6 24.8954 6.89543 24 8 24C9.10457 24 10 24.8954 10 26C10 27.1046 9.10457 28 8 28C6.89543 28 6 27.1046 6 26Z"
          fill="#3175DC"
          fillOpacity="0.25"
        />
        <path
          d="M6 26C6 24.8954 6.89543 24 8 24C9.10457 24 10 24.8954 10 26C10 27.1046 9.10457 28 8 28C6.89543 28 6 27.1046 6 26Z"
          fill="url(#paint17_linear_1549_12206)"
          fillOpacity="0.15"
        />
        <path
          d="M6 26C6 24.8954 6.89543 24 8 24C9.10457 24 10 24.8954 10 26C10 27.1046 9.10457 28 8 28C6.89543 28 6 27.1046 6 26Z"
          fill="url(#paint18_radial_1549_12206)"
        />
        <rect x="26" y="29" width="16" height="7" rx="2" fill="#C4C4C4" />
        <rect x="26" y="29" width="16" height="7" rx="2" fill="url(#paint19_linear_1549_12206)" />
        <circle opacity="0.15" cx="28.5" cy="31.5" r="1.5" fill="black" />
        <circle opacity="0.15" cx="31.5" cy="34" r="1" fill="black" />
        <circle opacity="0.15" cx="33.5" cy="31" r="1" fill="black" />
      </g>
      <g className={css.svgBlend}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 0C20.8954 0 20 0.89543 20 2V4H4C1.79086 4 0 5.79086 0 8V34C0 36.2091 1.79086 38 4 38H8L2.9087 46.4855C2.50878 47.152 2.9889 48 3.76619 48H9.43381C9.78507 48 10.1106 47.8157 10.2913 47.5145L12.4 44H35.6L37.7087 47.5145C37.8894 47.8157 38.2149 48 38.5662 48H44.2338C45.0111 48 45.4912 47.152 45.0913 46.4855L40 38H44C46.2091 38 48 36.2091 48 34V8C48 5.79086 46.2091 4 44 4H28V2C28 0.895431 27.1046 0 26 0H22ZM13.6 42L16 38H32L34.4 42H13.6Z"
          fill="url(#paint20_linear_1549_12206)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12206"
          x="-2"
          y="-2"
          width="50"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12206" />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12206"
          x1="13.5"
          y1="42"
          x2="34.5"
          y2="44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1549_12206"
          x1="8"
          y1="37.5"
          x2="10.8152"
          y2="47.265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1549_12206"
          x1="32.5"
          y1="37.5"
          x2="44.5396"
          y2="49.005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1549_12206"
          x1="20.5"
          y1="0.5"
          x2="25.5"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1549_12206"
          x1="48"
          y1="4"
          x2="15.926"
          y2="49.2809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1549_12206"
          x1="10.0033"
          y1="38"
          x2="16.7644"
          y2="1.37978"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.536458" stopColor="#F4F7FA" />
          <stop offset="1" stopColor="#D0DEE9" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1549_12206"
          x1="0"
          y1="4"
          x2="32.074"
          y2="49.2809"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0808844" stopColor="#00768F" />
          <stop offset="0.958435" stopColor="#002846" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1549_12206"
          x1="2"
          y1="6"
          x2="29.3774"
          y2="48.2669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1549_12206"
          x1="38.6635"
          y1="36"
          x2="33.1786"
          y2="3.51262"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.536458" stopColor="white" />
          <stop offset="1" stopColor="#E7EEF3" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1549_12206"
          x1="28"
          y1="10"
          x2="28"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1549_12206"
          x1="14"
          y1="10"
          x2="15.12"
          y2="17.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F56E32" />
          <stop offset="1" stopColor="#DD0D03" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1549_12206"
          x1="14"
          y1="17"
          x2="15.9917"
          y2="24.4689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1549_12206"
          x1="14"
          y1="24"
          x2="17.2"
          y2="30.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1549_12206"
          x1="8"
          y1="10"
          x2="8"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint14_radial_1549_12206"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.5 11.5) rotate(45) scale(2.82843)"
        >
          <stop offset="0.119792" stopColor="#FFA776" />
          <stop offset="0.906236" stopColor="#DD0D03" />
        </radialGradient>
        <linearGradient
          id="paint15_linear_1549_12206"
          x1="8"
          y1="17"
          x2="8"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint16_radial_1549_12206"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.5 18.5) rotate(45) scale(2.82843)"
        >
          <stop offset="0.119792" stopColor="#69FFB7" />
          <stop offset="0.906236" stopColor="#60AC00" />
        </radialGradient>
        <linearGradient
          id="paint17_linear_1549_12206"
          x1="8"
          y1="24"
          x2="8"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint18_radial_1549_12206"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.5 25.5) rotate(45) scale(2.82843)"
        >
          <stop offset="0.119792" stopColor="#51FFD5" />
          <stop offset="0.906236" stopColor="#0B58CC" />
        </radialGradient>
        <linearGradient
          id="paint19_linear_1549_12206"
          x1="26"
          y1="29"
          x2="30.7012"
          y2="40.5174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF491" />
          <stop offset="1" stopColor="#DC9B1D" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1549_12206"
          x1="0"
          y1="0"
          x2="48"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#589EBD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconScholarComplete: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_1549_12146)">
        <rect x="10" y="42" width="29" height="2" fill="url(#paint0_linear_1549_12146)" />
        <path
          d="M17 23H25L11.1652 46.058C10.4423 47.2628 9.14029 48 7.73524 48H3.76619C2.9889 48 2.50878 47.152 2.9087 46.4855L17 23Z"
          fill="#C4C4C4"
        />
        <path
          d="M17 23H25L11.1652 46.058C10.4423 47.2628 9.14029 48 7.73524 48H3.76619C2.9889 48 2.50878 47.152 2.9087 46.4855L17 23Z"
          fill="url(#paint1_linear_1549_12146)"
        />
        <path
          d="M31 23H23L36.8348 46.058C37.5577 47.2628 38.8597 48 40.2648 48H44.2338C45.0111 48 45.4912 47.152 45.0913 46.4855L31 23Z"
          fill="#C4C4C4"
        />
        <path
          d="M31 23H23L36.8348 46.058C37.5577 47.2628 38.8597 48 40.2648 48H44.2338C45.0111 48 45.4912 47.152 45.0913 46.4855L31 23Z"
          fill="url(#paint2_linear_1549_12146)"
        />
        <path
          d="M20 2C20 0.89543 20.8954 0 22 0H26C27.1046 0 28 0.895431 28 2V5H20V2Z"
          fill="url(#paint3_linear_1549_12146)"
        />
        <path
          d="M48 8C48 5.79086 46.2091 4 44 4H4C1.79086 4 0 5.79086 0 8V34C0 36.2091 1.79086 38 4 38C17.3333 38 30.6667 38 44 38C46.2091 38 48 36.2091 48 34V8Z"
          fill="url(#paint4_linear_1549_12146)"
        />
        <path
          d="M48 8C48 5.79086 46.2091 4 44 4H4C1.79086 4 0 5.79086 0 8V34C0 36.2091 1.79086 38 4 38C17.3333 38 30.6667 38 44 38C46.2091 38 48 36.2091 48 34V8Z"
          fill="url(#paint5_linear_1549_12146)"
        />
        <path
          d="M48 8C48 5.79086 46.2091 4 44 4H4C1.79086 4 0 5.79086 0 8V34C0 36.2091 1.79086 38 4 38C17.3333 38 30.6667 38 44 38C46.2091 38 48 36.2091 48 34V8Z"
          fill="url(#paint6_linear_1549_12146)"
        />
        <path
          d="M48 8C48 5.79086 46.2091 4 44 4H4C1.79086 4 0 5.79086 0 8V34C0 36.2091 1.79086 38 4 38C17.3333 38 30.6667 38 44 38C46.2091 38 48 36.2091 48 34V8Z"
          fill="white"
          fillOpacity="0.15"
        />
        <path
          d="M2 8C2 6.89543 2.89543 6 4 6H44C45.1046 6 46 6.89543 46 8V34C46 35.1046 45.1046 36 44 36H4C2.89543 36 2 35.1046 2 34V8Z"
          fill="url(#paint7_linear_1549_12146)"
        />
        <path
          d="M2 8C2 6.89543 2.89543 6 4 6H44C45.1046 6 46 6.89543 46 8V34C46 35.1046 45.1046 36 44 36H4C2.89543 36 2 35.1046 2 34V8Z"
          fill="url(#paint8_linear_1549_12146)"
        />
        <path
          d="M14 12C14 10.8954 14.8954 10 16 10H40C41.1046 10 42 10.8954 42 12C42 13.1046 41.1046 14 40 14H16C14.8954 14 14 13.1046 14 12Z"
          fill="white"
        />
        <path
          d="M14 12C14 10.8954 14.8954 10 16 10H40C41.1046 10 42 10.8954 42 12C42 13.1046 41.1046 14 40 14H16C14.8954 14 14 13.1046 14 12Z"
          fill="#3175DC"
          fillOpacity="0.25"
        />
        <path
          d="M14 12C14 10.8954 14.8954 10 16 10H40C41.1046 10 42 10.8954 42 12C42 13.1046 41.1046 14 40 14H16C14.8954 14 14 13.1046 14 12Z"
          fill="url(#paint9_linear_1549_12146)"
          fillOpacity="0.15"
        />
        <path
          d="M14 12C14 10.8954 14.8954 10 16 10H40C41.1046 10 42 10.8954 42 12C42 13.1046 41.1046 14 40 14H16C14.8954 14 14 13.1046 14 12Z"
          fill="url(#paint10_linear_1549_12146)"
        />
        <path
          d="M14 19C14 17.8954 14.8954 17 16 17H27C28.1046 17 29 17.8954 29 19C29 20.1046 28.1046 21 27 21H16C14.8954 21 14 20.1046 14 19Z"
          fill="url(#paint11_linear_1549_12146)"
        />
        <path
          d="M14 26C14 24.8954 14.8954 24 16 24H20C21.1046 24 22 24.8954 22 26C22 27.1046 21.1046 28 20 28H16C14.8954 28 14 27.1046 14 26Z"
          fill="url(#paint12_linear_1549_12146)"
        />
        <path
          d="M6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12C10 13.1046 9.10457 14 8 14C6.89543 14 6 13.1046 6 12Z"
          fill="white"
        />
        <path
          d="M6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12C10 13.1046 9.10457 14 8 14C6.89543 14 6 13.1046 6 12Z"
          fill="#3175DC"
          fillOpacity="0.25"
        />
        <path
          d="M6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12C10 13.1046 9.10457 14 8 14C6.89543 14 6 13.1046 6 12Z"
          fill="url(#paint13_linear_1549_12146)"
          fillOpacity="0.15"
        />
        <path
          d="M6 12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12C10 13.1046 9.10457 14 8 14C6.89543 14 6 13.1046 6 12Z"
          fill="url(#paint14_radial_1549_12146)"
        />
        <path
          d="M6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19C10 20.1046 9.10457 21 8 21C6.89543 21 6 20.1046 6 19Z"
          fill="white"
        />
        <path
          d="M6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19C10 20.1046 9.10457 21 8 21C6.89543 21 6 20.1046 6 19Z"
          fill="#3175DC"
          fillOpacity="0.25"
        />
        <path
          d="M6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19C10 20.1046 9.10457 21 8 21C6.89543 21 6 20.1046 6 19Z"
          fill="url(#paint15_linear_1549_12146)"
          fillOpacity="0.15"
        />
        <path
          d="M6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19C10 20.1046 9.10457 21 8 21C6.89543 21 6 20.1046 6 19Z"
          fill="url(#paint16_radial_1549_12146)"
        />
        <path
          d="M6 26C6 24.8954 6.89543 24 8 24C9.10457 24 10 24.8954 10 26C10 27.1046 9.10457 28 8 28C6.89543 28 6 27.1046 6 26Z"
          fill="white"
        />
        <path
          d="M6 26C6 24.8954 6.89543 24 8 24C9.10457 24 10 24.8954 10 26C10 27.1046 9.10457 28 8 28C6.89543 28 6 27.1046 6 26Z"
          fill="#3175DC"
          fillOpacity="0.25"
        />
        <path
          d="M6 26C6 24.8954 6.89543 24 8 24C9.10457 24 10 24.8954 10 26C10 27.1046 9.10457 28 8 28C6.89543 28 6 27.1046 6 26Z"
          fill="url(#paint17_linear_1549_12146)"
          fillOpacity="0.15"
        />
        <path
          d="M6 26C6 24.8954 6.89543 24 8 24C9.10457 24 10 24.8954 10 26C10 27.1046 9.10457 28 8 28C6.89543 28 6 27.1046 6 26Z"
          fill="url(#paint18_radial_1549_12146)"
        />
        <rect x="26" y="29" width="16" height="7" rx="2" fill="#C4C4C4" />
        <rect x="26" y="29" width="16" height="7" rx="2" fill="url(#paint19_linear_1549_12146)" />
        <circle opacity="0.15" cx="28.5" cy="31.5" r="1.5" fill="black" />
        <circle opacity="0.15" cx="31.5" cy="34" r="1" fill="black" />
        <circle opacity="0.15" cx="33.5" cy="31" r="1" fill="black" />
      </g>
      <defs>
        <filter
          id="filter0_i_1549_12146"
          x="-2"
          y="-2"
          width="50"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1549_12146" />
        </filter>
        <linearGradient
          id="paint0_linear_1549_12146"
          x1="13.5"
          y1="42"
          x2="34.5"
          y2="44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1549_12146"
          x1="8"
          y1="37.5"
          x2="10.8152"
          y2="47.265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1549_12146"
          x1="32.5"
          y1="37.5"
          x2="44.5396"
          y2="49.005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1549_12146"
          x1="20.5"
          y1="0.5"
          x2="25.5"
          y2="4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E18440" />
          <stop offset="1" stopColor="#C16B3B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1549_12146"
          x1="48"
          y1="4"
          x2="15.926"
          y2="49.2809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1549_12146"
          x1="10.0033"
          y1="38"
          x2="16.7644"
          y2="1.37978"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.536458" stopColor="#F4F7FA" />
          <stop offset="1" stopColor="#D0DEE9" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1549_12146"
          x1="0"
          y1="4"
          x2="32.074"
          y2="49.2809"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0808844" stopColor="#00768F" />
          <stop offset="0.958435" stopColor="#002846" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1549_12146"
          x1="2"
          y1="6"
          x2="29.3774"
          y2="48.2669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1549_12146"
          x1="38.6635"
          y1="36"
          x2="33.1786"
          y2="3.51262"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.536458" stopColor="white" />
          <stop offset="1" stopColor="#E7EEF3" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1549_12146"
          x1="28"
          y1="10"
          x2="28"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1549_12146"
          x1="14"
          y1="10"
          x2="15.12"
          y2="17.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F56E32" />
          <stop offset="1" stopColor="#DD0D03" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1549_12146"
          x1="14"
          y1="17"
          x2="15.9917"
          y2="24.4689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1549_12146"
          x1="14"
          y1="24"
          x2="17.2"
          y2="30.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#51FFD5" />
          <stop offset="1" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1549_12146"
          x1="8"
          y1="10"
          x2="8"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint14_radial_1549_12146"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.5 11.5) rotate(45) scale(2.82843)"
        >
          <stop offset="0.119792" stopColor="#FFA776" />
          <stop offset="0.906236" stopColor="#DD0D03" />
        </radialGradient>
        <linearGradient
          id="paint15_linear_1549_12146"
          x1="8"
          y1="17"
          x2="8"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint16_radial_1549_12146"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.5 18.5) rotate(45) scale(2.82843)"
        >
          <stop offset="0.119792" stopColor="#69FFB7" />
          <stop offset="0.906236" stopColor="#60AC00" />
        </radialGradient>
        <linearGradient
          id="paint17_linear_1549_12146"
          x1="8"
          y1="24"
          x2="8"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint18_radial_1549_12146"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.5 25.5) rotate(45) scale(2.82843)"
        >
          <stop offset="0.119792" stopColor="#51FFD5" />
          <stop offset="0.906236" stopColor="#0B58CC" />
        </radialGradient>
        <linearGradient
          id="paint19_linear_1549_12146"
          x1="26"
          y1="29"
          x2="30.7012"
          y2="40.5174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF491" />
          <stop offset="1" stopColor="#DC9B1D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconTotalPoints: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2285_3656)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9996 4C10.895 4 9.99959 4.89543 9.99959 6C9.99959 6.76825 10.4327 7.43532 11.0682 7.77033C11.1054 8.51967 11.1576 9.26349 11.2244 10H7.99986C6.36363 10 4.95075 11.3408 5.06399 13.0651C5.22635 15.5376 5.69647 17.4785 6.46753 19.0916C7.24177 20.7114 8.29365 21.9465 9.54237 23.0375C10.7286 24.0739 12.1159 24.9984 13.5989 25.9866L13.7665 26.0983C15.2481 27.086 16.8583 28.1668 18.588 29.5495C19.7404 30.6452 20.9684 31.3896 22.2338 31.7499C22.2333 31.8272 22.2378 31.9055 22.2477 31.9846L22.6702 35.3649C22.8451 36.7641 21.7541 38 20.3439 38H18.9996C17.895 38 16.9996 38.8954 16.9996 40V44H15.9996C15.4473 44 14.9996 44.4477 14.9996 45C14.9996 45.5523 15.4473 46 15.9996 46H31.9996C32.5519 46 32.9996 45.5523 32.9996 45C32.9996 44.4477 32.5519 44 31.9996 44H30.9996V40C30.9996 38.8954 30.1042 38 28.9996 38H27.6552C26.2451 38 25.1541 36.7641 25.329 35.3649L25.7515 31.9846C25.7614 31.9055 25.7659 31.8272 25.7654 31.7499C27.0308 31.3896 28.2588 30.6452 29.4111 29.5495C31.1409 28.1668 32.751 27.086 34.2327 26.0983L34.4003 25.9866C35.8832 24.9984 37.2706 24.0739 38.4568 23.0375C39.7055 21.9465 40.7574 20.7114 41.5317 19.0916C42.3027 17.4785 42.7728 15.5376 42.9352 13.0651C43.0484 11.3408 41.6356 10 39.9993 10H36.7748C36.8416 9.26349 36.8938 8.51967 36.931 7.77033C37.5664 7.43532 37.9996 6.76825 37.9996 6C37.9996 4.89543 37.1042 4 35.9996 4H11.9996ZM36.5551 12C35.969 16.5344 34.8181 20.7145 33.192 24.0919C33.1202 24.241 33.0476 24.3883 32.9743 24.5336L33.2345 24.3601C34.7653 23.3398 36.055 22.4802 37.1409 21.5314C38.2351 20.5754 39.0961 19.5493 39.7272 18.2291C40.3615 16.9021 40.7891 15.2241 40.9395 12.9341C40.971 12.454 40.5722 12 39.9993 12H36.5551ZM15.0249 24.5336C14.9516 24.3883 14.879 24.241 14.8072 24.0919C13.181 20.7145 12.0302 16.5344 11.4441 12H7.99986C7.42695 12 7.02816 12.454 7.05969 12.9341C7.21008 15.2241 7.63772 16.9021 8.27198 18.2291C8.90305 19.5493 9.76404 20.5754 10.8583 21.5314C11.9442 22.4801 13.2338 23.3397 14.7646 24.36L15.0249 24.5336Z"
          fill="white"
        />
        <g opacity="0.5" filter="url(#filter0_i_2285_3656)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7519 31.9846C25.8837 30.9308 25.062 30 24 30V30C22.938 30 22.1163 30.9308 22.2481 31.9846L22.6706 35.3649C22.8455 36.7641 21.7545 38 20.3444 38H19.5C18.6716 38 18 38.6716 18 39.5V39.5C18 40.3284 18.6716 41 19.5 41H28.5C29.3284 41 30 40.3284 30 39.5V39.5C30 38.6716 29.3284 38 28.5 38H27.6556C26.2455 38 25.1545 36.7641 25.3294 35.3649L25.7519 31.9846Z"
            fill="url(#paint0_linear_2285_3656)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7519 31.9846C25.8837 30.9308 25.062 30 24 30V30C22.938 30 22.1163 30.9308 22.2481 31.9846L22.6706 35.3649C22.8455 36.7641 21.7545 38 20.3444 38H19.5C18.6716 38 18 38.6716 18 39.5V39.5C18 40.3284 18.6716 41 19.5 41H28.5C29.3284 41 30 40.3284 30 39.5V39.5C30 38.6716 29.3284 38 28.5 38H27.6556C26.2455 38 25.1545 36.7641 25.3294 35.3649L25.7519 31.9846Z"
            fill="url(#paint1_radial_2285_3656)"
          />
          <path
            d="M17 40C17 38.8954 17.8954 38 19 38H29C30.1046 38 31 38.8954 31 40V44H17V40Z"
            fill="url(#paint2_linear_2285_3656)"
          />
          <rect x="15" y="44" width="18" height="2" rx="1" fill="url(#paint3_linear_2285_3656)" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9997 12H35.8459V10H39.9997C41.636 10 43.0488 11.3408 42.9356 13.0651C42.7732 15.5376 42.3031 17.4785 41.5321 19.0916C40.7578 20.7114 39.7059 21.9465 38.4572 23.0375C37.271 24.0739 35.8837 24.9984 34.4007 25.9866C34.345 26.0237 34.2891 26.061 34.2331 26.0983C32.6715 27.1393 30.9671 28.2838 29.1303 29.7761L27.8691 28.2239C29.7859 26.6665 31.5598 25.4767 33.1238 24.4342C33.161 24.4094 33.198 24.3847 33.2349 24.3601C34.7657 23.3398 36.0554 22.4802 37.1413 21.5314C38.2355 20.5754 39.0965 19.5493 39.7276 18.2291C40.3619 16.9021 40.7895 15.2241 40.9399 12.9341C40.9714 12.454 40.5726 12 39.9997 12Z"
            fill="#36CA3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9997 12H35.8459V10H39.9997C41.636 10 43.0488 11.3408 42.9356 13.0651C42.7732 15.5376 42.3031 17.4785 41.5321 19.0916C40.7578 20.7114 39.7059 21.9465 38.4572 23.0375C37.271 24.0739 35.8837 24.9984 34.4007 25.9866C34.345 26.0237 34.2891 26.061 34.2331 26.0983C32.6715 27.1393 30.9671 28.2838 29.1303 29.7761L27.8691 28.2239C29.7859 26.6665 31.5598 25.4767 33.1238 24.4342C33.161 24.4094 33.198 24.3847 33.2349 24.3601C34.7657 23.3398 36.0554 22.4802 37.1413 21.5314C38.2355 20.5754 39.0965 19.5493 39.7276 18.2291C40.3619 16.9021 40.7895 15.2241 40.9399 12.9341C40.9714 12.454 40.5726 12 39.9997 12Z"
            fill="url(#paint4_linear_2285_3656)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00027 12H12.1541V10H8.00027C6.36404 10 4.95116 11.3408 5.0644 13.0651C5.22676 15.5376 5.69688 17.4785 6.46794 19.0916C7.24218 20.7114 8.29406 21.9465 9.54278 23.0375C10.729 24.0739 12.1163 24.9984 13.5993 25.9866C13.655 26.0237 13.7109 26.061 13.7669 26.0983C15.3285 27.1393 17.0329 28.2838 18.8697 29.7761L20.1309 28.2239C18.2141 26.6665 16.4402 25.4767 14.8762 24.4342C14.839 24.4094 14.802 24.3847 14.7651 24.3601C13.2343 23.3398 11.9446 22.4802 10.8587 21.5314C9.76445 20.5754 8.90346 19.5493 8.27239 18.2291C7.63813 16.9021 7.21049 15.2241 7.0601 12.9341C7.02857 12.454 7.42736 12 8.00027 12Z"
            fill="#36CA3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00027 12H12.1541V10H8.00027C6.36404 10 4.95116 11.3408 5.0644 13.0651C5.22676 15.5376 5.69688 17.4785 6.46794 19.0916C7.24218 20.7114 8.29406 21.9465 9.54278 23.0375C10.729 24.0739 12.1163 24.9984 13.5993 25.9866C13.655 26.0237 13.7109 26.061 13.7669 26.0983C15.3285 27.1393 17.0329 28.2838 18.8697 29.7761L20.1309 28.2239C18.2141 26.6665 16.4402 25.4767 14.8762 24.4342C14.839 24.4094 14.802 24.3847 14.7651 24.3601C13.2343 23.3398 11.9446 22.4802 10.8587 21.5314C9.76445 20.5754 8.90346 19.5493 8.27239 18.2291C7.63813 16.9021 7.21049 15.2241 7.0601 12.9341C7.02857 12.454 7.42736 12 8.00027 12Z"
            fill="url(#paint5_linear_2285_3656)"
          />
          <path
            d="M37 5C37 12.1608 35.6304 19.0284 33.1924 24.0919C30.7544 29.1554 27.4478 32 24 32C20.5522 32 17.2456 29.1554 14.8076 24.0919C12.3696 19.0284 11 12.1608 11 5L24 5H37Z"
            fill="#C4C4C4"
          />
          <path
            d="M37 5C37 12.1608 35.6304 19.0284 33.1924 24.0919C30.7544 29.1554 27.4478 32 24 32C20.5522 32 17.2456 29.1554 14.8076 24.0919C12.3696 19.0284 11 12.1608 11 5L24 5H37Z"
            fill="url(#paint6_linear_2285_3656)"
          />
          <path
            d="M37 5C37 12.1608 35.6304 19.0284 33.1924 24.0919C30.7544 29.1554 27.4478 32 24 32C20.5522 32 17.2456 29.1554 14.8076 24.0919C12.3696 19.0284 11 12.1608 11 5L24 5H37Z"
            fill="url(#paint7_radial_2285_3656)"
          />
          <path
            d="M37 5C37 12.1608 35.6304 19.0284 33.1924 24.0919C30.7544 29.1554 27.4478 32 24 32C20.5522 32 17.2456 29.1554 14.8076 24.0919C12.3696 19.0284 11 12.1608 11 5L24 5H37Z"
            fill="url(#paint8_radial_2285_3656)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 4H12C10.8954 4 10 4.89543 10 6V6C10 7.10457 10.8954 8 12 8H24H36C37.1046 8 38 7.10457 38 6V6C38 4.89543 37.1046 4 36 4H24Z"
            fill="#C4C4C4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 4H12C10.8954 4 10 4.89543 10 6V6C10 7.10457 10.8954 8 12 8H24H36C37.1046 8 38 7.10457 38 6V6C38 4.89543 37.1046 4 36 4H24Z"
            fill="url(#paint9_linear_2285_3656)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 4H12C10.8954 4 10 4.89543 10 6V6C10 7.10457 10.8954 8 12 8H24H36C37.1046 8 38 7.10457 38 6V6C38 4.89543 37.1046 4 36 4H24Z"
            fill="url(#paint10_radial_2285_3656)"
            fillOpacity="0.5"
          />
          <rect x="19" y="40" width="10" height="3" fill="#C4C4C4" />
          <rect x="19" y="40" width="10" height="3" fill="url(#paint11_linear_2285_3656)" />
          <g filter="url(#filter1_i_2285_3656)">
            <path
              d="M23.0489 11.9271C23.3483 11.0057 24.6517 11.0057 24.9511 11.9271L25.7961 14.5279C25.93 14.9399 26.3139 15.2188 26.7472 15.2188H29.4818C30.4505 15.2188 30.8533 16.4585 30.0696 17.0279L27.8572 18.6353C27.5067 18.8899 27.3601 19.3413 27.494 19.7533L28.339 22.3541C28.6384 23.2754 27.5839 24.0415 26.8002 23.4721L24.5878 21.8647C24.2373 21.6101 23.7627 21.6101 23.4122 21.8647L21.1998 23.4721C20.4161 24.0415 19.3616 23.2754 19.661 22.3541L20.506 19.7533C20.6399 19.3413 20.4933 18.8899 20.1428 18.6353L17.9304 17.0279C17.1467 16.4585 17.5495 15.2188 18.5182 15.2188H21.2528C21.6861 15.2188 22.07 14.9399 22.2039 14.5279L23.0489 11.9271Z"
              fill="#0185F3"
            />
            <path
              d="M23.0489 11.9271C23.3483 11.0057 24.6517 11.0057 24.9511 11.9271L25.7961 14.5279C25.93 14.9399 26.3139 15.2188 26.7472 15.2188H29.4818C30.4505 15.2188 30.8533 16.4585 30.0696 17.0279L27.8572 18.6353C27.5067 18.8899 27.3601 19.3413 27.494 19.7533L28.339 22.3541C28.6384 23.2754 27.5839 24.0415 26.8002 23.4721L24.5878 21.8647C24.2373 21.6101 23.7627 21.6101 23.4122 21.8647L21.1998 23.4721C20.4161 24.0415 19.3616 23.2754 19.661 22.3541L20.506 19.7533C20.6399 19.3413 20.4933 18.8899 20.1428 18.6353L17.9304 17.0279C17.1467 16.4585 17.5495 15.2188 18.5182 15.2188H21.2528C21.6861 15.2188 22.07 14.9399 22.2039 14.5279L23.0489 11.9271Z"
              fill="url(#paint12_radial_2285_3656)"
            />
            <path
              d="M23.0489 11.9271C23.3483 11.0057 24.6517 11.0057 24.9511 11.9271L25.7961 14.5279C25.93 14.9399 26.3139 15.2188 26.7472 15.2188H29.4818C30.4505 15.2188 30.8533 16.4585 30.0696 17.0279L27.8572 18.6353C27.5067 18.8899 27.3601 19.3413 27.494 19.7533L28.339 22.3541C28.6384 23.2754 27.5839 24.0415 26.8002 23.4721L24.5878 21.8647C24.2373 21.6101 23.7627 21.6101 23.4122 21.8647L21.1998 23.4721C20.4161 24.0415 19.3616 23.2754 19.661 22.3541L20.506 19.7533C20.6399 19.3413 20.4933 18.8899 20.1428 18.6353L17.9304 17.0279C17.1467 16.4585 17.5495 15.2188 18.5182 15.2188H21.2528C21.6861 15.2188 22.07 14.9399 22.2039 14.5279L23.0489 11.9271Z"
              fill="url(#paint13_linear_2285_3656)"
            />
            <path
              d="M23.0489 11.9271C23.3483 11.0057 24.6517 11.0057 24.9511 11.9271L25.7961 14.5279C25.93 14.9399 26.3139 15.2188 26.7472 15.2188H29.4818C30.4505 15.2188 30.8533 16.4585 30.0696 17.0279L27.8572 18.6353C27.5067 18.8899 27.3601 19.3413 27.494 19.7533L28.339 22.3541C28.6384 23.2754 27.5839 24.0415 26.8002 23.4721L24.5878 21.8647C24.2373 21.6101 23.7627 21.6101 23.4122 21.8647L21.1998 23.4721C20.4161 24.0415 19.3616 23.2754 19.661 22.3541L20.506 19.7533C20.6399 19.3413 20.4933 18.8899 20.1428 18.6353L17.9304 17.0279C17.1467 16.4585 17.5495 15.2188 18.5182 15.2188H21.2528C21.6861 15.2188 22.07 14.9399 22.2039 14.5279L23.0489 11.9271Z"
              stroke="url(#paint14_linear_2285_3656)"
              strokeWidth="0.8"
            />
          </g>
        </g>
        <g style={{ mixBlendMode: 'color' }}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9996 4C10.895 4 9.99959 4.89543 9.99959 6C9.99959 6.76825 10.4327 7.43532 11.0682 7.77033C11.1054 8.51967 11.1576 9.26349 11.2244 10H7.99986C6.36363 10 4.95075 11.3408 5.06399 13.0651C5.22635 15.5376 5.69647 17.4785 6.46753 19.0916C7.24177 20.7114 8.29365 21.9465 9.54237 23.0375C10.7286 24.0739 12.1159 24.9984 13.5989 25.9866L13.7665 26.0983C15.2481 27.086 16.8583 28.1668 18.588 29.5495C19.7404 30.6452 20.9684 31.3896 22.2338 31.7499C22.2333 31.8272 22.2378 31.9055 22.2477 31.9846L22.6702 35.3649C22.8451 36.7641 21.7541 38 20.3439 38H18.9996C17.895 38 16.9996 38.8954 16.9996 40V44H15.9996C15.4473 44 14.9996 44.4477 14.9996 45C14.9996 45.5523 15.4473 46 15.9996 46H31.9996C32.5519 46 32.9996 45.5523 32.9996 45C32.9996 44.4477 32.5519 44 31.9996 44H30.9996V40C30.9996 38.8954 30.1042 38 28.9996 38H27.6552C26.2451 38 25.1541 36.7641 25.329 35.3649L25.7515 31.9846C25.7614 31.9055 25.7659 31.8272 25.7654 31.7499C27.0308 31.3896 28.2588 30.6452 29.4111 29.5495C31.1409 28.1668 32.751 27.086 34.2327 26.0983L34.4003 25.9866C35.8832 24.9984 37.2706 24.0739 38.4568 23.0375C39.7055 21.9465 40.7574 20.7114 41.5317 19.0916C42.3027 17.4785 42.7728 15.5376 42.9352 13.0651C43.0484 11.3408 41.6356 10 39.9993 10H36.7748C36.8416 9.26349 36.8938 8.51967 36.931 7.77033C37.5664 7.43532 37.9996 6.76825 37.9996 6C37.9996 4.89543 37.1042 4 35.9996 4H11.9996ZM36.5551 12C35.969 16.5344 34.8181 20.7145 33.192 24.0919C33.1202 24.241 33.0476 24.3883 32.9743 24.5336L33.2345 24.3601C34.7653 23.3398 36.055 22.4802 37.1409 21.5314C38.2351 20.5754 39.0961 19.5493 39.7272 18.2291C40.3615 16.9021 40.7891 15.2241 40.9395 12.9341C40.971 12.454 40.5722 12 39.9993 12H36.5551ZM15.0249 24.5336C14.9516 24.3883 14.879 24.241 14.8072 24.0919C13.181 20.7145 12.0302 16.5344 11.4441 12H7.99986C7.42695 12 7.02816 12.454 7.05969 12.9341C7.21008 15.2241 7.63772 16.9021 8.27198 18.2291C8.90305 19.5493 9.76404 20.5754 10.8583 21.5314C11.9442 22.4801 13.2338 23.3397 14.7646 24.36L15.0249 24.5336Z"
            fill="url(#paint15_linear_2285_3656)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_2285_3656"
          x="4.05762"
          y="3"
          width="38.8848"
          height="43"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2285_3656" />
        </filter>
        <filter
          id="filter1_i_2285_3656"
          x="15.1152"
          y="8.83594"
          width="15.7695"
          height="15.2344"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2285_3656" />
        </filter>
        <linearGradient
          id="paint0_linear_2285_3656"
          x1="17"
          y1="30"
          x2="22.2159"
          y2="44.5371"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.138653" stopColor="#FFED91" />
          <stop offset="0.991631" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_2285_3656"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.9825 40.9411) rotate(-89.9042) scale(10.4411 9.87393)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.740332" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.934727" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_2285_3656"
          x1="31"
          y1="44"
          x2="16.4179"
          y2="40.2849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#804F31" />
          <stop offset="1" stopColor="#E07A3C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2285_3656"
          x1="34"
          y1="47.5"
          x2="20.9725"
          y2="32.2634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#804F31" />
          <stop offset="1" stopColor="#E07A3C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2285_3656"
          x1="43.5"
          y1="17"
          x2="33"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2285_3656"
          x1="4.5"
          y1="17"
          x2="15"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2285_3656"
          x1="11"
          y1="-22"
          x2="52.5574"
          y2="7.28293"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.358355" stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_2285_3656"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.5 14) rotate(38.1572) scale(26.7067 26.4336)"
        >
          <stop offset="0.192708" stopColor="#FFED91" />
          <stop offset="0.574826" stopColor="#E5A749" />
          <stop offset="0.754059" stopColor="#CD6505" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_2285_3656"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.9622 31.7109) rotate(-90) scale(48 64.8523)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.451039" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.614179" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_2285_3656"
          x1="20.5"
          y1="5.22871e-08"
          x2="20.6742"
          y2="10.0057"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.222627" stopColor="#F5BC5D" />
          <stop offset="0.544001" stopColor="#F9E479" />
          <stop offset="0.73361" stopColor="#F5BC5D" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint10_radial_2285_3656"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23 6) scale(19.5 5.81713)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.536216" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.867768" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint11_linear_2285_3656"
          x1="22"
          y1="38.5"
          x2="24"
          y2="44.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3B04F" />
          <stop offset="0.380306" stopColor="#FFED91" />
          <stop offset="1" stopColor="#F1A645" />
        </linearGradient>
        <radialGradient
          id="paint12_radial_2285_3656"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27 22.5) rotate(-120.7) scale(18.6078 17.1964)"
        >
          <stop offset="0.202512" stopColor="#E7AC4E" />
          <stop offset="0.651981" stopColor="#CD6505" />
        </radialGradient>
        <linearGradient
          id="paint13_linear_2285_3656"
          x1="15"
          y1="9"
          x2="28.7042"
          y2="29.0558"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.358355" stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_2285_3656"
          x1="29"
          y1="24"
          x2="19.5"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D37414" />
          <stop offset="1" stopColor="#F0BF62" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_2285_3656"
          x1="5.05762"
          y1="4"
          x2="46.8352"
          y2="41.6833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCDFFB" />
          <stop offset="1" stopColor="#589EBD" />
        </linearGradient>
        <clipPath id="clip0_2285_3656">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconTotalPointsComplete: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2285_18650)">
        <g filter="url(#filter0_i_2285_18650)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7519 31.9846C25.8837 30.9308 25.062 30 24 30C22.938 30 22.1163 30.9308 22.2481 31.9846L22.6706 35.3649C22.8455 36.7641 21.7545 38 20.3444 38H19.5C18.6716 38 18 38.6716 18 39.5C18 40.3284 18.6716 41 19.5 41H28.5C29.3284 41 30 40.3284 30 39.5C30 38.6716 29.3284 38 28.5 38H27.6556C26.2455 38 25.1545 36.7641 25.3294 35.3649L25.7519 31.9846Z"
            fill="url(#paint0_linear_2285_18650)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7519 31.9846C25.8837 30.9308 25.062 30 24 30C22.938 30 22.1163 30.9308 22.2481 31.9846L22.6706 35.3649C22.8455 36.7641 21.7545 38 20.3444 38H19.5C18.6716 38 18 38.6716 18 39.5C18 40.3284 18.6716 41 19.5 41H28.5C29.3284 41 30 40.3284 30 39.5C30 38.6716 29.3284 38 28.5 38H27.6556C26.2455 38 25.1545 36.7641 25.3294 35.3649L25.7519 31.9846Z"
            fill="url(#paint1_radial_2285_18650)"
          />
          <path
            d="M17 40C17 38.8954 17.8954 38 19 38H29C30.1046 38 31 38.8954 31 40V44H17V40Z"
            fill="url(#paint2_linear_2285_18650)"
          />
          <rect x="15" y="44" width="18" height="2" rx="1" fill="url(#paint3_linear_2285_18650)" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9997 12H35.8459V10H39.9997C41.636 10 43.0488 11.3408 42.9356 13.0651C42.7732 15.5376 42.3031 17.4785 41.5321 19.0916C40.7578 20.7114 39.7059 21.9465 38.4572 23.0375C37.271 24.0739 35.8837 24.9984 34.4007 25.9866C34.345 26.0237 34.2891 26.061 34.2331 26.0983C32.6715 27.1393 30.9671 28.2838 29.1303 29.7761L27.8691 28.2239C29.7859 26.6665 31.5598 25.4767 33.1238 24.4342C33.161 24.4094 33.198 24.3847 33.2349 24.3601C34.7657 23.3398 36.0554 22.4802 37.1413 21.5314C38.2355 20.5754 39.0965 19.5493 39.7276 18.2291C40.3619 16.9021 40.7895 15.2241 40.9399 12.9341C40.9714 12.454 40.5726 12 39.9997 12Z"
            fill="#36CA3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9997 12H35.8459V10H39.9997C41.636 10 43.0488 11.3408 42.9356 13.0651C42.7732 15.5376 42.3031 17.4785 41.5321 19.0916C40.7578 20.7114 39.7059 21.9465 38.4572 23.0375C37.271 24.0739 35.8837 24.9984 34.4007 25.9866C34.345 26.0237 34.2891 26.061 34.2331 26.0983C32.6715 27.1393 30.9671 28.2838 29.1303 29.7761L27.8691 28.2239C29.7859 26.6665 31.5598 25.4767 33.1238 24.4342C33.161 24.4094 33.198 24.3847 33.2349 24.3601C34.7657 23.3398 36.0554 22.4802 37.1413 21.5314C38.2355 20.5754 39.0965 19.5493 39.7276 18.2291C40.3619 16.9021 40.7895 15.2241 40.9399 12.9341C40.9714 12.454 40.5726 12 39.9997 12Z"
            fill="url(#paint4_linear_2285_18650)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00027 12H12.1541V10H8.00027C6.36404 10 4.95116 11.3408 5.0644 13.0651C5.22676 15.5376 5.69688 17.4785 6.46794 19.0916C7.24218 20.7114 8.29406 21.9465 9.54278 23.0375C10.729 24.0739 12.1163 24.9984 13.5993 25.9866C13.655 26.0237 13.7109 26.061 13.7669 26.0983C15.3285 27.1393 17.0329 28.2838 18.8697 29.7761L20.1309 28.2239C18.2141 26.6665 16.4402 25.4767 14.8762 24.4342C14.839 24.4094 14.802 24.3847 14.7651 24.3601C13.2343 23.3398 11.9446 22.4802 10.8587 21.5314C9.76445 20.5754 8.90346 19.5493 8.27239 18.2291C7.63813 16.9021 7.21049 15.2241 7.0601 12.9341C7.02857 12.454 7.42736 12 8.00027 12Z"
            fill="#36CA3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00027 12H12.1541V10H8.00027C6.36404 10 4.95116 11.3408 5.0644 13.0651C5.22676 15.5376 5.69688 17.4785 6.46794 19.0916C7.24218 20.7114 8.29406 21.9465 9.54278 23.0375C10.729 24.0739 12.1163 24.9984 13.5993 25.9866C13.655 26.0237 13.7109 26.061 13.7669 26.0983C15.3285 27.1393 17.0329 28.2838 18.8697 29.7761L20.1309 28.2239C18.2141 26.6665 16.4402 25.4767 14.8762 24.4342C14.839 24.4094 14.802 24.3847 14.7651 24.3601C13.2343 23.3398 11.9446 22.4802 10.8587 21.5314C9.76445 20.5754 8.90346 19.5493 8.27239 18.2291C7.63813 16.9021 7.21049 15.2241 7.0601 12.9341C7.02857 12.454 7.42736 12 8.00027 12Z"
            fill="url(#paint5_linear_2285_18650)"
          />
          <path
            d="M37 5C37 12.1608 35.6304 19.0284 33.1924 24.0919C30.7544 29.1554 27.4478 32 24 32C20.5522 32 17.2456 29.1554 14.8076 24.0919C12.3696 19.0284 11 12.1609 11 5L24 5H37Z"
            fill="#C4C4C4"
          />
          <path
            d="M37 5C37 12.1608 35.6304 19.0284 33.1924 24.0919C30.7544 29.1554 27.4478 32 24 32C20.5522 32 17.2456 29.1554 14.8076 24.0919C12.3696 19.0284 11 12.1609 11 5L24 5H37Z"
            fill="url(#paint6_linear_2285_18650)"
          />
          <path
            d="M37 5C37 12.1608 35.6304 19.0284 33.1924 24.0919C30.7544 29.1554 27.4478 32 24 32C20.5522 32 17.2456 29.1554 14.8076 24.0919C12.3696 19.0284 11 12.1609 11 5L24 5H37Z"
            fill="url(#paint7_radial_2285_18650)"
          />
          <path
            d="M37 5C37 12.1608 35.6304 19.0284 33.1924 24.0919C30.7544 29.1554 27.4478 32 24 32C20.5522 32 17.2456 29.1554 14.8076 24.0919C12.3696 19.0284 11 12.1609 11 5L24 5H37Z"
            fill="url(#paint8_radial_2285_18650)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 4H12C10.8954 4 10 4.89543 10 6C10 7.10457 10.8954 8 12 8H24H36C37.1046 8 38 7.10457 38 6C38 4.89543 37.1046 4 36 4H24Z"
            fill="#C4C4C4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 4H12C10.8954 4 10 4.89543 10 6C10 7.10457 10.8954 8 12 8H24H36C37.1046 8 38 7.10457 38 6C38 4.89543 37.1046 4 36 4H24Z"
            fill="url(#paint9_linear_2285_18650)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 4H12C10.8954 4 10 4.89543 10 6C10 7.10457 10.8954 8 12 8H24H36C37.1046 8 38 7.10457 38 6C38 4.89543 37.1046 4 36 4H24Z"
            fill="url(#paint10_radial_2285_18650)"
            fillOpacity="0.5"
          />
          <rect x="19" y="40" width="10" height="3" fill="#C4C4C4" />
          <rect x="19" y="40" width="10" height="3" fill="url(#paint11_linear_2285_18650)" />
          <g filter="url(#filter1_i_2285_18650)">
            <path
              d="M23.0489 11.9271C23.3483 11.0057 24.6517 11.0057 24.9511 11.9271L25.7961 14.5279C25.93 14.9399 26.3139 15.2188 26.7472 15.2188H29.4818C30.4505 15.2188 30.8533 16.4585 30.0696 17.0279L27.8572 18.6353C27.5067 18.8899 27.3601 19.3413 27.494 19.7533L28.339 22.3541C28.6384 23.2754 27.5839 24.0415 26.8002 23.4721L24.5878 21.8647C24.2373 21.6101 23.7627 21.6101 23.4122 21.8647L21.1998 23.4721C20.4161 24.0415 19.3616 23.2754 19.661 22.3541L20.506 19.7533C20.6399 19.3413 20.4933 18.8899 20.1428 18.6353L17.9304 17.0279C17.1467 16.4585 17.5495 15.2188 18.5182 15.2188H21.2528C21.6861 15.2188 22.07 14.9399 22.2039 14.5279L23.0489 11.9271Z"
              fill="#0185F3"
            />
            <path
              d="M23.0489 11.9271C23.3483 11.0057 24.6517 11.0057 24.9511 11.9271L25.7961 14.5279C25.93 14.9399 26.3139 15.2188 26.7472 15.2188H29.4818C30.4505 15.2188 30.8533 16.4585 30.0696 17.0279L27.8572 18.6353C27.5067 18.8899 27.3601 19.3413 27.494 19.7533L28.339 22.3541C28.6384 23.2754 27.5839 24.0415 26.8002 23.4721L24.5878 21.8647C24.2373 21.6101 23.7627 21.6101 23.4122 21.8647L21.1998 23.4721C20.4161 24.0415 19.3616 23.2754 19.661 22.3541L20.506 19.7533C20.6399 19.3413 20.4933 18.8899 20.1428 18.6353L17.9304 17.0279C17.1467 16.4585 17.5495 15.2188 18.5182 15.2188H21.2528C21.6861 15.2188 22.07 14.9399 22.2039 14.5279L23.0489 11.9271Z"
              fill="url(#paint12_radial_2285_18650)"
            />
            <path
              d="M23.0489 11.9271C23.3483 11.0057 24.6517 11.0057 24.9511 11.9271L25.7961 14.5279C25.93 14.9399 26.3139 15.2188 26.7472 15.2188H29.4818C30.4505 15.2188 30.8533 16.4585 30.0696 17.0279L27.8572 18.6353C27.5067 18.8899 27.3601 19.3413 27.494 19.7533L28.339 22.3541C28.6384 23.2754 27.5839 24.0415 26.8002 23.4721L24.5878 21.8647C24.2373 21.6101 23.7627 21.6101 23.4122 21.8647L21.1998 23.4721C20.4161 24.0415 19.3616 23.2754 19.661 22.3541L20.506 19.7533C20.6399 19.3413 20.4933 18.8899 20.1428 18.6353L17.9304 17.0279C17.1467 16.4585 17.5495 15.2188 18.5182 15.2188H21.2528C21.6861 15.2188 22.07 14.9399 22.2039 14.5279L23.0489 11.9271Z"
              fill="url(#paint13_linear_2285_18650)"
            />
            <path
              d="M23.0489 11.9271C23.3483 11.0057 24.6517 11.0057 24.9511 11.9271L25.7961 14.5279C25.93 14.9399 26.3139 15.2188 26.7472 15.2188H29.4818C30.4505 15.2188 30.8533 16.4585 30.0696 17.0279L27.8572 18.6353C27.5067 18.8899 27.3601 19.3413 27.494 19.7533L28.339 22.3541C28.6384 23.2754 27.5839 24.0415 26.8002 23.4721L24.5878 21.8647C24.2373 21.6101 23.7627 21.6101 23.4122 21.8647L21.1998 23.4721C20.4161 24.0415 19.3616 23.2754 19.661 22.3541L20.506 19.7533C20.6399 19.3413 20.4933 18.8899 20.1428 18.6353L17.9304 17.0279C17.1467 16.4585 17.5495 15.2188 18.5182 15.2188H21.2528C21.6861 15.2188 22.07 14.9399 22.2039 14.5279L23.0489 11.9271Z"
              stroke="url(#paint14_linear_2285_18650)"
              strokeWidth="0.8"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_2285_18650"
          x="4.05762"
          y="3"
          width="38.8848"
          height="43"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2285_18650" />
        </filter>
        <filter
          id="filter1_i_2285_18650"
          x="15.1152"
          y="8.83594"
          width="15.7695"
          height="15.2344"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2285_18650" />
        </filter>
        <linearGradient
          id="paint0_linear_2285_18650"
          x1="17"
          y1="30"
          x2="22.2159"
          y2="44.5371"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.138653" stopColor="#FFED91" />
          <stop offset="0.991631" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_2285_18650"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.9825 40.9411) rotate(-89.9042) scale(10.4411 9.87393)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.740332" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.934727" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_2285_18650"
          x1="31"
          y1="44"
          x2="16.4179"
          y2="40.2849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#804F31" />
          <stop offset="1" stopColor="#E07A3C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2285_18650"
          x1="34"
          y1="47.5"
          x2="20.9725"
          y2="32.2634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#804F31" />
          <stop offset="1" stopColor="#E07A3C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2285_18650"
          x1="43.5"
          y1="17"
          x2="33"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2285_18650"
          x1="4.5"
          y1="17"
          x2="15"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2285_18650"
          x1="11"
          y1="-22"
          x2="52.5574"
          y2="7.28293"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.358355" stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_2285_18650"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.5 14) rotate(38.1572) scale(26.7067 26.4336)"
        >
          <stop offset="0.192708" stopColor="#FFED91" />
          <stop offset="0.574826" stopColor="#E5A749" />
          <stop offset="0.754059" stopColor="#CD6505" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_2285_18650"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.9622 31.7109) rotate(-90) scale(48 64.8523)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.451039" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.614179" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_2285_18650"
          x1="20.5"
          y1="5.22871e-08"
          x2="20.6742"
          y2="10.0057"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.222627" stopColor="#F5BC5D" />
          <stop offset="0.544001" stopColor="#F9E479" />
          <stop offset="0.73361" stopColor="#F5BC5D" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <radialGradient
          id="paint10_radial_2285_18650"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23 6) scale(19.5 5.81713)"
        >
          <stop offset="0.1875" stopColor="#FFED91" stopOpacity="0" />
          <stop offset="0.536216" stopColor="#E97C18" stopOpacity="0" />
          <stop offset="0.867768" stopColor="#E97C18" />
        </radialGradient>
        <linearGradient
          id="paint11_linear_2285_18650"
          x1="22"
          y1="38.5"
          x2="24"
          y2="44.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3B04F" />
          <stop offset="0.380306" stopColor="#FFED91" />
          <stop offset="1" stopColor="#F1A645" />
        </linearGradient>
        <radialGradient
          id="paint12_radial_2285_18650"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27 22.5) rotate(-120.7) scale(18.6078 17.1964)"
        >
          <stop offset="0.202512" stopColor="#E7AC4E" />
          <stop offset="0.651981" stopColor="#CD6505" />
        </radialGradient>
        <linearGradient
          id="paint13_linear_2285_18650"
          x1="15"
          y1="9"
          x2="28.7042"
          y2="29.0558"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.358355" stopColor="#FFED91" />
          <stop offset="1" stopColor="#E97C18" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_2285_18650"
          x1="29"
          y1="24"
          x2="19.5"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D37414" />
          <stop offset="1" stopColor="#F0BF62" />
        </linearGradient>
        <clipPath id="clip0_2285_18650">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export {
  IconApprentice,
  IconApprenticeComplete,
  IconBeginner,
  IconBeginnerComplete,
  IconGrandMaster,
  IconGrandMasterComplete,
  IconLegend,
  IconLegendComplete,
  IconSage,
  IconSageComplete,
  IconScholar,
  IconScholarComplete,
  IconTotalPoints,
  IconTotalPointsComplete,
};
