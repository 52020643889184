import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGivenPoint } from '../../../../store/selectors/studySelectors';
import { setChangingCourse } from '../../../../store/slices/userCourses';
import { closeModal } from '../../../../utils/modal';
import Button from '../../../atoms/Button';
import customStyles from './congratulation.module.scss';

const Congratulation: React.FC = () => {
  const dispatch = useDispatch();
  const point = useSelector(getGivenPoint);
  const cancelModal = () => {
    closeModal(dispatch);
    dispatch(setChangingCourse(''));
  };
  return (
    <div className={customStyles.completeLesson}>
      <div className={customStyles.image} />
      <h3>Congratulations!</h3>
      <h5>Keep going.</h5>
      <section className={customStyles.award}>
        <p>Dedication</p>
        <strong>+{point}</strong>
      </section>
      <Button type={'primary'} value={'Continue'} onClick={cancelModal} />
    </div>
  );
};

export default Congratulation;
