import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { IconHeaderLogout } from '../../../assets/imgComponent/IconsHeader';
import stylesHeader from '../../../components/AppHeader/header.module.scss';
import { logout } from '../../../utils/auth';

interface iLogoutProps {
  text?: string;
  customClass?: string;
}

const Logout: React.FC<iLogoutProps> = ({ text, customClass }) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    logout(dispatch);
  };

  return (
    <Link
      onClick={handleLogout}
      to="/login"
      className={`${stylesHeader.headerProfileLogout} ${customClass}`}
    >
      <IconHeaderLogout />
      {text && <span>{text}</span>}
    </Link>
  );
};

export default Logout;
