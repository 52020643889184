import React from 'react';

const DefaultUserPhoto: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 133 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_2_9112)">
        <circle cx="66.5" cy="66.5" r="60.5" fill="url(#paint0_linear_2_9112)" />
      </g>
      <g filter="url(#filter1_d_2_9112)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.4998 68.9722C78.4796 68.9722 88.1911 59.3526 88.1911 47.4861C88.1911 35.6197 78.4796 26 66.4998 26C54.52 26 44.8085 35.6197 44.8085 47.4861C44.8085 59.3526 54.52 68.9722 66.4998 68.9722ZM66.4999 74.0278C52.8403 74.0278 40.2251 78.4572 30.0384 85.9451C25.3912 89.3611 24.6042 95.8851 28.4645 100.156C37.8097 110.494 51.3879 117 66.5001 117C81.6122 117 95.1902 110.494 104.535 100.156C108.396 95.8853 107.609 89.3613 102.962 85.9453C92.7749 78.4573 80.1596 74.0278 66.4999 74.0278Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.4998 68.9722C78.4796 68.9722 88.1911 59.3526 88.1911 47.4861C88.1911 35.6197 78.4796 26 66.4998 26C54.52 26 44.8085 35.6197 44.8085 47.4861C44.8085 59.3526 54.52 68.9722 66.4998 68.9722ZM66.4999 74.0278C52.8403 74.0278 40.2251 78.4572 30.0384 85.9451C25.3912 89.3611 24.6042 95.8851 28.4645 100.156C37.8097 110.494 51.3879 117 66.5001 117C81.6122 117 95.1902 110.494 104.535 100.156C108.396 95.8853 107.609 89.3613 102.962 85.9453C92.7749 78.4573 80.1596 74.0278 66.4999 74.0278Z"
          fill="url(#paint1_linear_2_9112)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2_9112"
          x="4"
          y="4"
          width="123"
          height="123"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_9112" />
        </filter>
        <filter
          id="filter1_d_2_9112"
          x="24"
          y="24"
          width="89"
          height="99"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.295833 0 0 0 0 0.295833 0 0 0 0 0.295833 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_9112" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2_9112"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_9112"
          x1="6"
          y1="6"
          x2="127"
          y2="127"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12876" stopColor="#51FFD5" />
          <stop offset="0.861892" stopColor="#0B58CC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_9112"
          x1="66.5"
          y1="26"
          x2="66.5"
          y2="117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DefaultUserPhoto;
