import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AudioSound from '../../features/atoms/AudioSound';
import $api from '../../Service/api/intercepter';
import { getKanjiesData } from '../../store/selectors/studySelectors';
import { setKanjiesData } from '../../store/slices/study';
import Loader from '../Loader';
import styles from './popup.module.scss';

interface IKanjiPopup {
  character: string;
  positionX: number;
  positionY: number;
}

interface IKanjiWordData {
  audio: string;
  id: string;
  meanings: string;
  pronunciation: string;
  spelling: string;
}

export interface IKanjiData {
  description: string;
  english: string;
  id: string;
  reading: Array<string>;
  words: Array<IKanjiWordData>;
}

const kanjiInitialData = {
  description: '',
  english: '',
  id: '',
  reading: [],
  words: [],
};

const KanjiTooltip: React.FC<IKanjiPopup> = ({ character, positionX, positionY }) => {
  const dispatch = useDispatch();

  const [kanjiData, setKanjiData] = useState<IKanjiData>(kanjiInitialData);
  const kanjiesDataInStore = useSelector(getKanjiesData);

  useEffect(() => {
    setKanjiData(kanjiInitialData);
    if (kanjiesDataInStore[character]) {
      setKanjiData(kanjiesDataInStore[character]);
    } else {
      $api.get(`/courses/jp/script/${character}`).then(({ data }) => {
        setKanjiData({ ...data });
        dispatch(
          setKanjiesData({
            [character]: data,
          })
        );
      });
    }
  }, [character]);

  return (
    <div className={styles.toolTipMainPanel} style={{ top: positionY + 8 }}>
      <div className={styles.triangle} style={{ left: positionX - 25 }} />
      <div className={styles.triangleAfter} style={{ left: positionX - 25 }} />
      <div className={styles.toolTipMain}>
        {kanjiData.id ? (
          <div className={styles.toolTipContent} onClick={(e) => e.stopPropagation()}>
            <div className={styles.contentInner}>
              <div className={styles.contentDescription}>
                <div className={styles.contentHeadline}>
                  <h3>{kanjiData.english}</h3>
                </div>
                <div className={styles.contentDescriptionTxt}>
                  <p dangerouslySetInnerHTML={{ __html: kanjiData.description }}></p>
                </div>
              </div>
              <div className={styles.contentReading}>
                {kanjiData.reading.map((chr, index) => {
                  if (chr) {
                    return <span key={index}>{chr}</span>;
                  }
                })}
              </div>
              <div className={styles.contentWords}>
                {kanjiData.words.map(({ id, pronunciation, spelling, audio, meanings }) => {
                  return (
                    <div key={id} className={styles.wordLine}>
                      <p>{spelling}</p>
                      <p>{pronunciation}</p>
                      <p>{meanings}</p>
                      <div className={styles.audioButton}>
                        <AudioSound sound={audio} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <Loader color={'white'} />
        )}
      </div>
    </div>
  );
};

export default memo(KanjiTooltip);
