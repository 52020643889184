import React from 'react';

import { IBackendDataContent, ITableItem } from '../../../../types/study';
import styles from './table.module.scss';

interface ITextComponentProps {
  content: IBackendDataContent;
}

const TableComponent: React.FC<ITextComponentProps> = ({ content }) => {
  const getData = (): Array<any> => {
    const data = [];
    for (let i = 0; i < content.tableData[0].length; i++) {
      const asd: Array<any> = [];
      content.tableData.forEach((item) => {
        asd.push(item[i]);
      });
      data.push(asd);
    }
    return data;
  };
  return (
    <table className={styles.table}>
      <tbody>
        {getData().map((item: Array<ITableItem>, index) => {
          return (
            <tr key={index}>
              <>
                {item.map((el, i) => {
                  return (
                    <td
                      className={styles.td}
                      dangerouslySetInnerHTML={{ __html: el.text }}
                      key={i}
                    />
                  );
                })}
              </>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableComponent;
