import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BillingSecureIcon from '../../../../assets/imgComponent/BillingSecureIcon';
import {
  IconCardAmex,
  IconCardMC,
  IconCardVisa,
} from '../../../../assets/imgComponent/IconPaymentCards';
import Loader from '../../../../components/Loader';
import { CURRENCY } from '../../../../constants/billing';
import { usePlanData } from '../../../../hooks/usePlanData';
import $api from '../../../../Service/api/intercepter';
import {
  getPriceDifference,
  getUserSubscription,
  getUserTrial,
} from '../../../../store/selectors/authSelectors';
import { getCurrentPayment } from '../../../../store/selectors/modalSelectors';
import { setPriceDifference, setSubscription } from '../../../../store/slices/auth';
import { setNotification } from '../../../../store/slices/notification';
import { IBillingItem } from '../../../../types/billing';
import { IPriceDifference } from '../../../../types/user';
import Button from '../../../atoms/Button';
import styles from './billing_modal.module.scss';

const BillingModal: React.FC = () => {
  const dispatch = useDispatch();
  const { month, id } = useSelector(getCurrentPayment);
  const { isSubscriptionActive } = useSelector(getUserSubscription);
  const trial = useSelector(getUserTrial);
  const planData = usePlanData();

  const priceDifference: Partial<IPriceDifference> = useSelector(getPriceDifference);
  const [priceData, setPriceData] = useState<IBillingItem | undefined>();
  const monthText = month > 1 ? `${month} months` : 'month';
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(Boolean(!priceData));
  }, [priceData]);

  useEffect(() => {
    if (month && planData) {
      setPriceData(planData.find((el) => el.month === month));
    }
  }, [month, planData]);

  const modalText = useMemo(() => {
    if (isSubscriptionActive) {
      if (priceDifference?.amount) {
        if (priceDifference.pay) {
          return `As of today, you will be charged ${CURRENCY[priceDifference.currency || 'usd']}${
            priceDifference?.amount / 100
          } immediately, and then we will charge ${
            priceData?.price
          } again every ${monthText}, according to your new plan. You can cancel it at any time.`;
        } else {
          return `As of today, ${CURRENCY[priceDifference.currency || 'usd']}${Math.abs(
            priceDifference?.amount / 100
          )} will be added to your Stripe balance, which will be used during your next payment. We will charge ${
            priceData?.price
          } again every ${monthText}, according to your new plan. You can cancel it at any time.`;
        }
      }
    }
    return `We will charge your card ${priceData?.price} immediately for your next ${monthText}, and then again every ${monthText}. You can cancel at any time.`;
  }, [priceDifference.pay, priceDifference.amount, isSubscriptionActive, trial, priceData]);

  const subscribe = () => {
    setIsLoading(true);

    $api
      .post(
        isSubscriptionActive
          ? `/subscriptions/change-plan/${id}`
          : `subscriptions/checkout-session/${id}`,
        {
          success_url: `${window.location.href}?success=true`,
          cancel_url: `${window.location.href}`,
        }
      )
      .then(({ data: { data } }) => {
        if (data.url) {
          window.location.replace(data.url);
        } else {
          dispatch(
            setSubscription({
              planId: id,
              currency: 'usd',
              currentPeriodEnd: data.current_period_end * 1000,
              isSubscriptionWillExpired: data.cancel_at_period_end,
              isSubscriptionActive: true,
            })
          );
          navigate('/billing?success=true');
        }
      })
      .catch((err) => {
        dispatch(
          setNotification({
            message: err?.response?.data?.error?.message || 'Something went wrong',
            type: 'error',
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setPriceDifference({}));
      });
  };
  const ComponentIcon = useMemo(() => {
    if (priceData) {
      return priceData.Icon;
    }
  }, [priceData]);

  return (
    <div className={styles.billingModal}>
      <div className={styles.billingPlansItem}>
        {!!ComponentIcon && <ComponentIcon />}
        <div className={styles.billingPlansItemContent}>
          <h2>Billed every {priceData?.title}</h2>
          <p>
            {priceData?.price} / {priceData?.title}
          </p>
          <span>{priceData?.perMonthPrice} per month</span>
        </div>
      </div>
      <section
        className={styles.billingImages}
        style={{
          visibility: isLoading ? 'hidden' : 'visible',
        }}
      >
        <BillingSecureIcon />
        <p>Secured by Stripe</p>
        <div className={styles.billingImagesCards}>
          <IconCardVisa />
          <IconCardMC />
          <IconCardAmex />
        </div>
      </section>
      <p>{modalText}</p>
      <Button type={'primary'} value={'Subscribe'} onClick={subscribe} />
      <a
        className={styles.billingPrivacy}
        href="https://www.lingualift.com/privacy-policy/"
        target={'_blank'}
        rel="noreferrer"
      >
        Terms of Use and Privacy Policy
      </a>
      {(isLoading || (trial === 999 && !Object.keys(priceDifference).length)) && (
        <div className={styles.loader}>
          <Loader parentHeight={400} />
        </div>
      )}
    </div>
  );
};

export default BillingModal;
