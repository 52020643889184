import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AppHeader from '../../../../components/AppHeader';
import Loader from '../../../../components/Loader';
import { SIGN_UP_INITIAL } from '../../../../constants/authFLow';
import { useAllowSubmit } from '../../../../hooks/useAllowSubmitForm';
import { getAuthLoading } from '../../../../store/selectors/authSelectors';
import { registration } from '../../../../store/slices/auth';
import { handleSetFieldTouched, signUpValidationScheme } from '../../../../utils/authValidation';
import Input from '../../../atoms/Input';
import AuthView from '../../../organisms/AuthViews';

const SignUp: React.FC = () => {
  const [showPass, setShowPass] = useState<boolean>(false);
  const [isPoliceChecked, setIsPoliceChecked] = useState<boolean>(false);
  const isLoading = useSelector(getAuthLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      ...SIGN_UP_INITIAL,
    },
    validationSchema: signUpValidationScheme,
    onSubmit: () => {
      if (isPoliceChecked) {
        dispatch(registration(formik.values, navigate));
      }
    },
  });

  const allowSubmit = useAllowSubmit(formik, SIGN_UP_INITIAL);

  return (
    <>
      {isLoading ? (
        <>
          <AppHeader />
          <Loader />
        </>
      ) : (
        <AuthView
          type="sign up"
          submitHandler={formik.handleSubmit}
          allowSubmit={allowSubmit && isPoliceChecked}
          headerText="Welcome to LingualLift!"
          bottomText="Already have an account? "
          bottomLinkedText={{ href: 'login', title: 'Log In here!' }}
          buttonText="Continue"
          setIsPoliceChecked={setIsPoliceChecked}
        >
          <Input
            htmlFor="firstName"
            type="text"
            name="firstName"
            placeHolder="Enter your Username"
            label="Username"
            onBlur={(e: any) => handleSetFieldTouched(e, formik)}
            error={formik.touched.firstName && formik.values.firstName && formik.errors.firstName}
            onChange={(e) => {
              formik.setFieldTouched(e.target.name, false);
              formik.handleChange(e);
            }}
            value={formik.values.firstName}
            onClick={formik.setFieldTouched}
          />
          <Input
            htmlFor="email"
            type="text"
            name="email"
            placeHolder="Enter your email"
            label="Email"
            onBlur={(e: any) => handleSetFieldTouched(e, formik)}
            error={formik.touched.email && formik.values.email && formik.errors.email}
            onChange={(e) => {
              formik.setFieldTouched(e.target.name, false);
              formik.handleChange(e);
            }}
            value={formik.values.email}
            onClick={formik.setFieldTouched}
          />
          <Input
            htmlFor="password"
            type="text"
            isPassInput
            name="password"
            placeHolder="Set up password (min 8 characters)"
            label="Password"
            onClick={formik.setFieldTouched}
            onBlur={(e: any) => handleSetFieldTouched(e, formik)}
            error={formik.touched.password && formik.values.password && formik.errors.password}
            onChange={(e) => {
              formik.setFieldTouched(e.target.name, false);
              formik.handleChange(e);
            }}
            passShowMode={showPass}
            setPassShowMode={setShowPass}
            value={formik.values.password}
          />
        </AuthView>
      )}
    </>
  );
};

export default SignUp;
