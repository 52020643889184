import {
  Create,
  FlowerIcon,
  IconLevel01,
  IconLevel02,
  IconLevel03,
  IconLevel04,
  IconLevel05,
  IconLevel06,
  LightIcon,
  PersonIcon,
  Warning,
} from '../assets/imgComponent/IconsStudy';
import Assessment from '../components/StudyComponents/StudyPage/Assessment';
import BlankTest from '../components/StudyComponents/StudyPage/BlankTest';
import Script from '../components/StudyComponents/StudyPage/Script';
import Vocabulary from '../components/StudyComponents/StudyPage/Vocabulary';
import AudioComponent from '../features/moleculs/BackendComponents/AudioComponent';
import BoxComponent from '../features/moleculs/BackendComponents/BoxComponent';
import ButtonComponent from '../features/moleculs/BackendComponents/ButtonComponent';
import ImageComponent from '../features/moleculs/BackendComponents/ImageComponent';
import TableComponent from '../features/moleculs/BackendComponents/TableComponent';
import TextComponent from '../features/moleculs/BackendComponents/TextComponent';
import VideoComponent from '../features/moleculs/BackendComponents/VideoComponent';

export const LEVELS_ICONS: { [key: string]: any } = {
  brightness_auto: IconLevel01,
  place: IconLevel02,
  chat_bubble_outline: IconLevel03,
  mic: IconLevel04,
  color_lens: IconLevel05,
  business_center: IconLevel06,
  person: PersonIcon,
  warning: Warning,
  create: Create,
  local_florist: FlowerIcon,
  lightbulb_outline: LightIcon,
};

export const STUDY_COMPONENTS: { [key: string]: any } = {
  Primer: BlankTest,
  Assessment: Assessment,
  'Like a local': BlankTest,
  Vocabulary: Vocabulary,
  Listening: BlankTest,
  Cake: BlankTest,
  Script: Script,
};

export const BACKEND_COMPONENTS: { [key: string]: any } = {
  medium: TextComponent,
  box: BoxComponent,
  image: ImageComponent,
  'example-sentence': AudioComponent,
  button: ButtonComponent,
  table: TableComponent,
  video: VideoComponent,
};

export const TEST_ITEM_DATA_INITIAL = {
  _id: '',
  answer: '',
  course: '',
  hint: '',
  key: '',
  options: [],
  task: '',
};

export const KANJI_TOOLTIP_MARGIN_X = 7;
export const KANJI_TOOLTIP_MARGIN_Y = 5;
