import React from 'react';

const IconInfo: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 16.5C10.4834 16.5 11.9334 16.0601 13.1668 15.236C14.4001 14.4119 15.3614 13.2406 15.9291 11.8701C16.4968 10.4997 16.6453 8.99168 16.3559 7.53683C16.0665 6.08197 15.3522 4.7456 14.3033 3.6967C13.2544 2.64781 11.918 1.9335 10.4632 1.64411C9.00832 1.35472 7.50032 1.50325 6.12987 2.07091C4.75943 2.63856 3.58809 3.59986 2.76398 4.83323C1.93987 6.0666 1.5 7.51664 1.5 9C1.50574 10.9874 2.29776 12.8917 3.70304 14.297C5.10832 15.7022 7.01264 16.4943 9 16.5V16.5Z"
        stroke="#0185F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99854 12V8.25"
        stroke="#0185F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00244 6H8.99569"
        stroke="#0185F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconInfo;
