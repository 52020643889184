import React from 'react';

const IconCheckedGreen: React.FC = () => {
  return (
    <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_308_40649)">
        <circle cx="21" cy="21.5" r="21" fill="url(#paint0_linear_308_40649)" />
      </g>
      <g filter="url(#filter1_d_308_40649)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.8123 15.0613C32.9513 16.2003 32.9513 18.0471 31.8123 19.1861L21.2623 29.7361C19.6676 31.3308 17.0822 31.3308 15.4876 29.7361L10.1875 24.4361C9.04849 23.2971 9.04849 21.4503 10.1875 20.3113C11.3266 19.1723 13.1733 19.1723 14.3123 20.3113L18.3749 24.3739L27.6875 15.0613C28.8266 13.9223 30.6733 13.9223 31.8123 15.0613Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.8123 15.0613C32.9513 16.2003 32.9513 18.0471 31.8123 19.1861L21.2623 29.7361C19.6676 31.3308 17.0822 31.3308 15.4876 29.7361L10.1875 24.4361C9.04849 23.2971 9.04849 21.4503 10.1875 20.3113C11.3266 19.1723 13.1733 19.1723 14.3123 20.3113L18.3749 24.3739L27.6875 15.0613C28.8266 13.9223 30.6733 13.9223 31.8123 15.0613Z"
          fill="url(#paint1_linear_308_40649)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_308_40649"
          x="-2"
          y="-1.5"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_40649" />
        </filter>
        <filter
          id="filter1_d_308_40649"
          x="7.33325"
          y="12.207"
          width="31.3333"
          height="24.7266"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_40649" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_308_40649"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_308_40649"
          x1="0"
          y1="0.5"
          x2="42"
          y2="42.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4CFAA6" />
          <stop offset="1" stopColor="#60AC00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_308_40649"
          x1="20.9999"
          y1="14.207"
          x2="20.9999"
          y2="30.9321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconCheckedGreen;
