import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import $api from '../Service/api/intercepter';
import authReducer from '../store/slices/auth';
import billing from './slices/billing';
import modalReducer from './slices/modal';
import notificationReducer from './slices/notification';
import placementTest from './slices/placementTest';
import review from './slices/review';
import study from './slices/study';
import userCourses from './slices/userCourses';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    notification: notificationReducer,
    modal: modalReducer,
    userCourses: userCourses,
    study: study,
    placementTest: placementTest,
    review: review,
    billing: billing,
  },
});

$api.store = store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
