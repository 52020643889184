import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FORGOT_INITIAL } from '../../../../constants/authFLow';
import { useAllowSubmit } from '../../../../hooks/useAllowSubmitForm';
import { resetPassword, setIsEmailSend } from '../../../../store/slices/auth';
import { ForgotValidationScheme, handleSetFieldTouched } from '../../../../utils/authValidation';
import Input from '../../../atoms/Input';
import AuthView from '../../../organisms/AuthViews';

const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...FORGOT_INITIAL,
    },
    validationSchema: ForgotValidationScheme,
    onSubmit: (values: any) => {
      dispatch(resetPassword(values.email));
    },
  });
  const allowSubmit = useAllowSubmit(formik, FORGOT_INITIAL);

  useEffect(() => {
    dispatch(setIsEmailSend(false));
  }, []);

  return (
    <AuthView
      type="forgot"
      submitHandler={formik.handleSubmit}
      allowSubmit={allowSubmit}
      headerText="Forgot password?"
      buttonText="Continue"
      email={formik.values.email}
    >
      <Input
        htmlFor="email"
        type="text"
        name="email"
        placeHolder="Enter your email"
        label="Email"
        onBlur={(e: any) => handleSetFieldTouched(e, formik)}
        error={formik.touched.email && formik.values.email && formik.errors.email}
        onChange={(e) => {
          formik.setFieldTouched(e.target.name, false);
          formik.handleChange(e);
        }}
        value={formik.values.email}
        onClick={formik.setFieldTouched}
      />
    </AuthView>
  );
};

export default ForgotPassword;
