import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUserCourses } from '../../../../hooks/useUserCourses';
import { getChangingCourse, getUserCourses } from '../../../../store/selectors/courseSelectors';
import { getLanguageDataFromStore } from '../../../../store/selectors/studySelectors';
import { removeStudyData } from '../../../../store/slices/study';
import {
  removeCourse,
  setChangingCourse,
  updateCurrentCourse,
} from '../../../../store/slices/userCourses';
import { closeModal } from '../../../../utils/modal';
import StepButton from '../../../atoms/StepButton';
import styles from '../../../organisms/AppModal/modal.module.scss';

const DeleteCourse: React.FC = () => {
  const dispatch = useDispatch();
  const { pickedOutCourses, currentCourse } = useUserCourses();
  const deletingCourse = useSelector(getChangingCourse);
  const userCourses = useSelector(getUserCourses);
  const languageData = useSelector(getLanguageDataFromStore);

  const cancelModal = () => {
    closeModal(dispatch);
    dispatch(setChangingCourse(''));
  };

  const handleDelete = () => {
    dispatch(removeCourse(deletingCourse, userCourses));
    if (currentCourse === deletingCourse) {
      const index = pickedOutCourses.indexOf(deletingCourse);
      const isElementIsLast = index === pickedOutCourses.length - 1;
      dispatch(
        updateCurrentCourse(isElementIsLast ? pickedOutCourses[0] : pickedOutCourses[index + 1])
      );
      dispatch(removeStudyData());
    }
  };

  return (
    <div className={styles.profileCoursesModal}>
      <p>
        Are you sure you want to delete your
        {languageData && <strong> {languageData[deletingCourse]?.name} </strong>}
        course? All your progress in this course will be deleted.
      </p>
      <div className={styles.profileCoursesModalButtons}>
        <StepButton text="Cancel" onHandleClick={cancelModal} />
        <StepButton text="Delete" onHandleClick={handleDelete} />
      </div>
    </div>
  );
};

export default DeleteCourse;
