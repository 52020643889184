import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserStatistics } from '../store/selectors/authSelectors';
import { IStatisticsItem } from '../types/study';
import { getUserStatisticsData } from '../utils/auth';

export const useStatistics = () => {
  const userStatistics = useSelector(getUserStatistics);
  const [statistics, setStatistics] = useState<Array<IStatisticsItem>>([]);
  const [reviewStatistics, setReviewStatistics] = useState<Array<IStatisticsItem>>([]);

  useEffect(() => {
    if (Object.keys(userStatistics).length) {
      const data = getUserStatisticsData(userStatistics);
      setStatistics(data);
      const filteredStatistics = data.filter((item) => {
        return item.text === 'Assessment' || item.text === 'Vocabulary' || item.text === 'Script';
      });
      setReviewStatistics(filteredStatistics);
    }
  }, [userStatistics]);

  return {
    statistics,
    points: userStatistics.points,
    reviewStatistics,
  };
};
