import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { REVIEW_LIMIT } from '../../constants/review';
import $api from '../../Service/api/intercepter';
import { store } from '../store';
import { IReview, IReviewResponseData } from '../types';

const initialState: IReview = {
  questionData: null,
  reviewStatistics: {},
  isLoading: false,
};

export const modalSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    setQuestionData: (state, action) => {
      return {
        ...state,
        questionData: action.payload,
      };
    },
    setReviewStatisticsData: (state, action) => {
      return {
        ...state,
        reviewStatistics: action.payload,
      };
    },
    setIsLoading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

export const getReviewSkillsData =
  (locale: string) => (dispatch: Dispatch, getState: typeof store.getState) => {
    dispatch(setIsLoading(true));
    $api
      .get(`/reviews/examine/${locale}?limit=${REVIEW_LIMIT}`)
      .then((response) => {
        const reviewData: IReviewResponseData = response.data;
        const questionsData = reviewData.list.map((data) => {
          return {
            ...data.activity,
            ...data.quiz,
            activityId: data.activity.id,
            quizId: data.quiz.id,
          };
        });
        const prevData = getState().review.questionData;

        if (prevData) {
          dispatch(setQuestionData([...prevData, ...questionsData]));
        } else {
          dispatch(setQuestionData([...questionsData]));
          dispatch(setReviewStatisticsData(reviewData.summary));
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

export const setLastReviewData = () => () => {
  $api.post('/reviews/set-last-review-date');
};

export const { setQuestionData, setReviewStatisticsData, setIsLoading } = modalSlice.actions;

export default modalSlice.reducer;
