import React from 'react';

import styles from './button.module.scss';

interface IButtonProps {
  type: 'primary' | 'secondary';
  size?: 's' | 'l' | 'xl';
  customClass?: string;
  submit?: boolean;
  disabled?: boolean;
  value: string;
  onClick?: any;
}

const Button: React.FC<IButtonProps> = ({
  type,
  size = 'l',
  customClass = '',
  submit = false,
  disabled = false,
  value,
  onClick,
}) => {
  const buttonType = submit ? 'submit' : 'button';

  return (
    <button
      onClick={onClick && onClick}
      type={buttonType}
      className={`${styles[type]} ${styles[size]} ${styles.btn} ${styles[customClass]} ${
        disabled ? styles.disableBtn : ''
      }`}
    >
      {value}
    </button>
  );
};

export default Button;
