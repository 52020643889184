import React from 'react';

import PlanCards from '../../../../components/ProfileComponents/BillingPage/PlanCards';
import styles from '../../../organisms/AppModal/modal.module.scss';

const UpdatePlan: React.FC = () => {
  return (
    <div className={styles.profileCoursesModal}>
      <PlanCards isModal={true} itemClassName={'billingPlansModalItem'} />
    </div>
  );
};

export default UpdatePlan;
