import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { HEADER_ITEMS } from '../../constants/header';
import stylesHeader from './header.module.scss';

const HeaderMenu: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <div className={stylesHeader.headerProfileItems}>
      {HEADER_ITEMS.map(({ text, url, Icon, ActiveIcon }) => {
        const isActive = pathname === url;
        return (
          <Link key={text} to={url}>
            {isActive ? <ActiveIcon /> : <Icon />}

            <p className={isActive ? stylesHeader.activePage : ''}>{text}</p>
          </Link>
        );
      })}
    </div>
  );
};

export default HeaderMenu;
