import React from 'react';
import { useDispatch } from 'react-redux';

import { closeModal } from '../../../../utils/modal';
import Button from '../../../atoms/Button';
import customStyles from '../Congratulation/congratulation.module.scss';

const SuccessfulCancellation: React.FC = () => {
  const dispatch = useDispatch();
  const cancelModal = () => {
    closeModal(dispatch);
  };
  return (
    <div className={customStyles.completeLesson}>
      <div className={customStyles.image} />
      <h3>Successful Cancellation!</h3>
      <Button type={'primary'} value={'Continue'} onClick={cancelModal} />
    </div>
  );
};

export default SuccessfulCancellation;
