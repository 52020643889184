import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getActivitiesDataFromStore,
  getCurrentActivity,
} from '../../../../store/selectors/studySelectors';
import { ICurrentActivity, IVocabItemData } from '../../../../types/study';
import Loader from '../../../Loader';
import VocabularyPrologue from './VocabularyPrologue';
import VocabularyQuiz from './VocabularyQuiz';

const Vocabulary: React.FC = () => {
  const { id }: ICurrentActivity = useSelector(getCurrentActivity);
  const activities = useSelector(getActivitiesDataFromStore);
  const [data, setData] = useState<Array<IVocabItemData>>([]);
  const [stepsCount, setStepsCount] = useState<number>(0);
  const [isQuizStarted, setIsQuizStarted] = useState<boolean>(false);

  useEffect(() => {
    if (!data.length && id && activities[id]) {
      const value = activities[id]['vocab_list'];
      setStepsCount(value.length);
      setData(value);
    }
  }, [id, activities]);

  return (
    <>
      {data.length ? (
        isQuizStarted ? (
          <VocabularyQuiz data={data} stepsCount={stepsCount} />
        ) : (
          <VocabularyPrologue data={data} setIsQuizStarted={setIsQuizStarted} />
        )
      ) : (
        <Loader parentHeight={500} />
      )}
    </>
  );
};

export default Vocabulary;
