import React from 'react';

const CloseIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 5L5 19" stroke="#97B8CB" strokeWidth="2" strokeLinecap="round" />
      <path d="M19 19L5 5" stroke="#97B8CB" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default CloseIcon;
